import React, { useEffect } from 'react';
import { Row, Col,} from 'reactstrap';
import { Field, reduxForm} from 'redux-form';
import CloseIcon from '../../assets/Images/icons/close.png';
import _ from 'lodash';
import {
  renderReactSelectInputField,
  renderTextField,
  renderImageField,
  renderFileField,
} from '../../Components/common/renderInputField';
import Button from 'reactstrap-button-loader';
import { t } from 'react-multi-lang';
import DraftEditor from './DraftEditor';
import EventTimePicker from './TimePicker';
const CreateNewsForm = props => {
  const {
    isNewsFeedAddRequesting,
    handleUpdate,
    fileHandler,
    uploadFileHandler,
    closeRightBar,
    handleCategoryChange,
    handleSubCategoryChange,
    handleTitle,
    handleDescription,
    handleLink,
    handleSubmit,
    propsData,
    dateHandler,
    timeHandler,
    locationHandler,
    publishHandler,
    reset,
    fromListing,
    setFromListing,
  } = props;

  // const [validLink, setValidLink] = useState(true);
  useEffect(() => {
    if (propsData?.formReset) {
      reset();
    }
  }, [propsData?.formReset]);

  const CATEGORY = 'category';
  const HIDE_CATEGORIES = {
    IWMI_NEWS: 'iwmi_news',
    EXTERNAL_NEWS: 'external_news',
    RESOURCES: 'resources',
    EVENTS: 'events',
  };

  const renderAPIParameterOptions = (list, action_name) => {
    if (list) {
      let params = [...list];
      let options = [];
      if (params?.length) {
        params?.map(param => {
          let data = {
            value: param.name,
            label: `${param.name}`,
            id: param.id,
          };
          if (action_name === CATEGORY) {
            data.slug = param?.slug;
          }
          options.push(data);
          return true;
        });
        return options;
      }
    }
  };

  const handleFileChange = e => {
    if (e?.target?.value) {
      let fileExtension = e.target.value.split('.').pop();
      fileHandler(e.target.files[0], fileExtension);
    }
  };

  const handleUploadFileChange = e => {
    if (e?.target?.files) {
      uploadFileHandler(e.target.files[0]);
    }
  };

  const categoryChangeHandler = data => {
    //TO DO
    handleCategoryChange(data);
  };

  const handleStartDate = e => {
    dateHandler(e.target.value);
  };

  const handleTimeData = value => {
    timeHandler(value);
  };
  const handleLocation = e => {
    locationHandler(e.target.value);
  };

  const handlePublishStatus = e => {
    publishHandler(e.target.checked);
  };

  const subCategoryChangeHandler = data => {
    //TO DO
    handleSubCategoryChange(data);
  };

  const titleHandler = e => {
    if (e?.target) {
      handleTitle(e.target.value);
    }
  };

  const linkHandler = e => {
    if (e?.target) handleLink(e.target.value);
  };

  const onFormSubmit = e => {
    e.preventDefault();
    handleSubmit();
  };

  const updateHandler = () => {
    //TO DO
    handleUpdate();
    setFromListing();
  };
  return (
    <>
      <div className="st-right-sidebar-wrapper create-news-form">
        <div className="d-flex st-filter-header">
          {fromListing ? (
            <h5 className="st-txt-primary">{t('NEWS_FEED.MODIFIED_NEWS')}</h5>
          ) : (
            <h5 className="st-txt-primary">{t('NEWS_FEED.ADD_NEWS')}</h5>
          )}
          <div className="st-close ml-auto">
            <img
              className="st-close-img"
              alt={'not-available'}
              src={CloseIcon}
              onClick={() => {
                closeRightBar();
              }}
            />
          </div>
        </div>
        <div className="add-news-feed-div">
          <form id="news-add-form" onSubmit={e => onFormSubmit(e)}>
            <div className="static-resource-filter-field add-news-scroll">
              <Field
                component={renderReactSelectInputField}
                name="category"
                placeholder={t('NEWS_FEED.SELECT_CATEGORY')}
                className="my-3 filter-select-placeholder"
                onChange={e => categoryChangeHandler(e)}
                required={true}
                isMulti={false}
                options={renderAPIParameterOptions(propsData?.categoryList, CATEGORY)}
                isDisabled={fromListing}
              ></Field>
              {(propsData?.subCategoryList?.length !== 0 || props?.initialValues?.type?.id) &&
                !_.isEmpty(propsData?.selectedCategory) &&
                propsData?.selectedCategory !== HIDE_CATEGORIES.EXTERNAL_NEWS && (
                  <Field
                    component={renderReactSelectInputField}
                    name="type"
                    placeholder={t('NEWS_FEED.SELECT_TYPE')}
                    className="my-3 filter-select-placeholder"
                    onChange={e => subCategoryChangeHandler(e)}
                    required={true}
                    isMulti={false}
                    options={renderAPIParameterOptions(propsData?.subCategoryList)}
                  ></Field>
                )}

              {propsData?.selectedCategory === HIDE_CATEGORIES.EVENTS && (
                <>
                  <Field
                    component={renderTextField}
                    name="start_date"
                    bsSize="sm"
                    className="mt-1 my-3 input-style filter-select-placeholder news-feed-date"
                    placeholder="Select Start date"
                    type="date"
                    isMulti={false}
                    onChange={e => handleStartDate(e)}
                  ></Field>
                  <Field
                    component={EventTimePicker}
                    name="event_time"
                    bsSize="sm"
                    className="mt-1 my-3 input-style filter-select-placeholder news-feed-date"
                    placeholder="event time"
                    time={propsData?.time}
                    timeChange={handleTimeData}
                  ></Field>
                  <Field
                    component={renderTextField}
                    name="location"
                    bsSize="sm"
                    className="mt-1 my-3 input-style filter-select-placeholder news-feed-date"
                    placeholder="Location"
                    isMulti={false}
                    onChange={e => handleLocation(e)}
                  ></Field>
                </>
              )}

              <Field
                component={renderTextField}
                name="title"
                placeholder={t('NEWS_FEED.SELECT_TITLE')}
                bsSize="sm"
                className=" my-3 filter-select-placeholder"
                onChange={e => titleHandler(e)}
                required={true}
                isMulti={false}
              ></Field>
              <Field
                component={DraftEditor}
                name="description"
                className="mt-1 my-3 filter-select-placeholder"
                onEditorChange={handleDescription}
                required={false}
                type="textarea"
                isMulti={false}
                content={propsData?.content}
                placeholder={t('NEWS_FEED.ADD_DESCRIPTION')}
              ></Field>
              {propsData?.isResourceSelected && (
                <>
                  <Field
                    component={renderFileField}
                    name="upload_file"
                    type="file"
                    id="upload_file"
                    accept="application/pdf"
                    className="my-3 mt-1 filter-select-placeholder"
                    onChange={e => handleUploadFileChange(e)}
                    isMulti={false}
                    placeholder="upload-file"
                  ></Field>
                  {propsData?.isResourceSelected && propsData?.uploadedFile && (
                    <div className="upload-file-name">
                      {t('NEWS_FEED.FILE_NAME')}:
                      <a href={propsData?.uploadedFile} target="_blank" rel="noreferrer">
                        {propsData?.uploadedFile}
                      </a>
                    </div>
                  )}
                </>
              )}
              {propsData?.selectedCategory !== HIDE_CATEGORIES.EXTERNAL_NEWS && (
                <>
                  <Field
                    component={renderImageField}
                    name="upload_image"
                    id="upload_image"
                    type="file"
                    accept="image/*"
                    className="my-3 mt-2 filter-select-placeholder upload-image-field"
                    onChange={e => handleFileChange(e)}
                    isMulti={false}
                    placeholder={t('NEWS_FEED.UPLOAD_IMAGE')}
                  ></Field>
                </>
              )}

              {propsData?.imageFile && (
                <Row>
                  <Col lg="6" className="mx-auto">
                    <div className={propsData?.imageFile === undefined ? '' : 'mb-5 news-image'}>
                      {propsData?.fileType === 'image' ? (
                        propsData?.imageFile ? (
                          <img
                            className="img-fluid"
                            src={propsData?.imageFile}
                            alt={'not-available'}
                          />
                        ) : (
                          propsData?.imageFile !== undefined && (
                            <p className="new-upload-no-data">{t('NEWS_FEED.NO_DATA')}</p>
                          )
                        )
                      ) : propsData?.fileType === 'video' && propsData?.imageFile ? (
                        <video className="img-fluid" controls>
                          <source src={propsData?.file} />
                        </video>
                      ) : (
                        propsData?.imageFile !== undefined && <p>{t('NEWS_FEED.NO_DATA')}</p>
                      )}
                    </div>
                  </Col>
                </Row>
              )}

              <Field
                component={renderTextField}
                name="external_link"
                className="my-3 filter-select-placeholder"
                onChange={e => linkHandler(e)}
                required={false}
                isMulti={false}
                placeholder={t('NEWS_FEED.ADD_A_LINK')}
              ></Field>
              <div className="publish-div">
                <Field
                  component={'input'}
                  type="checkbox"
                  name="publish_status"
                  className="my-3 filter-select-placeholder mr-1"
                  onChange={e => handlePublishStatus(e)}
                  required={false}
                />
                <label htmlFor="publish_status" className="publish_style">
                  {t('NEWS_FEED.PUBLISH')}
                </label>
              </div>
            </div>
            <hr />
            <div className=" filter-buttons">
              {!props?.initialValues ? (
                <Button
                  className="btn st-btn-custom submit-btn"
                  disabled={propsData?.saveButtonDisabled}
                  type="submit"
                  loading={isNewsFeedAddRequesting}
                  id="save"
                >
                  {t('NEWS_FEED.SAVE')}
                </Button>
              ) : (
                <Button
                  className="btn st-btn-custom submit-btn"
                  disabled={propsData?.saveButtonDisabled}
                  type="button"
                  onClick={updateHandler}
                  id="update"
                >
                  {t('NEWS_FEED.UPDATE')}
                </Button>
              )}
            </div>
            <hr />
          </form>
        </div>
      </div>
    </>
  );
};
export default reduxForm({
  form: 'news-add-form',
  enableReinitialize: true,
})(CreateNewsForm);
