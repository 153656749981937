import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-multi-lang';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import Optionicon from '../../assets/Images/option.png';
import PropTypes from 'prop-types';
import Detailmodal from './DetailsModal';
import DeleteConfirmationModal from './DeleteConfirmationModal';
import {
  userByIdRequest,
  userPermissionsRequest,
  userPermissionsByIdRequest,
} from '../../redux/user-list/userListAction';

const Detailsdropdown = props => {
  const { request_Id, handleLatestData, selectedPageTab, activeTab, email, handleReset } = props;
  const t = useTranslation();
  const nextProps = useSelector(state => ({
    isDeleteUserRequesting: state.userList.isDeleteUserRequesting,
    deleteData: state.userList.deleteUser || null,
    detailUserInfo: state.userList.userById || null,
    permissionsData: state.userList.userPermissionsData || null,
    userPermissionsByIdData: state.userList.userPermissionsByIdData || null,
  }));

  const dispatch = useDispatch();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedReqModalData, setSelectedReqModalData] = useState();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [permissionsList, setPermissionsList] = useState([]);
  const [preSelectedPermissions, setPreSelectedPermissions] = useState([]);
  const [selectedTabListData, setSelectedTabListData] = useState({
    view_details: true,
    delete: false,
  });

  const toggle = () => setDropdownOpen(prevState => !prevState);
  useEffect(() => {
    if (nextProps.detailUserInfo) {
      const {
        data: { result },
      } = nextProps.detailUserInfo;
      if (result) {
        setSelectedReqModalData(result);
      }
    }
  }, [nextProps.detailUserInfo]);

  useEffect(() => {
    if (nextProps.userPermissionsByIdData) {
      const {
        data: { result },
      } = nextProps.userPermissionsByIdData;
      if (result) {
        setPreSelectedPermissions(result);
      }
    }
  }, [nextProps.userPermissionsByIdData]);

  useEffect(() => {
    if (nextProps.permissionsData) {
      const {
        data: { result },
      } = nextProps.permissionsData;
      if (result) {
        result?.map(i => {
          i.checked = false;
        });
        setPermissionsList(result);
      }
    }
  }, [nextProps.permissionsData]);

  useEffect(() => {
    if (selectedPageTab === 'public-user') {
      let a = {
        view_details: true,
        delete: true,
      };
      setSelectedTabListData(a);
    } else if (selectedPageTab === 'admin-user') {
      let a = {
        view_details: true,
        delete: true,
      };
      setSelectedTabListData(a);
    }
  }, [selectedPageTab]);

  const handleViewDetailsModal = () => {
    setIsModalOpen(true);
    dispatch(userByIdRequest({ id: request_Id }));
    dispatch(userPermissionsRequest());
    dispatch(userPermissionsByIdRequest({ id: request_Id }));
  };

  const handleModalToggle = () => {
    setIsModalOpen(false);
  };

  const handleDelete = () => {
    setIsDeleteModalOpen(true);
  };

  const handleDeleteConfirm = (boolean_value, value) => {
    if (value === 'no') {
      setIsDeleteModalOpen(boolean_value);
    } else {
      setIsDeleteModalOpen(boolean_value);
      props.latestData();
    }
  };

  return (
    <>
      <Dropdown isOpen={dropdownOpen} toggle={toggle} className="dropdown-option-icon">
        <DropdownToggle>
          <img src={Optionicon} alt={'not-available'} />
        </DropdownToggle>
        <DropdownMenu className="details-dropdown-menu">
          {selectedTabListData.view_details ? (
            <DropdownItem
              className="details-dropdown-item"
              onClick={() => handleViewDetailsModal()}
            >
              {' '}
              {t('PUBLIC_API_ADMIN.VIEW_DETAILS')}
            </DropdownItem>
          ) : (
            ''
          )}
          {selectedTabListData.delete ? (
            <DropdownItem className="details-dropdown-item" onClick={() => handleDelete()}>
              {t('PUBLIC_API_ADMIN.DELETE')}
            </DropdownItem>
          ) : (
            ''
          )}
        </DropdownMenu>
      </Dropdown>
      {isModalOpen && (
        <Detailmodal
          activeTab={activeTab}
          modalOpen={isModalOpen}
          handleModalToggle={handleModalToggle}
          email={email}
          req_data={selectedReqModalData}
          handleReset={handleReset}
          handleLatestData={handleLatestData}
          permissionsList={permissionsList}
          preSelectedPermissions={preSelectedPermissions}
        />
      )}
      {isDeleteModalOpen ? (
        <DeleteConfirmationModal
          handleDeleteConfirm={handleDeleteConfirm}
          isOpen={isDeleteModalOpen}
          request_Id={request_Id}
        />
      ) : (
        ''
      )}
    </>
  );
};

export default Detailsdropdown;
Detailsdropdown.propTypes = {
  request_Id: PropTypes.number,
  key: PropTypes.string,
  handleLatestData: PropTypes.func,
  selectedPageTab: PropTypes.string,
  latestData: PropTypes.func,
  activeTab: PropTypes.string,
  email: PropTypes.string,
};
