import { USER_GUIDE_CONSTS } from './userGuideConsts';

//add user guide api request
export const addUserGuideRequest = formData => ({
  type: USER_GUIDE_CONSTS.ADD_USER_GUIDE_REQUEST,
  payload: formData,
});

export const addUserGuideSuccess = response => ({
  type: USER_GUIDE_CONSTS.ADD_USER_GUIDE_SUCCESS,
  payload: response,
});

export const addUserGuideFailed = error => ({
  type: USER_GUIDE_CONSTS.ADD_USER_GUIDE_FAILED,
  payload: error,
});

//get all topic and subtopic api request
export const getAllTopicsRequest = data => ({
  type: USER_GUIDE_CONSTS.GET_ALL_TOPICS_REQUEST,
  payload: data,
});

export const getAllTopicSuccess = response => ({
  type: USER_GUIDE_CONSTS.GET_ALL_TOPICS_SUCCESS,
  payload: response,
});

export const getAllTopicFailed = error => ({
  type: USER_GUIDE_CONSTS.GET_ALL_TOPICS_FAILED,
  payload: error,
});

//get all by criteria api request
export const getAllUserGuideRequest = data => ({
  type: USER_GUIDE_CONSTS.GET_ALL_USER_GUIDE_REQUEST,
  payload: data,
});

export const getAllUserGuideSuccess = response => ({
  type: USER_GUIDE_CONSTS.GET_ALL_USER_GUIDE_SUCCESS,
  payload: response,
});

export const getAllUserGuideFailed = error => ({
  type: USER_GUIDE_CONSTS.GET_ALL_USER_GUIDE_FAILED,
  payload: error,
});

//get all topics user guide request
export const getAllTopicsListRequest = data => ({
  type: USER_GUIDE_CONSTS.GET_All_TOPICS_LIST_REQUEST,
  payload: data,
});

export const getAllTopicsListSuccess = response => ({
  type: USER_GUIDE_CONSTS.GET_All_TOPICS_LIST_SUCCESS,
  payload: response,
});

export const getAllTopicsListFailed = error => ({
  type: USER_GUIDE_CONSTS.GET_All_TOPICS_LIST_FAILED,
  payload: error,
});

//update user guide request
export const updateUserGuideRequest = data => ({
  type: USER_GUIDE_CONSTS.UPDATE_USER_GUIDE_REQUEST,
  payload: data,
});

export const updateUserGuideSuccess = response => ({
  type: USER_GUIDE_CONSTS.UPDATE_USER_GUIDE_SUCCESS,
  payload: response,
});

export const updateUserGuideFailed = error => ({
  type: USER_GUIDE_CONSTS.UPDATE_USER_GUIDE_FAILED,
  payload: error,
});

//delete user guide request
export const deleteUserGuideRequest = id => ({
  type: USER_GUIDE_CONSTS.DELETE_USER_GUIDE_REQUEST,
  payload: id,
});

export const deleteUserGuideSuccess = response => ({
  type: USER_GUIDE_CONSTS.DELETE_USER_GUIDE_SUCCESS,
  payload: response,
});

export const deleteUserGuideFailed = error => ({
  type: USER_GUIDE_CONSTS.DELETE_USER_GUIDE_FAILED,
  payload: error,
});

//change status user guide request
export const changeStatusUserGuideRequest = data => ({
  type: USER_GUIDE_CONSTS.CHANGE_STATUS_USER_GUIDE_REQUEST,
  payload: data,
});

export const changeStatusUserGuideSuccess = response => ({
  type: USER_GUIDE_CONSTS.CHANGE_STATUS_USER_GUIDE_SUCCESS,
  payload: response,
});

export const changeStatusUserGuideFailed = error => ({
  type: USER_GUIDE_CONSTS.CHANGE_STATUS_USER_GUIDE_FAILED,
  payload: error,
});

//search by criteria api request
export const searchByCriteriaUserGuideRequest = data => ({
  type: USER_GUIDE_CONSTS.SEARCH_BY_CRITERIA_USER_GUIDE_REQUEST,
  payload: data,
});

export const searchByCriteriaUserGuideSuccess = response => ({
  type: USER_GUIDE_CONSTS.SEARCH_BY_CRITERIA_USER_GUIDE_SUCCESS,
  payload: response,
});

export const searchByCriteriaUserGuideFailed = error => ({
  type: USER_GUIDE_CONSTS.SEARCH_BY_CRITERIA_USER_GUIDE_FAILED,
  payload: error,
});
//search by content api request
export const searchContentUserGuideRequest = data => ({
  type: USER_GUIDE_CONSTS.SEARCH_CONTENT_USER_GUIDE_REQUEST,
  payload: data,
});

export const searchContentUserGuideSuccess = response => ({
  type: USER_GUIDE_CONSTS.SEARCH_CONTENT_USER_GUIDE_SUCCESS,
  payload: response,
});

export const searchContentUserGuideFailed = error => ({
  type: USER_GUIDE_CONSTS.SEARCH_CONTENT_USER_GUIDE_FAILED,
  payload: error,
});
