import React, { useEffect, useState } from 'react';
import { RiArrowDropDownLine, RiArrowDropUpLine } from 'react-icons/ri';
import { Collapse, Input } from 'reactstrap';
import _ from 'lodash';
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai';
import { DROUGHT_MODULE_INDICATORS, INDICES_PARAMS } from '../../Components/constants';
import IndicesInfoIcons from './IndicesInfoIcons';

const DroughtIndicatorsCollapse = (props) => {
  const {
    DroughtIndicesData,
    setMinStartDate,
    setSelectedParam,
    setCompareParam,
    isCompare,
    selectedModule,
    selectedParam,
  } = props;
  const checkboxStyle = {
    color: '#2A547C',
  };
  const [DroughtIndicators, setDroughtIndicators] = useState({
    mandatory: '',
    impact: '',
  });
  const [CompareDroughtIndicators, setCompareDroughtIndicators] = useState({
    mandatory: '',
    impact: '',
  });

  const [handleCategory, setHandleCategory] = useState([
    { name: 'Mandatory', id: 'Mandatory', category: 'Mandatory', checked: true },
    { name: 'Impact', id: 'Impact', category: 'Impact', checked: true },
  ]);

  const [handleCompareCategory, setCompareCategory] = useState([]);
  const [handleIndices, setHandleIndices] = useState();
  const [handleCompareIndices, setHandleCompareIndices] = useState();

  useEffect(() => {
    if (DroughtIndicesData) {
      const { data } = DroughtIndicesData;
      let mandatoryData = [];
      let impactData = [];
      let paramsList = [];
      let count = 0;
      let categoriesList = JSON.parse(JSON.stringify(handleCategory));

      data?.result?.map((item) => {
        let mandatoryCategoryData = Object.assign({}, item);
        mandatoryCategoryData.category = 'Mandatory';
        let impactCategoryData = Object.assign({}, item);
        impactCategoryData.category = 'Impact';

        let mandatoryParamData = item?.parameter?.filter(
          (x) => x.mandatory === true && x.status === true,
        );
        let mandatoryParamUpdatedData = mandatoryParamData?.map((i) => {
          let obj = JSON.parse(JSON.stringify(i));
          obj.paramIndex = count;
          count = count + 1;
          if(selectedParam?.id === obj?.id) 
          obj.checked = true
         else 
          obj.checked = false;
          return obj;
        });
        if (mandatoryParamUpdatedData?.length) {
          categoriesList.push({
            name: item.name,
            id: item.name,
            category: 'Mandatory',
            checked: true,
          });
          mandatoryCategoryData.parameter = mandatoryParamUpdatedData;
          mandatoryData.push(mandatoryCategoryData);
        }

        let impactParamData = item?.parameter?.filter(
          (x) => x.impact === true && x.status === true,
        );
        let impactParamUpdatedData = impactParamData?.map((i) => {
          let obj = JSON.parse(JSON.stringify(i));
          obj.paramIndex = count;
          if(selectedParam?.id === obj?.id) 
           obj.checked = true
          else 
           obj.checked = false;
         count = count + 1;
          return obj;
        });
        if (impactParamUpdatedData?.length) {
          categoriesList?.push({
            name: item.name,
            id: item.name,
            category: 'Impact',
            checked: true,
          });
          impactCategoryData.parameter = impactParamUpdatedData;
          impactData.push(impactCategoryData);
        }

        item?.parameter?.map((param) => {
          if ((param?.impact || param?.mandatory) && param.status) {
            paramsList?.push({ name: param?.name, id: param?.id, checked:  selectedParam?.id === param?.id  ? true : false});
          }
        });
      });
      setDroughtIndicators({
        mandatory: mandatoryData,
        impact: impactData,
      });
      setCompareDroughtIndicators({
        mandatory: mandatoryData,
        impact: impactData,
      });
      setHandleCategory(categoriesList);
      setCompareCategory(categoriesList);
      setHandleCompareIndices(paramsList);
      setHandleIndices(paramsList);
    } else {
      setDroughtIndicators({
        mandatory: [],
        impact: [],
      });
      setCompareDroughtIndicators({
        mandatory: [],
        impact: [],
      });
    }
  }, [DroughtIndicesData]);

  const handleCategoryToggle = (indicatorItem, categoryArr, isCompare) => {
    let categoryList = JSON.parse(JSON.stringify(categoryArr));
    let indicatorList;
    const index = _.findIndex(categoryList, function (list) {
      if (list.name === indicatorItem.name && list.category === indicatorItem.category) {
        return true;
      } else {
        return false;
      }
    });

    if (indicatorItem.name === 'Mandatory' || indicatorItem.name === 'Impact') {
      indicatorList = handleDefaultCategoryToggle(
        indicatorItem.category,
        categoryList[index].checked,
        categoryList,
      );
    } else {
      if (index >= 0) {
        categoryList[index] = {
          ...categoryList[index],
          checked: !categoryList[index].checked,
        };
      }
      indicatorList = categoryList;
    }
    if (isCompare) {
      setCompareCategory(indicatorList);
    } else {
      setHandleCategory(indicatorList);
    }
  };



  const handleDefaultCategoryToggle = (category, isChecked, categoryList) => {
    categoryList?.map((item, index) => {
      if (item.category === category) {
        categoryList[index] = {
          ...categoryList[index],
          checked: !isChecked,
        };
      }
    });
    return categoryList;
  };

  const renderCheckboxes = ({
    item,
    index,
    data,
    handleCategory,
    isSubCategory,
    isCategory,
    isCompare,
  }) => {
    const selectedItem = handleCategory?.find(
      (x) => x?.name === item?.name && x?.category === item?.category,
    );
    if (!_.isEmpty(item)) {
      return (
        <>
          <span
            onClick={() => handleCategoryToggle(item, handleCategory, isCompare)}
            className="drought-category-style"
          >
            <p
              className={isSubCategory ? 'drought-sub-category-labels' : 'drought-category-labels'}
              style={checkboxStyle}
            >
              {item.name || ''}
              <span className="category-span">
                {selectedItem?.checked ? (
                  <RiArrowDropUpLine size={25} />
                ) : (
                  <RiArrowDropDownLine size={25} />
                )}
              </span>
            </p>
          </span>
          <Collapse
            id={'collapse' + index}
            isOpen={selectedItem?.checked}
            className="drought-collapse-moadal"
          >
            {isCategory ? (
              data.length ? (
                data?.map((category, index) => {
                  let x = index;
                  return (
                    <>
                      {renderCheckboxes({
                        item: category,
                        index: x,
                        data: category?.parameter,
                        handleCategory,
                        isSubCategory: true,
                        isCategory: false,
                        isCompare,
                      })}
                    </>
                  );
                })
              ) : (
                <>
                  <div>
                    <p>No Indicators are available</p>
                  </div>
                </>
              )
            ) : (
              returnCheckbox(
                data,
                isCompare ? handleCompareIndices : handleIndices,
                index,
                isCompare,
              )
            )}
          </Collapse>
        </>
      );
    } else {
      return <></>;
    }
  };

  const handleCheckbox = (param, indicesList, isCompare) => {
    let checkedParam = indicesList?.find((item) => item.id === param.id);
    if (!checkedParam.checked) {
      let indicesData = JSON.parse(JSON.stringify(indicesList));
      indicesData?.length &&
        indicesData?.map((value, i) => {
          if (value?.id !== param?.id) {
            value.checked = false;
          } else {
            value.checked = true;
          }
        });
      if (isCompare) {
        setHandleCompareIndices(indicesData);
        setCompareParam(param);
      } else {
        setMinStartDate(param.min_date);
        setSelectedParam({ ...param, checked: true });
        setHandleIndices(indicesData);
      }
    }
  };

  const returnCheckbox = (category, handleIndices, index, isCompare) => {
    return (
      category?.length &&
      category?.map((param, index) => {
        let parameter = handleIndices?.find((item) => item?.id === param?.id);
        return (
          <>
            <ul className="drought-param-style position-relative">
              <li
                className={
                  parameter?.checked
                    ? 'drought-li-checked d-inline-block'
                    : 'drought-submenu-li d-inline-block'
                }
                onClick={() => handleCheckbox(param, handleIndices, isCompare)}
              >
                {parameter?.checked ? <AiFillEye size={20} /> : <AiFillEyeInvisible size={20} />}
                <span style={{ paddingLeft: '10px' }}>{param?.name || ''}</span>
              </li>
              <IndicesInfoIcons
                checked={parameter?.checked}
                indicesName={param?.name}
                selectedModule={selectedModule}
              ></IndicesInfoIcons>
              {param.id === INDICES_PARAMS.DRY_SPELL.id && parameter?.checked ? (
                <div className="mx-3 mt-3 mb-3">
                  <label htmlFor="quantity">Precipitation Threshold (mm)</label>
                  <Input
                    type="number"
                    id="quantity"
                    name="quantity"
                    min="0"
                    value={props.drySpellPrecipitationThreshold}
                    onChange={e => props.setDrySpellPrecipitationThreshold(e.target.value)}
                  />
                </div>
              ) : (
                ''
              )}
            </ul>
          </>
        );
      })
    );
  };

  return (
    <>
      {selectedModule.api_slug === 'drought-monitor' ? (
        <div className="drought-sidebar-dropdown">
          {DROUGHT_MODULE_INDICATORS?.map((item, index) => {
            let data = '';
            if (item.name === 'Mandatory') {
              data = isCompare ? CompareDroughtIndicators?.mandatory : DroughtIndicators?.mandatory;
            } else if (item.name === 'Impact') {
              data = isCompare ? CompareDroughtIndicators?.impact : DroughtIndicators?.impact;
            }
            return (
              <>
                {renderCheckboxes({
                  item,
                  index,
                  data,
                  handleCategory: isCompare ? handleCompareCategory : handleCategory,
                  isSubCategory: false,
                  isCategory: true,
                  isCompare: isCompare,
                })}
              </>
            );
          })}
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default DroughtIndicatorsCollapse;
