import React, { useState, useEffect } from 'react';

import {
  Button,
  Dropdown,
  Row,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Card,
  CardImg,
  CardTitle,
  CardSubtitle,
} from 'reactstrap';
import moment from 'moment';
import { isSuperAdmin, isStaffUser } from '../../Components/common/utils';
import _ from 'lodash';
import { DISPLAY_DATE_FORMAT } from '../../Components/constants';
import BulletDpModal from './BulletinModal';
import '../../assets/CSS/bulletin.css';
import { t } from 'react-multi-lang';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';
import Loader from '../../Components/common/loader';
import AlertModal from './AlertModal';
import {
  getAllTemplateListRequest,
  getAllTemplateListSuccess,
  getTemplateRequest,
  templateDeleteRequest,
  templateDeleteSuccess,
  templateDeleteFailed
} from '../../redux/bulletin/bulletinActions';
import RightArrow from '../../assets/Images/back-button.svg';
import ReactTooltip from 'react-tooltip';
import { showSuccess, showError } from '../../Components/Notifications';

const BulletIn = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [bulletModal, setBulletModal] = useState(false);
  const [dropDownText, setdropDownText] = useState(t(`BULLET_IN.BI_WEEKLY_BULLETIN`));
  const [templateListOptions, setTemplateListOptions] = useState([]);
  const [templates, setTemplates] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageCount, setPageCount] = useState();
  const [filterChanges, setFilterChanges] = useState({});
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const [updateBulletin, setUpdateBulletin] = useState('');

  const { Bulletin } = useSelector(state => ({
    Bulletin: state.Bulletin,
  }));


  useEffect(() => {
    if (isStaffUser() || isSuperAdmin()) {
      dispatch(getAllTemplateListRequest());
    } else {
      history.push('/home');
    }
    window.scroll(0, 0);
      return ()=>{
        dispatch(getAllTemplateListSuccess({}))
        dispatch(templateDeleteSuccess({}))
        dispatch(templateDeleteFailed({}))
      }
  }, []);

  useEffect(() => {
    if (Bulletin?.getAllTemplateListData) {
      let options = renderTemplateListOptions(Bulletin?.getAllTemplateListData) ? renderTemplateListOptions(Bulletin?.getAllTemplateListData) :  null;
      if (options?.length && options) {
        setTemplateListOptions(options);
        dispatch(
          getTemplateRequest({
            page: pageNumber,
            data: { category_id: options[0].id },
          }),
        );
        setFilterChanges(options[0])
      }
    }
  }, [Bulletin.getAllTemplateListData]);

  useEffect(() => {
    if (Bulletin?.getTemplateData?.data) {
      let new_list = [];
      const {
        data: { result },
      } = Bulletin.getTemplateData.data;
      if (result) {
        if (templates) {
          if (pageNumber > 1) {
            new_list = templates?.concat(result?.results);
          } else {
            new_list = result?.results;
          }
        } else {
          new_list = result?.results;
        }
        setTemplates(new_list);
        setPageCount(result?.count);
      } else {
        setTemplates([]);
      }
    }
  }, [Bulletin.getTemplateData]);

  useEffect(() => {
    if (!_.isEmpty(Bulletin?.isTemplateDeleteData?.data?.data)) {
      setTemplates([]);
      showSuccess(Bulletin?.isTemplateDeleteData?.data?.data?.result);
    }
    setIsDeleteModal(false);
  }, [Bulletin.isTemplateDeleteData]);

  useEffect(() => {
    if (!_.isEmpty(Bulletin?.isTemplateDeleteDataError?.data?.response?.data?.message)) {
      showError(Bulletin?.isTemplateDeleteDataError?.data?.response?.data?.message);
    }
    setIsDeleteModal(false);
  }, [Bulletin.isTemplateDeleteDataError]);
  
  useEffect(() => {
    if (_.isEmpty(templates)) {
        setPageNumber(1);
        setPageCount();
          
   dispatch(
        getTemplateRequest({
          page: 1,
          data: { category_id: filterChanges?.id },
        }),
      );
    }
  }, [templates]);


  const renderTemplateListOptions = data => {
    if (data) {
      let list = data?.data?.data?.result;
      let params = [...list];
      let options = [];
      if (params?.length) {
        params.map(param => {
          let data = {
            value: param?.name,
            label: `${param?.name}`,
            id: param?.id,
          };
          options.push(data);
          return true;
        });
        return options;
      }
    }
  };

  const toggle = () => setDropdownOpen(prevState => !prevState);

  const cardHandler = data => {
    let list = templateListOptions;
    let defaultList = {
      label: data?.category_name,
      value: data?.category_name,
      id: data?.category_id,
    };
    history.push({
      pathname: '/create-template',
      state: [list, defaultList, data],
    });
  };

  const handleDropDownFilter = data => {
    setdropDownText(data?.label);
    setPageNumber(1);
    setPageCount();
    setTemplates([]);
    setFilterChanges(data);
    dispatch(
      getTemplateRequest({
        page: 1,
        data: { category_id: data?.id },
      }),
    );
  };

  const fetchNextTemplateData = () => {
    setPageNumber(pageNumber + 1);
    dispatch(
      getTemplateRequest({
        page: pageNumber + 1,
        data: { category_id: filterChanges?.id },
      }),
    );
  };


  const handleDeleteModal = (e, data) => {
    e?.stopPropagation();
    setIsDeleteModal(!isDeleteModal);
    if (!isDeleteModal) setUpdateBulletin(data);
  };

  const handleDelete = value => {
    dispatch(templateDeleteRequest(value));     
  };

  return (
    <>
      {isDeleteModal && (
        <>
          <AlertModal
            isOpen={isDeleteModal}
            isToggle={handleDeleteModal}
            onClick={handleDelete}
            data={updateBulletin}
            onDeleteClick={true}
            onPublishClick={false}
            fromTemplate= {true}
          />
        </>
      )}
      <div className="bulletin-header">
        <Row>
          <Col lg={9}>
            <div className='d-inline-block d-flex template-page-header'>
              <img
                src={RightArrow}
                className="template-bo-cursor"
                onClick={() => history.goBack()}
              />
		  
            <h3 className="bulletin-header-h3 d-inline-block">
              {t('BULLET_IN.BULLET_IN_TEMPLATES')}
            </h3>
            </div>
          </Col>
          <Col lg={3}>
            <Dropdown
              isOpen={dropdownOpen}
              toggle={toggle}
              className="bulletin-dp d-inline-block me-3 create-template-menu template-buttons-margin"
            >
              <DropdownToggle>
                {dropDownText}
                <i className="fa fa-caret-down m-2"></i>
              </DropdownToggle>
              <DropdownMenu className="bullet-dp-menu">
                {templateListOptions?.map((item, id) => {
                  return (
                    <div key={id}>
                      <DropdownItem
                        className="bullet-dp-item"
                        onClick={() => {
                          handleDropDownFilter(item);
                        }}
                      >
                        {item?.label}
                      </DropdownItem>
                    </div>
                  );
                })}
              </DropdownMenu>
            </Dropdown>

            <Button
              className="template-login-btn-grn-key my-4 create-template template-button-margin"
              onClick={() => setBulletModal(true)}
            >
              {t('BULLET_IN.CREATE_TEMPLATE')}
            </Button>
          </Col>
        </Row>
      </div>
      <Row className="mx-4">
        {bulletModal && (
          <BulletDpModal
            isOpen={bulletModal}
            toggleModal={setBulletModal}
            templateList={templateListOptions}
          />
        )}
      </Row>
      <div className="template-cards-container">
        <div className="loader-div">{Bulletin.isGetTemplateRequesting && <Loader />}</div>
        {_.isEmpty(templates) && !Bulletin.isGetTemplateRequesting && _.isEmpty(Bulletin?.getTemplateData) && (
          <h5 className="online-bulletin-no-records-found">{Bulletin?.getTemplateDataError?.data?.response?.data?.message}</h5>
        )}
        <InfiniteScroll
          dataLength={pageNumber * 10 < pageCount ? pageNumber * 10 : ''}
          next={fetchNextTemplateData}
          hasMore={pageNumber * 10 < pageCount ? true : false}
          loader={
            Bulletin.isGetTemplateRequesting && (
              <center>
                <h4>{t('BULLET_IN.LOADING')} </h4>
              </center>
            )
          }
        >
          {templates?.length ? templates?.map(i => {
            return (
              <Col className=" bulletin-template-modal-card-col" lg={3}>
                <Card
                  className="bulletin-modal-cards mt-3 m-3"
                  onClick={() => {
                    cardHandler(i);
                  }}
                >
                  <CardImg src={i?.preview_image || ''} top width="100%" className="index-card-Image" />
                  <div className="m-3">
                    <CardTitle tag="h5">{i?.title}</CardTitle>
                    <CardSubtitle className="mb-2 text-muted" tag="h6">
                      {i?.category_name}
                    </CardSubtitle>
                    <CardSubtitle className="mb-2 text-muted" tag="h6">
                      {moment(i?.updated_date).format(DISPLAY_DATE_FORMAT)}
                    </CardSubtitle>
                    <i
                      class="fa fa-trash bulletin-delete-btn position-absolute "
                      onClick={e => {
                      handleDeleteModal(e, i);
                      }}
                      id="delete-bulletin"
                      data-tip
                      data-for="delete-bulletin"
                    ></i>
                  </div>
                  <ReactTooltip id="delete-bulletin" effect="solid" place="top">
                        {t('BULLET_IN.CLICK_TO_DELETE')}
                      </ReactTooltip>
                </Card>
              </Col>
            );
          }):(<></>)}
        </InfiniteScroll>
        {/* </Row> */}
      </div>
    </>
  );
};

export default BulletIn;
