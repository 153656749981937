import React, { memo} from 'react';

import Textarea from 'rc-textarea';

function Summary({ condition, name}) {
  return (
    <div className="bulletin-summary mx-1">
  
      <h5 className="mt-3 text-div">Summary of {condition} Condition :</h5>
      < div className="hr-lines"></div>
      <Textarea
        autoSize={{ minRows: 4 }}
        name={name}
        onPressEnter={e => e.preventDefault()}
        className="mb-3 mt-2 text-area-border-radius"
        placeholder={`     Write down something...`}
      />
    </div>
  );
}
export default memo(Summary);
