import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { AXIOS_INSTANCE, WEATHER_URL,STATIC_RESOURCE_URL, NOAA_TIMESERIES, ERPAS_TIMESERIES, IRI_TIMESERIES } from '../api/apiEndPoint';
import {
  fetchWeatherSuccess,
  fetchWeatherFailed,
  getWeatherForecastSourcesSuccess,
  getWeatherForecastSourcesFailed,
  getWeatherForecastDataSuccess,
  getWeatherForecastDataFailed,
  getNoaaTimeSeriesSuccess,
  getNoaaTimeSeriesError,
  getErpasTimeSeriesSuccess,
  getErpasTimeSeriesError,
  getIRITimeSeriesSuccess,
  getIRITimeSeriesError,
  getIRIAvailableDatesDataSuccess,
  getIRIAvailableDatesDataFailed
} from './weatherActions';
import WEATHER_CONST from './weatherConst';

require('dotenv').config();

const weatherDataApiRequest = async () => {
  let response = {};
  try {
    const data = await AXIOS_INSTANCE.get(WEATHER_URL);
    response = {
      data: data.data,
      status: 200,
    };
  } catch (error) {
    const data = error;
    response = {
      error: data,
      status: 400,
    };
  }
  return response;
};

function* fetchWeather(action) {
  try {
    const apiResponse = yield call(weatherDataApiRequest, action.payload);
    if (apiResponse.status === 200) {
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.data,
        },
      };
      yield put(fetchWeatherSuccess(responseData));
    } else {
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.error,
        },
      };
      yield put(fetchWeatherFailed(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(fetchWeatherFailed(errorData));
  }
}

//forest data sources by country Id
const getWeatherDataSourcesApiRequest = async (payload) => {
  let response = {};
  try {
    const data = await AXIOS_INSTANCE.post(
      `${process.env.REACT_APP_API_URL}/weather/get-forecast-data-sources-with-parameter-type`,
      payload,
    );
    response = {
      data: data.data,
      status: 200,
    };
  } catch (error) {
    const data = error;
    response = {
      error: data,
      status: 400,
    };
  }
  return response;
};
function* getWeatherDataSources(action) {
  try {
    const apiResponse = yield call(getWeatherDataSourcesApiRequest, action.payload);
    if (apiResponse.status === 200) {
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.data,
        },
      };
      yield put(getWeatherForecastSourcesSuccess(responseData));
    } else {
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.error,
        },
      };
      yield put(getWeatherForecastSourcesFailed(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(getWeatherForecastSourcesFailed(errorData));
  }
}

const getWeatherForecastDataApiRequest = async (payload) => {
  let response = {};
  try {
    const data = await AXIOS_INSTANCE.post(
      `${process.env.REACT_APP_API_URL}/weather/forecast-data`,
      payload,
    );
    response = {
      data: data.data,
      status: 200,
    };
  } catch (error) {
    const data = error;
    response = {
      error: data,
      status: 400,
    };
  }
  return response;
};
function* getWeatherForecastData(action) {
  try {
    const apiResponse = yield call(getWeatherForecastDataApiRequest, action.payload);
    if (apiResponse.status === 200) {
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.data,
        },
      };
      yield put(getWeatherForecastDataSuccess(responseData));
    } else {
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.error,
        },
      };
      yield put(getWeatherForecastDataFailed(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(getWeatherForecastDataFailed(errorData));
  }
}

const getNoaaTimeseriesApiRequest = async (payload) => {
  let response = {};
  try {
    const data = await AXIOS_INSTANCE.post(NOAA_TIMESERIES, payload);
    response = {
      data: data.data,
      status: 200,
    };
  } catch (error) {
    const data = error;
    response = {
      error: data,
      status: 400,
    };
  }
  return response;
};

function* getNoaaTimeSeriesData(action) {
  try {
    const apiResponse = yield call(getNoaaTimeseriesApiRequest, action.payload);
    if (apiResponse.status === 200) {
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.data,
        },
      };
      yield put(getNoaaTimeSeriesSuccess(responseData));
    } else {
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.error,
        },
      };
      yield put(getNoaaTimeSeriesError(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(getNoaaTimeSeriesError(errorData));
  }
}

const getErpasTimeseriesApiRequest = async (payload) => {
  let response = {};
  try {
    const data = await AXIOS_INSTANCE.post(ERPAS_TIMESERIES, payload);
    response = {
      data: data.data,
      status: 200,
    };
  } catch (error) {
    const data = error;
    response = {
      error: data,
      status: 400,
    };
  }
  return response;
};

const getIRITimeseriesApiRequest = async (payload) => {
  let response = {};
  try {
    const data = await AXIOS_INSTANCE.post(IRI_TIMESERIES, payload);
    response = {
      data: data.data,
      status: 200,
    };
  } catch (error) {
    const data = error;
    response = {
      error: data,
      status: 400,
    };
  }
  return response;
};

const getIRIAvailableDatesDataApiRequest = async (payload) => {
  let response = {};
  try {
    const data = await AXIOS_INSTANCE.get(`${STATIC_RESOURCE_URL}/iriAvailableDates`, payload);
    response = {
      data: data.data,
      status: 200,
    };
  } catch (error) {
    const data = error;
    response = {
      error: data,
      status: 400,
    };
  }
  return response;
};

function* getErpasTimeSeriesData(action) {
  try {
    const apiResponse = yield call(getErpasTimeseriesApiRequest, action.payload);
    if (apiResponse.status === 200) {
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.data,
        },
      };
      yield put(getErpasTimeSeriesSuccess(responseData));
    } else {
      const responseData = {
        response: {
          statusCode: apiResponse.status,
          data: apiResponse.error,
        },
      };
      yield put(getErpasTimeSeriesError(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(getErpasTimeSeriesError(errorData));
  }
}

function* getIRITimeSeriesData(action) {
  try {
    const apiResponse = yield call(getIRITimeseriesApiRequest, action.payload);
    if (apiResponse.status === 200) {
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.data,
        },
      };
      yield put(getIRITimeSeriesSuccess(responseData));
    } else {
      const responseData = {
        response: {
          statusCode: apiResponse.status,
          data: apiResponse.error,
        },
      };
      yield put(getIRITimeSeriesError(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(getIRITimeSeriesError(errorData));
  }
}

function* getIRIAvailableDatesDataRequest(action) {
  try {
    const apiResponse = yield call(getIRIAvailableDatesDataApiRequest, action.payload);
    if (apiResponse.status === 200) {
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.data,
        },
      };
      yield put(getIRIAvailableDatesDataSuccess(responseData));
    } else {
      const responseData = {
        response: {
          statusCode: apiResponse.status,
          data: apiResponse.error,
        },
      };
      yield put(getIRIAvailableDatesDataFailed(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(getIRIAvailableDatesDataFailed(errorData));
  }
}

export function* watchWeatherDataSources() {
  yield takeEvery(WEATHER_CONST.GET_FORECAST_DATA_SOURCES_REQUEST, getWeatherDataSources);
}
export function* watchWeatherForecastData() {
  yield takeEvery(WEATHER_CONST.GET_FORECAST_DATA_REQUEST, getWeatherForecastData);
}
export function* watchWeather() {
  yield takeEvery(WEATHER_CONST.FETCH_REQUEST, fetchWeather);
}

export function* watchNoaaTimeSeries() {
  yield takeEvery(WEATHER_CONST.NOAA_TIMESERIES_REQUEST, getNoaaTimeSeriesData);
}

export function* watchErpasTimeSeries() {
  yield takeEvery(WEATHER_CONST.ERPAS_TIMESERIES_REQUEST, getErpasTimeSeriesData);
}

export function* watchIRITimeSeries() {
  yield takeEvery(WEATHER_CONST.IRI_TIMESERIES_REQUEST, getIRITimeSeriesData);
}

export function* watchIRIAvailableDates() {
  yield takeEvery(WEATHER_CONST.IRI_AVAILABLE_DATES_REQUEST, getIRIAvailableDatesDataRequest);
}

function* weatherSaga() {
  yield all([
    fork(watchWeather),
    fork(watchWeatherDataSources),
    fork(watchWeatherForecastData),
    fork(watchNoaaTimeSeries),
    fork(watchErpasTimeSeries),
    fork(watchIRITimeSeries),
    fork(watchIRIAvailableDates)
  ]);
}
export default weatherSaga;
