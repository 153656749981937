import React, { useEffect, useState } from 'react';
import { Modal, ModalHeader, ModalBody, Row, Col } from 'reactstrap';
import _ from 'lodash';
import { MdOutlineClose } from 'react-icons/md';
import moment from 'moment';
import { t } from 'react-multi-lang';
import { WEATHER_STATUS } from '../../../Components/constants';

const WeatherForecastModal = props => {
  const { weatherForecastData, isOpen, toggle, cordinateAndZoom,countryFor,stateFor,districtFor} = props;

  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    if (weatherForecastData?.length) {
      let { Open_Weather: openWeatherData } = weatherForecastData[0];
      if (openWeatherData && !_.isEmpty(openWeatherData?.daily)) {
        setTableData(openWeatherData.daily);
      }
    }
  }, [weatherForecastData]);

  const returnDateString = () => {
    const startDate = new Date();
    const endDate = new Date();
    endDate.setDate(endDate.getDate() + 6);
    let formattedStartDate = '';
    let formattedEndDate = '';
    if (startDate?.getFullYear() === endDate?.getFullYear()) {
      formattedStartDate = moment(startDate)
        .local()
        .format('Do MMMM');
      formattedEndDate = moment(endDate)
        .local()
        .format('Do MMMM');
      return `${formattedStartDate} to ${formattedEndDate}, ${startDate?.getFullYear()}`;
    } else {
      formattedStartDate = moment(startDate)
        .local()
        .format('Do MMMM YYYY');
      formattedEndDate = moment(endDate)
        .local()
        .format('Do MMMM YYYY');
      return `${formattedStartDate} to ${formattedEndDate}`;
    }
  };

  const checkLoaction=()=>{
    if (districtFor === null && stateFor === null){
        return `${countryFor?.name}`
    }
    else if(districtFor === null){
      return `${stateFor?.name}`
    }
    else{
    return `${districtFor?.name}`
  }
}

  return (
    <div>
      <Modal
        isOpen={isOpen}
        toggle={toggle}
        className="alert-modal-map-sm col-2 order-modal model-content"
        backdrop="static"
        keyboard={false}
      >
        <ModalHeader className="close-weather-data" toggle={toggle}>
          <div className="weather-modal-style">
            <p className="header-style">
              {t('DROUGHT_DSS.WEATHER_DATA_INFO')}
              <span className="cursor-ponter" onClick={toggle}>
                <MdOutlineClose size={24} />
              </span>
              <br />
            </p>
            <Row className="sub-header-style">
              <Col sm={5}lg={5} md={5}>{`${checkLoaction()}(${cordinateAndZoom.locationCordinates[0].toFixed(2)},${cordinateAndZoom.locationCordinates[1].toFixed(2)})`}</Col>
              <Col>{returnDateString()}</Col>
            </Row>
          </div>
        </ModalHeader>
        <ModalBody className="weather-modal-bdy">
          <div className="weather-modal-div">
            {tableData?.length
              ? tableData?.map(item => {
                  return (
                    <>
                      <Row className="weather-row-style">
                        <Col className="row-col-style">
                          <Row className="row-one">
                            <Col className="col-one">
                              {moment(new Date(item.dt_txt))
                                .local()
                                .format('ddd')}
                            </Col>
                          </Row>
                          <Row className="row-two">
                            <Col className="col-two">
                              {moment(new Date(item.dt_txt))
                                .local()
                                .format('DD/M')}
                            </Col>
                          </Row>
                        </Col>
                        <Col className="row-col-style">
                          {WEATHER_STATUS.map(x => {
                            if (x?.label === item?.weather[0]?.main) {
                              return x.icon;
                            } else {
                              return <></>;
                            }
                          })}
                        </Col>
                        <Col className="row-col-style">
                          <Row className="row-one">
                            <Col className="col-one">{item?.temp?.min || 0}</Col>
                          </Row>
                          <Row className="row-two">
                            <Col className="col-two">{`${t('DROUGHT_DSS.MIN')}(°C)`}</Col>
                          </Row>
                        </Col>
                        <Col className="row-col-style">
                          <Row className="row-one">
                            <Col className="col-one">{item?.temp?.max || 0}</Col>
                          </Row>
                          <Row className="row-two">
                            <Col className="col-two">{`${t('DROUGHT_DSS.MAX')}(°C)`}</Col>
                          </Row>
                        </Col>
                        <Col className="row-col-style">
                          <Row className="row-one">
                            <Col className="col-one">{item?.rain || 0}</Col>
                          </Row>
                          <Row className="row-two">
                            <Col className="col-two">{`${t('DROUGHT_DSS.RAINFALL')}(mm)`}</Col>
                          </Row>
                        </Col>
                        <Col className="row-col-style">
                          <Row className="row-one">
                            <Col className="col-one">{item?.humidity || 0}</Col>
                          </Row>
                          <Row className="row-two">
                            <Col className="col-two">{`${t('DROUGHT_DSS.HUMIDITY')}(%)`}</Col>
                          </Row>
                        </Col>
                      </Row>
                    </>
                  );
                })
              : ''}
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default WeatherForecastModal;
