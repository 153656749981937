export const NEWS_FEED_URL_DOMAIN_CONST = 'https://api.rss2json.com';

export const AMZONS_S3_URL_DOMAIN_CONST = 'https://zadms-iwmi.s3.ap-south-1.amazonaws.com';

export const COORDINATES_DATA = [
  {
    countryName: "Zambia",
    cordinates: [-14.207133, 27.385223],
    locationCordinates: [-14.207133, 27.385223],
    markerCordinates: [-14.207133, 27.385223],
    zoom: 4.8,
  },
  {
    stateName: 'Central',
    cordinates: [-13.973508493489165, 28.432166415216866],
    locationCordinates: [-14.183507, 29.037554],
    markerCordinates: [-14.183507, 29.037554],
    zoom: 6,
  },
  {
    stateName: 'Copperbelt',
    cordinates: [-13.405505077069348, 27.72407351581433],
    locationCordinates: [-13.021417, 27.887618],
    markerCordinates: [-13.021417, 27.887618],
    zoom: 7,
  },
  {
    stateName: 'Eastern',
    cordinates: [-13.442900016992006, 31.388081508355263],
    locationCordinates: [-13.651474, 31.995854],
    markerCordinates: [-13.651474, 31.995854],
    zoom: 6,
  },
  {
    stateName: 'Luapula',
    cordinates: [-11.231812796898156, 30.003883854124645],
    locationCordinates: [-10.468024, 29.127561],
    markerCordinates: [-10.468024, 29.127561],
    zoom: 6,
  },
  {
    stateName: 'Lusaka',
    cordinates: [-15.765682029051453, 29.14133756203303],
    locationCordinates: [-15.416412, 28.282479],
    markerCordinates: [-15.416412, 28.282479],
    zoom: 7,
  },
  {
    stateName: 'Muchinga',
    cordinates: [-11.684454344991765, 31.190261771632713],
    locationCordinates: [-11.385112, 31.952542],
    markerCordinates: [-11.385112, 31.952542],
    zoom: 6,
  },
  {
    stateName: 'Northern',
    cordinates: [-10.060603917195436, 30.525514427693345],
    locationCordinates: [-9.451417, 30.8881],
    markerCordinates: [-9.451417, 30.8881],
    zoom: 7,
  },
  {
    stateName: 'North-Western',
    cordinates: [-12.993541543479063, 25.081842545562402],
    locationCordinates: [-13.014366, 25.462469],
    markerCordinates: [-13.014366, 25.462469],
    zoom: 6,
  },
  {
    stateName: 'Southern',
    cordinates: [-16.799188023682657, 26.88909178974406],
    locationCordinates: [-16.538172, 26.7375],
    markerCordinates: [-16.538172, 26.7375],
    zoom: 7,
  },
  {
    stateName: 'Western',
    cordinates: [-16.583408975880573, 23.719388873308752],
    locationCordinates: [-15.85435, 23.80168],
    markerCordinates: [-15.85435, 23.80168],
    zoom: 6,
  },
  { districtName:'Senanga',cordinates:[-16.1041,23.3018],locationCordinates:[-16.1041,23.3018],markerCordinates:[-16.1041,23.3018],zoom:8,},
{ districtName:'Chililabombwe',cordinates:[-12.3681,27.8357],locationCordinates:[-12.3681,27.8357],markerCordinates:[-12.3681,27.8357],zoom:8},
{ districtName:'Chongwe',cordinates:[-15.3275,28.6813],locationCordinates:[-15.3275,28.6813],markerCordinates:[-15.3275,28.6813],zoom:8,},
{ districtName:'Chasefu',cordinates:[-11.9226,33.0921],locationCordinates:[-11.9226,33.0921],markerCordinates:[-11.9226,33.0921],zoom:8,},
{ districtName:'Nakonde',cordinates:[-9.5079,32.7988],locationCordinates:[-9.5079,32.7988],markerCordinates:[-9.5079,32.7988],zoom:8,},
{ districtName:'Ngabwe',cordinates:[-14.0851,27.2518],locationCordinates:[-14.0851,27.2518],markerCordinates:[-14.0851,27.2518],zoom:8,},
{ districtName:'Shangombo',cordinates:[-16.3217,22.0998],locationCordinates:[-16.3217,22.0998],markerCordinates:[-16.3217,22.0998],zoom:8,},
{ districtName:'Lavushimanda',cordinates:[-12.6745,31.0094],locationCordinates:[-12.6745,31.0094],markerCordinates:[-12.6745,31.0094],zoom:8,},
{ districtName:'Kazungula',cordinates:[-17.7878,25.276],locationCordinates:[-17.7878,25.276],markerCordinates:[-17.7878,25.276],zoom:8,},
{ districtName:'Lusaka',cordinates:[-15.4164,28.2825],locationCordinates:[-15.4164,28.2825],markerCordinates:[-15.4164,28.2825],zoom:8,},
{ districtName:'Luwingu',cordinates:[-10.2648,29.9241],locationCordinates:[-10.2648,29.9241],markerCordinates:[-10.2648,29.9241],zoom:8,},
{ districtName:'Mafinga',cordinates:[-10.3264,33.2651],locationCordinates:[-10.3264,33.2651],markerCordinates:[-10.3264,33.2651],zoom:8,},
{ districtName:'Mambwe',cordinates:[-13.3547,32.0311],locationCordinates:[-13.3547,32.0311],markerCordinates:[-13.3547,32.0311],zoom:8,},
{ districtName:'Lupososhi',cordinates:[-10.6542,29.5911],locationCordinates:[-10.6542,29.5911],markerCordinates:[-10.6542,29.5911],zoom:8,},
{ districtName:'Chinsali',cordinates:[-10.55,32.0661],locationCordinates:[-10.55,32.0661],markerCordinates:[-10.55,32.0661],zoom:8,},
{ districtName:'Chilanga',cordinates:[-15.5588,28.2755],locationCordinates:[-15.5588,28.2755],markerCordinates:[-15.5588,28.2755],zoom:8,},
{ districtName:'Kawambwa',cordinates:[-9.792,29.077],locationCordinates:[-9.792,29.077],markerCordinates:[-9.792,29.077],zoom:8,},
{ districtName:'Mungwi',cordinates:[-10.1722,31.3667],locationCordinates:[-10.1722,31.3667],markerCordinates:[-10.1722,31.3667],zoom:8,},
{ districtName:'Itezhi-tezhi',cordinates:[-15.7439,26.0182],locationCordinates:[-15.7439,26.0182],markerCordinates:[-15.7439,26.0182],zoom:8,},
{ districtName:'Monze',cordinates:[-16.276,27.4764],locationCordinates:[-16.276,27.4764],markerCordinates:[-16.276,27.4764],zoom:8,},
{ districtName:'Petauke',cordinates:[-14.2502,31.3298],locationCordinates:[-14.2502,31.3298],markerCordinates:[-14.2502,31.3298],zoom:8,},
{ districtName:'Chirundu',cordinates:[-16.032,28.8515],locationCordinates:[-16.032,28.8515],markerCordinates:[-16.032,28.8515],zoom:8,},
{ districtName:'Zimba',cordinates:[-17.3167,26.2008],locationCordinates:[-17.3167,26.2008],markerCordinates:[-17.3167,26.2008],zoom:8,},
{ districtName:'Chipata',cordinates:[-13.6391,32.6458],locationCordinates:[-13.6391,32.6458],markerCordinates:[-13.6391,32.6458],zoom:8,},
{ districtName:'Mwandi',cordinates:[-17.5144,24.8239],locationCordinates:[-17.5144,24.8239],markerCordinates:[-17.5144,24.8239],zoom:8,},
{ districtName:'Mansa',cordinates:[-11.2007,28.8894],locationCordinates:[-11.2007,28.8894],markerCordinates:[-11.2007,28.8894],zoom:8,},
{ districtName:'Chingola',cordinates:[-12.5475,27.86],locationCordinates:[-12.5475,27.86],markerCordinates:[-12.5475,27.86],zoom:8,},
{ districtName:'Kalabo',cordinates:[-14.995,22.6745],locationCordinates:[-14.995,22.6745],markerCordinates:[-14.995,22.6745],zoom:8,},
{ districtName:'Mwinilunga',cordinates:[-11.7315,24.426],locationCordinates:[-11.7315,24.426],markerCordinates:[-11.7315,24.426],zoom:8,},
{ districtName:'Chifunabuli',cordinates:[-11.0895,29.6556],locationCordinates:[-11.0895,29.6556],markerCordinates:[-11.0895,29.6556],zoom:8,},
{ districtName:'Lunte',cordinates:[-10.212,30.2335],locationCordinates:[-10.212,30.2335],markerCordinates:[-10.212,30.2335],zoom:8,},
{ districtName:'Pemba',cordinates:[-16.5207,27.3631],locationCordinates:[-16.5207,27.3631],markerCordinates:[-16.5207,27.3631],zoom:8,},
{ districtName:'Chisamba',cordinates:[-14.9799,28.3761],locationCordinates:[-14.9799,28.3761],markerCordinates:[-14.9799,28.3761],zoom:8,},
{ districtName:'Mumbwa',cordinates:[-14.985,27.0614],locationCordinates:[-14.985,27.0614],markerCordinates:[-14.985,27.0614],zoom:8,},
{ districtName:'Senga Hill',cordinates:[-9.3668,31.2419],locationCordinates:[-9.3668,31.2419],markerCordinates:[-9.3668,31.2419],zoom:8,},
{ districtName:'Mporokoso',cordinates:[-9.3706,30.1219],locationCordinates:[-9.3706,30.1219],markerCordinates:[-9.3706,30.1219],zoom:8,},
{ districtName:'Manyinga',cordinates:[-13.4192,24.3304],locationCordinates:[-13.4192,24.3304],markerCordinates:[-13.4192,24.3304],zoom:8,},
{ districtName:'Mufulira',cordinates:[-12.5512,28.2409],locationCordinates:[-12.5512,28.2409],markerCordinates:[-12.5512,28.2409],zoom:8,},
{ districtName:'Masaiti',cordinates:[-13.2635,28.4043],locationCordinates:[-13.2635,28.4043],markerCordinates:[-13.2635,28.4043],zoom:8,},
{ districtName:'Vubwi',cordinates:[-14.0374,32.8665],locationCordinates:[-14.0374,32.8665],markerCordinates:[-14.0374,32.8665],zoom:8,},
{ districtName:'Livingstone',cordinates:[-17.8531,25.8614],locationCordinates:[-17.8531,25.8614],markerCordinates:[-17.8531,25.8614],zoom:8,},
{ districtName:'Serenje',cordinates:[-13.2322,30.2371],locationCordinates:[-13.2322,30.2371],markerCordinates:[-13.2322,30.2371],zoom:8,},
{ districtName:'Chavuma',cordinates:[-13.0815,22.6901],locationCordinates:[-13.0815,22.6901],markerCordinates:[-13.0815,22.6901],zoom:8,},
{ districtName:'Lufwanyama',cordinates:[-12.8568,27.6253],locationCordinates:[-12.8568,27.6253],markerCordinates:[-12.8568,27.6253],zoom:8,},
{ districtName:'Mwansabombwe',cordinates:[-9.9288,28.7685],locationCordinates:[-9.9288,28.7685],markerCordinates:[-9.9288,28.7685],zoom:8,},
{ districtName:'Kabwe',cordinates:[-14.4434,28.4465],locationCordinates:[-14.4434,28.4465],markerCordinates:[-14.4434,28.4465],zoom:8,},
{ districtName:'Kalulushi',cordinates:[-12.8411,28.0948],locationCordinates:[-12.8411,28.0948],markerCordinates:[-12.8411,28.0948],zoom:8,},
{ districtName:'Luano',cordinates:[-14.1133,30.1346],locationCordinates:[-14.1133,30.1346],markerCordinates:[-14.1133,30.1346],zoom:8,},
{ districtName:'Mitete',cordinates:[-14.2743,22.9302],locationCordinates:[-14.2743,22.9302],markerCordinates:[-14.2743,22.9302],zoom:8,},
{ districtName:'Namwala',cordinates:[-15.7512,26.4371],locationCordinates:[-15.7512,26.4371],markerCordinates:[-15.7512,26.4371],zoom:8,},
{ districtName:'Choma',cordinates:[-16.8142,26.9779],locationCordinates:[-16.8142,26.9779],markerCordinates:[-16.8142,26.9779],zoom:8,},
{ districtName:'Mulobezi',cordinates:[-16.7463,25.1552],locationCordinates:[-16.7463,25.1552],markerCordinates:[-16.7463,25.1552],zoom:8,},
{ districtName:'Sesheke',cordinates:[-17.4809,24.2945],locationCordinates:[-17.4809,24.2945],markerCordinates:[-17.4809,24.2945],zoom:8,},
{ districtName:'Kaputa',cordinates:[-8.4733,29.6638],locationCordinates:[-8.4733,29.6638],markerCordinates:[-8.4733,29.6638],zoom:8,},
{ districtName:'Chiengi',cordinates:[-8.6606,29.1599],locationCordinates:[-8.6606,29.1599],markerCordinates:[-8.6606,29.1599],zoom:8,},
{ districtName:'Kabompo',cordinates:[-13.5985,24.208],locationCordinates:[-13.5985,24.208],markerCordinates:[-13.5985,24.208],zoom:8,},
{ districtName:'Mbala',cordinates:[-8.8456,31.3709],locationCordinates:[-8.8456,31.3709],markerCordinates:[-8.8456,31.3709],zoom:8,},
{ districtName:'Chibombo',cordinates:[-14.6802,28.0947],locationCordinates:[-14.6802,28.0947],markerCordinates:[-14.6802,28.0947],zoom:8},
{ districtName:'Gwembe',cordinates:[-16.4975,27.605],locationCordinates:[-16.4975,27.605],markerCordinates:[-16.4975,27.605],zoom:8,},
{ districtName:'Chitambo',cordinates:[-12.9153,30.6363],locationCordinates:[-12.9153,30.6363],markerCordinates:[-12.9153,30.6363],zoom:8,},
{ districtName:'Mpulungu',cordinates:[-8.7662,31.1196],locationCordinates:[-8.7662,31.1196],markerCordinates:[-8.7662,31.1196],zoom:8,},
{ districtName:'Chembe',cordinates:[-11.9665,28.7461],locationCordinates:[-11.9665,28.7461],markerCordinates:[-11.9665,28.7461],zoom:8,},
{ districtName:'Samfya',cordinates:[-11.3685,29.5517],locationCordinates:[-11.3685,29.5517],markerCordinates:[-11.3685,29.5517],zoom:8,},
{ districtName:'Nsama',cordinates:[-8.8926,29.9567],locationCordinates:[-8.8926,29.9567],markerCordinates:[-8.8926,29.9567],zoom:8,},
{ districtName:'Chama',cordinates:[-11.2746,32.7967],locationCordinates:[-11.2746,32.7967],markerCordinates:[-11.2746,32.7967],zoom:8,},
{ districtName:'Solwezi',cordinates:[-12.1821,26.3991],locationCordinates:[-12.1821,26.3991],markerCordinates:[-12.1821,26.3991],zoom:8,},
{ districtName:'Kasama',cordinates:[-10.2088,31.1883],locationCordinates:[-10.2088,31.1883],markerCordinates:[-10.2088,31.1883],zoom:8,},
{ districtName:'Luanshya',cordinates:[-13.1319,28.4175],locationCordinates:[-13.1319,28.4175],markerCordinates:[-13.1319,28.4175],zoom:8,},
{ districtName:'Mushindamo',cordinates:[-12.4938,26.8358],locationCordinates:[-12.4938,26.8358],markerCordinates:[-12.4938,26.8358],zoom:8,},
{ districtName:'Limulunga',cordinates:[-15.1292,23.1475],locationCordinates:[-15.1292,23.1475],markerCordinates:[-15.1292,23.1475],zoom:8,},
{ districtName:'Sinda',cordinates:[-14.2133,31.759],locationCordinates:[-14.2133,31.759],markerCordinates:[-14.2133,31.759],zoom:8,},
{ districtName:"Ikeleng'I",cordinates:[-11.2372,24.2678],locationCordinates:[-11.2372,24.2678],markerCordinates:[-11.2372,24.2678],zoom:8,},
{ districtName:'Nyimba',cordinates:[-14.5591,30.8196],locationCordinates:[-14.5591,30.8196],markerCordinates:[-14.5591,30.8196],zoom:8,},
{ districtName:'Chikankanta',cordinates:[-16.2497,28.1328],locationCordinates:[-16.2497,28.1328],markerCordinates:[-16.2497,28.1328],zoom:8,},
{ districtName:'Kapiri Mposhi',cordinates:[-13.9695,28.6788],locationCordinates:[-13.9695,28.6788],markerCordinates:[-13.9695,28.6788],zoom:8,},
{ districtName:'Katete',cordinates:[-14.0602,32.0427],locationCordinates:[-14.0602,32.0427],markerCordinates:[-14.0602,32.0427],zoom:8,},
{ districtName:'Mpongwe',cordinates:[-13.5116,28.1561],locationCordinates:[-13.5116,28.1561],markerCordinates:[-13.5116,28.1561],zoom:8,},
{ districtName:'Chilubi',cordinates:[-11.072,30.2951],locationCordinates:[-11.072,30.2951],markerCordinates:[-11.072,30.2951],zoom:8,},
{ districtName:'Kalomo',cordinates:[-17.0237,26.4899],locationCordinates:[-17.0237,26.4899],markerCordinates:[-17.0237,26.4899],zoom:8,},
{ districtName:'Sioma',cordinates:[-16.6019,23.5028],locationCordinates:[-16.6019,23.5028],markerCordinates:[-16.6019,23.5028],zoom:8,},
{ districtName:'Chadiza',cordinates:[-14.0615,32.4345],locationCordinates:[-14.0615,32.4345],markerCordinates:[-14.0615,32.4345],zoom:8,},
{ districtName:'Siavonga',cordinates:[-16.5365,28.7102],locationCordinates:[-16.5365,28.7102],markerCordinates:[-16.5365,28.7102],zoom:8,},
{ districtName:'Nkeyema',cordinates:[-14.8683,25.1577],locationCordinates:[-14.8683,25.1577],markerCordinates:[-14.8683,25.1577],zoom:8,},
{ districtName:'Lundazi',cordinates:[-12.2927,33.1742],locationCordinates:[-12.2927,33.1742],markerCordinates:[-12.2927,33.1742],zoom:8,},
{ districtName:'Lusangazi',cordinates:[-13.6386,31.5811],locationCordinates:[-13.6386,31.5811],markerCordinates:[-13.6386,31.5811],zoom:8,},
{ districtName:'Sinazongwe',cordinates:[-17.2609,27.4606],locationCordinates:[-17.2609,27.4606],markerCordinates:[-17.2609,27.4606],zoom:8,},
{ districtName:'Mkushi',cordinates:[-13.6189,29.3912],locationCordinates:[-13.6189,29.3912],markerCordinates:[-13.6189,29.3912],zoom:8,},
{ districtName:'Mufumbwe',cordinates:[-13.1373,25.0056],locationCordinates:[-13.1373,25.0056],markerCordinates:[-13.1373,25.0056],zoom:8,},
{ districtName:'Shibuyunji',cordinates:[-15.4481,27.8286],locationCordinates:[-15.4481,27.8286],markerCordinates:[-15.4481,27.8286],zoom:8,},
{ districtName:'Kasempa',cordinates:[-13.4583,25.8315],locationCordinates:[-13.4583,25.8315],markerCordinates:[-13.4583,25.8315],zoom:8,},
{ districtName:'Chipili',cordinates:[-10.7346,29.0949],locationCordinates:[-10.7346,29.0949],markerCordinates:[-10.7346,29.0949],zoom:8,},
{ districtName:'Kanchibiya',cordinates:[-11.7384,31.0575],locationCordinates:[-11.7384,31.0575],markerCordinates:[-11.7384,31.0575],zoom:8,},
{ districtName:'Lukulu',cordinates:[-14.3768,23.239],locationCordinates:[-14.3768,23.239],markerCordinates:[-14.3768,23.239],zoom:8,},
{ districtName:'Mwense',cordinates:[-10.3842,28.698],locationCordinates:[-10.3842,28.698],markerCordinates:[-10.3842,28.698],zoom:8,},
{ districtName:'Kitwe',cordinates:[-12.8104,28.2068],locationCordinates:[-12.8104,28.2068],markerCordinates:[-12.8104,28.2068],zoom:8,},
{ districtName:'Kaoma',cordinates:[-14.8009,24.7986],locationCordinates:[-14.8009,24.7986],markerCordinates:[-14.8009,24.7986],zoom:8,},
{ districtName:'Lumezi',cordinates:[-12.5416,33.0396],locationCordinates:[-12.5416,33.0396],markerCordinates:[-12.5416,33.0396],zoom:8,},
{ districtName:'Luampa',cordinates:[-15.7122,25.0178],locationCordinates:[-15.7122,25.0178],markerCordinates:[-15.7122,25.0178],zoom:8,},
{ districtName:'Mpika',cordinates:[-11.8432,31.4555],locationCordinates:[-11.8432,31.4555],markerCordinates:[-11.8432,31.4555],zoom:8,},
{ districtName:'Nchelenge',cordinates:[-9.3476,28.7353],locationCordinates:[-9.3476,28.7353],markerCordinates:[-9.3476,28.7353],zoom:8,},
{ districtName:'Milenge',cordinates:[-11.9589,29.4961],locationCordinates:[-11.9589,29.4961],markerCordinates:[-11.9589,29.4961],zoom:8,},
{ districtName:'Luangwa',cordinates:[-15.6226,30.401],locationCordinates:[-15.6226,30.401],markerCordinates:[-15.6226,30.401],zoom:8,},
{ districtName:'Shiwangandu',cordinates:[-11.2121,31.7735],locationCordinates:[-11.2121,31.7735],markerCordinates:[-11.2121,31.7735],zoom:8,},
{ districtName:'Kafue',cordinates:[-15.7445,28.1755],locationCordinates:[-15.7445,28.1755],markerCordinates:[-15.7445,28.1755],zoom:8,},
{ districtName:'Isoka',cordinates:[-10.1535,32.6369],locationCordinates:[-10.1535,32.6369],markerCordinates:[-10.1535,32.6369],zoom:8,},
{ districtName:'Chipangali',cordinates:[-13.2164,32.7637],locationCordinates:[-13.2164,32.7637],markerCordinates:[-13.2164,32.7637],zoom:8,},
{ districtName:'Mazabuka',cordinates:[-15.8585,27.7613],locationCordinates:[-15.8585,27.7613],markerCordinates:[-15.8585,27.7613],zoom:8,},
{ districtName:'Rufunsa',cordinates:[-15.2564,29.9844],locationCordinates:[-15.2564,29.9844],markerCordinates:[-15.2564,29.9844],zoom:8,},
{ districtName:'Kasenengwa',cordinates:[-13.5688,32.4783],locationCordinates:[-13.5688,32.4783],markerCordinates:[-13.5688,32.4783],zoom:8,},
{ districtName:'Ndola',cordinates:[-12.9693,28.6366],locationCordinates:[-12.9693,28.6366],markerCordinates:[-12.9693,28.6366],zoom:8,},
{ districtName:'Mongu',cordinates:[-15.2669,23.1365],locationCordinates:[-15.2669,23.1365],markerCordinates:[-15.2669,23.1365],zoom:8,},
{ sdistrictName:'Lunga',cordinates:[-11.5473,30.1199],locationCordinates:[-11.5473,30.1199],markerCordinates:[-11.5473,30.1199],zoom:8,},
{ districtName:'Sikongo',cordinates:[-15.038,22.1557],locationCordinates:[-15.038,22.1557],markerCordinates:[-15.038,22.1557],zoom:8,},
{ districtName:'Kalumbila',cordinates:[-12.2229,25.4107],locationCordinates:[-12.2229,25.4107],markerCordinates:[-12.2229,25.4107],zoom:8,},
{ districtName:'Zambezi',cordinates:[-13.5501,23.1152],locationCordinates:[-13.5501,23.1152],markerCordinates:[-13.5501,23.1152],zoom:8,},
{ districtName:'Nalolo',cordinates:[-15.5401,23.1152],locationCordinates:[-15.5401,23.1152],markerCordinates:[-15.5401,23.1152],zoom:8,},

];
