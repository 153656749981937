import LOCATION_CONST from './const';

const Location = (state, action) => {
  if (typeof state == 'undefined') {
    state = {
      isFetching: true,
      countryList: [],
      stateList: [],
      districtList: [],
      locationConventionList: [],
    };
  }
  switch (action.type) {
    //get country
    case LOCATION_CONST.COUNTRY_LIST_SUCCESS:
      return {
        ...state,
        countryList: action.payload.response ? action.payload.response.data : [],
      };
    //get state
    case LOCATION_CONST.STATE_LIST_SUCCESS:
      return {
        ...state,
        stateList: action.payload.response ? action.payload.response : [],
      };
    //get disctrict
    case LOCATION_CONST.DISTRICT_LIST_SUCCESS:
      return {
        ...state,
        districtList: action.payload.response ? action.payload.response : [],
      };
    //get disctrict
    case LOCATION_CONST.LOCATION_CONVENTION_SUCCESS:
      return {
        ...state,
        locationConventionList: action.payload.response ? action.payload.response.data : [],
      };
    //get country Back office
    case LOCATION_CONST.COUNTRY_LIST_BO_SUCCESS:
      return {
        ...state,
        countryList: action.payload.response ? action.payload.response.data : [],
      };
    default:
      return state;
  }
};

export default Location;
