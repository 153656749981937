import React from 'react';
import { Modal, ModalBody, Row, Col } from 'reactstrap';
import _ from 'lodash';
import { MdOutlineClose } from 'react-icons/md';
import { Link } from 'react-router-dom';
import { t } from 'react-multi-lang';
import { DEFAULT_COUNTRY } from '../../../Components/constants';

const DroughtCheckModal = props => {
  const { isDrought, isOpen, toggle, selectedLocation } = props;
  return (
    <div>
      <Modal
        isOpen={isOpen}
        toggle={toggle}
        className="drought-check-modal"
        backdrop="static"
        keyboard={false}
      >
        <div className="alert-heading">
                <div className="header-text">
              <i
                className="cursor-ponter fa fa-exclamation-triangle info-icon-edit-btn"
                />&nbsp;&nbsp;
              <h6 className="heading">Drought Alert</h6>
              </div>
              <span className="cursor-ponter close-drought-icon-new" onClick={toggle}>
                <MdOutlineClose size={20} />
              </span>
              </div>
        <ModalBody>
          <Row>
            <Col sm={11} md={11} lg={11}>
              <div className="text-center">
                {isDrought ? (
                  <span style={{fontSize:"16px",color:'#28537d'}}>
                   
                    {selectedLocation?.country?.id === DEFAULT_COUNTRY?.id ? (
                      <>
                         {t('DROUGHT_DSS.CHANCE_TO_DROUGHT')}{' '}
                        <span>{t('DROUGHT_DSS.FULL_STOP')}</span>
                      </>
                      ) 
                      : (
                      <>
                         {t('DROUGHT_DSS.EXTRA_TEXT_FOR_NON_INDIA_COUNTRY')}
                      </>
                    )}
                  </span>
                ) : (
                  <span>{t('DROUGHT_DSS.NO_CHANCE_TO_DROUGHT')}</span>
                )}
              </div>
            </Col>
            {/* <Col sm={1} md={1} lg={1}>
              <span className="cursor-ponter close-drought-icon" onClick={toggle}>
                <MdOutlineClose size={20} />
              </span>
            </Col> */}
          </Row>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default DroughtCheckModal;
