import React, { useEffect, useRef, useState, createRef } from 'react';
import { createJSON, getNestedObjectData } from '../../Components/helpers';
import _ from 'lodash';
import {
  getCountryRequest,
  getIndicesRequest,
  getStateByCountryRequest,
  getDistrictByStateRequest,
  getAllTemplateListRequest,
} from '../../redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import { DEFAULT_COUNTRY, LOCATION, MAP_PREFERENCES_lABELS } from '../../Components/constants';
import { API_BASE_URL, AXIOS_INSTANCE } from '../../redux/api/apiEndPoint';
import OnlineBulletinModal from './OnlineBulletinModal';
import Loader from '../../Components/common/loader';
import { showError } from '../../Components/Notifications';
import Header from './Header';
import Branding from './Content/BulletinBranding';
import BulletinPreview from './Content/BulletinPreview';
import BulletinFooter from './Footer';
import { useHistory } from 'react-router-dom';
import {isLogedIn } from '../../Components/common/utils';

function OnlineBulletin() {
  const history = useHistory();
  const elementToPrint = 'contentToPrint';
  const dispatch = useDispatch();
  const [selectedLocation, setSelectedLocation] = useState({ country:"", district: '', state: '' });
  const [loadingLayers, setLoadingLayers] = useState(false);
  const [mapURLResponse, setMapURLResponse] = useState({});
  const [indicatorsList, setIndicatorsList] = useState([]);
  const [mapInstances, setMapInstances] = useState({});
  const [selectedCountryId, setSelectedCountryId] = useState(null);
  const [selectedStateId, setSelectedStateId]= useState(null);
  const [indicatorRows, setIndicatorRows] = useState({});
  const [eachRowAPIPayload, setEachRowAPIPayload] = useState({});
  const [mapRefs, setMapRefs] = useState({});
  const layerRef = useRef([]);
  const [title,setTitle]=useState();
  let queryPrams =
    (window.location.search && createJSON(window.location.search.substring(1))) || {};
  /**
   * value and toggle for create bulletin model
   */
  const [isOpenBulletin, setIsOpenBulletin] = useState(false);
  const toggleModal = () => {
    setIsOpenBulletin(!isOpenBulletin);
  };

  /**
   * on mounting
   */
  useEffect(() => {
    if (!isLogedIn()) {
      let path = window.location.pathname
      history.push(`/login?prevPath=${path.substring(1,path.length)}`);
    }
  setSelectedLocation({country:"",district: '', state: '' })
    dispatch(getCountryRequest({ location_type: LOCATION.Country, parent_id: '' }));
    dispatch(getAllTemplateListRequest({}));
    //unmounting
    return () => {
      setMapInstances({});
      setMapRefs({});
    };
  }, []);

  //retrieve  country ,state ,district and boundaries from store
  const { LocationData, DroughtIndicesData, getAllTemplateListData } = useSelector(state => ({
    LocationData: state.Location,
    getAllTemplateListData: state.Bulletin.getAllTemplateListData,
    DroughtIndicesData: state.AccessKey.getIndicesData,
  }));

  useEffect(() => {
    if (LocationData.countryList?.result) {
      const { result } = LocationData.countryList;
      setSelectedLocation(prev => ({ ...prev, country: result[0] }));
    }
  }, [LocationData]);

  useEffect(() => {
    if (
      queryPrams.country &&
      queryPrams?.indicators?.split(',')?.length &&
      _.get(LocationData, 'stateList.data.result') &&
      _.get(LocationData,'districtList.data.result')
    ) {
      let selectedState = '';
      selectedState = _.get(LocationData, 'stateList.data.result')?.find(
        e => e.name === queryPrams.state,
      );

      let selectedDistrict = '';
      selectedDistrict = _.get(LocationData, 'districtList.data.result')?.find(
        e => e.name === queryPrams.district,
      );

      setSelectedLocation(prev => {
        return {
          ...prev,
          state: queryPrams.state ? selectedState : prev.state,
           district : queryPrams.district ? selectedDistrict : prev.district,
        };
      });
    }
  }, [queryPrams.country, queryPrams.indicators, LocationData.stateList, LocationData.districtList]);

  /**
   * to call multiple map api's based on indicators on mounting with queryparams
   */
  useEffect(() => {
    if (
      Object.keys(queryPrams)?.length &&
      queryPrams.country &&
     // queryPrams.state &&
      queryPrams.startDate &&
      queryPrams.endDate &&
      indicatorsList?.length &&
      queryPrams?.indicators?.split(',')?.length
    ) {
      layerRef.current = [];
      const MAP_API_URLS = [];
      queryPrams.indicatorsList = [];
      queryPrams.indicators?.split(',').forEach(item => {
        indicatorsList.forEach(indicator => {
          if (item === indicator.name) {
            queryPrams.indicatorsList.push(indicator);
          }
        });
      });
      queryPrams.indicatorsList.forEach(item => {
        const ref = createRef();
        layerRef.current.push(ref);
        const config = {
          headers: {
            Authorization: `Api-Key ${process.env.REACT_APP_API_TOKEN}`,
          },
        };
        const requestObj = {
          state_name: queryPrams.state,
          district_name: queryPrams.district,
          precipitation_threshold: '2.5',
          temporal_aggregation: 'mean',
          country_name: DEFAULT_COUNTRY.name,
          start_date: queryPrams.startDate,
          end_date: queryPrams.endDate,
          indicatorName: item.name,
        };
        MAP_API_URLS.push(
          AXIOS_INSTANCE.post(`${API_BASE_URL}/${item.api_slug}/map`, requestObj, config),
        );
      });
      setLoadingLayers(true);

      let promises = [];
      MAP_API_URLS.forEach(item => {
        promises.push(Promise.resolve(item).catch(err => err.response));
      });
      Promise.all(promises)
        .then(response => {
          let res = JSON.parse(JSON.stringify(response));
          setMapURLResponse({ [`row0`]: res });
          setLoadingLayers(false);
        })
        .catch(err => {
          setLoadingLayers(false);
          const errorMessage = _.get(err, 'response.data.message');
          if (errorMessage?.length) {
            showError(errorMessage[0]);
          }
        });
      // saving first row as base row
      setIndicatorRows(prev => ({
        ...prev,
        [`row0`]: { ...queryPrams },
      }));
    }
  }, [
   // queryPrams.state,
    queryPrams.district,
    indicatorsList,
    queryPrams.indicators,
    queryPrams.startDate,
    queryPrams.endDate,
  //  queryPrams.country
  ]);

  /**
   * to call multiple map api's at once based on indicators list everytime when row added
   */
  const isAPICalling = useRef(true);
  useEffect(() => {
    if (isAPICalling.current) {
      isAPICalling.current = false;
      return;
    }
    const MAP_API_URLS = [];
    if (Object.keys(eachRowAPIPayload)?.length) {
      const row = eachRowAPIPayload;

      row.indicatorsList.forEach(item => {
        const ref = createRef();
        layerRef.current.push(ref);
        const config = {
          headers: {
            Authorization: `Api-Key ${process.env.REACT_APP_API_TOKEN}`,
          },
        };
        const requestObj = {
        //  state_name: row.state,
          precipitation_threshold: '2.5',
          temporal_aggregation: 'mean',
         country_name: DEFAULT_COUNTRY.name,
          start_date: row.startDate,
          end_date: row.endDate,
          indicatorName: item.name,
        };
        if(row.district){
          requestObj.district_name = row.district
        }
        if(row.state){
          requestObj.state_name= row.state
        }
        MAP_API_URLS.push(
          AXIOS_INSTANCE.post(`${API_BASE_URL}/${item.api_slug}/map`, requestObj, config),
        );
      });

      if (MAP_API_URLS?.length) {
        setLoadingLayers(true);
        let promises = [];
        const uniqURLS = _.uniq(MAP_API_URLS);
        uniqURLS.forEach((item, key) => {
          promises.push(Promise.resolve(item).catch(err => err.response));
        });

        Promise.all(promises)
          .then(response => {
            const res = JSON.parse(JSON.stringify(response));
            // setMapURLResponse(res);
            setMapURLResponse(prev => ({
              ...prev,
              [`row${Object.keys(indicatorRows).length - 1}`]: [...res],
            }));

            setLoadingLayers(false);
          })
          .catch(err => {
            setLoadingLayers(false);
            const errorMessage = _.get(err, 'response.data.message');
            if (typeof errorMessage !== 'string' && errorMessage?.length) {
              showError(errorMessage[0]);
            } else {
              showError(errorMessage);
            }
          });
      }
    }
  }, [eachRowAPIPayload]);

  /**
   * runs when province changes
  //  */
  // useEffect(() => {
  //   if (eachRowAPIPayload.state && _.get(LocationData, 'stateList.data.result')) {
  //     let selectedState = '';
  //     selectedState = _.get(LocationData, 'stateList.data.result').find(
  //       e => e.name === eachRowAPIPayload.state,
  //     );
  //     setSelectedLocation(prev => {
  //       return {
  //         ...prev,
  //         state: selectedState,
  //       };
  //     });
  //   }
  // }, [eachRowAPIPayload.state, LocationData.stateList]);

  /**
   * to get province and indices based on country id
   */
  const isCountryAPI = useRef(true);
  useEffect(() => {
    if (isCountryAPI.current) {
      isCountryAPI.current = false;
      return;
    }
    if (selectedLocation?.country?.id) {
      if (selectedLocation.country.id !== selectedCountryId) {
        setSelectedCountryId(selectedLocation.country.id);
        if (!DroughtIndicesData) {
          dispatch(
            getIndicesRequest({
              requestObj: {
                type: MAP_PREFERENCES_lABELS.COUNTRY,
                type_id: selectedLocation.country.id,
              },
              module: 'drought-monitor',
            }),
          );
        }
        dispatch(
          getStateByCountryRequest({
            requestObj: {
              location_type: LOCATION.State,
              parent_id: selectedLocation.country.id,
            },
            isCompareLocation: false,
          }),
        );
    }
    if (selectedLocation?.state?.id){
      if(selectedLocation.state.id !== selectedStateId){
        setSelectedStateId(selectedLocation.state.id);
        dispatch(
          getDistrictByStateRequest({
            requestObj: {
              location_type: LOCATION.District,
              parent_id: selectedLocation.state.id,
            },
            isCompareLocation: false,
          }),
        );
      }
    }  
  }}, [DroughtIndicesData, selectedLocation.country, selectedLocation.state]);



  /**TODO indicators will change based on client requirement */
  const selectedIndicators = [
    'NDVI',
    'Crop Cover',
    'VCI',
    'Extreme Rainfall',
    'Accumulated Rainfall',
  ];
  useEffect(() => {
    if (_.get(DroughtIndicesData, 'data.result')?.length) {
      let filteredIndicatorsList = [];
      selectedIndicators.forEach(item => {
        filteredIndicatorsList.push(
          getNestedObjectData(_.get(DroughtIndicesData, 'data.result'), item),
        );
      });
      if (filteredIndicatorsList?.length) {
        let mapReferences = {};
        filteredIndicatorsList.map(item => {
          /**
           * to create empty refs initially based on indicators
           */
          mapReferences[item.name] = createRef(null);
          mapReferences[`row0${item.name}`] = createRef(null);
        });
        setMapRefs(mapReferences);
      }
      setIndicatorsList(filteredIndicatorsList);
    }
  }, [DroughtIndicesData]);

  /**
   *function to store dynamic map instances based on indicators
   */
  const setMapReference = (mapInstance, keyName) => {
    if (mapRefs[keyName]) {
      mapRefs[keyName].current = mapInstance;
    }
    setMapInstances(prev => ({ ...prev, [`mapInstance${keyName}`]: mapInstance }));
  };

  /**
   *function to add layers in the map
   */
  const addLayers = (selectedParamRef, keyName) => {
    if (mapRefs[keyName]?.current && selectedParamRef.current) {
      const leafletMapRef = mapRefs[keyName].current;
      const firstLayer = selectedParamRef.current;
      [firstLayer].forEach(layer => {
        leafletMapRef.addLayer(layer);
      });
    }
  };
  /**
   *calls whenever mapURLresponses changes
   */
  const isSkipRow = useRef(true);
  useEffect(() => {
    if (isSkipRow.current) {
      isSkipRow.current = false;
      return;
    }
    let layerIndex = 0;
    if (Object.keys(mapURLResponse).length === Object.keys(indicatorRows).length) {
      Object.keys(indicatorRows).forEach((eachResponse, index) => {
        const row = indicatorRows[eachResponse];
        mapURLResponse[eachResponse].forEach((item, key) => {
          const indicators = row.indicatorsList;
          if (indicators[key]) {
            const keyName = `${eachResponse}${indicators[key].name}`;
            if (item?.status === 200) {
              const map_data = item.data.result.map_data;
              let legendObj = {
                ...map_data,
                id: indicators[key].name,
                add: true,
                parameter: indicators[key].name,
                measure: map_data?.units || '',
                dataSource: indicatorsList.find(e => e.name === indicators[key].name)
                  ?.parentIndiceName,
                fromMapPreferences: false,
              };
              const currentParamRef = layerRef.current[layerIndex];
              if (!mapInstances[`legendObj${keyName}`]) {
                setMapInstances(prev => ({ ...prev, [`legendObj${keyName}`]: legendObj }));
              }
              if (!_.isEmpty(currentParamRef) && !_.isEmpty(currentParamRef?.current)) {
                currentParamRef.current?.eachLayer(layer => {
                  if (map_data) {
                    layer.setUrl(map_data.map_url);
                  }
                });
              }
              if (!_.isEmpty(currentParamRef) && currentParamRef?.current !== null) {
                addLayers(currentParamRef, keyName);
              }
            } else {
              const errorMessage = _.get(item, 'data.message');
              if (typeof errorMessage !== 'string' && errorMessage?.length) {
                showError(`For ${indicators[key]?.name} ${errorMessage[0]} `);
              } else {
                showError(`For ${indicators[key]?.name} ${errorMessage}`);
              }
            }
            layerIndex += 1;
          }
        });
      });
    }
  }, [mapURLResponse]);

  // const country = _.get(LocationData, 'countryList.result')?.find(
  //   e => e.name === queryPrams.country,
  // )

  const bulletinType = _.get(getAllTemplateListData, 'data.data.result')?.find(
    e => e.name === queryPrams.bulletinType,
  );

  const province = _.get(LocationData, 'stateList.data.result')?.find(
    e => e.name === queryPrams.state,
  );

  const district = _.get(LocationData, 'districtList.data.result')?.find(
    e => e.name === queryPrams.district,
  )

  const initialValues = {
   // country : {...country, label:country?.name},
    bulletinType: { ...bulletinType, label: bulletinType?.name },
    province: { ...province, label: province?.name },
    district : {...district, label: district?.name},
  };

  return (
    <>
      <div className="global-top-new">
        <div className="w-100 ">
          <div>
            <Header
              queryPrams={queryPrams}
              elementToPrint={elementToPrint}
              setIsOpenBulletin={setIsOpenBulletin}
              title={title}
            />
            {loadingLayers && <Loader />}{' '}
            <div className="online-bulletin">
              {isOpenBulletin && (
                <OnlineBulletinModal
                  isBulletinPage={true}
                  LocationData={LocationData}
                  setIsOpenBulletin={setIsOpenBulletin}
                  isOpen={isOpenBulletin}
                  toggleModal={toggleModal}
                  indicatorsList={indicatorsList}
                  initialValues={initialValues}
                  setIndicatorRows={setIndicatorRows}
                  setMapRefs={setMapRefs}
                  setEachRowAPIPayload={setEachRowAPIPayload}
                  indicatorRows={indicatorRows}
                  bulletinCategories={_.get(getAllTemplateListData, 'data.data.result')}
                />
              )}
              <div className="empty-space" />
              <div className="contentToPrint mx-4">
                <div id={elementToPrint}>
                  <Branding queryPrams={queryPrams} setTitle={setTitle} title={title}/>
                  <hr className=" mx-4" />
                  <div className="mt-3 px-4">
                    {Object.keys(indicatorRows)?.length ? (
                      <>
                        <BulletinPreview
                          queryPrams={queryPrams}
                          selectedLocation={selectedLocation}
                          setMapReference={setMapReference}
                          layerRef={layerRef}
                          indicatorRows={indicatorRows}
                          mapInstances={mapInstances}
                          mapURLResponse={mapURLResponse}
                          indicatorsList={indicatorsList}
                          setTitle={setTitle}
                        />
                      </>
                    ) : null}
                  </div>
                  <BulletinFooter />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default OnlineBulletin;
