import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

// import { checkHttpStatusResult } from "../../api/apiUtils";

import USER_CONST from './usersConst';

import { addUserSuccess, addUserFailed } from './usersActions';
// eslint-disable-next-line import/no-unresolved

// import Axios from "axios";

// eslint-disable-next-line no-unused-vars

function* addUser(action) {
  try {
    const response = yield call(action.payload);
    // const response = yield call(checkHttpStatusResult, apiResponse);
    if (response) {
      const responseData = {
        response: {
          statusCode: 200,
          data: response,
        },
      };
      yield put(addUserSuccess(responseData));
    } else {
      const responseData = {
        response: {
          statusCode: 200,
          data: response.error,
        },
      };
      yield put(addUserFailed(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(addUserFailed(errorData));
  }
}

function* watchAddUser() {
  yield takeEvery(USER_CONST.ADD_USER_REQUEST, addUser);
}

function* userSaga() {
  yield all([fork(watchAddUser)]);
}

export default userSaga;
