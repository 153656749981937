import React, { useState, useEffect } from 'react';
import { BsFillRecordFill, BsCheckAll } from 'react-icons/bs';
import Loader from '../../Components/common/loader';
import AlertModal from './AlertModal';
import { AiFillCheckCircle } from 'react-icons/ai';
import ReactTooltip from 'react-tooltip';
import {
  Card,
  CardImg,
  CardSubtitle,
  CardTitle,
  Button,
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import '../../assets/CSS/bulletin.css';
import BulletTemplateModal from './BulletinTemplateModal';
import UploadBulletinTypeModal from './UploadBulletinTypeModal';
import InfiniteScroll from 'react-infinite-scroll-component';
import moment from 'moment';
import {
  DISPLAY_DATE_FORMAT,
  BULLETIN_TAB_CONSTS,
  BULLETIN_CODES,
  LOGIN,
} from '../../Components/constants';
import { t } from 'react-multi-lang';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { isSuperAdmin, isStaffUser } from '../../Components/common/utils';
import { showSuccess, showError } from '../../Components/Notifications';
import {
  getAllBulletinRequest,
  bulletinPublishRequest,
  bulletinDeleteRequest,
  bulletinPublishSuccess,
  bulletinDeleteSuccess,
  approveRejectBulletinRequest,
  previewBulletinRequest,
} from '../../redux/bulletin/bulletinActions';
import { userDetailRequest, userPermissionsRequest } from '../../redux/user-list/userListAction';
import _ from 'lodash';
import RightArrow from '../../assets/Images/back-button.svg';
//import DummyImage from '../..//assets/Images/Frame 8239.jpg';
import NewDummyImage from "../..//assets/Images/Zambia_map.png";

const BackOffcieBulletin = props => {
  const dispatch = useDispatch();
  let form = new FormData();

  const [bulletModal, setBulletModal] = useState(false);
  const [bulletinTypeModal, setBulletTypeModal] = useState(false);
  const [activeTab, setActiveTab] = useState(BULLETIN_TAB_CONSTS.PENDING);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageCount, setPageCount] = useState();
  const [bulletinStatus, setBulletinStatus] = useState(0);
  const [dataList, setDataList] = useState([]);
  const [superAdmin, setSuperAdmin] = useState(isSuperAdmin());
  const [userPermisions, setUserPermisions] = useState([]);
  const [isPublishModal, setIsPublishModal] = useState(false);
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const [updateBulletin, setUpdateBulletin] = useState('');
  const [showChangeStatus, setShowChangeStatus] = useState();
  const [Buttonclicked, setButtonclicked] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [textAreaCount, ChangeTextAreaCount] = useState(0);
  const [rejectionText, setRejectionText] = useState('');
  const [deleteData, setDeleteData]=useState()


  const { Bulletin, isApproveRejectRequesting } = useSelector(state => ({
    isApproveRejectRequesting: state.Bulletin.isApproveRejectRequesting,
    Bulletin: state.Bulletin,
  }));

  const nextProps = useSelector(state => ({
    userDetailData: state.userList.userDetailData || null,
    userPermissionsData: state.userList.userPermissionsData || null,
  }));

  const history = useHistory();

  const bulletinStatusConstants = {
    Created: 'Created',
    Pending: '0',
    Approved: '1',
    Rejected: '2',
  };

  useEffect(() => {
    if (!isStaffUser() && !isSuperAdmin()) {
      history.push('/home');
    }
    dispatch(userDetailRequest());
    dispatch(userPermissionsRequest());
    setDataList([]);
    return () => {
      setDataList([]);
      dispatch(bulletinPublishSuccess({}));
      dispatch(bulletinDeleteSuccess({}));
    };
  }, []);

  useEffect(() => {
    if (nextProps.userPermissionsData && nextProps.userDetailData) {
      const {
        data: { result },
      } = nextProps?.userPermissionsData;
      const {
        data: {
          result: { user_permissions },
        },
      } = nextProps?.userDetailData;
      let a = [];
      if (user_permissions) {
        BULLETIN_CODES.map(i => {
          user_permissions?.map(j => {
            if (i == j.codename) {
              a?.push(i);
            }
          });
        });
      }
      setUserPermisions(a);
    }
  }, [nextProps.userPermissionsData, nextProps.userDetailData]);

  useEffect(() => {
    if (!_.isEmpty(Bulletin?.bulletinPublishData?.data?.data)) {
      setDataList([]);
      showSuccess(Bulletin?.bulletinPublishData?.data?.data?.result);
    }
    setIsPublishModal(false);
  }, [Bulletin.bulletinPublishData]);

  useEffect(() => {
    if (!_.isEmpty(Bulletin?.isBulletinDeleteData?.data?.data)) {
      setDataList([]);
      showSuccess(Bulletin?.isBulletinDeleteData?.data?.data?.result);
    }
    setIsDeleteModal(false);
  }, [Bulletin.isBulletinDeleteData]);

  useEffect(() => {
    let currentTab = props.match.params.currentTab;
    if (currentTab) {
      switch (currentTab) {
        case BULLETIN_TAB_CONSTS.CREATED:
          setActiveTab(BULLETIN_TAB_CONSTS.CREATED);
          break;
        case BULLETIN_TAB_CONSTS.PENDING:
          setActiveTab(BULLETIN_TAB_CONSTS.PENDING);
          break;
        case BULLETIN_TAB_CONSTS.APPROVED:
          setActiveTab(BULLETIN_TAB_CONSTS.APPROVED);
          break;
        case BULLETIN_TAB_CONSTS.REJECTED:
          setActiveTab(BULLETIN_TAB_CONSTS.REJECTED);
          break;
        default:
          /**
           * added by :sai krishna thalla
           * reason : after logging out page is not redirecting to login page
           * Date : 6-sep-2022
           */
          if (currentTab !== LOGIN) {
            props.history.push(`/back-office-bulletin/${BULLETIN_TAB_CONSTS.PENDING}`);
            break;
          } else {
            props.history.push('/login');
          }
      }
    } else {
      //props.history.push(`/back-office-bulletin/${BULLETIN_TAB_CONSTS.PENDING}`);
      if (currentTab == LOGIN) props.history.push('/login');
    }
  }, [props.match.params.currentTab]);

  const toggle = tab => {
    props.history.push(`/back-office-bulletin/${tab}`);
  };

  useEffect(() => {
    if (Bulletin?.getAllBulletinData?.data) {
      let new_list = [];
      const {
        data: { result },
      } = Bulletin?.getAllBulletinData;
      if (result) {
        if (dataList?.length) {
          if (pageNumber > 1) {
            new_list = dataList?.concat(result?.results);
          } else {
            new_list = result?.results;
          }
        } else {
          new_list = result?.results;
        }
        setDataList(new_list);
        setPageCount(result?.count);
      } else {
        setDataList([]);
      }
    }
  }, [Bulletin.getAllBulletinData]);

  useEffect(() => {
    setDataList([]);
    if (_.isEmpty(dataList)) {
      setPageNumber(1);
      setDataList([]);
      setBulletinStatus(bulletinStatusConstants[activeTab]);
      dispatch(
        getAllBulletinRequest({
          page: 1,
          data: { bulletin_status: bulletinStatusConstants[activeTab], is_delete: 'False' },
        }),
      );
    }
  }, [activeTab]);

  useEffect(() => {
    if (_.isEmpty(dataList)) {
      setPageNumber(1);
      setBulletinStatus(bulletinStatusConstants[activeTab]);
      dispatch(
        getAllBulletinRequest({
          page: 1,
          data: { bulletin_status: bulletinStatusConstants[activeTab], is_delete: 'False' },
        }),
      );
    }
  }, [dataList]);

  useEffect(() => {
    if (Bulletin?.getPreviewBulletinData?.data?.data?.result) {
      window.open(Bulletin?.getPreviewBulletinData?.data?.data?.result);
    }
  }, [Bulletin.getPreviewBulletinData]);

  useEffect(() => {
    if (Bulletin?.approveRejectData && Buttonclicked) {
      showSuccess(Bulletin?.approveRejectData?.data?.data?.result);
     // setOpenModal(!openModal);
    }
  }, [Bulletin.approveRejectData]);

  useEffect(() => {
    if (Bulletin?.approveRejectDataError?.data?.response?.data?.message && Buttonclicked) {
      showError(Bulletin?.approveRejectDataError?.data?.response?.data?.message);
    }
  }, [Bulletin.approveRejectDataError]);

  //needed in future
  
  // const cardHandler = data => {
  //   let list = [];
  //   let defaultList = {};
  //   let currentTab = props.match.params.currentTab;
  //   if (activeTab === BULLETIN_TAB_CONSTS.APPROVED || activeTab === BULLETIN_TAB_CONSTS.REJECTED) {
  //     if (data?.template_id) {
  //       history.push({
  //         pathname: '/create-bulletin',
  //         state: [list, defaultList, data, 'true', userPermisions, currentTab, 'staffUser'],
  //       });
  //     } else {
  //       history.push({
  //         pathname: '/create-bulletinPdf',
  //         state: [list, defaultList, data, 'true', userPermisions, currentTab, 'staffUser'],
  //       });
  //     }
  //   } else {
  //     if (data?.template_id) {
  //       history.push({
  //         pathname: '/create-bulletin',
  //         state: [list, defaultList, data, 'true', userPermisions, currentTab, 'staffUser'],
  //       });
  //     } else {
  //       history.push({
  //         pathname: '/create-bulletinPdf',
  //         state: [list, defaultList, data, 'true', userPermisions, currentTab, 'staffUser'],
  //       });
  //     }
  //   }
  // };

  const fetchNextTemplateData = () => {
    setPageNumber(pageNumber + 1);
    dispatch(
      getAllBulletinRequest({
        page: pageNumber + 1,
        data: { bulletin_status: bulletinStatus, is_delete: 'False' },
      }),
    );
  };

  const handleBulletinPublish = (data, { is_publish }) => {
    dispatch(bulletinPublishRequest({ id: data?.id, is_publish: is_publish }));
  };

  const handlePublishModal = (e, data) => {
    e?.stopPropagation();
    setShowChangeStatus(data?.is_publish);
    setIsPublishModal(!isPublishModal);
    if (!isPublishModal) setUpdateBulletin(data);
  };

  const handleDeleteModal = (e, data) => {
    e?.stopPropagation();
    setIsDeleteModal(!isDeleteModal);
    if (!isDeleteModal) setUpdateBulletin(data);
  };

  const handleDelete = value => {
    dispatch(bulletinDeleteRequest(value));
  };
  const handleCreateBulletinPdf = () => {
    history.push('/create-bulletinPdf');
  };

  const handleModalSubmitReject = () => {
    form.append('id', deleteData.id);
    form.append('reject_response', rejectionText);
    form.append('bulletin_status', '2');
    dispatch(approveRejectBulletinRequest(form));
   setButtonclicked(true);
   setOpenModal(!openModal);
  };
  const handleRejectionText = e => {
    setRejectionText(e?.target?.value);
    ChangeTextAreaCount(e?.target?.value?.length);
  };

  const handleModal = () => {
    setOpenModal(!openModal);
  };
  const handlePreview = (e,i) => {
    e?.stopPropagation();
   dispatch(previewBulletinRequest(i.id));
  };

  const handleApprove = (e,i) => {
    e?.stopPropagation();
  //  setApproveButtonLoader(true);
    form.append('id', i.id);
    form.append('bulletin_status', '1');
   dispatch(approveRejectBulletinRequest(form));
    setButtonclicked(true);
  };
  const handleReject = (e,i) => {
    e?.stopPropagation();
    setOpenModal(true);
    setDeleteData(i)
  };

  return (
    <>
      {isPublishModal && (
        <>
          <AlertModal
            isOpen={isPublishModal}
            isToggle={handlePublishModal}
            onClick={handleBulletinPublish}
            data={updateBulletin}
            onPublishClick={true}
            onDeleteClick={false}
          />
        </>
      )}
      {isDeleteModal && (
        <>
          <AlertModal
            isOpen={isDeleteModal}
            isToggle={handleDeleteModal}
            onClick={handleDelete}
            data={updateBulletin}
            onDeleteClick={true}
            onPublishClick={false}
          />
        </>
      )}
       <Modal isOpen={openModal} className="bullet-modal">
        <ModalHeader toggle={handleModal} className="reason-modal-header">
          {t('BULLET_IN.REJECTION_REASON')}
        </ModalHeader>
        <ModalBody>
          <div className="reject-char-count">
            <p>{textAreaCount}/500</p>
          </div>
          <textarea
            type="text"
            rows={5}
            placeholder={t('BULLET_IN.ENTER_SOMETHING_HERE')}
            className="approve-editor-input"
            maxLength={500}
            onChange={e => {
              handleRejectionText(e);
            }}
          />
        </ModalBody>
        <ModalFooter>
          <Button
            className="btn btn-block st-btn-custom mt-0 create-template-save-update"
            disabled={_.isEmpty(rejectionText?.replace(/\s/g, ''))}
            onClick={() => {
              handleModalSubmitReject();
            }}
            loading={isApproveRejectRequesting}
          >
            {t('BULLET_IN.SUBMIT')}
          </Button>
        </ModalFooter>
      </Modal>
      <div className="bulletin-header bulletin-heading-background">
        <Row className="bulletin-top-text">
          <Col xs={2} lg={4}>
            <img
              src={RightArrow}
              className="back-offc-bulletin-header-margin"
              onClick={() => history.push('/settingsDashboard')}
            />
            <h3 className="bulletin-header-h3 d-inline-block">{t('BULLET_IN.ALL_BULLETINS')}</h3>
          </Col>
        <Col xs={8} lg={6}>
         {
          <div className='d-flex justify-content-end'>
            <Button
              className="bulletin-login-btn-grn-key my-3 create-bulletin bulletin-button-margin"
              onClick={() => history.push('/create-bulletinPdf')}
            >
              {t('BULLET_IN.CREATE_BULLETINS')}
            </Button>
            </div>
         }
          </Col> 
        </Row>
      </div>
      <Row className="Nav-tab">
        <Nav tabs>
          <Col lg={4}>
            <NavItem className="bulletin-nav-item">
              <NavLink
                className={activeTab === BULLETIN_TAB_CONSTS.PENDING ? 'navText active ' : ''}
                onClick={() => {
                  toggle(BULLETIN_TAB_CONSTS.PENDING);
                }}
              >
                {t('BULLET_IN.PENDING')}
              </NavLink>
            </NavItem>
          </Col>
          <Col lg={4}>
            <NavItem className="bulletin-nav-item">
              <NavLink
                className={activeTab === BULLETIN_TAB_CONSTS.APPROVED ? 'navText active ' : ''}
                onClick={() => {
                  toggle(BULLETIN_TAB_CONSTS.APPROVED);
                }}
              >
                {t('BULLET_IN.APPROVED')}
              </NavLink>
            </NavItem>
          </Col>
          <Col lg={4}>
            <NavItem className="bulletin-nav-item">
              <NavLink
                className={activeTab === BULLETIN_TAB_CONSTS.REJECTED ? 'navText active ' : ''}
                onClick={() => {
                  toggle(BULLETIN_TAB_CONSTS.REJECTED);
                }}
              >
                {t('BULLET_IN.REJECTED')}
              </NavLink>
            </NavItem>
          </Col>
        </Nav>
      </Row>
      <div className="loader-div">{Bulletin.isGetAllBulletinRequesting && <Loader />}</div>
      {_.isEmpty(dataList) &&
        !Bulletin.isGetAllBulletinRequesting &&
        Bulletin.getAllBulletinData && (
          <h2 className="bulletin-template-modal-no-records-found">
            {t('BULLET_IN.NO_RECORDS_FOUND')}
          </h2>
        )}
      <InfiniteScroll
        dataLength={pageNumber * 10 < pageCount ? pageNumber * 10 : ''}
        next={fetchNextTemplateData}
        hasMore={pageNumber * 10 < pageCount ? true : false}
        loader={
          Bulletin.isGetAllBulletinRequesting && (
            <center>
              <h4>{t('BULLET_IN.LOADING')} </h4>
            </center>
          )
        }
        className="flex flex-wrap"
      >
        <div className="mt-3">
          <Row className="m-3">
            {bulletModal && (
              <BulletTemplateModal isOpen={bulletModal} toggleModal={setBulletModal} />
            )}
            {bulletinTypeModal && (
              <UploadBulletinTypeModal
                isOpen={bulletinTypeModal}
                toggleModal={setBulletTypeModal}
                handleCreateBulletinPdf={handleCreateBulletinPdf}
                setBulletModal={setBulletModal}
              />
            )}
            {dataList?.length ? (
              dataList?.map((i, index) => {
                return (
                  <Col className=" bulletin-template-modal-card-col" lg={3} key={index}>
                    <div className="position-relative">
                      <Card
                        className="bulletin-modal-cards mt-3 "
                      >
                        <CardImg
                          src={i?.content_file || NewDummyImage }
                          top
                          width="100%"
                          className="back-office-bulletin-card-Image"
                        />
                        <div className="m-3">
                          <CardTitle tag="h5">
                            {i?.topic?.length
                              ? i?.topic?.length > 29
                                ? i?.topic?.substring(0, 29) + '...'
                                : i?.topic
                              : ''}
                          </CardTitle>
                          <CardSubtitle className="mb-2 text-muted" tag="h6">
                            {moment(i?.updated_date).format(DISPLAY_DATE_FORMAT)}
                          </CardSubtitle>
                          {!i?.is_publish ? (
                            activeTab !== BULLETIN_TAB_CONSTS.REJECTED ? (
                              activeTab === BULLETIN_TAB_CONSTS.APPROVED ? (
                                <div className="back-office-bulletin-In-progress">
                                  <BsFillRecordFill />
                                  <span>{t('BULLET_IN.APPROVED')}</span>
                                </div>
                              ) : (
                                <div className="back-office-bulletin-In-progress">
                                  <BsFillRecordFill />
                                  <span>{t('BULLET_IN.IN_PROGRESS')}</span>
                                </div>
                              )
                            ) : (
                              <div className="back-office-bulletin-In-progress">
                                <span>{t('BULLET_IN.REJECTED')}</span>
                              </div>
                            )
                          ) : activeTab !== BULLETIN_TAB_CONSTS.REJECTED ? (
                            <div className="back-office-bulletin-Publish">
                              <BsCheckAll />
                              <span>{t('BULLET_IN.PUBLISHED')}</span>
                            </div>
                          ) : (
                            <div className="back-office-bulletin-In-progress">
                              <span>{t('BULLET_IN.REJECTED')}</span>
                            </div>
                          )}
                        </div>{' '}
                        {(isSuperAdmin() || userPermisions?.includes(BULLETIN_CODES[4])) &&
                          activeTab === BULLETIN_TAB_CONSTS.APPROVED &&
                          !i?.is_publish && (
                            <AiFillCheckCircle
                              id="publish_bulletin"
                              className="cursor-ponter bulletin-publish-icon-style position-absolute"
                              //className="bulletin-publish publish-bulletin position-absolute mt-5"
                              data-tip
                              data-for="publish_bulletin"
                              size={23}
                              onClick={e => {
                                handlePublishModal(e, i);
                              }}
                            />
                          )}
                              <div className="icon-div1">
                        <i className="fa fa-eye bulletin-delete-btn" aria-hidden="true"
                         onClick={e => {
                          handlePreview(e, i);
                        }}
                        // loading={Bulletin?.isPreviewBulletinRequesting}
                         id="eye-bulletin"
                              data-tip
                              data-for="eye-bulletin"></i>

                        {(isSuperAdmin() || userPermisions?.includes(BULLETIN_CODES[3]))  &&
                          activeTab === BULLETIN_TAB_CONSTS.PENDING && (
                            <i className="fa fa-check bulletin-delete-btn" aria-hidden="true"
                            onClick={e => {
                              handleApprove(e, i);
                            }}
                           // loading={Bulletin.isApproveRejectRequesting && approveButtonLoader}
                            id="approve-bulletin"
                              data-tip
                              data-for="approve-bulletin"></i>)}

                             {(isSuperAdmin() || userPermisions?.includes(BULLETIN_CODES[3])) && 
                              activeTab !== BULLETIN_TAB_CONSTS.REJECTED &&  (
                              <i className="fa fa-ban bulletin-delete-btn" aria-hidden="true" onClick={e => {
                                handleReject(e, i);
                              }}
                              id="ban-bulletin"
                              data-tip
                              data-for="ban-bulletin"></i>
                                )}

                        {(isSuperAdmin() || userPermisions?.includes(BULLETIN_CODES[2])) &&
                          activeTab === BULLETIN_TAB_CONSTS.PENDING && (
                            <i
                              class="fa fa-trash bulletin-delete-btn"
                              onClick={e => {
                                handleDeleteModal(e, i);
                              }}
                              id="delete-bulletin"
                              data-tip
                              data-for="delete-bulletin"
                            ></i>
                          )}
                          </div>
                        <ReactTooltip id="publish_bulletin" effect="solid" place="top">
                          {t('BULLET_IN.CLICK_TO_PUBLISH')}
                        </ReactTooltip>
                        <ReactTooltip id="delete-bulletin" effect="solid" place="top">
                          {t('BULLET_IN.CLICK_TO_DELETE')}
                        </ReactTooltip>
                        <ReactTooltip id="ban-bulletin" effect="solid" place="top">
                          {t('BULLET_IN.CLICK_TO_REJECT')}
                        </ReactTooltip>
                        <ReactTooltip id="eye-bulletin" effect="solid" place="top">
                          {t('BULLET_IN.CLICK_TO_PREVIEW')}
                        </ReactTooltip>
                        <ReactTooltip id="approve-bulletin" effect="solid" place="top">
                          {t('BULLET_IN.CLICK_TO_APPROVE')}
                        </ReactTooltip>
                      </Card>
                    </div>
                  </Col>
                );
              })
            ) : (
              <></>
            )}
          </Row>
        </div>
      </InfiniteScroll>
    </>
  );
};
export default BackOffcieBulletin;
