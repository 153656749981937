import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { t } from 'react-multi-lang';
import Button from 'reactstrap-button-loader';
import { Field, reduxForm, reset } from 'redux-form';
import { Card, Container, Col, FormGroup, Label, Row } from 'reactstrap';
import { renderTextField } from '../../Components/common/renderInputField';
import { isSuperAdmin, isStaffUser,isLogedIn } from '../../Components/common/utils';
import {
  forgotPassword,
  resetPassword,
  forgotPasswordSuccess,
  resetPasswordSuccess,
  changePassword,
  changePasswordSuccess,
  resetLinkVerificationRequest,
  resetLinkVerificationSuccess,
} from '../../redux/login/loginActions';
import { showSuccess } from '../../Components/Notifications';
import SuccessModal from './Success';
import { isValidEmail } from '../../Components/common/utils';

const validate = values => {
  const errors = {};
  if (values.email && !values.email) {
    errors.email = 'This field is required.';
  } else if (values.email && !isValidEmail(values.email.trim())) {
    errors.email = 'Email is not valid.';
  } else if (!values.new_password) {
    errors.new_password = 'This field is required.';
  } else if (!values.confirm_password) {
    errors.confirm_password = 'This field is required.';
  } else if (values.old_password && !values.old_password) {
    errors.old_password = 'This field is required.';
  } else if (values.new_password && values.new_password.length < 8) {
    errors.new_password = 'Password must be atleast 8 characters.';
  } else if (values.confirm_password) {
    if (values.confirm_password.length < 8) {
      errors.confirm_password = 'Password must be atleast 8 characters.';
    } else if (values.confirm_password !== values.new_password) {
      errors.confirm_password = 'New Password and Confirm Password must be same.';
    }
  }
  return errors;
};

const ResetPassword = props => {
  const { handleSubmit, pristine, submitting, invalid } = props;
  const nextProps = useSelector(state => ({
    isForgotPasswordRequesting: state.Login.isForgotPasswordRequesting,
    isResetPasswordRequesting: state.Login.isResetPasswordRequesting,
    isChangePasswordRequesting: state.Login.isChangePasswordRequesting,
    forgotPasswordData: state.Login.forgotPasswordData,
    resetPasswordData: state.Login.resetPasswordData,
    changePasswordData: state.Login.changePasswordData,
    resetLinkVerificationData: state.Login.resetLinkVerificationData || null,
    resetLinkVerificationError: state.Login.resetLinkVerificationError,
    resetLinkVerificationNetworkError: state.Login.resetLinkVerificationNetworkError,
    changePasswordError: state.Login.changePasswordError,
  }));

  let req_data = {
    email: '',
  };
  const [formData, setFormData] = useState(req_data);
  const [successModal, setSuccessModal] = useState(false);
  const [expiredLink, setExpiredLink] = useState(true);
  const [modalMessage, setModalMessage] = useState();
  const [uniqueCode, setUniqueCode] = useState();
  const [passwordTypes, setPasswordTypes] = useState({
    old_password: true,
    new_password: true,
    confirm_password: true,
  });

  let history = useHistory();
  let hasChangePassword = window.location.href.includes('change-password');
  const dispatch = useDispatch();

  useEffect(() => {
    if (hasChangePassword && !(isSuperAdmin() || isStaffUser() || isLogedIn())) {
      history.push('/home');
    }
    let params = new URLSearchParams(window.location.search);
    let keyValue;
    keyValue = params.get('key');
    if (window.location.href.includes('key')) {
      setUniqueCode(keyValue);
      dispatch(resetLinkVerificationRequest({ id: keyValue }));
    }
  }, []);


  useEffect(() => {
    if (nextProps.resetLinkVerificationData) {
      const {
        data: { result },
      } = nextProps.resetLinkVerificationData;
      if (result) {
        setExpiredLink(false);
      }
    }
  }, [nextProps.resetLinkVerificationData]);

  useEffect(() => {
    if (nextProps.resetLinkVerificationError) {
      const {
        data: { response },
      } = nextProps.resetLinkVerificationError;
      if (response?.data?.message) {
        setExpiredLink(true);
      }
    }
  }, [nextProps.resetLinkVerificationError]);

  useEffect(() => {
    if (nextProps.resetPasswordData) {
      const {
        data: { result },
      } = nextProps.resetPasswordData;
      if (result) {
        showSuccess(result);
        history.push('/login');
      }
    }
  }, [nextProps.resetPasswordData]);

  useEffect(() => {
    if (nextProps.forgotPasswordData) {
      const {
        data: { result },
      } = nextProps.forgotPasswordData;
      if (result) {
        setSuccessModal(true);
        setModalMessage(result);
      }
    }
  }, [nextProps.forgotPasswordData]);

  useEffect(() => {
    if (nextProps.changePasswordData) {
      const {
        data: { result },
      } = nextProps.changePasswordData;
      if (result) {
        showSuccess(result);
        history.push('/login');
      }
    }
  }, [nextProps.changePasswordData]);

  useEffect(() => {
    return () => {
      dispatch(forgotPasswordSuccess({}));
      dispatch(resetPasswordSuccess({}));
      dispatch(changePasswordSuccess({}));
      dispatch(resetLinkVerificationSuccess({}));
    };
  }, []);

  useEffect(() => {
    if (nextProps.changePasswordError) {
      dispatch(reset('reset-password-form'));
    }
  }, [nextProps.changePasswordError]);

  const onFormSubmit = () => {
    if (uniqueCode) {
      delete formData.email;
      formData.unique_code = uniqueCode;
      dispatch(resetPassword(formData));
    } else if (hasChangePassword) {
      delete formData.email;
      dispatch(changePassword(formData));
    } else {
      dispatch(forgotPassword(formData));
    }
  };

  const handleEmailChange = e => {
    formData.email = e.target.value;
    setFormData({ ...formData });
  };

  const handleOkayButtonClick = () => {
    setSuccessModal(false);
    history.push('/home');
  };

  const handleNewPasswordChange = e => {
    formData.new_password = e.target.value;
    setFormData({ ...formData });
  };

  const showOldPassword = () => {
    passwordTypes.old_password = !passwordTypes.old_password;
    setPasswordTypes({ ...passwordTypes });
  };
  const showNewPassword = () => {
    passwordTypes.new_password = !passwordTypes.new_password;
    setPasswordTypes({ ...passwordTypes });
  };

  const showConfirmPassword = () => {
    passwordTypes.confirm_password = !passwordTypes.confirm_password;
    setPasswordTypes({ ...passwordTypes });
  };

  const handleConfirmPasswordChange = e => {
    formData.confirm_password = e.target.value;
    setFormData({ ...formData });
  };

  const handleOldPasswordChange = e => {
    formData.old_password = e.target.value;
    setFormData({ ...formData });
  };

  const handleResetDescription = () => {
    if (!uniqueCode && !hasChangePassword) {
      return <p className="reset-desc">{t('LOGIN.RESET_DESC_ONE')}</p>;
    } else {
      if (!expiredLink) {
        return <p className="reset-desc">{t('REGISTER.PASSWORD_ERROR_MSG')}</p>;
      }
    }
  };

  const handleInputFields = () => {
    if (!uniqueCode && !hasChangePassword) {
      return (
        <>
          <FormGroup className="textOnInput mb-3">
            <Label htmlFor="exampleEmail">{t('LOGIN.EMAIL')}</Label>
            <Field
              component={renderTextField}
              name="email"
              bsSize="sm"
              className="email-input"
              type="text"
              onChange={e => handleEmailChange(e)}
            ></Field>
          </FormGroup>
        </>
      );
    } else {
      return (
        <>
          {hasChangePassword && (
            <FormGroup className="textOnInput mb-3">
              <Label htmlFor="old_password">{t('LOGIN.OLD_PASSWORD')}</Label>
              <Field
                component={renderTextField}
                name="old_password"
                bsSize="sm"
                className="email-input"
                type={passwordTypes.old_password ? 'password' : 'text'}
                onChange={e => handleOldPasswordChange(e)}
              ></Field>
              <span className="reset-password-show-hide" onClick={showOldPassword}>
                {passwordTypes.old_password ? t('LOGIN.SHOW') : t('LOGIN.HIDE')}
              </span>
            </FormGroup>
          )}

          <div className={uniqueCode && expiredLink ? 'disable-password-fields' : ''}>
            <FormGroup className="textOnInput mb-3">
              <Label htmlFor="new_password">{t('LOGIN.NEW_PASSWORD')}</Label>
              <Field
                component={renderTextField}
                name="new_password"
                bsSize="sm"
                className="email-input"
                type={passwordTypes.new_password ? 'password' : 'text'}
                onChange={e => handleNewPasswordChange(e)}
              ></Field>

              <span className="reset-password-show-hide" onClick={showNewPassword}>
                {passwordTypes.new_password ? t('LOGIN.SHOW') : t('LOGIN.HIDE')}
              </span>
            </FormGroup>

            <FormGroup className="textOnInput mb-3">
              <Label htmlFor="confirm_password">{t('LOGIN.CONFIRM_PASSWORD')}</Label>
              <Field
                component={renderTextField}
                name="confirm_password"
                bsSize="sm"
                className="email-input"
                type={passwordTypes.confirm_password ? 'password' : 'text'}
                onChange={e => handleConfirmPasswordChange(e)}
              ></Field>
              <span className="reset-password-show-hide" onClick={showConfirmPassword}>
                {passwordTypes.confirm_password ? t('LOGIN.SHOW') : t('LOGIN.HIDE')}
              </span>
            </FormGroup>
          </div>
        </>
      );
    }
  };


  const handleSendResetButtons = () => {
    if (!uniqueCode && !hasChangePassword) {
      return (<>
        <Button
          className="btn forgot-password-login-btn-grn-key btn-block w-100"
          disabled={pristine || submitting || invalid}
          loading={nextProps.isForgotPasswordRequesting}
        >
          {t('LOGIN.SEND')}
        </Button>
        <div className="text-center w-100 mb-3">
                  <p className="m-0 p-0 login-term-text mt-3 cursor-ponter">
                          {t('LOGIN.BACK_TO')}{' '}
                    <a
                      href="javascript:void(0)"
                      onClick={() => history.push('/login')}
                      className="register-link"
                    >
                          {t('LOGIN.SIGN_IN')}
                    </a>
                  </p>
            </div>
        </>
      );
    } else {
      return (
        <Button
          className="btn login-btn-grn-key btn-block w-100"
          disabled={pristine || submitting || invalid}
          loading={nextProps.isResetPasswordRequesting || nextProps.isChangePasswordRequesting}
        >
          {t('LOGIN.RESET')}
        </Button>
      );
    }
  };

  return (
    <>
      <div className="login-wrapper">
        <Container fluid className="p-0">
          <Row>
            <Col lg={5} sm={7} xs={12} className="mx-auto login-form-wrapper login-zIndex w-100">
              <div className="login-card-wrapper" id="login-card-wrapper">
                <Card className="card-shadow py-4 px-5 login-card">
                  {!successModal ? (
                    <div className="login-form w-100">
                      <div className="pb-3">
                        {hasChangePassword ? (
                          <h5 className="login-key-text mt-5">{t('LOGIN.RESET_PASSWORD')}</h5>
                        ) : (
                          <h5 className="login-key-text mt-5">{t('LOGIN.FORGOT_PASSWORD')}</h5>
                        )}
                        {handleResetDescription()}
                        
                      </div>
                      <form id="reset-password-form" onSubmit={handleSubmit(e => onFormSubmit())}>
                        {handleInputFields()}
                        <div></div>
                        {handleSendResetButtons()}
                      </form>
                      <div className="text-center w-100 mb-3">
                        <p className="m-0 p-0 login-term-text mt-3"></p>
                      </div>
                    </div>
                  ) : (
                    <SuccessModal
                      modalHeaderMessage="Sent Verification Link"
                      okayButtonClickHandler={handleOkayButtonClick}
                      modalSuccessMessage={modalMessage}
                    />
                  )}
                </Card>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};
export default reduxForm({
  form: 'reset-password-form',
  validate,
})(ResetPassword);
