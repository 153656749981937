import React, { useState, useEffect, useRef } from 'react';
import { Label, Input, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Button from 'reactstrap-button-loader';
import { t } from 'react-multi-lang';
import RightArrow from '../../assets/Images/back-button.svg';
import { useLocation, useHistory } from 'react-router-dom';
import Select from 'react-select';
import { renderImageField, renderFileField } from '../../Components/common/renderInputField';
import {
  addBulletinRequest,
  getAllTemplateListRequest,
  updateBulletinRequest,
  approveRejectBulletinRequest,
} from '../../redux/bulletin/bulletinActions';
import { Field, reduxForm } from 'redux-form';
import { useDispatch, useSelector } from 'react-redux';
import { isSuperAdmin, isStaffUser } from '../../Components/common/utils';
import { BULLETIN_CODES } from '../../Components/constants';
import _ from 'lodash';
import { showSuccess, showError } from '../../Components/Notifications';

const CreateBulletinPdf = () => {
  const [formData, setFormData] = useState({
    topic: '',
    content_file: '',
    bulletin_file: '',
    category_id: '',
    id: '',
  });
  const [templateListOptions, setTemplateListOptions] = useState([]);
  const [uploadedImageName, setUploadedImageName] = useState('');
  const [uploadedFileName, setUploadedFileName] = useState('');
  const [userPermisions, setUserPermisions] = useState([]);
  const [Buttonclicked, setButtonclicked] = useState(false);
  const [approveButtonLoader, setApproveButtonLoader] = useState(false);
  const [isImageUploaded, setIsImageUploaded] = useState(false);
  const [rejectionText, setRejectionText] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [textAreaCount, ChangeTextAreaCount] = useState(0);
  const [changeImageUpdate, setChangeImageUpdate] = useState(false);

  const dispatch = useDispatch();
  let form = new FormData();
  const { Bulletin } = useSelector(state => ({
    Bulletin: state.Bulletin,
  }));

  const history = useHistory();
  const location = useLocation();

  const nextProps = useSelector(state => ({
    userDetailData: state.userList.userDetailData || null,
    userPermissionsData: state.userList.userPermissionsData || null,
  }));

  useEffect(() => {
    if (nextProps.userPermissionsData && nextProps.userDetailData) {
      const {
        data: {},
      } = nextProps.userPermissionsData;
      const {
        data: {
          result: { user_permissions },
        },
      } = nextProps?.userDetailData;
      let a = [];
      if (user_permissions) {
        BULLETIN_CODES.map(i => {
          user_permissions?.map(j => {
            if (i === j.codename) {
              a?.push(i);
            }
          });
        });
      }
      setUserPermisions(a);
    }
  }, [nextProps.userPermissionsData, nextProps.userDetailData]);

  const isFirstRun = useRef(true);
  useEffect(() => {
    if (!isFirstRun.current) {
      return;
    }
    if (!_.isEmpty(location?.state?.[2]) && templateListOptions) {
      const selectedCategory = _.find(templateListOptions, {
        label: location?.state[2]?.category_name,
      });
      if (selectedCategory) {
        isFirstRun.current = false;
        setFormData({
          ...formData,
          category_id: selectedCategory,
          topic: location?.state[2]?.topic,
          id: location?.state[2]?.id,
        });
        setChangeImageUpdate(true);
      }
    }
  }, [templateListOptions]);
  useEffect(() => {
    if (isStaffUser() || isSuperAdmin()) {
      dispatch(getAllTemplateListRequest());
    } else {
      history.push('/home');
    }
    window.scroll(0, 0);
  }, []);

  useEffect(() => {
    if (Bulletin?.getAllTemplateListData) {
      let options = renderTemplateListOptions(Bulletin?.getAllTemplateListData)
        ? renderTemplateListOptions(Bulletin?.getAllTemplateListData)
        : null;
      if (options?.length && options) {
        setTemplateListOptions(options);
      }
    }
  }, [Bulletin]);

  useEffect(() => {
    if (Bulletin?.addBulletinData?.data && Buttonclicked) {
      showSuccess(Bulletin?.addBulletinData?.data?.data?.result);
      history.goBack();
    }
  }, [Bulletin.addBulletinData]);

  useEffect(() => {
    if (Bulletin?.updateBulletinData?.data?.data?.result && Buttonclicked) {
      showSuccess(Bulletin?.updateBulletinData?.data?.data?.result);
      history.goBack();
    }
  }, [Bulletin.updateBulletinData]);

  useEffect(() => {
    if (Bulletin?.approveRejectData && Buttonclicked) {
      showSuccess(Bulletin?.approveRejectData?.data?.data?.result);
      history.goBack();
    }
  }, [Bulletin.approveRejectData]);

  useEffect(() => {
    let message =
      Bulletin?.addBulletinDataError?.data?.response?.data?.message ||
      Bulletin?.updateBulletinDataError?.data?.response?.data?.message;
    if (message && message[0] && Buttonclicked) {
      showError(message[0]);
      setButtonclicked(false);
    }
  }, [Bulletin]);

  const renderTemplateListOptions = data => {
    if (data) {
      let list = data?.data?.data?.result;
      let params = [...list];
      let options = [];
      if (params?.length) {
        params.map(param => {
          let data = {
            value: param?.id,
            label: `${param?.name}`,
            id: param?.id,
          };
          options.push(data);
          return true;
        });
        return options;
      }
    }
  };

  const handleCategoryChange = e => {
    setFormData({ ...formData, category_id: e });
  };
  const handleImage = e => {
    handleImageUpdateToggle();
    setFormData({ ...formData, content_file: e?.target?.files[0] });

    setIsImageUploaded(true);
    if (e?.target?.files[0]?.name) {
      setUploadedImageName(e?.target?.files[0]?.name);
    }
  };

  const handleImageUpdateToggle = () => {
    setChangeImageUpdate(false);
  };

  const toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
});

  const handleContent = async e => {
   const base64 = await toBase64(e?.target?.files[0])
   const slicedBase64 = base64.slice(28, )
   setFormData({ ...formData, bulletin_file:slicedBase64 });

    if (e?.target?.files[0]?.name) {
      setUploadedFileName(e?.target?.files[0]?.name);
    }
  };
  const handleTitle = e => {
    setFormData({ ...formData, topic: e?.target?.value });
  };

  const handleSubmit = () => {
    setButtonclicked(true);
    form.append('topic', formData?.topic);
    form.append('content_file', formData?.content_file);
    form.append('bulletin_content', formData?.bulletin_file);
    form.append('category_id', formData?.category_id?.value);
    form.append('template_id', '');
    form.append('html_content', '');
    form.append('json_content', '');
    dispatch(addBulletinRequest(form));
  };

  const handleUpdate = () => {
    setButtonclicked(true);
    form.append('topic', formData?.topic);
    form.append('content_file', formData?.content_file);
    form.append('bulletin_content', formData?.bulletin_file);
    form.append('category_id', formData?.category_id?.value);
    form.append('template_id', '');
    form.append('html_content', '');
    form.append('json_content', '');
    form.append('id', formData?.id);
    dispatch(updateBulletinRequest(form));
  };

  const handleApprove = () => {
    setApproveButtonLoader(true);
    form.append('id', location?.state[2]?.id);
    form.append('bulletin_status', '1');
    dispatch(approveRejectBulletinRequest(form));
    setButtonclicked(true);
  };

  const handleModalSubmitReject = () => {
    form.append('id', location?.state[2]?.id);
    form.append('reject_response', rejectionText);
    form.append('bulletin_status', '2');
    dispatch(approveRejectBulletinRequest(form));
    setButtonclicked(true);
  };

  const handleReject = () => {
    setOpenModal(true);
  };

  const handleRejectionText = e => {
    setRejectionText(e?.target?.value);
    ChangeTextAreaCount(e?.target?.value?.length);
  };

  const handleModal = () => {
    setOpenModal(!openModal);
  };

  const handlePreview = () => {
    window.open(location.state?.[2].bulletin_file);
  };
  return (
    <div className="">
      <span className="col-lg-1 cursor-ponter">
        <img
          src={RightArrow}
          className="create-bulletin-back-button"
          onClick={() => history.goBack()}
        />
      </span>
      <div className="email-editor-container">
        <div className="d-flex justify-content-end">
          {location.state?.[3] === 'true' && (
            <Button
              onClick={handlePreview}
              className="btn btn-block st-btn-custom mt-3 me-3 create-template-save-update"
              loading={Bulletin.isPreviewBulletinRequesting}
            >
              {t('BULLET_IN.PREVIEW')}
            </Button>
          )
          // </a>
          }
          {(isSuperAdmin() || userPermisions?.includes(BULLETIN_CODES[3])) &&
            location.state?.[3] === 'true' &&
            location.state?.[2]?.bulletin_status !== 2 && (
              <Button
                onClick={handleReject}
                className="btn me-3 btn-block mt-3 approve-editor-reject-button"
              >
                {t('BULLET_IN.REJECT')}
              </Button>
            )}
          {(isSuperAdmin() || userPermisions?.includes(BULLETIN_CODES[3])) &&
            location.state?.[3] === 'true' &&
            location.state?.[2]?.bulletin_status !== 1 && (
              <Button
                onClick={handleApprove}
                className="btn btn-block st-btn-custom mt-3 me-3 create-template-save-update"
                loading={Bulletin.isApproveRejectRequesting && approveButtonLoader}
              >
                {t('BULLET_IN.APPROVE')}
              </Button>
            )}
        </div>

        {/* <div className="pdf-editor-form-container mt-3 d-flex justify-content justify-content-end inline-block"> */}
        <Modal isOpen={openModal} className="bullet-modal">
          <ModalHeader toggle={handleModal} className="reason-modal-header">
            {t('BULLET_IN.REJECTION_REASON')}
          </ModalHeader>
          <ModalBody>
            <div className="reject-char-count">
              <p>{textAreaCount}/500</p>
            </div>
            <textarea
              type="text"
              rows={5}
              placeholder={t('BULLET_IN.ENTER_SOMETHING_HERE')}
              className="approve-editor-input"
              maxLength={500}
              onChange={e => {
                handleRejectionText(e);
              }}
            />
          </ModalBody>
          <ModalFooter>
            <Button
              className="btn btn-block st-btn-custom mt-0 create-template-save-update"
              disabled={_.isEmpty(rejectionText?.replace(/\s/g, ''))}
              onClick={() => {
                handleModalSubmitReject();
              }}
              loading={Bulletin?.isApproveRejectRequesting}
            >
              {t('BULLET_IN.SUBMIT')}
            </Button>
          </ModalFooter>
        </Modal>
        {/* </div> */}
        <div
          className={
            location.state === undefined
              ? 'create-bulletin-pdf-container add-buliten-margin'
              : 'create-bulletin-pdf-container mt-3'
          }
        >
          
          <div className="create-bulletin-pdf-editor">
            <div className='d-flex justify-content-center mb-2'>
          <h5 className="st-filter-text news-feed-add-text title-margin-right">{t('BULLET_IN.ADD_BULLETIN')} </h5>
            </div>
            <div className="bulletin-editor-field">
              <Label className="bulletin-field-label">{t('NEWS_FEED.CATEGORY')}</Label>
              <div className="field-width">
                <Select
                  className="upload-image"
                  name="category"
                  id="category"
                  options={templateListOptions}
                  value={formData?.category_id}
                  onChange={e => {
                    handleCategoryChange(e);
                  }}
                  placeholder={t('NEWS_FEED.SELECT_CATEGORY')}
                />
              </div>
            </div>
            <div className="bulletin-editor-field">
              <Label className="bulletin-field-label">{t('NEWS_FEED.SELECT_TITLE')}</Label>
              <div className="field-width">
                <Input
                  className="upload-image"
                  name="title"
                  id="tile"
                  type="text"
                  onChange={e => {
                    handleTitle(e);
                  }}
                  value={formData?.topic}
                  placeholder={t('NEWS_FEED.SELECT_TITLE')}
                />
              </div>
            </div>
            <div className="bulletin-filed-container">
              <div className="bulletin-editor-field mb-0">
                <Label className="bulletin-field-label">{t('BULLET_IN.PREVIEW_IMAGE')}</Label>
                <div className="mb-2 field-width">
                  <Field
                    component={renderImageField}
                    className="upload-image"
                    name="upload_image"
                    id="upload_image"
                    type="file"
                    accept=".png, .jpg, .jpeg"
                    onChange={e => {
                      handleImage(e);
                    }}
                    isMulti={false}
                    placeholder={t('NEWS_FEED.UPLOAD_IMAGE')}
                  ></Field>
                </div>
              </div>
              <div className=" mb-2 selected-image-name text-right">
                {!uploadedImageName && location?.state
                  ? location?.state[2]?.content_file?.split('/').reverse()[0]?.length > 27
                    ? location?.state[2]?.content_file
                        ?.split('/')
                        .reverse()[0]
                        .substring(0, 23) + '...'
                    : location?.state[2]?.content_file?.split('/').reverse()[0]
                  : uploadedImageName?.length > 27
                  ? uploadedImageName?.substring(0, 23) + '...'
                  : uploadedImageName}
              </div>
            </div>

            <div className="bulletin-filed-container">
              <div className="bulletin-editor-field mb-0">
                <Label className="bulletin-field-label">{t('BULLET_IN.CONTENT_FILE')}</Label>
                <div className="mb-2 field-width">
                  <Field
                    component={renderFileField}
                    className="upload-image"
                    name="upload_content"
                    id="upload_content"
                    type="file"
                    accept="application/pdf"
                    onChange={e => {
                      handleContent(e);
                    }}
                    isMulti={false}
                    placeholder={t('NEWS_FEED.UPLOAD_CONTENT')}
                  ></Field>
                </div>
              </div>
              <div className=" mb-2 selected-image-name text-right">
                {!uploadedFileName && location?.state
                  ? location?.state[2]?.bulletin_file?.split('/').reverse()[0]?.length > 27
                    ? location?.state[2]?.bulletin_file
                        ?.split('/')
                        .reverse()[0]
                        .substring(0, 23) + '...'
                    : location?.state[2]?.bulletin_file?.split('/').reverse()[0]
                  : uploadedFileName?.length > 27
                  ? uploadedFileName?.substring(0, 23) + '...'
                  : uploadedFileName}
              </div>
            </div>
            <div className="text-center">
              {!location?.state?.[3] ? ( //If data doesn't exist
                isSuperAdmin() || userPermisions?.includes(BULLETIN_CODES[0]) ? ( //if has add permission
                  <Button
                    onClick={handleSubmit}
                    className="btn btn-block st-btn-custom mt-3 create-template-save-update"
                    loading={Bulletin.isAddBulletinRequesting}
                    disabled={formData.topic === '' || isImageUploaded === false ? true : false}
                  >
                    {t('BULLET_IN.SUBMIT')}
                  </Button>
                ) : (
                  ''
                )
              ) : (location.state?.[2]?.bulletin_status === 0 && isSuperAdmin()) ||
                userPermisions?.includes(BULLETIN_CODES[1]) ? ( //if data exist
                <Button // has update permission
                  onClick={handleUpdate}
                  className="btn btn-block st-btn-custom mt-3 me-3 create-template-save-update"
                  loading={Bulletin.isUpdateBulletinRequesting}
                  disabled={
                    formData.title === '' && (formData.preview_image !== '' || changeImageUpdate)
                      ? true
                      : false
                  }
                >
                  {t('BULLET_IN.UPDATE')}
                </Button>
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
        <div></div>
      </div>
    </div>
  );
};

export default reduxForm({
  form: 'create-bulletin-pdf',
  enableReinitialize: true,
})(CreateBulletinPdf);
