import React from 'react';
import Switch from 'react-switch';
import { Col, Row, Input } from 'reactstrap';
import { DRY_SPELL } from '../../../Components/constants';
import Select from 'react-select';
import IndicesInfoIcons from '../IndicesInfoIcons';

const DroughtStageSwitch = props => {
  const handleChange = item => {
    props.handleDroughtIndices(item);
  };
  return (
    <>
      {props?.droughtIndicesList?.length && props?.droughtIndicesList?.map((i,index) => {
        return (
          <>
            <div className="drought-switch-label" key={index}>
              <Row>
                <Col sm={2} md={2} lg={2} xs={2}>
                  <Switch
                    handleDiameter={24}
                    height={14}
                    width={34}
                    offColor="#33699c"
                    onColor="#33699c"
                    offHandleColor="#edeff2"
                    onHandleColor="#2a547c"
                    checkedIcon={false}
                    uncheckedIcon={false}
                    onChange={() => handleChange(i)}
                    checked={i?.checked}
                    className="stage-options"
                    boxShadow="1px 1px 1px 1px #848F9A"
                    disabled={i?.disabled}
                  />
                </Col>
                <Col sm={10} md={10} lg={10} xs={10} className="position-relative w-75  ">
                  <span>{i?.label}</span>
                  <IndicesInfoIcons
                    checked={false}
                    indicesName={i?.label}
                    dss={true}
                    selectedModule={props?.selectedModule}
                  ></IndicesInfoIcons>
                </Col>
              </Row>
            </div>
            {i?.slug === 'checkdroughtcondition' && i?.checked ? (
              <>
                 <div className="mx-3 mt-2 mb-2">
                  <label htmlFor="threshold">Select Dry spell Day Range</label>
                  <Select
                    id="threshold"
                    name="threshold"
                    placeholder="Select Threshold"
                    options={props?.thresholdDataList?.dryspellList}
                    onChange={props?.handleDryspellThreshold}
                    isMulti={false}
                    required={true}
                    value={props?.thresholdDataList?.selectedDryspellThreshold}
                  />
                </div>
                <div className="mx-3 mt-3 mb-3">
                  <label htmlFor="threshold-spi">Select SPI Threshold</label>
                  <Input
                    type="number"
                    id="threshold-spi"
                    name="threshold-spi"
                    min={props?.thresholdDataList?.spiData?.min.toString()}
                    max={props?.thresholdDataList?.spiData?.max.toString()}
                    value={props?.thresholdDataList?.selectedSpiThreshold}
                    onChange={e => props.handleSPIThreshold(e.target.value)}
                  />
                </div>
              </>
            ):''}
            {i.slug === DRY_SPELL.DRY_SPELL_SLUG ? (
              <>
                <div className="mx-3 mt-3 mb-3">
                  <label htmlFor="quantity">Precipitation Threshold (in mm)</label>
                  <Input
                    type="number"
                    id="quantity"
                    name="quantity"
                    min="0"
                    step="any"
                    //max="10"
                    value={props?.drySpellPrecipitationThreshold}
                    onChange={e => props?.setDrySpellPrecipitationThreshold(e.target.value)}
                  />
                </div>
               
              </>
            ) : (
              ''
            )}
            {i?.slug === 'finaldroughtmap' && i.checked ? (
              <>
                {props?.finalDroughtParameterMonthsList?.length
                  ? props?.finalDroughtParameterMonthsList?.map((i,index) => {
                      return (
                        <>
                          <div className="mx-3 mt-2 mb-2" key={index}>
                            <label htmlFor={i.slug}>{`select ${i.name} Month`}</label>
                            <Select
                              id={i?.slug}
                              name={i?.name}
                              placeholder={`select ${i?.name} Month`}
                              options={i?.options}
                              onChange={(value) => props.handleFinalDroughtParamMonthChange(i.slug,value)}
                              isMulti={false}
                              required={true}
                              value={i?.selectedOption}
                            />
                          </div>
                        </>
                      );
                    })
                  : ''}
              </>
            ) : (
              ''
            )}
          </>
        );
      })}
    </>
  );
};
export default DroughtStageSwitch;
