import DROUGHT_CONST from './droughtMonitorConsts';
export const getDroughtMapRequest = (apiURL, requestObj,isDroughtModule) => ({
  type: DROUGHT_CONST.GET_DROUGHT_MAP_REQUEST,
  payload: {
    apiURL,
    requestObj,
    isDroughtModule
  },
});

export const getDroughtMapSuccess = (response) => ({
  type: DROUGHT_CONST.GET_DROUGHT_MAP_SUCCESS,
  payload: response,
});

export const getDroughtMapFailed = (error) => ({
  type: DROUGHT_CONST.GET_DROUGHT_MAP_FAILED,
  payload: error,
});

export const getDroughtTimeSeriesRequest = (apiURL, requestObj) => ({
  type: DROUGHT_CONST.GET_DROUGHT_TIMESERIES_REQUEST,
  payload: {
    apiURL,
    requestObj
  }
});

export const getDroughtTimeSeriesSuccess = (response) => ({
  type: DROUGHT_CONST.GET_DROUGHT_TIMESERIES_SUCCESS,
  payload: response,
});

export const getDroughtTimeSeriesFailed = (error) => ({
  type: DROUGHT_CONST.GET_DROUGHT_TIMESERIES_FAILED,
  payload: error,
});

export const getDroughtAllSeasonRequest = (requestObj) => ({
  type: DROUGHT_CONST.GET_DROUGHT_ALL_SEASON_REQUEST,
  payload: requestObj
});

export const getDroughtAllSeasonSuccess = (response) => ({
  type: DROUGHT_CONST.GET_DROUGHT_ALL_SEASON_SUCCESS,
  payload: response,
});

export const getDroughtAllSeasonFailed = (error) => ({
  type: DROUGHT_CONST.GET_DROUGHT_ALL_SEASON_FAILED,
  payload: error,
});

export const getAlertActiveYearsMonthsRequest = (requestObj) => ({
  type: DROUGHT_CONST.GET_ALERT_ACTIVE_YEAR_MONTHS_REQUEST,
  payload: requestObj
});

export const getAlertActiveYearsMonthsSuccess = (response) => ({
  type: DROUGHT_CONST.GET_ALERT_ACTIVE_YEAR_MONTHS_SUCCESS,
  payload: response,
});

export const getAlertActiveYearsMonthsFailed = (error) => ({
  type: DROUGHT_CONST.GET_ALERT_ACTIVE_YEAR_MONTHS_FAILED,
  payload: error,
});

export const getFinalDroughtMapRequest = (requestObj) => ({
  type: DROUGHT_CONST.GET_FINAL_DROUGHT_MAP_REQUEST,
  payload: requestObj
});

export const getFinalDroughtMapSuccess = (response) => ({
  type: DROUGHT_CONST.GET_FINAL_DROUGHT_MAP_SUCCESS,
  payload: response,
});

export const getFinalDroughtMapFailed = (error) => ({
  type: DROUGHT_CONST.GET_FINAL_DROUGHT_MAP_FAILED,
  payload: error,
});


export const getDSSThresholdData = (requestObj) => ({
  type: DROUGHT_CONST.GET_DSS_THRESHOLD_DATA_REQUEST,
  payload: requestObj
});

export const getDSSThresholdDataSuccess = (response) => ({
  type: DROUGHT_CONST.GET_DSS_THRESHOLD_DATA_SUCCESS,
  payload: response,
});

export const getDSSThresholdDataFailed = (error) => ({
  type: DROUGHT_CONST.GET_DSS_THRESHOLD_DATA_FAILED,
  payload: error,
});

export const getDSSIndicesRequest = () => ({
  type: DROUGHT_CONST.GET_DSS_INDICES_REQUEST,
  payload: null
});

export const getDSSIndicesSuccess = (response) => ({
  type: DROUGHT_CONST.GET_DSS_INDICES_SUCCESS,
  payload: response,
});

export const getDSSIndicesFailed = (error) => ({
  type: DROUGHT_CONST.GET_DSS_INDICES_FAILED,
  payload: error,
});

export const getDroughtPredictionIndicesMonths = () => ({
  type: DROUGHT_CONST.GET_DROUGHT_PREDICTION_INDICES_MONTHS,
  payload: null
})


export const getDroughtPredictionIndicesMonthsSuccess = (response) => ({
  type: DROUGHT_CONST.GET_DROUGHT_PREDICTION_INDICES_MONTHS_SUCCESS,
  payload: response,
})

export const getDroughtPredictionIndicesMonthsFailed = (error) => ({
  type: DROUGHT_CONST.GET_DROUGHT_PREDICTION_INDICES_MONTHS_FAILED,
  payload: error,
})




