import React from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

toast.configure();
export default function Alert() {
  const notify = () => {
    toast('Common Alert', { position: toast.POSITION.TOP_LEFT });
    toast.success('Common Alert for Success', { position: toast.POSITION.TOP_CENTER });
    toast.info('Common Alert for Info', { position: toast.POSITION.TOP_RIGHT });
    toast.warn('Common Alert for Warning', { position: toast.POSITION.BOTTOM_LEFT });
    toast.error('Common Alert for Error', { position: toast.POSITION.BOTTOM_CENTER });
    toast('Common Alert', { position: toast.POSITION.BOTTOM_RIGHT });
  };

  const defaultAlert = () => {
    toast('Common Alert(Default)', { position: toast.POSITION.TOP_RIGHT });
  };

  const successAlert = () => {
    toast.success('Common Alert for Success', { position: toast.POSITION.TOP_RIGHT });
    toast.dismiss();
  };

  const infoAlert = () => {
    toast.info('Common Alert for Info', { position: toast.POSITION.TOP_RIGHT });
  };

  const warningAlert = () => {
    toast.warn('Common Alert for Warning', { position: toast.POSITION.TOP_RIGHT });
  };

  const errorAlert = () => {
    toast.error('Common Alert for Error', { position: toast.POSITION.TOP_RIGHT });
  };
  return (
    <div>
      <button type="submit" onClick={defaultAlert}>
        Show Default Alert
      </button>
      <ToastContainer limit={1} />
      <button type="submit" onClick={successAlert}>
        Show Success Alert
      </button>
      <button type="submit" onClick={infoAlert}>
        Show Info Alert
      </button>
      <button type="submit" onClick={warningAlert}>
        Show Warning Alert
      </button>
      <button type="submit" onClick={errorAlert}>
        Show Error Alert
      </button>
      <button type="submit" onClick={notify}>
        Show Alert in all possible positions
      </button>
    </div>
  );
}
