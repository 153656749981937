import React, { useEffect, useRef, useState } from 'react';
import { Container, Row, Col, Card, FormGroup, Label, Spinner } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { t } from 'react-multi-lang';
import { Field, reduxForm } from 'redux-form';
import { toast } from 'react-toastify';
import SwaggerUI from 'swagger-ui-react';
import 'swagger-ui-react/swagger-ui.css';
import Footer from '../landing-page/footer';
import RightImg from '../../assets/Images/right-img.png';
import LeftImg from '../../assets/Images/left-img.png';
import { COUNTRIES_JSON_LIST } from '../../Components/constants/index';
import {
  renderTextField,
  renderReactSelectInputField,
} from '../../Components/common/renderInputField';
import { getParametersRequest } from '../../redux/home/homeActions';
import { getAccessKeyRequest, getCountryRequest } from '../../redux/actions';
import { LOCATION, SWAGGER_JSON_FILE_URL_LIST } from '../../Components/constants';
import { showSuccess, showError } from '../../Components/Notifications';
import myConstClass from '../../Constants';
import Apicategories from '../../Components/Apicategories';

// Redux-form validate function
const validate = values => {
  const errors = {};
  const requiredFields = [
    'name',
    'email',
    'organization',
    'access_country_id',
    'country_name',
    'api_ids',
    'purpose',
    'application_url',
  ];

  requiredFields.forEach(field => {
    if (!values[field]) {
      errors[field] = t('PUBLIC_API.FIELD_REQUIRED');
    }
  });
  if (!values.name) {
    errors.name = t('PUBLIC_API.NAME_REQUIRED');
  }
  if (!values.email) {
    errors.email = t('PUBLIC_API.EMAIL_REQUIRED');
  } else if (!myConstClass.EMAIL_REGEX.test(values.email)) {
    errors.email = t('PUBLIC_API.INVALID_EMAIL');
  }
  if (!values.organization) {
    errors.organization = t('PUBLIC_API.ORGANIZATION_REQUIRED');
  }
  if (!values.access_country_id) {
    errors.access_country_id = t('PUBLIC_API.COUNTRY_REQUIRED');
  }
  if (!values.country_name) {
    errors.country_name = t('PUBLIC_API.COUNTRY_REQUIRED');
  }
  if (!values.api_ids) {
    errors.api_ids = t('PUBLIC_API.API_REQUIRED');
  }
  if (!values.purpose) {
    errors.purpose = t('PUBLIC_API.PURPOSE_REQUIRED');
  }
  if (values.application_url) {
    if (!myConstClass.URL_REGEX.test(values.application_url)) {
      errors.application_url = t('PUBLIC_API.APPLICATION_URL_INVALID');
    }
  }
  return errors;
};

const PublicApi = props => {
  toast.configure();
  const dispatch = useDispatch();
  const { handleSubmit, reset } = props;
  const [loading, setLoading] = useState('');
  const [country, setCountry] = useState({});
  const [accessCountryList, setAccessCountryList] = useState();
  const [selectedAccessCountryList, setSelectedAccessCountryList] = useState([]);
  const [selectedAPI, setSelectedAPI] = useState([]);
  const [parametersList, setParams] = useState([]);
  const [activeCategoryUrl, setActiveCategoryUrl] = useState();

  const { Parameters, AccessKey, Location } = useSelector(state => ({
    Parameters: state.Home,
    AccessKey: state.AccessKey,
    Location: state.Location,
  }));

  useEffect(() => {
    setActiveCategoryUrl(SWAGGER_JSON_FILE_URL_LIST[0].url);
    dispatch(getParametersRequest());
    dispatch(getCountryRequest({ location_type: LOCATION.Country, parent_id: '' }));
    window.scrollTo({ top: 0, behavior: 'instant' });
    /*scrolling behavior changed by Ramya.s on 6/9/2022*/
  }, []);

  useEffect(() => {
    if (Location.countryList) {
      if (Location.countryList.result) {
        setAccessCountryList(Location.countryList.result);
      }
    }
  }, [Location.countryList]);

  useEffect(() => {
    if (Parameters.getParametersData) {
      if (Parameters.getParametersData.data) {
        const { result } = Parameters.getParametersData.data;
        setParams(result);
      }
    } else if (Parameters.getParametersDataError) {
      setParams([]);
    } else if (Parameters.getParametersDataNetworkError) {
      setParams([]);
    }
  }, [
    Parameters.isGetParametersDataRequesting,
    Parameters.getParametersData,
    Parameters.getParametersDataError,
    Parameters.getParametersDataNetworkError,
  ]);

  const isGetAccessKeyFirstRun = useRef(true);
  useEffect(() => {
    if (isGetAccessKeyFirstRun.current) {
      isGetAccessKeyFirstRun.current = false;
      return;
    }
    if (AccessKey.accessKeyRequesting) {
      setLoading(true);
    }

    if (AccessKey.accessKeyData) {
      setLoading(false);
      if (AccessKey.accessKeyData.data) {
        showSuccess(t('PUBLIC_API.SUCCESSFULLY_REGISTERED'));
        reset('publicApiForm');
      }
    } else if (AccessKey.accessKeyDataError) {
      if (AccessKey.accessKeyDataError.data) {
        const { response } = AccessKey.accessKeyDataError.data;
        let errorMessage = response.data?.errors;
        if (typeof errorMessage === 'object' && Object.keys(errorMessage).length) {
          let firstValue = Object.values(errorMessage)[0];
          showError(firstValue[0]);
        }
        setLoading(false);
      } else {
        setLoading(false);
        showError(t('PUBLIC_API.ERROR_GENERATE_KEY'));
      }
    } else if (AccessKey.accessKeyDataNetworkError) {
      setLoading(false);
      showError(t('PUBLIC_API.ERROR_GENERATE_KEY'));
    }
  }, [
    AccessKey.accessKeyRequesting,
    AccessKey.accessKeyData,
    AccessKey.accessKeyDataError,
    AccessKey.accessKeyDataNetworkError,
    AccessKey,
  ]);

  const handleAPIChange = selectedOption => {
    setSelectedAPI(selectedOption);
  };

  const onFormSubmit = props => {
    props.country_name = country.id;
    props.api_ids = selectedAPI.map(item => item.id);
    props.choosed_access_countries = selectedAccessCountryList.map(item => item.id);
    dispatch(getAccessKeyRequest(props));
  };

  const handleCountryChange = (options, e) => {
    const selectedOption = options.find(item => item.name === e.value);
    setCountry({ name: e.value, id: selectedOption.name });
  };

  const handleAccessCountryChange = selectedOption => {
    setSelectedAccessCountryList(selectedOption);
  };
  const renderAPIParameterOptions = (list, value) => {
    if (list) {
      let params = [...list];
      let options = [];
      if (params.length) {
        params.map(param => {
          let data = {
            value: param.name,
            label: `${param.name}`,
            id: value === 'country' ? param.name : param.id,
          };
          options.push(data);
          return true;
        });
        return options;
      }
    }
  };

  const handleApiCategories = value => {
    SWAGGER_JSON_FILE_URL_LIST.map(i => {
      if (i.id === value) {
        setActiveCategoryUrl(i.url);
      }
    });
  };
  return (
    <>
      <div className="right-img-wrapper">
        <img src={RightImg} className="right-img-abs" alt="right img" />
        <Container fluid className="c1-padding mt-5">
          <Row className="c-padding">
            <Col lg={7} className="mx-auto">
              <h1 className="public-api-heading">{t('PUBLIC_API.SADMS')}</h1>
              <Row>
                <p className="public-api-para ">{t('PUBLIC_API.DESC_ONE')}</p>
              </Row>
              <Row>
                <p className="public-api-para ">{t('PUBLIC_API.DESC_TWO')}</p>
              </Row>
              <Row>
                <p className="public-api-para">{t('PUBLIC_API.DESC_THREE')}</p>
              </Row>
            </Col>
            <Col lg={5} className="mr-auto c-mx mb-3">
              <Card className="card-shadow-api py-4 px-5">
                <form
                  id="publicApiForm"
                  className="public-api-form"
                  onSubmit={handleSubmit(onFormSubmit)}
                >
                  <div className="pb-3">
                    <h5 className="key-text">{t('PUBLIC_API.GENERATE_KEY')}</h5>
                    <small className="small-key-text">{t('PUBLIC_API.MORE_DETAILS')}</small>
                  </div>
                  <FormGroup className="textOnInput">
                    <Label htmlFor="name">{t('PUBLIC_API.NAME')}</Label>
                    <Field
                      name="name"
                      type="text"
                      className="form-control input-style-css"
                      component={renderTextField}
                      required
                    />
                  </FormGroup>
                  <FormGroup className="textOnInput">
                    <Label htmlFor="email">{t('PUBLIC_API.EMAIL')}</Label>
                    <Field
                      name="email"
                      type="text"
                      className="form-control input-style-css"
                      component={renderTextField}
                      required={true}
                    />
                  </FormGroup>
                  <FormGroup className="textOnInput">
                    <Label htmlFor="designation">{t('PUBLIC_API.DESIGNATION')}</Label>
                    <Field
                      name="designation"
                      type="text"
                      className="form-control input-style-css"
                      component={renderTextField}
                      required={true}
                    />
                  </FormGroup>
                  <FormGroup className="textOnInput">
                    <Label htmlFor="organization">{t('PUBLIC_API.ORGANIZATION')}</Label>
                    <Field
                      name="organization"
                      type="text"
                      className="form-control input-style-css"
                      component={renderTextField}
                      required={true}
                    />
                  </FormGroup>
                  <FormGroup className="textOnInput">
                    <Label htmlFor="country_id">{t('PUBLIC_API.COUNTRY')}</Label>
                    <Field
                      component={renderReactSelectInputField}
                      name="country_id"
                      className="input-style-css"
                      placeholder=""
                      type="select"
                      onChange={e => handleCountryChange(COUNTRIES_JSON_LIST, e)}
                      required={true}
                      isMulti={false}
                      options={renderAPIParameterOptions(COUNTRIES_JSON_LIST, 'country')}
                    ></Field>
                  </FormGroup>
                  <FormGroup className="textOnInput">
                    <Label htmlFor="access_country_id">Access Country</Label>
                    <Field
                      component={renderReactSelectInputField}
                      name="access_country_id"
                      className="input-style-css"
                      placeholder=""
                      type="select"
                      onChange={handleAccessCountryChange}
                      required={true}
                      isClearable={true}
                      isMulti={true}
                      options={renderAPIParameterOptions(accessCountryList)}
                    ></Field>
                  </FormGroup>
                  <FormGroup className="textOnInput">
                    <Label htmlFor="api_ids" className="choose_label">
                      {t('PUBLIC_API.CHOOSE_INDICES')}
                    </Label>
                    <Field
                      name="api_ids"
                      component={renderReactSelectInputField}
                      className="input-style-css"
                      id="chooseAPI"
                      options={renderAPIParameterOptions(parametersList, 'indices')}
                      onChange={handleAPIChange}
                      placeholder=""
                      isClearable={true}
                      isMulti={true}
                    />
                  </FormGroup>
                  <FormGroup className="textOnInput">
                    <Label htmlFor="application_url">{t('PUBLIC_API.APPLICATION_URL')}</Label>
                    <Field
                      name="application_url"
                      type="text"
                      className="form-control input-style-css"
                      component={renderTextField}
                      required={true}
                    />
                  </FormGroup>
                  <FormGroup className="textOnInput">
                    <Label htmlFor="purpose">{t('PUBLIC_API.PURPOSE')}</Label>
                    <Field
                      name="purpose"
                      type="textarea"
                      className="form-control input-style-css"
                      component={renderTextField}
                      required={true}
                    />
                  </FormGroup>
                  <div>
                    <button
                      type="submit"
                      class="btn btn-grn-key btn-block col-md-12"
                      style={{ paddingRight: '20px' }}
                      // disabled={pristine}
                    >
                      {t('PUBLIC_API.GENERATE_KEY')}
                      <span className="generate-btn-loader">
                        {loading && <Spinner color="light" size="sm" />}
                      </span>
                    </button>
                    <div className="py-3">
                      <p className="m-0 p-0 term-text mt-2">{t('PUBLIC_API.CLICK_GENERATE_KEY')}</p>

                      <a href="/terms-conditions" className="term-text-a">
                        {t('PUBLIC_API.TERMS')}
                      </a>
                    </div>
                  </div>
                </form>
              </Card>
            </Col>
          </Row>

          <img className="left-img-abs" src={LeftImg} alt="left img" />
          <div className="left-img-wrapper">
            <h2 className="left-s-text">{t('PUBLIC_API.API_DESC')}</h2>
          </div>
          <Row>
            <Col lg="3" md="3" sm="12" xs="12">
              <Apicategories handleApiCategories={handleApiCategories} />
            </Col>
            <Col lg="9" md="9" sm="12" xs="12">
              <SwaggerUI url={activeCategoryUrl} />
            </Col>
          </Row>
          <Footer publicFooter={false} />
        </Container>
      </div>
    </>
  );
};

export default reduxForm({
  form: 'publicApiForm',
  validate,
  enableReinitialize: true,
})(PublicApi);
