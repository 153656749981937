import React from 'react';
import { Modal, ModalBody, Row, Col } from 'reactstrap';
import _ from 'lodash';
import { MdOutlineClose } from 'react-icons/md';
import { t } from 'react-multi-lang';
import { Link } from 'react-router-dom';

const DataPopUp = props => {
  const { isOpen, toggle } = props;

return (
    <>
      <div className="news-feed-alert-modal">
        <Row>
          <Col>
            <Modal
              className="modal-dialog modal-lg modal-dialog-centered disclaimer-modal-placing"
              isOpen={isOpen}
              toggle={toggle}
              centered> 
              <div className="alert-heading">
                <div className="header-text">
            &nbsp;&nbsp;
            <h6 className="heading text-white disclaimer-header-text">Disclaimer</h6>
            </div>
            <span className="cursor-ponter close-drought-icon-new" onClick={toggle}>
              <MdOutlineClose size={20} />
            </span>
            </div>
              <ModalBody className="delete-modal-body">
                <div
                  className="cursor-ponter  text-box"
                >   
                <p className="warning-text text-width">{t('DISCLAIMER.TEXT1')}</p>
                <p className="warning-text text-width">{t('DISCLAIMER.TEXT2')}</p>
                <p className="warning-text text-width">{t('DISCLAIMER.TEXT3')}</p>
                <p className="warning-text text-width">{t('DISCLAIMER.TEXT4')}
                <Link style={{color:'#dc9023'}} to={{ 
                    pathname: "https://www.cgiar.org/funders",
                    }} target={"_blank"} rel='noopener noreferrer' > {t('DISCLAIMER.LINK2')}</Link>
                    ). 
                </p>
                </div>
		        </ModalBody>
            </Modal>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default DataPopUp;