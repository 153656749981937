const GraphLoader = () => {
  return (
    <>
      <div className="drought-loader-container">
        <div className="drought-loader">
          <div className="loadingio-spinner-spinner-m63cop84tg">
            <div className="ldio-3ucpol5dcb2">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GraphLoader;
