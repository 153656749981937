import WEATHER_CONST from './weatherConst';

export const getNoaaTimeSeries = (payload) => ({
  type : WEATHER_CONST.NOAA_TIMESERIES_REQUEST,
  payload : payload
})

export const getNoaaTimeSeriesSuccess = (response) => ({
  type: WEATHER_CONST.NOAA_TIMESERIES_SUCCESS,
  payload: response,
});

export const getNoaaTimeSeriesError = (response) => ({
  type: WEATHER_CONST.NOAA_TIMESERIES_FAILED,
  payload: response,
});

export const getErpasTimeSeries = (payload) => ({
  type : WEATHER_CONST.ERPAS_TIMESERIES_REQUEST,
  payload : payload
})

export const getErpasTimeSeriesSuccess = (response) => ({
  type: WEATHER_CONST.ERPAS_TIMESERIES_SUCCESS,
  payload: response,
});

export const getErpasTimeSeriesError = (response) => ({
  type: WEATHER_CONST.ERPAS_TIMESERIES_FAILED,
  payload: response,
});


export const getIRITimeSeries = (payload) => ({
  type : WEATHER_CONST.IRI_TIMESERIES_REQUEST,
  payload : payload
})

export const getIRITimeSeriesSuccess = (response) => ({
  type: WEATHER_CONST.IRI_TIMESERIES_SUCCESS,
  payload: response,
});

export const getIRITimeSeriesError = (response) => ({
  type: WEATHER_CONST.IRI_TIMESERIES_FAILED,
  payload: response,
});

const fetchWeather = () => ({
  type: WEATHER_CONST.FETCH_REQUEST,
  payload: {},
});

export const fetchWeatherSuccess = (response) => ({
  type: WEATHER_CONST.FETCH_SUCCESS,
  payload: response,
});

export const fetchWeatherFailed = (error) => ({
  type: WEATHER_CONST.FETCH_FAILED,
  payload: error,
});

//by country-Id
export const getWeatherForecastSources = (req_body) => ({
  type: WEATHER_CONST.GET_FORECAST_DATA_SOURCES_REQUEST,
  payload: req_body,
});

export const getWeatherForecastSourcesSuccess = (response) => ({
  type: WEATHER_CONST.GET_FORECAST_DATA_SOURCES_SUCCESS,
  payload: response,
});

export const getWeatherForecastSourcesFailed = (error) => ({
  type: WEATHER_CONST.GET_FORECAST_DATA_SOURCES_FAILED,
  payload: error,
});

export const getWeatherForecastData = (obj) => ({
  type: WEATHER_CONST.GET_FORECAST_DATA_REQUEST,
  payload: obj,
});

export const getWeatherForecastDataSuccess = (response) => ({
  type: WEATHER_CONST.GET_FORECAST_DATA_SUCCESS,
  payload: response,
});

export const getWeatherForecastDataFailed = (error) => ({
  type: WEATHER_CONST.GET_FORECAST_DATA_FAILED,
  payload: error,
});

//IRI Available Dates
export const getIRIAvailableDatesDataRequest = (obj) => ({
  type: WEATHER_CONST.IRI_AVAILABLE_DATES_REQUEST,
  payload: obj,
});

export const getIRIAvailableDatesDataSuccess = (response) => ({
  type: WEATHER_CONST.IRI_AVAILABLE_DATES_SUCCESS,
  payload: response,
});

export const getIRIAvailableDatesDataFailed = (error) => ({
  type: WEATHER_CONST.IRI_AVAILABLE_DATES_FAILED,
  payload: error,
});
export default fetchWeather;
