import '../../assets/CSS/static-page.css';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { TabContent, TabPane, Nav, NavItem, NavLink, Card, Row, Col } from 'reactstrap';
import Button from 'reactstrap-button-loader';
import ConfirmationModal from './Confirmation';
import classnames from 'classnames';
import RightSideBar from './right-side-bar';
import Filter from './Filter';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight} from 'react-icons/md';
import moment from 'moment';
import { DISPLAY_DATE_FORMAT, SOUTH_ASIA_CONST, DATA_SOURCES, DEFAULT_COUNTRY } from '../../Components/constants';
import Loader from '../../Components/common/loader';
import RightArrow from '../../assets/Images/back-button.svg';
import Image_not_available from "../../assets/Images/Image_not_available.svg"
import { t } from 'react-multi-lang';
import { getCountryRequest, getWeatherForecastSources } from '../../redux/actions';
import FilterIcon from '../../assets/Images/icons/mi_filter.svg';
import {
  staticResourceRequest,
  deleteStaticResourceRequest,
  deleteStaticResourceSuccess,
} from '../../redux/staticResource/staticResourceAction';
import _ from 'lodash';
import { userDetailRequest } from '../../redux/user-list/userListAction';
import { showError } from '../../Components/Notifications.js';
import InfiniteScroll from 'react-infinite-scroll-component';
import { FORECAST_LEAD_TIME_LIST } from '../../Components/constants';
import { isStaffUser, isSuperAdmin } from '../../Components/common/utils';

const ManageStaticData = () => {
  const dispatch = useDispatch();
  let history = useHistory();
  const [activeTab, setActiveTab] = useState('2');
  const [toggleRightBar, setToggleRightBar] = useState(false);
  const [isHideBar, setHideBar] = useState(false);
  const [countryList, setCountryList] = useState();
  const [dataSources, setDataSources] = useState();
  const [dataSourceList, setDataSourceList] = useState();
  const [parameterList, setParameterList] = useState();
  const [pageNumber, setPageNumber] = useState(1);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [file, setFile] = useState();
  const [deleteId, setDeleteId] = useState();
  const regionList = [{ id: SOUTH_ASIA_CONST.id, name: 'South Asia' }];
  const req_data = {
    region: '',
    country: '',
    forecast_data_source: '',
    parameter: '',
    start_date: '',
    end_date: '',
    source_link: '',
    description: '',
    upload_image: '',
    forecast_year_month: ''
  };
  const filter_req_data = {
    region_id: '',
    country_id: '',
    forecast_data_source_id: [],
    parameter_id: [],
    start_date: null,
    end_date: null,
    limit: 0,
  };
  const dataSource_type = 'static';
  const dataSource = 'ERPAS (Static)';
  const [formFilterData, setFormFilterData] = useState(filter_req_data);
  const [imageExtension, setImageExtension] = useState();
  const [formData, setFormData] = useState(req_data);
  const [staticResourceList, setStaticResourcesList] = useState([]);
  const [noRecordsFound, setNoRecordsFound] = useState(false);
  const [editFormData, setEditFormData] = useState({});
  const [selectedStaticResourceId, setSelectedStaticResourceId] = useState();
  const [editClicked, setEditClicked] = useState(false);
  const [pageCount, setCount] = useState();
  const [filterFormData, setFilterFormData] = useState([]);
  const [isIRISelected, setIsIRISelected] = useState(false);
  const [filterDataSources, setFilterDataSources] = useState();
  const [closed, setClosed] = useState(false);
  const [updatedPermissions, setUpdatedPermissions] = useState();
  const [haltInfiniteScroll, setHaltInfiniteScroll] = useState(true);
  const ORDER_BY = { order_by: 'created_date' };
  const nextProps = useSelector(state => ({
    Location: state.Location.countryList,
    staticResource: state.staticResource,
    ForeCastSourcesData: state.Weather.weatherForecastSourcesData,
    userDetailData: state.userList.userDetailData || null,
  }));

  useEffect(() => {
    if (nextProps.userDetailData) {
      let a = [];
      const {
        data: { result },
      } = nextProps.userDetailData;
      if (result) {
        result.user_permissions.map(i => {
          a.push(i.codename);
        });
        setUpdatedPermissions(a);
      }
    }
  }, [nextProps.userDetailData]);

  const {
    staticResourceData,
    isStaticResourceRequesting,
    staticResourceDataError,
    staticResourceAddData,
    updateStaticResourceData,
  } = nextProps.staticResource;

  useEffect(() => {
    return () => {
      dispatch(deleteStaticResourceSuccess({}));
    };
  }, []);

  useEffect(() => {
    if (staticResourceDataError) {
      const {
        data: { response },
      } = staticResourceDataError;
      if (response && response?.data?.message) {
        showError(response?.data?.message);
      }
    }
  }, [staticResourceDataError]);

  useEffect(() => {
    if (staticResourceAddData || updateStaticResourceData) {
      setPageNumber(1);
      let reqData = {
        ...formFilterData,  // after filtering on add or update keeping the filter values
        order_by: ORDER_BY.order_by,
        page: 1,
      };
      dispatch(staticResourceRequest(reqData));
    }
  }, [staticResourceAddData, updateStaticResourceData]);

  const toggle = tab => {
    if (activeTab !== tab) setActiveTab(tab);
    if (tab === '2') {
      setHaltInfiniteScroll(true);
      let reqData = {
        order_by: ORDER_BY.order_by,
        page: pageNumber,
      };
      setNoRecordsFound(false);
      dispatch(staticResourceRequest(reqData));
    } else {
      setHaltInfiniteScroll(false);
    }
  };

  const handleSelectedTab = () => {
    setActiveTab('2');
    setHaltInfiniteScroll(true);
    let reqData = {
      order_by: ORDER_BY.order_by,
      page: pageNumber,
    };

    // dispatch(staticResourceRequest(reqData));
  };

  const handleFilter = data => {
    setActiveTab('2');
    setHaltInfiniteScroll(true);
    setFormFilterData(data);
    let reqData = {
      ...data,
      order_by: ORDER_BY.order_by,
      page: pageNumber,
    };
    setPageNumber(1);
    setNoRecordsFound(false);
    dispatch(staticResourceRequest(reqData));
  };

  useEffect(() => {
    if (staticResourceData && haltInfiniteScroll) {
      const {
        data: { result },
      } = staticResourceData;
      let new_list = [];
      if (staticResourceList) {
        if (pageNumber > 1) {
          new_list = staticResourceList.concat(result.results);
        } else {
          new_list = result.results;
        }
      } else {
        new_list = result.results;
      }
      setStaticResourcesList(new_list);
      setCount(result.count);
    }
  }, [staticResourceData]);

  useEffect(() => {
    if (activeTab === '1') {
      setToggleRightBar(false);
    }
  }, [activeTab]);

  useEffect(() => {
    window.scroll(0, 0);
    if (isSuperAdmin() || isStaffUser()) {
      let reqData = {
        order_by: ORDER_BY.order_by,
        page: pageNumber,
      };
      setActiveTab('2');
      setHaltInfiniteScroll(true);
      dispatch(getCountryRequest({ location_type: 'Country' }));
      dispatch(staticResourceRequest(reqData));
      dispatch(getWeatherForecastSources({ country_id: DEFAULT_COUNTRY.id }));
    } else {
      history.push('/home');
    }
    dispatch(userDetailRequest());
  }, []);

  useEffect(() => {
    const { result } = nextProps.Location;
    if (result) {
      setCountryList(result);
    }
  }, [nextProps.Location]);
  useEffect(() => {
    if (nextProps.ForeCastSourcesData) {
      setFilterDataSources(nextProps.ForeCastSourcesData);
      const { data } = nextProps.ForeCastSourcesData;
      if (data?.result) {
        console.log()
        let d = [];
        data?.result.map(i => {
          if (i.category !== 'Weather Forecast') {
            i.name = i.category;
            i.id = i.data[0].forecast_data_source.forecast_data_source_category_id;
            d.push(i);
            // console.log({i})
          }
        });
        setDataSources(d);
      }
    }
  }, [nextProps.ForeCastSourcesData]);

  const handleDataSourcesEmptyListChange = () => {
    setDataSources([]);
  };

  const closeRightBar = () => {
    setToggleRightBar(false);
  };

  const handleRegion = id => {
    if (id === 'select') {
      formData.region = id;
      setFormData({ ...formData });
    } else {
      formData.region = id;
      setFormData({ ...formData });
      dispatch(getCountryRequest({ location_type: 'Country', parent_id: id }));
      dispatch(getWeatherForecastSources({ country_id: DEFAULT_COUNTRY.id }));
    }
  };

  const handleCountry = id => {
    formData.country = id;
    setFormData({ ...formData });
    dispatch(getWeatherForecastSources({ country_id: id }));
  };

  const handleCategoryData = data => {
    if (data) {
      let d = [];
      data.map(i => {
        if (
          i.forecast_data_source.type === dataSource_type 
          &&
          i.forecast_data_source.name !== dataSource
        ) {
          i.name = i.forecast_data_source.name;
          i.id = i.forecast_data_source.id;
          d.push(i);
        }
      });
      setDataSourceList(d);
    } else {
      setDataSourceList([]);
    }
  };

  
  const forecastYearMonthHandler = value => {
    formData.forecast_year_month = value;
    setFormData({...formData});
  }

  const forecastLeadTimeHandler = e => {
    formData.forecast_lead_time = e.value;
    setFormData({...formData});
  } 



  const handlePreSelectedDataSource = (data, id) => {
    if (data) {
      formData.forecast_data_source = id;
      setFormData({ ...formData });
      let a = [];
      //if(id !== "e2f886a7-3299-4c82-af2e-123fe92faf84"){
      data.map(i => {
        i.parameter.map(item => {
          item.name = item.parameter.name;
          item.id = item.parameter.id;
          a.push(item);
        });
      });
      setParameterList(a);
      if(id !== DATA_SOURCES.IRI.id){

        setIsIRISelected(false)
      }
      else{
        setIsIRISelected(true)
      }
   }
  else {
    setParameterList([]);
  }
  };
  
  const handleResetIRIFields = (bool_val) => {
    setIsIRISelected(bool_val)
  }

  const handleSelectedDataSource = (data, id) => {
    if(id === DATA_SOURCES.IRI.id){
      setIsIRISelected(true);
    }
    else{
      setIsIRISelected(false);
    }
    if (data) {
      formData.forecast_data_source = id;
      setFormData({ ...formData });
      let a = [];
      data.map(i => {
        i.name = i.parameter.name;
        i.id = i.parameter.id;
        a.push(i);
      });
      setParameterList(a);
    } else {
      setParameterList([]);
    }
  };

  const handleParameter = id => {
    formData.parameter = id;
    setFormData({ ...formData });
  };

  const startDateHandler = value => {
    formData.start_date = value;
    setFormData({ ...formData });
  };

  const handleManageCropsBackButton = () => {
    history.push('/settingsDashboard');
  };

  const endDateHandler = value => {
    formData.end_date = value;
    setFormData({ ...formData });
  };
  const linkHandler = value => {
    formData.source_link = value;
    setFormData({ ...formData });
  };

  const descriptionHandler = value => {
    formData.description = value;
    setFormData({ ...formData });
  };

  const fileHandler = (value,e) => {
    if(e?.target?.value){
      setImageExtension((e?.target?.value)?.substr(e?.target?.value?.lastIndexOf('.') + 1))
    }
    setHaltInfiniteScroll(false);
    setActiveTab('1');
    setStaticResourcesList([]);
    setFile(value);
  };

  const handlePreForecastLeadTime = (lead_time,month) => {
    let list =  JSON.parse(JSON.stringify(FORECAST_LEAD_TIME_LIST));
    let option_list = list?.splice(parseInt(month),4)
    let lead_time_label;;
    let latest_list;
    let new_list = JSON.parse(JSON.stringify(FORECAST_LEAD_TIME_LIST))
      let new_arr = new_list?.splice(parseInt(0),4 - option_list.length)
      latest_list = option_list.concat(new_arr)
      latest_list?.map((i,index) => {
        i.name = i.name + "(" + `${index+1}.0` + ")"
       })
       latest_list?.map((k) => {
        if(parseInt(lead_time) === ((parseInt(k.name?.substr(k.name?.indexOf('(')+1,
      k.name?.indexOf(')')-k.name?.indexOf('(')-1))))){
        lead_time_label = k
      }
        
      })
      return lead_time_label?.name
    }

  const noRecordsErrorHandler = () => {
    setNoRecordsFound(true);
  };

  const handleFilterFormData = (data, i) => {
    setPageNumber(1);
    setFormFilterData({ ...data });
  };
  const handleEditDataSource = i => {
    setEditClicked(true);
    let categoryName;
    if (
      i.weather_forecast_source_name === 'CPC (Static)' ||
      i.weather_forecast_source_name === 'IRI'
    ) {
      categoryName = 'Seasonal';
    } else {
      categoryName = 'Sub - Seasonal';
    }
    let filterInitialValues = {
      region: { value: i.region_name, label: i.region_name, id: i.region_id },
      start_date: i.start_date,
      Link: i.source_link,
      country: { value: i.country_name, label: i.country_name, id: i.country_id },
      forecast_category: {
        value: i.forecast_data_source_category_name,
        label: i.forecast_data_source_category_name,
        id: i.forecast_data_source_category_id,
      },
      datasource_type: {
        value: i.forecast_data_source_name,
        label: i.forecast_data_source_name,
        id: i.forecast_data_source_id,
      },
      parameter_type: { value: i.parameter_name, label: i.parameter_name, id: i.parameter_id },
      end_date: i.end_date,
      Description: i.description,
      Upload: i.upload_image,
      static_resource_id: i.id,
      forecast_lead_time: forecastPreSelectedLeadTime(i?.forecast_lead_time,i?.forecast_time_month),
      forecast_year_month: forecastPreSelectedYearMonth(i?.forecast_time_year,i?.forecast_time_month)
    };
    dispatch(getWeatherForecastSources({ region_id: SOUTH_ASIA_CONST.id }));
    setEditFormData(filterInitialValues);
    setEditClicked(true);
  };

  const updateInitialValues = ({ dataSourceData, parameterData, start_date, end_date }) => {
    let data = {
      dataSourceData,
      parameterData,
      start_date,
      end_date,
    };
    setFilterFormData(data);
  };


  const forecastPreSelectedLeadTime = (y,month) => {
    let list =  JSON.parse(JSON.stringify(FORECAST_LEAD_TIME_LIST)); 
    let option_list = list?.splice(parseInt(month),4)
    let item;
    let latest_list;
  // if(month >= 9){
let new_list = JSON.parse(JSON.stringify(FORECAST_LEAD_TIME_LIST))
      let new_arr = new_list?.splice(parseInt(0),4 - option_list.length)
      latest_list = option_list.concat(new_arr)
  // }
   latest_list?.map((i,index) => {
    i.name = i.name + "(" + `${index+1}.0` + ")";
    i.label = i.label + "(" + `${index+1}.0` + ")";
   })
  latest_list?.map((k) => {
    if(parseInt(y) === ((parseInt(k.name?.substr(k.name?.indexOf('(')+1,
  k.name?.indexOf(')')-k.name?.indexOf('(')-1))))){
    item = k
  }
  })
  return item;
}

 const forecastPreSelectedYearMonth = (x,y) => {
  return (x+'-'+y)
}


  const handleResetInitialValues = () => {
    let filterInitialValues = {
      region: '',
      start_date: '',
      Link: '',
      country: '',
      forecast_category: '',
      datasource_type: '',
      parameter_type: '',
      end_date: '',
      Description: '',
      Upload: '',
      static_resource_id: '',
    };
    setEditFormData(filterInitialValues);
  };

  const handleDeleteDataSource = id => {
    setConfirmationModalOpen(true);
    setSelectedStaticResourceId(id);
    //setDeleteToggle(!deleteToggle);
  };

  const handleUpdatedListData = () => {
    setPageNumber(1);
    let reqData = {
      ...formFilterData,
      order_by: ORDER_BY.order_by,
      page: 1,
    };
    dispatch(staticResourceRequest(reqData));
  };

  const handleModalToggle = () => {
    setConfirmationModalOpen(false);
  };

  const handleDeleteConfirm = () => {
    setDeleteId(selectedStaticResourceId);
    dispatch(deleteStaticResourceRequest(selectedStaticResourceId));
  };

  const fetchNextStaticResources = () => {
    if (haltInfiniteScroll) {
      let reqData = {
        ...formFilterData,
        order_by: ORDER_BY.order_by,
        page: pageNumber + 1,
      };
      setPageNumber(pageNumber + 1);
      dispatch(staticResourceRequest(reqData));
    }
  };
  const handleClearFilterInitialValues = (item, paramItem) => {
    setFormFilterData(filter_req_data);
    let data = {
      dataSourceData: item,
      parameterData: paramItem,
      start_date: '',
      end_date: '',
    };
    setFilterFormData(data);
  };

  const handleClose = () => {
    setClosed(true);
  };


  return (
    <>
      <div className="st-helper-sidebar">
        {toggleRightBar && (
          <RightSideBar
            formFilterData={formFilterData}
            handleFilterFormData={handleFilterFormData}
            closeRightBar={closeRightBar}
            handleFilter={handleFilter}
            noRecordsErrorHandler={noRecordsErrorHandler}
            initialValues={filterFormData}
            updateInitialValues={updateInitialValues}
            handleClearFilterInitialValues={handleClearFilterInitialValues}
            filterDataSources={filterDataSources}
            closed={closed}
            handleClose={handleClose}
          />
        )}

        <div className="static-resource-wrapper">
          <Row className="">
            {!isHideBar && (
              <Col md={2} lg={2} className="filter-col">
                <div className="hide-bar">
                  <span className="cursor-ponter navyBlueColor ">
                    {t('FILTER.HIDE_BAR')}
                    <span>
                      <MdKeyboardArrowRight
                        className="hide-icon expand-margin"
                        onClick={() => setHideBar(!isHideBar)}
                        size={25}
                      />
                    </span>
                  </span>
                </div>
              </Col>
            )}
          </Row>
          <Row>
            {!isHideBar ? (
              <Col lg={2} md={2} className="filter-col">
                <div className="filter-section-scroll">
                  <Filter
                    updatedPermissions={updatedPermissions}
                    descriptionHandler={descriptionHandler}
                    setStaticResourcesList={setStaticResourcesList}
                    regionList={regionList}
                    handleRegion={handleRegion}
                    countryList={countryList}
                    handleCountry={handleCountry}
                    dataSources={dataSources}
                    editClicked={editClicked}
                    handleCategoryData={handleCategoryData}
                    dataSourceList={dataSourceList}
                    handleDataSourcesEmptyListChange={handleDataSourcesEmptyListChange}
                    handleSelectedDataSource={handleSelectedDataSource}
                    handlePreSelectedDataSource={handlePreSelectedDataSource}
                    parameterList={parameterList}
                    handleParameter={handleParameter}
                    startDateHandler={startDateHandler}
                    endDateHandler={endDateHandler}
                    linkHandler={linkHandler}
                    fileHandler={fileHandler}
                    handleSelectedTab={handleSelectedTab}
                    initialValues={editFormData}
                    handleUpdatedListData={handleUpdatedListData}
                    handleResetInitialValues={handleResetInitialValues}
                    handleFilter={handleFilter}
                    handleResetIRIFields={handleResetIRIFields}
                    isIRISelected={isIRISelected}
                    forecastYearMonthHandler={forecastYearMonthHandler}
                    forecastLeadTimeHandler={forecastLeadTimeHandler}
                    setFile={setFile}
                    setEditClicked={setEditClicked}
                  />
                </div>
              </Col>
            ) : (
              ''
            )}
            <Col lg={isHideBar ? 12 : 10} md={10} className="static-data-col">
              {isHideBar && (
                <div className="filter-expand-bar">
                  <span className="cursor-ponter navyBlueColor ">
                    {t('FILTER.EXPAND_BAR')}
                    <span>
                      <MdKeyboardArrowLeft
                        className="hide-icon expand-margin"
                        onClick={() => setHideBar(!isHideBar)}
                        size={25}
                      />
                    </span>
                  </span>
                </div>
              )}
              <div className={isHideBar ? 'st-heading-left row mt-4' : 'manage-static-heading row'}>
                <span className="col-lg-1">
                  <img
                    src={RightArrow}
                    className="manage-static-header-back-button"
                    onClick={() => handleManageCropsBackButton()}
                  />
                </span>
                <span className="col-lg-5">
                  <h5>{t('FILTER.MANAGE_DATA')}</h5>
                </span>
                {activeTab === '2' ? (
                  <span
                    onClick={() => {
                      setToggleRightBar(!toggleRightBar);
                    }}
                    className="filter-icon-text col-lg-6"
                  >
                    <img src={FilterIcon} alt={'not-available'} className="st-filter-img" />
                    <h5 className="st-filter-text">{t('FILTER.FILTER')} </h5>
                  </span>
                ) : (
                  ''
                )}
              </div>

              <div className={isHideBar ? 'st-tab-wrapper st-tab-margin' : 'st-tab-wrapper'}>
                <Row>
                  <Nav className="st-tabs" tabs>
                    <NavItem className="st-li">
                      <NavLink
                        className={classnames({ active: activeTab === '1' })}
                        onClick={() => {
                          toggle('1');
                        }}
                      >
                        {t('FILTER.NEW_UPLOADS')}
                      </NavLink>
                    </NavItem>
                    <NavItem className="st-li">
                      <NavLink
                        className={classnames({ active: activeTab === '2' })}
                        onClick={() => {
                          toggle('2');
                        }}
                      >
                        {t('FILTER.PREVIOUSLY_UPLOADED')}
                      </NavLink>
                    </NavItem>
                  </Nav>
                </Row>
              </div>
              <hr className="st-hr" />
              <div
                className={isHideBar ? 'st-tab-content mt-4 st-tab-margin' : 'st-tab-content mt-4'}
              >
                <Row>
                  <TabContent activeTab={activeTab}>
                    <TabPane tabId="1">
                      <Row>
                        <Col lg="6" className="mx-auto">
                          <div className="mb-5">
                          {file ? (
                              (file.substr(file.lastIndexOf('.') + 1) !== "tiff") ?
                             (imageExtension !== "tiff") ?
                              <img className="img-fluid" src={file} alt={'not-available'} />
                              :
                              <img className="img-fluid" src={Image_not_available} alt={'preview not-available'} />
                              :
                              <img className="img-fluid" src={Image_not_available} alt={'preview not-available'} />
                            ) : (
                              <p className="new-upload-no-data">No data available</p>
                            )}
                          </div>
                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane tabId="2">
                      <Row>
                        <Col lg="12">
                          <div className="st-card-wrapper">
                            <div className="loader-div">
                              {isStaticResourceRequesting && <Loader />}
                            </div>
                            {noRecordsFound && (
                              <div className="manage-static-data-no-records">
                                  <h4>No Records Found.</h4>
                              </div>
                            )}
                            <InfiniteScroll
                              dataLength={pageNumber * 10 < pageCount ? pageNumber * 10 : ''}
                              next={fetchNextStaticResources}
                              hasMore={
                                pageNumber * 10 < pageCount && haltInfiniteScroll ? true : false
                              }
                              loader={<h4>Loading ... </h4>}
                            >
                              {staticResourceList?.map((i,index) => {
                                return (
                                  <Card className="st-card card-one" key={index}>
                                    {i?.upload_image?.substr(i?.upload_image.lastIndexOf('.') + 1) !== "tiff" ?
                                    (
                                    <img
                                      src={i.upload_image}
                                      alt={'not-available'}
                                      className="card-img-top"
                                    />
                                    )
                                    :
                                    (
                                      <img className="img-fluid" src={Image_not_available} alt={'preview not-available'} />
                                    )}
                                    <div className="card-body">
                                      <h5 className="card-title font-weight-light st-txt-primary">
                                        {i.description}
                                      </h5>
                                      <p className="card-text">
                                        {t('FILTER.SOURCE')}: {i.forecast_data_source_name} |{' '}
                                        {t('FILTER.PARAMETER')}: {i.parameter_name}
                                      </p>
                                      {
                                      (i?.forecast_data_source_id === DATA_SOURCES.IRI.id) ?
                                      <>
                                      <p className="card-text">
                                      {t('FILTER.FORECAST_DATE')} : {' '}
                                      {moment(i?.forecast_time_year+'-'+i?.forecast_time_month).format('YYYY-MM')}
                                      </p>
                                      <p className="card-text">
                                      {t('FILTER.FORECAST_LEAD_TIME')} : {' '}
                                      {handlePreForecastLeadTime(i?.forecast_lead_time,i?.forecast_time_month)}
                                      </p>
                                      </>
                                      :
                                      <p className="card-text">
                                      {t('FILTER.DATE_RANGE')} :{' '}
                                      {moment(i.start_date).format(DISPLAY_DATE_FORMAT)} |{' '}
                                      {moment(i.end_date).format(DISPLAY_DATE_FORMAT)}
                                    </p>
                                      }
                                      {i.source_link ? (
                                        <p className="card-text">
                                          {' '}
                                          {t('FILTER.LINK')}:{' '}
                                          <a href={i.source_link} target="_blank" rel="noreferrer">
                                            {i.source_link.length > 45
                                              ? i.source_link.substring(0, 45) + '...'
                                              : i.source_link}
                                          </a>
                                        </p>
                                      ) : (
                                        ''
                                      )}

                                      {i.forecast_data_source_name !== 'ERPAS (Static)' && (
                                        <div className="edit-delete-options new-option">
                                          <Button
                                            color="secondary"
                                            className="manage-static-edit-btn"
                                            onClick={() => handleEditDataSource(i)}
                                            disabled={
                                              isSuperAdmin() ||
                                              updatedPermissions?.includes('change_static_resource')
                                                ? false
                                                : true
                                            }
                                          >
                                            {t('FILTER.EDIT')}
                                          </Button>
                                          <Button
                                            color="secondary"
                                            className="manage-static-delete-btn"
                                            loading={i.id == deleteId ? true : false}
                                            onClick={() => handleDeleteDataSource(i.id)}
                                            disabled={
                                              isSuperAdmin() ||
                                              updatedPermissions?.includes('delete_static_resource')
                                                ? false
                                                : true
                                            }
                                          >
                                            {t('FILTER.DELETE')}
                                          </Button>
                                        </div>
                                      )}
                                    </div>
                                  </Card>
                                );
                              })}
                            </InfiniteScroll>
                          </div>
                        </Col>
                      </Row>
                    </TabPane>
                  </TabContent>
                </Row>
              </div>
            </Col>
          </Row>
        </div>
      </div>
      {confirmationModalOpen && (
        <ConfirmationModal
          isOpen={confirmationModalOpen}
          handleModalToggle={handleModalToggle}
          handleDeleteConfirm={handleDeleteConfirm}
        />
      )}
    </>
  );
};
export default ManageStaticData;
