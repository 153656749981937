import React, { memo, useState, useEffect } from 'react';
import { Row, Col, Modal, ModalBody } from 'reactstrap';
import { GrFormClose } from 'react-icons/gr';
import { t } from 'react-multi-lang';
import { Field, reduxForm } from 'redux-form';
import {
  renderImageField
} from '../../../Components/common/renderInputField';

const PublishModel = props => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const [validSize, setValidSize] = useState(false);

  const handlePublish = () => {
    props.publishBulletin();
   props?.isToggle();
 };

 const handleImage = (e) => {
  setSelectedImage(e.target.files[0])
  var reader = new FileReader();
reader.readAsDataURL(e.target.files[0]);
reader.onload = function (e) {
  var image = new Image();
  image.src = e.target.result;
  image.onload = () => {
    console.log(image.height,"height");
    console.log(image.width,"width");
    if (image.height  <= image.width && image.width > 450) {
      setValidSize(true);
    }else{
      setValidSize(false);
    }
    
  };
};
  props.handlePreviewImage(e);
};

useEffect(() => {
  if (selectedImage) {
    setImageUrl(URL.createObjectURL(selectedImage));
  }
}, [selectedImage]);

  return (
    <>
      <div className="news-feed-alert-modal">
        <Row>
          <Col>
            <Modal
              className="modal-dialog modal-lg modal-dialog-centered publish-modal"
              isOpen={props?.isOpen}
              toggle={props?.isToggle}
              centered
            >
              <div className="alert-heading">
                <div className="header-text">
            &nbsp;&nbsp;
            <h6 className="online-heading">Upload Preview Image</h6>
            </div>
            <div
                  style={{ display: 'flex', justifyContent: 'flex-end' }}
                  className="cursor-ponter"
                >
                  <GrFormClose size={25} onClick={props?.isToggle} />
                </div>
            </div>
              <ModalBody className="text-center delete-modal-body">
              <form>
            {
              <>
              <div className="image-file">
                 <label>Upload Image:</label>&nbsp;&nbsp;
                <Field
                  component={renderImageField}
                  className="upload-image"
                  name="upload_image"
                  id="upload_image"
                  type="file"
                  accept=".png, .jpg, .jpeg"
                  onChange={(e) => {
                    handleImage(e);
                  }}
                  isMulti={false}
                  placeholder={t('NEWS_FEED.UPLOAD_IMAGE')}
                ></Field>
              </div>
            </>
            }
          </form>
          {!validSize ? (<span style={{ color: 'red', fontSize: '12px' }}>
              {' '}
              {t('BULLET_IN.ADDING_UPDATING_IMAGE_SIZE_ERROR')}
            </span>):(<br></br>)}
              <div className='image-file1'>
              <label>Image Preview:</label>&nbsp;&nbsp;
              <div className="image-box">
              {imageUrl && selectedImage && (
              <img src={imageUrl} alt={selectedImage.name} height="180px" width="350px"/>
              )}
              </div>
              </div>
                <div className="mt-3">
                  <button className="btn btn-cancel btn-news-feed-submit mx-2" onClick={props?.isToggle}>
                    {t('USER_GUIDE.CANCEL')}
                  </button>
                  <button
                    className="btn btn-news-feed-submit mx-2"
                    onClick={handlePublish}
                    disabled={!validSize}
                  >
                    {t('USER_GUIDE.PUBLISH')}
                  </button>
                </div>
              </ModalBody>
            </Modal>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default reduxForm({ form: 'publish-model' })(PublishModel);
