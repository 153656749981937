export const CONTINGENCY_PLAN_RISK_CONST = {
  CONTINGENCY_PLAN_RISK_REQUEST: 'CONTINGENCY_PLAN_RISK_REQUEST',
  CONTINGENCY_PLAN_RISK_SUCCESS: 'CONTINGENCY_PLAN_RISK_SUCCESS',
  CONTINGENCY_PLAN_RISK_FAILED: 'CONTINGENCY_PLAN_RISK_FAILED',
};

export const CONTINGENCY_PLAN_MONSOON_CONST = {
  CONTINGENCY_PLAN_MONSOON_REQUEST: 'CONTINGENCY_PLAN_MONSOON_REQUEST',
  CONTINGENCY_PLAN_MONSOON_SUCCESS: 'CONTINGENCY_PLAN_MONSOON_SUCCESS',
  CONTINGENCY_PLAN_MONSOON_FAILED: 'CONTINGENCY_PLAN_MONSOON_FAILED',
};

export const STAGE_CONST = {
  STAGE_REQUEST: 'STAGE_REQUEST',
  STAGE_SUCCESS: 'STAGE_SUCCESS',
  STAGE_FAILED: 'STAGE_FAILED',
};

export const SYSTEM_CONST = {
  SYSTEM_REQUEST: 'SYSTEM_REQUEST',
  SYSTEM_SUCCESS: 'SYSTEM_SUCCESS',
  SYSTEM_FAILED: 'SYSTEM_FAILED',
};

export const DROUGHT_INDICES_CONST = {
  DROUGHT_INDICES_REQUEST: 'DROUGHT_INDICES_REQUEST',
  DROUGHT_INDICES_SUCCESS: 'DROUGHT_INDICES_SUCCESS',
  DROUGHT_INDICES_FAILED: 'DROUGHT_INDICES_FAILED',
};

export const STRATEGY_CONST = {
  STRATEGY_REQUEST: 'STRATEGY_REQUEST',
  STRATEGY_SUCCESS: 'STRATEGY_SUCCESS',
  STRATEGY_FAILED: 'STRATEGY_FAILED',
};

export const DROUGHT_STAGES_TIME_PERIOD_CONST = {
  DROUGHT_STAGES_TIME_PERIOD_REQUEST: 'DROUGHT_STAGES_TIME_PERIOD_REQUEST',
  DROUGHT_STAGES_TIME_PERIOD_SUCCESS: 'DROUGHT_STAGES_TIME_PERIOD_SUCCESS',
  DROUGHT_STAGES_TIME_PERIOD_FAILED: 'DROUGHT_STAGES_TIME_PERIOD_FAILED',
};

export const CATEGORY_CONST = {
  CATEGORY_REQUEST: 'CATEGORY_REQUEST',
  CATEGORY_SUCCESS: 'CATEGORY_SUCCESS',
  CATEGORY_FAILED: 'CATEGORY_FAILED',
};

export const SUB_CATEGORY_CONST = {
  SUB_CATEGORY_REQUEST: 'SUB_CATEGORY_REQUEST',
  SUB_CATEGORY_SUCCESS: 'SUB_CATEGORY_SUCCESS',
  SUB_CATEGORY_FAILED: 'SUB_CATEGORY_FAILED',
};

export const DISTRICT_PROFILE_CONST = {
  DISTRICT_PROFILE_REQUEST: 'DISTRICT_PROFILE_REQUEST',
  DISTRICT_PROFILE_SUCCESS: 'DISTRICT_PROFILE_SUCCESS',
  DISTRICT_PROFILE_FAILED: 'DISTRICT_PROFILE_FAILED',
};

export const CONTINGENCY_MEASURES_CROP_TYPE_CONST = {
  CONTINGENCY_MEASURES_CROP_TYPE_REQUEST: 'CONTINGENCY_MEASURES_CROP_TYPE_REQUEST',
  CONTINGENCY_MEASURES_CROP_TYPE_SUCCESS: 'CONTINGENCY_MEASURES_CROP_TYPE_SUCCESS',
  CONTINGENCY_MEASURES_CROP_TYPE_FAILED: 'CONTINGENCY_MEASURES_CROP_TYPE_FAILED',
};

export const CONTINGENCY_MEASURES_DATA_CONST = {
  CONTINGENCY_MEASURES_DATA_REQUEST: 'CONTINGENCY_MEASURES_DATA_REQUEST',
  CONTINGENCY_MEASURES_DATA_SUCCESS: 'CONTINGENCY_MEASURES_DATA_SUCCESS',
  CONTINGENCY_MEASURES_DATA_FAILED: 'CONTINGENCY_MEASURES_DATA_FAILED',
};

export const CONTINGENCY_DISTRICT_GRAPH_CONST = {
 CONTINGENCY_DISTRICT_GRAPH_REQUEST : 'CONTINGENCY_DISTRICT_GRAPH_REQUEST',
 CONTINGENCY_DISTRICT_GRAPH_SUCCESS: 'CONTINGENCY_DISTRICT_GRAPH_SUCCESS',
 CONTINGENCY_DISTRICT_GRAPH_FAILED : 'CONTINGENCY_DISTRICT_GRAPH_FAILED',
}
