import React, { useState, useEffect } from 'react';
import { Row, Col, Card, Button, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import {
  renderReactSelectInputField,
  renderTextField,
} from '../../Components/common/renderInputField';
import { t } from 'react-multi-lang';
import { renderDateFormat } from '../../Components/common/utils';
import { renderOptions } from '../../Components/helpers';
import { required } from '../../Components/constants/validate';
import { createRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { MONTHLY_BULLETIN, LOCATION } from '../../Components/constants';
import {
  getDistrictByStateRequest,
} from '../../redux/actions';
import Select from 'react-select';


function CreateBulletin(props) {
  const [startDate, setStartDate] = useState(null);
  const dispatch = useDispatch();
  const [indicators, setIndicators] = useState([]);
  const [countrysList, setCountryList] = useState([]);
  const [statesList, setStateList] = useState([]);
  const [districtsList,setDistrictList] = useState([]);
   const [bulletinType, setBulletinType]= useState('');
  const [state, setState] = useState();
  const [country, setCountry]=useState();
  const [district, setDistrict]= useState();

  const [selectedLocation, setSelectedLocation] = useState({
    country: {},
    state: {},
    district: {},
  });
  const {
    handleSubmit,
    isOpen,
    toggleModal,
    LocationData,
    isBulletinPage,
    indicatorsList,
    bulletinCategories,
    initialValues,
    setIndicatorRows,
    setMapRefs,
    indicatorRows,
    setEachRowAPIPayload,
  } = props;
  const history = useHistory();


  const onSubmit = values => {
    toggleModal();
    let indicators = values.indicators?.length
      ? values.indicators.map(item => {
          return item.label;
        })
      : [];
        
     let country =""
     if(values?.country?.label){
      country=values.country.label
     }else{
      country="Zambia"
     }
     
     let state="";

     if(values?.province?.label){
      if(values?.province?.label=="Select All"){
        state=""
       }else{
        state = values.province.label
       }
     }else{
      state=""
     } 

    let district="";
    if (values?.district?.label){
      if(values?.district?.label=="Select All"){
        district=""
      }else{
        district = values.district.label
      }
    }
    else{
      district=""
    };
    let startDate;
    let endDate;
        if((bulletinType  === MONTHLY_BULLETIN ) || (initialValues?.bulletinType?.label === MONTHLY_BULLETIN)){
       startDate = `${values.startDate}-01`;
       endDate=`${values.startDate}-28`;
    }else{
    startDate = values.startDate;
    endDate = values.endDate;
    }
    toggleModal();
    const newQueryParams = `?country=${country.toString()}&state=${state.toString()}&district=${district.toString()}&indicators=${indicators.toString()}&startDate=${
      startDate
    }&endDate=${endDate}&bulletinType=${values.bulletinType.label}&bulletinId=${values.bulletinType.id}`;


    if (!isBulletinPage) {
      history.push({
        pathname: '/create-online-bulletin',
        search: newQueryParams,
      });
    } else {
      setMapRefs(prev => ({
        ...prev,
        [`row${Object.keys(indicatorRows)?.length}`]: createRef({}),
      }));
      const formData = {
        country : "Zambia",
        state: values.province?.label,
        district :values.district?.label,
        indicatorsList: values.indicators,
        startDate: startDate,
        endDate: endDate,
      };
      // data for api call
      setEachRowAPIPayload(formData);
      // pushing row into existing row data
      setIndicatorRows(prev => ({
        ...prev,
        [`row${Object.keys(prev)?.length}`]: formData,
      }));
    }
  };

  useEffect(() => { 
    if (LocationData.countryList) {
      const { result } = LocationData.countryList;
      if (result?.[0]) {
        let arr = [];
        result.map((i) => {
          return arr.push({
            id: i.id,
            name: i.name,
            value: i.name,
            label: i.name,
            region_id: i.region_id,
            latitude: i.latitude,
            longitude: i.longitude,
            zoom_level: i.zoom_level,
          });
        });
        setCountryList(arr);
      }
    }
  }, [LocationData.countryList]);

  useEffect(() => {
    if (LocationData.stateList) {
      let result=[]
      let defaultOption = { value: '', label: 'Select All', id: 'select',name:"Select All" };
      result.push(defaultOption)
    //  if (LocationData?.stateList?.data) result.push(LocationData?.stateList?.data?.result);
      if (LocationData?.stateList?.data){
        LocationData?.stateList?.data.result.map( param => {
          result.push(param);
          return true;
        });
      //  return result;
      }
      setStateList(result);
    }
  }, [LocationData.stateList]);



  useEffect(()=>{
    if(LocationData.districtList){
      let result=[];
      let defaultOption = { value: '', label: 'Select All', id: 'select',name:"Select All" };
      result.push(defaultOption)
      if(LocationData?.districtList?.data){
        LocationData?.districtList?.data.result.map( param => {
          result.push(param);
          return true;
        });
      } 
      setDistrictList(result);
    }
  },[LocationData.districtList]);


  const handleIndicators = useCallback(e => {
    if (e.length < 4) {
      setIndicators(e);
    }
  }, []);

  const handleBulletinType=(e)=>{
  setBulletinType(e.name)
  }

  const handleSelectedCountry = (e)=>{
    setCountry(e.name)
  }

  const handleSelectedState = (e) =>{
    setState(e.name)
  }
  const handleSelectedDistrict =(e)=>{
    setDistrict(e.name)
  }


  useEffect(()=>{
    if( state){
      let stateObject = LocationData?.stateList?.data?.result.filter((item)=>{
        return item.name==state
      })
      setSelectedLocation((prev) => {
        return {
          ...prev,
          state: stateObject,
        };
      });
      dispatch(
        getDistrictByStateRequest({
          requestObj: {
            location_type: LOCATION.District,
            parent_id: stateObject[0]?.id,
          },
          isCompareLocation: false,
        }),
      );
    }
  },[state])

  useEffect (()=>{
    if(district){
      let districtObject = LocationData?.districtList?.data?.result.filter((item)=>{
        return item.name === district
      })
      setSelectedLocation((prev)=>{
        return{
          ...prev,
          district :districtObject,
        }
      })
    }
},[district])

  const getCurrentYearMonth = () => {
    let dateObj = new Date();
    let month =
      (dateObj.getMonth() + 1) % 10 !== dateObj.getMonth() + 1
        ? dateObj.getMonth() + 1
        : `0${dateObj.getMonth() + 1}`;
    return dateObj.getUTCFullYear() + '-' + month;
  };


  return (
    <Modal
      isOpen={isOpen}
      className="modal-map-sm col-2 order-modal model-content create-bulletin-popup"
    >
      <ModalHeader className="custom-header" toggle={toggleModal}>
        <p className="create-bulletin-header mb-0">{t('ONLINE_BULLETIN.CREATE_BULLETIN.HEADER')}</p>
      </ModalHeader>
      <ModalBody>
        <Card className="card-style">
          <form onSubmit={handleSubmit(onSubmit)} className="filter-control-dp">
            <div className="search-container">
              <Row>
                <Col md={12}>
                  <Select
                 // component={Select}
                  type="select"
              name="country"
            //  placeholder={"Zambia"}
              label={t('ONLINE_BULLETIN.CREATE_BULLETIN.COUNTRY')}
              //options={renderOptions(countrysList)}
              onChange={handleSelectedCountry}
              isMulti={false}
            //  validate={[required]}
              className="field-space custom-placeholder"
             isDisabled={true}
          //  value={defaultSelectedCountry}
          value={{
            id
            : 
            "13c88c14-ca9b-4775-9e58-f47df6bcf619",
            label
            : 
            "Zambia",
            value
            : 
            "Zambia"}}
            /> 
        </Col> 
        <Col md={12}>
            <Field   
            component={renderReactSelectInputField}
            name="province"
            label={t('ONLINE_BULLETIN.CREATE_BULLETIN.PROVINCE')}
           // validate={[required]}
            placeholder={t('ONLINE_BULLETIN.CREATE_BULLETIN.PROVINCE_PLACEHOLDER')}
            isMulti={false}
            options={renderOptions(statesList)}
            isDisabled={initialValues?.province?.label || initialValues?.bulletinType?.label}
            className="field-space custom-placeholder"
            onChange={handleSelectedState}
            />
            </Col>
        <Col md={12}>
             <Field   
            component={renderReactSelectInputField}
              name="district"
              placeholder={t('ONLINE_BULLETIN.CREATE_BULLETIN.DISTRICT_PLACEHOLDER')}
              label={t('ONLINE_BULLETIN.CREATE_BULLETIN.DISTRICT')}
              options={renderOptions(districtsList)}
              isMulti={false}
            //  validate={[required]}
              className="field-space custom-placeholder" 
              isDisabled={initialValues?.district?.label || initialValues?.bulletinType?.label}
              onChange={handleSelectedDistrict}
            />
            </Col>
                <Col md={12}>
                  <Field
                    component={renderReactSelectInputField}
                    name="bulletinType"
                    placeholder={t('ONLINE_BULLETIN.CREATE_BULLETIN.SELECT_BULLETIN_TYPE')}
                    label={t('ONLINE_BULLETIN.CREATE_BULLETIN.BULLETIN_TYPE')}
                    isMulti={false}
                    isDisabled={initialValues?.bulletinType?.label}
                    options={renderOptions(bulletinCategories)}
                    validate={[required]}
                    className="field-space custom-placeholder"
                    onChange={handleBulletinType}
                  />
                </Col>
                <Col md={12}>
                  <Field
                    component={renderReactSelectInputField}
                    name="indicators"
                    placeholder={t('ONLINE_BULLETIN.CREATE_BULLETIN.INDICATOR_PLACEHOLDER')}
                    label={t('ONLINE_BULLETIN.CREATE_BULLETIN.INDICATORS')}
                    onChange={e => handleIndicators(e)}
                    options={renderOptions(indicatorsList)}
                    isMulti={true}
                    isOptionDisabled={() => indicators.length === 3}
                    validate={[required]}
                    className="field-space custom-placeholder"
                  />
                </Col>

                <Col md={12}>           
                    <Field
                     type={bulletinType ? bulletinType === MONTHLY_BULLETIN  ? "month" :"date": (initialValues?.bulletinType?.label === MONTHLY_BULLETIN ) ? "Month" :"date" }
                      max={bulletinType === MONTHLY_BULLETIN ? getCurrentYearMonth() : renderDateFormat(new Date()) }
                      isMulti={false}
                      component={renderTextField}
                      name="startDate"
                      label={bulletinType ? bulletinType === MONTHLY_BULLETIN ? t('ONLINE_BULLETIN.CREATE_BULLETIN.MONTH_YEAR'):t('ONLINE_BULLETIN.CREATE_BULLETIN.START_DATE')
                    :(initialValues?.bulletinType?.label === MONTHLY_BULLETIN ) ? t('ONLINE_BULLETIN.CREATE_BULLETIN.MONTH_YEAR'):t('ONLINE_BULLETIN.CREATE_BULLETIN.START_DATE')}
                      bsSize="sm"
                      validate={[required]}
                      className="field-space w-90 date-field"
                      onChange={e => setStartDate(e.target.value)}
                />
               <Field
                  type={bulletinType ? bulletinType === MONTHLY_BULLETIN  ? "month" :"date": (initialValues?.bulletinType?.label === MONTHLY_BULLETIN ) ? "Month" :"date" }
                  required={true}
                  isMulti={false}
                  component={renderTextField}
                    className={bulletinType ? bulletinType === MONTHLY_BULLETIN ? "montly-display":"field-space w-90 date-field" :(initialValues?.bulletinType?.label === MONTHLY_BULLETIN ) ? "montly-display":"field-space w-90 date-field"}
                    name="endDate"
                    label={bulletinType ? bulletinType === MONTHLY_BULLETIN ? "":t('ONLINE_BULLETIN.CREATE_BULLETIN.END_DATE')
                    :(initialValues?.bulletinType?.label === MONTHLY_BULLETIN ) ? "":t('ONLINE_BULLETIN.CREATE_BULLETIN.END_DATE')}
                    isDisabled={bulletinType ? bulletinType === MONTHLY_BULLETIN ? true :!startDate :(initialValues?.bulletinType?.label === MONTHLY_BULLETIN ) ? true :!startDate}
                    min={startDate}
                    bsSize="sm"
                /> 
                </Col>

                <Col md={12}>
                  <Button
                    disabled={props.invalid}
                    type="submit"
                    className="navyBlueBgColor submitBtnClass field-space w-90 primary-btn"
                  >
                    Submit
                  </Button>
                </Col>
              </Row>
            </div>
          </form>
        </Card>
      </ModalBody>
    </Modal>
  );
}

export default reduxForm({ form: 'create-bulletin' })(CreateBulletin);
