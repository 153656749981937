import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';
import {
  Dropdown,
  Card,
  CardImg,
  CardSubtitle,
  CardTitle,
  Row,
  Col,
} from 'reactstrap';
import moment from 'moment';
import { DISPLAY_DATE_FORMAT } from '../../Components/constants';
import '../../assets/CSS/bulletin.css';
import { t } from 'react-multi-lang';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import InfiniteScroll from 'react-infinite-scroll-component';
import Loader from '../../Components/common/loader';
import {
  getTemplateRequest,
  getAllTemplateListRequest,
} from '../../redux/bulletin/bulletinActions';

const BulletTemplateModal = props => {
  const { isOpen, toggleModal } = props;

  const history = useHistory();

  const dispatch = useDispatch();


  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggleDp = () => setDropdownOpen(prevState => !prevState);
  const [templates, setTemplates] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageCount, setPageCount] = useState();
  const [templateListOptions, setTemplateListOptions] = useState([]);

  const { Bulletin } = useSelector(state => ({
    Bulletin: state.Bulletin,
  }));

  useEffect(() => {
    dispatch(
      getTemplateRequest({
        page: pageNumber,
        data: {},
      }),
    );
    dispatch(getAllTemplateListRequest());
  }, []);

  useEffect(() => {
    if (Bulletin?.getAllTemplateListData) {
      let options = [];
      let data = Bulletin.getAllTemplateListData;
      if (data) {
        const resultData = data?.data;
        if (resultData && resultData?.result) {
          let list = resultData?.result;
          let params = [...list];
          if (params?.length) {
            params?.map(param => {
              let data = {
                value: param.name,
                label: `${param.name}`,
                id: param.id,
              };
              options.push(data);
              return true;
            });
          }
        }
      }
      setTemplateListOptions(options);
    }
  }, [Bulletin.getAllTemplateListData]);

  useEffect(() => {
    if (Bulletin?.getTemplateData?.data) {
      let new_list = [];
      const {
        data: { result },
      } = Bulletin.getTemplateData.data;
      if (result) {
        if (templates) {
          if (pageNumber > 1) {
            new_list = templates?.concat(result?.results);
          } else {
            new_list = result?.results;
          }
        } else {
          new_list = result?.results;
        }
        setTemplates(new_list);
        setPageCount(result?.count);
      } else {
        setTemplates([]);
      }
    }
  }, [Bulletin.getTemplateData]);

  const toggle = () => {
    toggleModal();
  };

  const fetchNextTemplateData = () => {
    setPageNumber(pageNumber + 1);
    dispatch(
      getTemplateRequest({
        page: pageNumber + 1,
        data: {},
      }),
    );
  };

  const cardHandler = data => {
    let list = templateListOptions;
    let defaultList = {
      label: data?.category_name,
      value: data?.category_name,
      id: data?.category_id,
    };
    history.push({
      pathname: '/create-bulletin',
      state: [list, defaultList, data],
    });
  };

  return (
    <div>
      <Modal
        isOpen={isOpen}
        toggle={toggle}
        size="xl"
        fade={true}
        scrollable="true"
        className="over-flow-auto bulletin-template-modal"
      >
        <ModalHeader toggle={toggle} className="reason-modal-header" scrollable>
          {t('BULLET_IN.BULLET_IN_TEMPLATES')}
        </ModalHeader>
        <ModalBody className="bullet-modal-body m-2" scrollable id="infinteScrollReference">
          <Dropdown
            isOpen={dropdownOpen}
            toggle={toggleDp}
            className="bulletin-dp dropdown-body-size"
          ></Dropdown>
          <Row className="mt-3">
            <div className="loader-div">{Bulletin.isGetTemplateRequesting && <Loader />}</div>
            {_.isEmpty(templates) && !Bulletin.isGetTemplateRequesting && (
              <h2 className="bulletin-template-modal-no-records-found">
                {t('BULLET_IN.NO_RECORDS_FOUND')}
              </h2>
            )}
            <InfiniteScroll
              dataLength={pageNumber * 10 < pageCount ? pageNumber * 10 : ''}
              next={fetchNextTemplateData}
              hasMore={pageNumber * 10 < pageCount ? true : false}
              loader={
                Bulletin.isGetTemplateRequesting && (
                  <center>
                    <h4>{t('BULLET_IN.LOADING')} </h4>
                  </center>
                )
              }
              scrollableTarget="infinteScrollReference"
            >
              {templates?.length ? templates?.map(i => {
                return (
                  <Col className="m-3 ms-5 bulletin-template-modal-card-col" lg={3}>
                    <Card
                      className="bulletin-modal-cards"
                      onClick={() => {
                        cardHandler(i);
                      }}
                    >
                      <CardImg
                        src={i?.preview_image || ''}
                        top
                        width="100%"
                        className="template-modal-card-Image"
                      />
                      <div className="m-3">
                        <CardTitle tag="h5">
                          {i?.title === String(i?.title).toUpperCase()
                            ? i?.title?.length > 16
                              ? i?.title?.substring(0, 16) + '...'
                              : i?.title
                            : i?.title?.length > 21
                            ? i?.title?.substring(0, 21) + '...'
                            : i?.title}
                        </CardTitle>
                        <CardSubtitle className="mb-2 text-muted" tag="h6">
                          {i?.category_name}
                        </CardSubtitle>
                        <CardSubtitle className="mb-2 text-muted" tag="h6">
                          {moment(i?.created_date).format(DISPLAY_DATE_FORMAT)}
                        </CardSubtitle>
                      </div>
                    </Card>
                  </Col>
                );
              }):(<></>)}
            </InfiniteScroll>
          </Row>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default BulletTemplateModal;
