import React, { useState, useEffect } from 'react';
import { Collapse, Modal, ModalHeader, ModalBody, Row, Col } from 'reactstrap';
import { RiArrowDropUpLine, RiArrowDropDownLine } from 'react-icons/ri';
import PropTypes from 'prop-types';
import _ from 'lodash';
import '../../assets/CSS/LeafletWrapper.css';
import { t } from 'react-multi-lang';
import Loader from '../../Components/common/TextLoader';
import { useSelector, useDispatch } from 'react-redux';
import {
  getMapPreferenceBoundariesFailed,
  getMapPreferenceBoundariesRequest,
} from '../../redux/actions';
import { showError } from '../../Components/Notifications';
import {
  MAP_PREFERENCES_lABELS,
  SOUTH_ASIA_CONST,
  TALUKAS,
  LOCATION_ZOOM_LEVEL_THRESHOLD_CONSTS,
} from '../../Components/constants';
import ReactTooltip from 'react-tooltip';

require('dotenv').config();

function MapPreferences(props) {
  const {
    isToggle,
    handleToggle,
    loc,
    regionId,
    getMapPreferencesData,
    geoJsonData,
    mapPreferencesAllData,
    selectedParamLegendData,
    getMapPreferencesGeojsonData,
    isMapPerferGeoJsonLoading,
    fromContingencyPlan,
    fromDroughtMonitor,
    countryData,
    stateData,
    districtData,
    cordinateAndZoom,
  } = props;
  const dispatch = useDispatch();
  const [modal, setModal] = useState(false);
  const [handleCateogry, setHandleCategory] = useState([]);
  const [getBoundariesData, setGetBoundariesData] = useState([]);
  const [geoJsonLayerData, setGeoJsonLayerData] = useState([]);
  const [mapPreferencesData, setMapPreferencesData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedMapPreferences, setSelectedMapPreferences] = useState([]);
  const [talukasDisable, setTalukasDisable] = useState(true);
  const [talukasBoundaryData, setTalukasBoundaryData] = useState();

  const { boundries } = useSelector(state => ({
    boundries: state.Map_Preference,
  }));

  useEffect(() => {
    setModal(isToggle);
  }, [isToggle]);

  const {
    isMapPreferenceBoundariesRequesting,
    mapPreferenceBoundariesData,
    mapPreferenceBoundariesDataError,
  } = boundries;

  useEffect(() => {
    setMapPreferencesData([]);
    getMapPreferencesData([]);
    return () => {
      dispatch(getMapPreferenceBoundariesFailed(''));
    };
  }, []);

  useEffect(() => {
    if (cordinateAndZoom?.zoom) {
      let zoomLevelThreshold = LOCATION_ZOOM_LEVEL_THRESHOLD_CONSTS?.find(
        item => item.location_type === MAP_PREFERENCES_lABELS.DISTRICTS,
      )?.zoom_threshold;
      if (
        zoomLevelThreshold?.length &&
        (zoomLevelThreshold?.includes(cordinateAndZoom?.zoom) ||
          cordinateAndZoom?.zoom > zoomLevelThreshold[zoomLevelThreshold?.length - 1])
      ) {
        setTalukasDisable(false);
      } else if (
        districtData?.name === 'select' ||
        _.isEmpty(districtData) ||
        stateData?.name === 'select' ||
        _.isEmpty(stateData)
      ) {
        setTalukasDisable(true);
      } else {
        setTalukasDisable(false);
      }
    }
  }, [cordinateAndZoom, districtData, stateData]);

  useEffect(() => {
    if (districtData?.name === 'select' || _.isEmpty(districtData)) {
      if (talukasBoundaryData?.e?.target?.checked) {
        let event = { target: { checked: false } };
        handleCheckbox(event, talukasBoundaryData.boundry, { fromMapPreferences: false });
      }
    }
  }, [stateData, districtData]);

  useEffect(() => {
    if (boundries?.mapPreferenceData) {
      let data = boundries?.mapPreferenceData?.data?.result;
      let categoryList = [];
      let updatedCategoryList = [];
      if (data?.length) {
        data?.map(item => {
          categoryList.push({ name: item.category, checked: true });
        });
      }
      setHandleCategory(categoryList);
      if (data) {
        updatedCategoryList = data?.map(item => {
          if (item && item.hasOwnProperty('isShowCategory')) {
            return item;
          } else {
            item.isShowCategory = true;
            return item;
          }
        });
      }
      if (updatedCategoryList?.length) {
        setGetBoundariesData(updatedCategoryList);
      }
    }
  }, [boundries?.mapPreferenceData]);

  let mapPreferences = mapPreferencesData;

  useEffect(() => {
    if (boundries.isMapPreferenceBoundariesRequesting) {
      setLoading(true);
    }
    if (boundries.mapPreferenceBoundariesData) {
      if (
        boundries.mapPreferenceBoundariesData?.payload.fromContingencyPlan === fromContingencyPlan
      ) {
        setLoading(false);
        const { result } = boundries.mapPreferenceBoundariesData.data;
        const { category, name, slug, legendName } = boundries.mapPreferenceBoundariesData.payload;
        const {
          boundarytype_id: boundaryId,
        } = boundries.mapPreferenceBoundariesData.payload.requestObj;
        if (result.code === 200) {
          let data = {};
          data = mapPreferencesData?.find(x => x.id === boundaryId);
          if (_.isEmpty(data)) {
            mapPreferences.push({
              result,
              id: boundaryId,
              category: category,
              dataSource: name,
              slug: slug,
              parameter: category,
              legendName: legendName,
            });

            setMapPreferencesData(mapPreferences);
            getMapPreferencesData(mapPreferences);
          }
        } else if (result.code === 400) {
          showError(result?.message);
        }
      }
    } else if (boundries.mapPreferenceBoundariesDataError) {
      setLoading(false);
      if (
        boundries.mapPreferenceBoundariesDataError?.data &&
        boundries.mapPreferenceBoundariesDataError?.data?.response
      ) {
        const { data } = boundries.mapPreferenceBoundariesDataError?.data?.response;
        if (data?.exception) {
          showError(data?.exception);
        }
      }
    }
  }, [
    isMapPreferenceBoundariesRequesting,
    mapPreferenceBoundariesData,
    mapPreferenceBoundariesDataError,
  ]);

  useEffect(() => {
    setGeoJsonLayerData(geoJsonData);
  }, [geoJsonData]);

  useEffect(() => {
    setMapPreferencesData(mapPreferencesAllData);
  }, [mapPreferencesAllData]);

  useEffect(() => {
    let categoryList = JSON.parse(JSON.stringify(handleCateogry));
    function defaultCheckList(layerData) {
      layerData?.map(item => {
        let index = categoryList?.findIndex(x => x.name === item.category && !x.checked);
        if (index >= 0) {
          categoryList[index] = {
            ...categoryList[index],
            checked: true,
          };
        }
      });
    }
    if (geoJsonLayerData?.length) {
      defaultCheckList(geoJsonLayerData);
      let categoryListData = [];
      geoJsonLayerData?.map(item => {
        categoryListData.push(item?.category);
      });
    }
    if (mapPreferencesData?.length) {
      defaultCheckList(mapPreferencesData);
    }
    if (categoryList?.length) {
      setHandleCategory(categoryList);
    }
  }, [geoJsonLayerData, mapPreferencesData]);

  const handleCateogryToggle = (item, { fromCpAdmin }) => {
    let categoryList = [...handleCateogry];
    const index = _.findIndex(categoryList, list => list.name === item.category);
    if (index >= 0) {
      categoryList[index] = {
        ...categoryList[index],
        checked: fromCpAdmin ? true : !categoryList[index].checked,
      };
    }
    setHandleCategory(categoryList);
  };

  const toggle = () => {
    setModal(!modal);
    handleToggle(!modal);
  };

  useEffect(() => {
    if (fromContingencyPlan) {
      if (!_.isEmpty(props?.districtData) && getBoundariesData?.length) {
        getBoundariesData?.map(item => {
          if (item.category === 'Admin') {
            if (item.data?.length) {
              handleCateogryToggle(item, { fromCpAdmin: false });
              item.data.map(x => {
                let e = { target: {} };
                e.target.checked = true;
                if (
                  x.boundarytype.slug === MAP_PREFERENCES_lABELS.TALUKAS ||
                  x.boundarytype.slug === MAP_PREFERENCES_lABELS.DISTRICTS
                ) {
                  handleCheckbox(e, x, { fromMapPreferences: false });
                }
              });
            }
          }
        });
      } else if (_.isEmpty(props?.districtData)) {
        getBoundariesData?.map(item => {
          if (item.category === 'Admin') {
            if (item.data?.length) {
              handleCateogryToggle(item, { fromCpAdmin: true });
              item.data.map(x => {
                let e = { target: {} };
                e.target.checked = false;
                if (
                  x.boundarytype.slug === MAP_PREFERENCES_lABELS.TALUKAS ||
                  x.boundarytype.slug === MAP_PREFERENCES_lABELS.DISTRICTS
                ) {
                  handleCheckbox(e, x, { fromMapPreferences: false });
                }
              });
            }
          }
        });
      }
    }
  }, [fromContingencyPlan, props?.districtData, getBoundariesData]);

  useEffect(() => {
    if (fromDroughtMonitor) {
      if (
        !_.isEmpty(props?.stateData) &&
        props?.stateData?.name !== 'select' &&
        getBoundariesData?.length
      ) {
        handleDefaultCheckboxes(getBoundariesData, MAP_PREFERENCES_lABELS.DISTRICTS, {
          checked: true,
          fromMapPreferences: false,
        });
      } else if (_.isEmpty(props?.stateData) || props?.stateData?.name === 'select') {
        if (talukasBoundaryData?.e?.target?.checked) {
          let event = { target: { checked: false } };
          handleCheckbox(event, talukasBoundaryData.boundry, { fromMapPreferences: true });
        }
        handleDefaultCheckboxes(getBoundariesData, MAP_PREFERENCES_lABELS.DISTRICTS, {
          checked: false,
          fromMapPreferences: false,
        });
      }
    }
  }, [props?.stateData, getBoundariesData, fromDroughtMonitor]);

  useEffect(() => {
    if (fromDroughtMonitor) {
      if (
        !_.isEmpty(props?.countryData) &&
        getBoundariesData?.length &&
        props?.countryData.name === SOUTH_ASIA_CONST.name
      ) {
        setTalukasDisable(true);
        handleDefaultCheckboxes(getBoundariesData, MAP_PREFERENCES_lABELS.COUNTRY, {
          checked: true,
          fromMapPreferences: false,
        });
      } else {
        if (!_.isEmpty(props?.countryData) && getBoundariesData?.length) {
          setTalukasDisable(true);
          //   -------->>stoping default application of boundaries 
          //   -------->>uncomment handleCateogryToggle while default boundaries are added.
          // handleDefaultCheckboxes(getBoundariesData, MAP_PREFERENCES_lABELS.STATES, {
          //   checked: true,
          //   fromMapPreferences: false,
          // });
        }
      }
    }
  }, [props?.countryData, getBoundariesData, fromDroughtMonitor]);

  useEffect(() => {
    if (fromContingencyPlan) {
      if (!_.isEmpty(props?.countryData) && getBoundariesData?.length) {
        setTalukasDisable(true);
      }
    }
  }, [fromContingencyPlan, props?.countryData, getBoundariesData]);

  useEffect(() => {
    if (fromDroughtMonitor) {
      if (
        !_.isEmpty(props?.districtData) &&
        props?.districtData?.value !== '' &&
        getBoundariesData?.length
      ) {
        handleDefaultCheckboxes(getBoundariesData, MAP_PREFERENCES_lABELS.TALUKAS, {
          checked: true,
          fromMapPreferences: false,
        });
      }
    }
  }, [props?.districtData, getBoundariesData, fromDroughtMonitor]);

  //TODO: Contingency default NRSC LULC map code commented.

  // useEffect(() => {
  //   if (fromContingencyPlan) {
  //     if (defaultBoundariesData?.length) {
  //       defaultBoundariesData?.map(item => {
  //         if (item.category === 'Land use land cover') {
  //           if (item.data?.length) {
  //             handleCateogryToggle(item);
  //             item.data.map(x => {
  //               let e = { target: {} };
  //               e.target.checked = true;
  //               if (x.boundarytype.slug === MAP_PREFERENCES_lABELS.NPSC_LULC) {
  //                 let filterLULC = false;

  //                 if (mapPreferencesData?.length) {
  //                   filterLULC = mapPreferencesData?.some(item => item.id === x.boundarytype.id);
  //                 }
  //                 if (!filterLULC) {
  //                   handleCheckbox(e, x,  { fromMapPreferences: false });
  //                 }
  //               }
  //             });
  //           }
  //         }
  //       });
  //     }
  //   }
  // }, [fromContingencyPlan, defaultBoundariesData]);

  const handleDefaultCheckboxes = (boundaryData, selectedLocationName, { checked }) => {
    boundaryData?.map(item => {
      if (item.category === 'Admin') {
        if (item.data?.length) {
         // opening admin category by default
         // handleCateogryToggle(item, { fromCpAdmin: false });
          item.data.map(x => {
            let e = { target: {} };
            e.target.checked = checked;
            if (x.boundarytype.slug === selectedLocationName) {
              if (selectedLocationName == MAP_PREFERENCES_lABELS.COUNTRY) {
                handleCheckbox(e, x, { fromMapPreferences: true });
              } else {
                handleCheckbox(e, x, { fromMapPreferences: false });
              }
            }
          });
        }
      }
    });
  };

  let geoJsonLayers = geoJsonLayerData;

  const addGeoJsonLayer = boundry => {
    geoJsonLayers.push({
      name: boundry?.boundarytype?.boundarytype,
      url: boundry?.boundary_data_url,
      color: boundry?.boundarytype?.color?.code,
      id: boundry?.boundarytype_id,
      category: boundry?.category,
      country_id: boundry?.country_id,
      fromMapPreferences: boundry?.fromMapPreferences,
    });
    getMapPreferencesGeojsonData(geoJsonLayers);
    setGeoJsonLayerData(geoJsonLayers);
  };

  const removeGeoJsonLayer = boundry => {
    let tempLayer = geoJsonLayers.filter(layer => layer.id !== boundry?.boundarytype_id);
    geoJsonLayers = tempLayer;
    getMapPreferencesGeojsonData(geoJsonLayers);
    setGeoJsonLayerData(geoJsonLayers);
  };

  const addMapPreferencesLayer = boundry => {
    let requestObj = {};
    if (regionId && countryData?.id === SOUTH_ASIA_CONST.id) requestObj.region_id = regionId;
    else requestObj.country_name = loc;
    requestObj.boundarytype_id = boundry?.boundarytype_id;
    dispatch(
      getMapPreferenceBoundariesRequest(
        requestObj,
        boundry?.category,
        boundry?.boundarytype?.boundarytype,
        boundry?.boundarytype?.slug,
        boundry?.boundarytype?.legend_name,

        fromContingencyPlan,
      ),
    );
  };

  let mapRemove = [];
  const removeMapPreferencesLayer = boundry => {
    mapPreferences = mapPreferences.filter(layer => layer.id !== boundry?.boundarytype_id);
    // mapPreferences = tempLayer;
    getMapPreferencesData(mapPreferences);
    setMapPreferencesData(mapPreferences);
    mapRemove.push(boundry?.boundarytype_id);
  };

  let selectedMapArr = selectedMapPreferences;
  const addMapLayer = boundry => {
    // selectedMapArr.push(boundry);
    if (selectedMapPreferences?.length) {
      let selectedData = selectedMapPreferences?.find(x => x.id === boundry?.boundarytype_id);
      if (_.isEmpty(selectedData)) {
        setSelectedMapPreferences(prev => [...prev, boundry]);
        addMapPreferencesLayer(boundry);
      }
    } else {
      setSelectedMapPreferences(prev => [...prev, boundry]);
      addMapPreferencesLayer(boundry);
    }
  };

  const removeMapLayer = boundry => {
    const filteredMapArr = selectedMapArr?.filter(
      item => item.boundarytype.boundarytype !== boundry.boundarytype.boundarytype,
    );
    // if (filteredMapArr?.length) {
    setSelectedMapPreferences(filteredMapArr);
    // }
    removeMapPreferencesLayer(boundry);
  };

  const handleCheckbox = (e, boundry, { fromMapPreferences }) => {
    if (boundry.boundarytype.slug === TALUKAS) {
      setTalukasBoundaryData({ e, boundry });
    }
    let data = JSON.parse(JSON.stringify(boundry));
    data.fromMapPreferences = fromMapPreferences;
    if (e.target.checked) {
      boundry?.boundary_data_url.length ? addGeoJsonLayer(data) : addMapLayer(data);
    } else {
      boundry?.boundary_data_url.length ? removeGeoJsonLayer(data) : removeMapLayer(data);
    }
  };

  const isCheckbox = inputId => {
    if (mapPreferencesData.find(item => item?.id === inputId)) {
      return true;
    }

    return geoJsonLayerData.find(item => item?.id === inputId);
  };

  const checkboxStyle = {
    color: '#2A547C',
  };

  const renderCheckboxes = items => {
    return items?.map((item, index) => {
      const selectedItem = handleCateogry?.find(x => x.name === item.category);
      return (
        <>
          <span
            onClick={() => handleCateogryToggle(item, { fromCpAdmin: false })}
            data-tip
            data-for={item.category === 'Admin' ? 'registerTip' : ''}
          >
            {item.category === 'Admin' ? (
              <p className="map-preferences-labels" style={checkboxStyle}>
                {item.category} &nbsp;
                <span className="info-style">
                  <i
                    className="cursor-ponter fa fa-info info-icon-edit-btn"
                    id="info-icon-tool-tip"
                    data-tip
                    data-for="info-icon-tool-tip"
                  />
                </span>
                <span className="category-span">
                  <span className="category-span">
                    {selectedItem?.checked ? (
                      <RiArrowDropUpLine size={25} />
                    ) : (
                      <RiArrowDropDownLine size={25} />
                    )}
                  </span>
                </span>
              </p>
            ) : (
              <p className="map-preferences-labels" style={checkboxStyle}>
                {item.category}
                <span className="category-span">
                  <span className="category-span">
                    {selectedItem?.checked ? (
                      <RiArrowDropUpLine size={25} />
                    ) : (
                      <RiArrowDropDownLine size={25} />
                    )}
                  </span>
                </span>
              </p>
            )}
          </span>
          {item.category === 'Admin' ? (
            <ReactTooltip
              id="registerTip"
              effect="float"
              place="bottom"
              html={true}
              className="map-prefer-tooltip-st1"
            >
              {`Disclaimer: IWMI has been following the guidelines of the UN on territorial boundaries for publications. The
                  guidelines can be accessed from: https://www.ifpri.org/sites/default/files/guidelines_countries-territories-maps.pdf`}
            </ReactTooltip>
          ) : (
            ''
          )}
          <Collapse
            id={'collapse' + index}
            isOpen={selectedItem?.checked}
            className="collapse-moadal"
          >
            {returnCheckbox(item, item.data, index)}
          </Collapse>
        </>
      );
    });
  };

  useEffect(() => {
    let categoryList = JSON.parse(JSON.stringify(getBoundariesData));
    setGetBoundariesData(categoryList);
  }, [isMapPerferGeoJsonLoading]);

  const showCheckBox = (mapPreferences, boundry) => {
    let url = boundry?.boundary_data_url;
    let id = boundry.boundarytype.id;
    let boundaryName = boundry.boundarytype.slug;
    let isAddedLayer = selectedParamLegendData?.some(i => i.add);
    if (isAddedLayer && mapPreferences?.length === 1 && _.isEmpty(url)) {
      if (mapPreferences?.some(i => i?.boundarytype.id === id)) {
        return false;
      } else {
        return true;
      }
    } else if (mapPreferences?.length === 2 && _.isEmpty(url)) {
      if (mapPreferences?.some(i => i?.boundarytype.id === id)) {
        return false;
      } else {
        return true;
      }
    } else if (boundaryName === TALUKAS) {
      if (fromDroughtMonitor || fromContingencyPlan) {
        return handleTalukasEnableDisable(id, boundry);
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const handleTalukasEnableDisable = (id, boundry) => {
    if (talukasDisable) {
      // todo need to dynamically remove checkbox while selecting state as select in left side bar
      if (isCheckbox(id)) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  };

  const showToolTip = (selectedMapPreferences, selectedParamLegendData) => {
    if (
      selectedMapPreferences?.length === 2 ||
      (selectedMapPreferences?.length === 1 &&
        selectedParamLegendData?.filter(i => i.add)?.length === 1)
    ) {
      return true;
    } else return false;
  };

  const returnCheckbox = (boundary, boundryTypes, index) => {
    return boundryTypes.map((boundry, index) => {
      return (
        <>
          <Row className="row-mb">
            <Col 
            // md="10" lg="10" sm="10" 
            key={index} 
            className="checkbox-rp"
            id={boundry.boundary_data_url || !showCheckBox(selectedMapPreferences,boundry) ? 'geojson_input' : 'map_preference_input'}
            data-tip
            data-for={boundry.boundary_data_url || !showCheckBox(selectedMapPreferences,boundry) ? 'geojson_input' : 'map_preference_input'}
            >
              <div className="label-block">
              <input
                className="checkbox checkbox-mt"
                type="checkbox"
                name={boundry.boundarytype.id}
                onChange={e => handleCheckbox(e, boundry, { fromMapPreferences: true })}
                checked={isCheckbox(boundry.boundarytype.id) ? isCheckbox(boundry.boundarytype.id) : false}
                disabled={loading ? true : showCheckBox(selectedMapPreferences,boundry)}
              />
              <label htmlFor="checkbox"  className="label-lp">{boundry.boundarytype.boundarytype}</label>
              </div>
            </Col>
            {showToolTip(selectedMapPreferences, selectedParamLegendData) ? (
              <ReactTooltip
                id="map_preference_input"
                effect="float"
                place="bottom"
                type="info"
                className="map-prefer-tooltip-st"
              >
                {t('FILTER.REMOVE_LAYER')}
              </ReactTooltip>
            ) : (
              ''
            )}
          </Row>
        </>
      );
    });
  };

  return (
    <>
      <div>
        {modal && loading && <Loader />}
        <Modal
          isOpen={modal}
          toggle={toggle}
          className="modal-map-sm col-2 order-modal model-content"
        >
          <ModalHeader className="close-map-preference" toggle={toggle}>
            <p className="map-preferences-side-bar-title left-mrg-header">
              {t('WEATHER_FORECAST.MAP_PREFERENCES.HEADER.TITLE')} <br />
              <span className="map-preferences-side-bar-sub-title" style={{ fontSize: '10px' }}>
                {t('WEATHER_FORECAST.MAP_PREFERENCES.HEADER.SUB_TITLE')}
              </span>
            </p>
          </ModalHeader>
          <ModalBody className="modal-bdy">
            {renderCheckboxes(getBoundariesData)}
            <br />
          </ModalBody>
        </Modal>
      </div>
    </>
  );
}
export default React.memo(MapPreferences);
MapPreferences.propTypes = {
  // eslint-disable-next-line react/no-typos
  isToggle: PropTypes.bool,
  productModalData: PropTypes.object,
  handleToggle: PropTypes.func,
  Map_Preferences: PropTypes.object,
  handleRenderingList: PropTypes.func,
  handleCheckBoxRenderingList: PropTypes.func,
  renderingList: PropTypes.object,
  handleShow: PropTypes.func,
};
