import L from 'leaflet';
import { useRef, useEffect } from 'react';
import { MONTHS_LIST } from '../constants';
import moment from 'moment';
/**
 * Remove Legends
 */

export const removeLegend = id => {
  const legend = L.control();
  const image = document.getElementById(`${id}`);
  legend.onRemove = () => {
    if (image) {
      L.DomUtil.remove(image);
    }
  };
  legend.onRemove();
};

//global utility to create querystring
export const createJSON = search => {
  return JSON.parse(
    '{"' +
      decodeURI(search)
        .replace(/"/g, '\\"')
        .replace(/&/g, '","')
        .replace(/=/g, '":"') +
      '"}',
  );
};
//global function to rename name as label in dropdown options
export const renderOptions = optionsList => {
  if (optionsList?.length) {
    let newOptions = [];
    optionsList.forEach(element => {
      newOptions.push({
        ...element,
        value: element.id,
        label: element.name,
      });
    });
    return newOptions;
  }
  return [];
};

//global function to compare props
export function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}
//get selected element from nested object of array's data
export const getNestedObjectData = (dataArray, id) => {
  return dataArray
    .filter(x => x.parameter.some(child => id === child.name))
    .map(y => ({
      ...y.parameter.find(child => id === child.name),
      parentIndiceName: y.name,
    }))?.[0];
};
export const getDateSuffix = function(d) {
  if (d > 3 && d < 21) return 'th';
  switch (d % 10) {
    case 1:
      return 'st';
    case 2:
      return 'nd';
    case 3:
      return 'rd';
    default:
      return 'th';
  }
};
export const getBulletinDateFormat = dateString => {
  const givenDate = new Date(dateString);
  const date = givenDate.getDate(),
    month = MONTHS_LIST[givenDate.getMonth()],
    year = givenDate.getFullYear();

 return date + getDateSuffix(date) + ' ' + month + ' ' + year;
 //return `${month}-${year}`; 
};

export const getBulletinMonthFormat = dateString =>{
  const givenDate = new Date(dateString);
  const month = MONTHS_LIST[givenDate.getMonth()],
        year = givenDate.getFullYear();

  return `${month}-${year}`;
}


export const iriDate = (selectedDate, dateListing) => {
  const date = {
    startDate: '',
    endDate: '',
  };
  const endIriDate = selectedDate?.month?.id + '-' + selectedDate?.year?.value;
  const addValue = dateListing.find(each => each?.id === selectedDate?.lead_time?.id);
  const endIriEnd12 = moment(endIriDate, 'MM-YYYY')
    .add(addValue?.indexValue, 'months')
    .format('MM-YYYY');
  let iriStartDate = moment(endIriEnd12, 'MM-YYYY')
    .startOf('month')
    .format('DD-MM-YYYY');
  let iriEndDate = moment(endIriEnd12, 'MM-YYYY')
    .endOf('month')
    .format('DD-MM-YYYY');
  date.startDate = iriStartDate
  date.endDate = iriEndDate
  return date;
};