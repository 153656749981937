import React from 'react';
import { Editor } from '@tinymce/tinymce-react';

const DraftEditor = props => {
  const { content, onEditorChange } = props;
  return (
    <>
    <div className="decription-editor" id="tiny-editor">
      <Editor
        value={content === undefined ? '' : content}
        apiKey="8sg2vaosizljdlh4xg6frgc3nsfs0wvg022tnp6ibklzdez6"
        onEditorChange={Text => onEditorChange(Text)}
        init={{
          height: '26rem',
          selector: '#tiny-editor',
          plugins: [
            'advlist autolink lists link image charmap print preview anchor',
            'searchreplace visualblocks code fullscreen',
            'insertdatetime media  paste imagetools wordcount',
          ],
          toolbar:
            'insertfile undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | link image | code',
          image_title: true,
          file_picker_types: 'image',
          file_picker_callback: function(cb) {
            var input = document.createElement('input');
            input.setAttribute('type', 'file');
            input.setAttribute('accept', 'image/*');
            input.onchange = function() {
              var file = this.files[0];
              var reader = new FileReader();
              reader.onload = () => {
                var id = 'blobid' + new Date().getTime();
                var blobCache = window.tinymce.activeEditor.editorUpload.blobCache;
                var base64 = reader.result.split(',')[1];
                var blobInfo = blobCache.create(id, file, base64);
                blobCache.add(blobInfo);
                cb(blobInfo.blobUri(), { title: file.name });
              };
              reader.readAsDataURL(file);
            };
            input.click();
          },
        }}
      />
    </div>
     <p className="text-test">Note:- For better user experience, kindly choose font family:"National/verdana" and font size:"14px".</p>
     </>
  );
};

export default DraftEditor;
