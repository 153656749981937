import React, { useEffect, useState } from 'react';
import { Row, Col } from 'reactstrap';
import axios from 'axios';
import {
  Card,
  CardTitle,
  CardSubtitle,
  CardBody,
  ListGroup,
  ListGroupItem,
} from 'reactstrap';
import moment from 'moment';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDispatch, useSelector } from 'react-redux';
import { DISPLAY_DATE_FORMAT } from '../../../Components/constants';
import { useTranslation } from 'react-multi-lang';
import {
  NEWS_FEED_CATEGORIES,
  NEWS_FEED_LENGTH_CONSTANT,
} from '../../../Components/constants/index';
import Loader from '../../../Components/common/loader';
import _ from 'lodash';
import { newsFeedSubCategoriesRequest } from '../../../redux/newsFeed/newsFeedAction';
import {
  EXTERNAL_RSS_FEED_REQUEST_URL,
  EXTERNAL_RSS_FIELD_CONST,
} from '../../../Components/constants/index';

const IWMINews = props => {
  const t = useTranslation();
  const dispatch = useDispatch();
  const [pageNumber, setPageNumber] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const [rssFeedList, setRssFeedList] = useState([]);
  const [selectedRssFeedItem, setSelectedRssFeedItem] = useState('');
  const [initialRssFeedItemList, setInitialRssFeedItemList] = useState('');
  const [selectedRssFeedItemList, setSelectedRssFeedItemList] = useState([]);
  const [visibleRssFeedList] = useState({
    start: EXTERNAL_RSS_FIELD_CONST.startIndex,
    end: EXTERNAL_RSS_FIELD_CONST.endIndex,
  });
  const nextProps = useSelector(state => ({
    NewsFeedSubCategoriesData: state.NewsFeed.newsFeedSubCategoriesListData,
    isNewsFeedSubCategoriesRequesting: state.NewsFeed.isNewsFeedSubCategoriesRequesting,
  }));

  useEffect(() => {
    if (nextProps.NewsFeedSubCategoriesData) {
      const {
        data: { result },
      } = nextProps.NewsFeedSubCategoriesData;
      setRssFeedList(result);
      if (result?.length) {
        result.map((i, index) => {
          if (index === 0) {
            setSelectedRssFeedItem(i);
          }
        });
      }
    }
  }, [nextProps.NewsFeedSubCategoriesData]);

  useEffect(() => {
    if (!_.isEmpty(props.selectedMenuItem)) {
      dispatch(newsFeedSubCategoriesRequest({ category_id: props.selectedMenuItem.id }));
    }
  }, [props.selectedMenuItem]);

  useEffect(() => {
    if (!_.isEmpty(selectedRssFeedItem)) {
      axios
        .get(EXTERNAL_RSS_FEED_REQUEST_URL.replace('{link}', selectedRssFeedItem.url))
        .then(response => {
          let data = response?.data;
          setInitialRssFeedItemList(data);
          let items = data?.items?.slice(0, 6);
          setSelectedRssFeedItemList({ ...data, items });
          setPageCount(data?.items?.length);
          setPageNumber(1);
        });
    }
  }, [selectedRssFeedItem]);


  const handleSelectedRSSFeedItem = (i, index) => {
    setSelectedRssFeedItem(i);
  };


  const fetchNextTemplateData = () => {
    let pageNuM = pageNumber + 1;
    setPageNumber(pageNuM);
    if (initialRssFeedItemList?.items) {
      let news_list = [];
      let list = _.cloneDeep(selectedRssFeedItemList?.items);
      let hasMoreData =
        selectedRssFeedItemList?.items?.length + 6 < initialRssFeedItemList?.items?.length
          ? true
          : false;
      let data = initialRssFeedItemList?.items?.slice(
        selectedRssFeedItemList?.items?.length,
        hasMoreData
          ? selectedRssFeedItemList?.items?.length + 6
          : initialRssFeedItemList?.items?.length,
      );
      if (selectedRssFeedItemList?.items?.length) {
        if (pageNuM > 1) {
          news_list = data?.length ? list?.concat(data) : list;
        }
      } else {
        news_list = list;
      }
      setSelectedRssFeedItemList({ ...selectedRssFeedItemList, items: news_list });
    } else {
      setSelectedRssFeedItemList([]);
    }
  };

  return (
    <div className="iwmi-content-section news-feed-cls">
      <div className="content-title">
        <span className="">{NEWS_FEED_CATEGORIES.IWMI_News}</span>
        <hr className="title-hr" />
      </div>
      <div className="">
        {(props.isNewsFeedRequesting || nextProps.isNewsFeedSubCategoriesRequesting) && <Loader />}
        <Row>
          <div className="external-rss-feed-list">
            <ListGroup horizontal>
              {rssFeedList?.length
                ? rssFeedList
                    ?.slice(visibleRssFeedList.start, visibleRssFeedList.end + 1)
                    ?.map((item, index) => {
                      return (
                        <ListGroupItem
                          active={selectedRssFeedItem.id === item.id ? true : false}
                          tag="button"
                          onClick={() => handleSelectedRSSFeedItem(item, index)}
                          action
                          key={index}
                        >
                          {item.sub_category_name}
                        </ListGroupItem>
                      );
                    })
                : ''}
            </ListGroup>
          </div>
          <div className=""></div>
        </Row>
        <InfiniteScroll
          dataLength={pageNumber * 6 < pageCount ? pageNumber * 6 : ''}
          next={() => fetchNextTemplateData()}
          hasMore={pageNumber * 6 < pageCount ? true : false}
          className="flex flex-wrap"
        >
          <Row>
            {!_.isEmpty(selectedRssFeedItemList) &&
              selectedRssFeedItemList?.items?.length &&
              selectedRssFeedItemList?.items.map((i,index) => {
                return (
                  <Col xs="4" md="4" lg="4" sm="4" key={index} className="iwmi-card-section">
                    <Card className="news-feed-card iwmi-card">
                      <CardBody className="iwmi-card-body">
                        <CardTitle className="news-feed-card-title">
                          <a
                            className="news-feed-title-link"
                            href={i?.link}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {i?.title?.length > NEWS_FEED_LENGTH_CONSTANT.SIXTY
                              ? `${i?.title.substring(0, 60)}... `
                              : i?.title}
                          </a>
                        </CardTitle>
                        <div className="news-feed-card-content position-relative">
                           <div className="iwmi-raw-data"
                            dangerouslySetInnerHTML={{ __html: i?.content }}
                          ></div>
                        </div>
                        <CardSubtitle className="news-feed-card-sub-title">
                          {t('BULLET_IN.PUBLISHED_ON') +
                            ' ' +
                            moment(i?.pubDate).format(DISPLAY_DATE_FORMAT)}
                        </CardSubtitle>
                      </CardBody>
                    </Card>
                  </Col>
                );
              })}
          </Row>
        </InfiniteScroll>
      </div>
    </div>
  );
};
export default IWMINews;
