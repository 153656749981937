import React, { useState } from 'react';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle} from 'reactstrap';
import { Modal, ModalHeader, ModalBody} from 'reactstrap';
import { t } from 'react-multi-lang';
import { useHistory } from 'react-router-dom';

const BulletDpModal = props => {
  const history = useHistory();
  const { isOpen, toggleModal, templateList } = props;
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [dropDownText] = useState('');

  const toggleDp = () => setDropdownOpen(prevState => !prevState);

  const toggle = () => {
    toggleModal();
  };

  const handleTemplates = (list, defaultList) => {
    history.push({
      pathname: '/create-template',
      state: [list, defaultList],
    });
  };

  return (
    <div>
      <Modal isOpen={isOpen} toggle={toggle} className="bullet-modal modal-sm">
        <ModalHeader toggle={toggle} className="reason-modal-header">
          {t('BULLET_IN.CREATE_BULLETIN_TEMPLATE')}
        </ModalHeader>
        <ModalBody className="bullet-modal-body">
          <Dropdown isOpen={dropdownOpen} toggle={toggleDp} className="bulletin-dp bullet-modal-dp">
            <DropdownToggle className="p-2">
              {dropDownText ? dropDownText : t('BULLET_IN.SELECT_TEMPLATE_CATEGORY')}
              <i className="fa fa-caret-down float-right"></i>
            </DropdownToggle>
            <DropdownMenu className="bullet-dp-menu bullet-modal-dp-menu">
              {templateList?.length ? templateList?.map((item, id, array) => {
                return (
                  <div key={id}>
                    <DropdownItem
                      className="bullet-dp-item"
                      onClick={() => handleTemplates(array, item)}
                    >
                      {item?.label}
                    </DropdownItem>
                  </div>
                );
              }):(<></>)}
            </DropdownMenu>
          </Dropdown>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default BulletDpModal;
