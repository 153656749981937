import React, { useEffect, useState } from 'react';
import { ListGroup, ListGroupItem} from 'reactstrap';

const Menu = props => {
  const { handleSelectedItem } = props;
  

  const [year, setYear] = useState([])
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear())

  const handleSelectedMenuItem = value => {
    setSelectedYear(value)
    handleSelectedItem(value);
  };


  useEffect(() => {
    let listOfYears = []
    
    for (let i = new Date().getFullYear(); i >= (new Date().getFullYear() - 4 ) ; i--) {
      listOfYears.push(i)
    }
    setYear(listOfYears)
  }, [])
  return (
    <div className="news-feed-menu menu-container  ">
      {/* <p className="filter-heading"> Year </p> */}
      <ListGroup className='years-list' style={{width:"75%",marginLeft:"20%",marginTop:"10%"} }>
        {year?.map((i,index) => {
          return (
            <ListGroupItem
              active={i === selectedYear}
              tag="button"
              onClick={() =>  handleSelectedMenuItem(i)}
              action
              key={index}
            >
              {i}
            </ListGroupItem>
          );
        })}
      </ListGroup>
    </div>
  );
};
export default Menu;
