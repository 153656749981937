import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-multi-lang';
import PropTypes from 'prop-types';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Button from 'reactstrap-button-loader';
import { changeStatusRequest } from '../../redux/accessKey/accessKeyActions';

const ConfirmationModal = props => {
  const {
    className,
    isOpen,
    modalData,
    handleLatestData,
    handleApprovedModalToggle,
    fromModal,
    reactivateClicked,
    handleReactivateModalToggle,
    activeTab,
  } = props;
  const dispatch = useDispatch();
  const t = useTranslation();
  const nextProps = useSelector(state => ({
    changeStatusData: state.AccessKey.changeStatusData || '',
    isChangeStatusRequesting: state.AccessKey.changeStatusRequesting,
  }));

  function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }
  const currentprops = nextProps.changeStatusData && nextProps.changeStatusData.data;
  const previousprops = usePrevious(currentprops);

  const isSecondRun = useRef(true);
  useEffect(() => {
    if (isSecondRun.current) {
      isSecondRun.current = false;
      return;
    }
    if (currentprops && currentprops !== previousprops) {
      if (currentprops) {
        handleApprovedModalToggle(false);
        if (reactivateClicked === true) {
          handleReactivateModalToggle();
        }
        handleLatestData();
      }
    }
  }, [currentprops, previousprops]);

  const toggle = value => {
    if (value === 'no') {
      if (reactivateClicked === true) {
        handleReactivateModalToggle();
      }
      handleApprovedModalToggle(false);
    } else if (value === 'yes') {
      dispatch(changeStatusRequest(modalData));
    }
  };

  const handleClose = () => {
    handleApprovedModalToggle(false);
  };

  return (
    <div>
      <Modal isOpen={isOpen} toggle={toggle} className={className}>
        <ModalHeader toggle={() => handleClose()} className="reason-modal-header">
          {t('PUBLIC_API_ADMIN.CONFIRMATION')}
        </ModalHeader>
        <ModalBody>
          <p className="confirmation-modal-text">
            {fromModal && (activeTab === 'approve' || activeTab === 'reject')
              ? t('PUBLIC_API_ADMIN.UPDATE_CONFIRMATION')
              : reactivateClicked === true
              ? t('PUBLIC_API_ADMIN.REACTIVATE_CONFIRMATION')
              : t('PUBLIC_API_ADMIN.APPROVE_CONFIRMATION')}
          </p>
        </ModalBody>
        <ModalFooter className="reason-modal">
          <Button color="secondary" onClick={() => toggle('no')} className="cancel-button">
            {t('PUBLIC_API_ADMIN.NO')}
          </Button>{' '}
          <Button
            loading={nextProps.isChangeStatusRequesting}
            color="primary"
            onClick={() => toggle('yes')}
            className="modal-reject-button"
          >
            {t('PUBLIC_API_ADMIN.YES')}
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default ConfirmationModal;
ConfirmationModal.propTypes = {
  isOpen: PropTypes.bool,
  modalData: PropTypes.object,
  handleLatestData: PropTypes.func,
  handleApprovedModalToggle: PropTypes.bool,
  fromModal: PropTypes.bool,
  activeTab: PropTypes.string,
};
