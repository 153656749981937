import React, { useEffect } from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAllUserGuideRequest } from '../../redux/actions';
import CreateUserGuidePage from './CreateUserGuidePage';
import _ from 'lodash';
const ManageForm = props => {
  const dispatch = useDispatch();
  const { UserGuide } = useSelector(state => ({
    UserGuide: state.UserGuide,
  }));
  const place=[
    {id:1,label: "Before" ,value:"before"},
    {id:2,label: "After" ,value:"after"},
  ]
  const [defaultUserGuideOptions, setDefaultUserGuideOptions] = useState({});
  const [categoryList, setCategoryList] = useState([]);
  const [fromListing, setFromListing] = useState(false);
  const [fromManageUser, setFromManageUser] = useState(false);
  const [subTopicOptions, setSubTopicOptions] = useState();


  const [formUserGuideData, setFormUserGuideData] = useState({
    id: '',
    topic: '',
    topic_id: '',
    sub_topic: '',
    sub_topic_id: '',
    title: '',
    description: '',
    publish_status: false,
    before_sub_topic_order:'',
    level:'',
  });

  useEffect(() => {
    let search = window.location.search;
    let URLParams = new URLSearchParams(search);
    let topicId = URLParams.get('category');
    let Id = URLParams.get('unique');
    let subTopicId = URLParams.get('sub-category');

    if (!_.isEmpty(topicId) && !_.isEmpty(subTopicId)) {
      dispatch(
        getAllUserGuideRequest({
          data: { id: Id, topic_id: topicId, subtopic_id: subTopicId, is_delete: 'False',is_backoffice: "True" },
        }),
      );
    } else {
      dispatch(getAllUserGuideRequest({ data: { id: Id, topic_id: topicId, is_delete: 'False',is_backoffice: "True" } }));
    }
    setFromManageUser(true);
    setDefaultUserGuideOptions({});
    return () => {
      setFromManageUser(false);
    };
  }, []);

  useEffect(() => {
    if (UserGuide?.getAllTopicData) {
      if (UserGuide?.getAllTopicData?.data) {
        const {
          data: { result },
        } = UserGuide?.getAllTopicData?.data;
        setCategoryList(result);
      }
    }
  }, [UserGuide.getAllTopicData]);


  useEffect(() => {
    if (UserGuide.getAllUserGuideData?.data?.result?.results) {
      let new_list = [];
      const {
        data: { result },
      } = UserGuide.getAllUserGuideData;
      if (result) {
        const { results } = result;
        if (results?.length) {
          const action = props.match.params?.action;
          if (action === 'edit' && fromManageUser) {
            afterUpdate(results[0]);
            setFromListing(true);
          } else {
            setDefaultUserGuideOptions({});
            setFromListing(false);
            setFormUserGuideData({
              id: '',
              topic: '',
              topic_id: '',
              sub_topic: '',
              sub_topic_id: '',
              title: '',
              description: '',
              publish_status: false,
              before_sub_topic_order:'',
              level:'',
            });
          }
        }
      }
    }
  }, [UserGuide.getAllUserGuideData]);

  useEffect(() => {
    if (UserGuide.updateUserGuideData) {
      setFormUserGuideData({
        id: '',
        topic: '',
        topic_id: '',
        sub_topic: '',
        sub_topic_id: '',
        title: '',
        description: '',
        publish_status: false,
      });

      dispatch(getAllUserGuideRequest({ page: 1, data: { is_delete: 'False' ,is_backoffice: "True"} }));
    }
  }, [UserGuide.updateUserGuideData]);

  /**
   * added by : sai krishna thalla
   * reason : add default order and levels while updating the user guide
   * date: 8-sep-2022
   */
  useEffect(()=>{
    if (!_.isEmpty(categoryList) && formUserGuideData?.topic){
      renderAPISubTopicOptions(categoryList,formUserGuideData?.topic)
    }
  },[categoryList,formUserGuideData?.topic])


  useEffect(()=>{
    if (subTopicOptions?.length && subTopicOptions && defaultUserGuideOptions.order_subtopic_id){
         let orderOption = subTopicOptions.find(i => i.id == defaultUserGuideOptions?.order_subtopic_id)
         let obj = _.clone(defaultUserGuideOptions)
         obj.Beforesubtopic = orderOption
          setDefaultUserGuideOptions(obj)
    }
  },[subTopicOptions,defaultUserGuideOptions.order_subtopic_id])

  const renderAPISubTopicOptions = (list, option) => {
    if (list) {
      let params = [...list];
      let options = [];
      let requiredTopic = params.filter(param => param.name === option);
      if (requiredTopic?.length) {
        let requiredSubTopic = requiredTopic[0].sub_topic;

        if (requiredSubTopic?.length) {
          requiredSubTopic.forEach(element => {
            let data = {
              value: element.name,
              label: `${element.name}`,
              id: element.id,
              order:element.order,
            };
            options.push(data);
          });
          setSubTopicOptions(options);
        } else {
          setSubTopicOptions([]);
        }
      }
    }
  };

  const afterUpdate = data => {
    let arr = {
      topic: {
        id: data?.topic_id,
        value: data?.topic_name,
        label: data?.topic_name,
      },
      subtopic:  data?.subtopic_name,
      title: data?.title,
      status: data?.status,
      order:data?.order,
      level:data?.level,
      order_subtopic_id:data?.order_subtopic_id
    };
    formUserGuideData.topic = data?.topic_name;
    formUserGuideData.topic_id = data?.topic_id;
    formUserGuideData.sub_topic = data?.subtopic_name;
    formUserGuideData.sub_topic_id = data?.subtopic_id;
    formUserGuideData.description = data?.content;
    formUserGuideData.publish_status = data?.status;
    formUserGuideData.title = data?.title;
    formUserGuideData.id = data?.id;
    formUserGuideData.level= data?.level;
    formUserGuideData.before_sub_topic_order = data?.order;
    formUserGuideData.before_sub_topic_id = data?.order_subtopic_id
    setDefaultUserGuideOptions(arr);
    setFormUserGuideData({ ...formUserGuideData });
  };

  const handleFromManageUserGuide = data => {
    setFromManageUser(false);
  };
  return (
    <>
      <CreateUserGuidePage
        formUserGuideData={formUserGuideData}
        initialValues={defaultUserGuideOptions}
        fromListing={fromListing}
        categoryList={categoryList}
        handleFromManageUserGuide={handleFromManageUserGuide}
      />
    </>
  );
};

export default ManageForm;
