import React, { useState } from 'react';
import { Modal, ModalBody, ModalHeader, FormGroup, Label, Input, Button } from 'reactstrap';
import { t } from 'react-multi-lang';

const UploadBulletinTypeModal = (props) => {
  const { isOpen, toggleModal, setBulletModal, handleCreateBulletinPdf } = props;

  const fromTemplate = 'fromTemplate';
  const fromPdf = 'fromPdf';
  const [selectedType, setSelectedType] = useState(fromTemplate);
  const toggle = () => {
    toggleModal();
  };
  
  const handleChange = (e) => {
    setSelectedType(e.target.value);
  };
  const handleClick = () => {
    if (selectedType === fromTemplate) {
      setBulletModal(true);
      toggleModal();
    } else if (selectedType === fromPdf) {
      handleCreateBulletinPdf();
      toggleModal();
    }
  };
  return (
    <>
      <Modal isOpen={isOpen} toggle={toggle} className="bullet-modal modal-sm">
        <ModalHeader toggle={toggle} className="reason-modal-header" scrollable>
          {t('BULLET_IN.SELECT_TYPE')}
        </ModalHeader>
        <ModalBody>
          <FormGroup className="py-2">
            <div className="d-flex" onChange={(e) => handleChange(e)}>
              <div className="form-check m-2">
                <Input
                  className="form-check-input"
                  type="radio"
                  name="flexRadioDefault"
                  value={fromTemplate}
                  id="flexRadioDefault1"
                  defaultChecked
                />
                <Label className="form-check-label" htmlFor="flexRadioDefault1">
                  {t('BULLET_IN.FROM_TEMPLATE')}
                </Label>
              </div>
              <div className="form-check m-2">
                <Input
                  className="form-check-input"
                  type="radio"
                  name="flexRadioDefault"
                  value={fromPdf}
                  id="flexRadioDefault2"
                />
                <Label className="form-check-label" htmlFor="flexRadioDefault2">
                  {t('BULLET_IN.FROM_PDF')}
                </Label>
              </div>
            </div>
          </FormGroup>
          <div className="d-flex flex-row-reverse">
            <Button
              type="submit"
              className="bulletin-login-btn-grn-key select-bulletin-next"
              onClick={handleClick}
              disabled={!selectedType}
            >
              Next
            </Button>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default UploadBulletinTypeModal;
