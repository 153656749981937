export const NEWS_FEED_CATEGORIES_CONST = {
  NEWS_FEED_CATEGORIES_REQUEST: 'NEWS_FEED_CATEGORIES_REQUEST',
  NEWS_FEED_CATEGORIES_SUCCESS: 'NEWS_FEED_CATEGORIES_SUCCESS',
  NEWS_FEED_CATEGORIES_FAILED: 'NEWS_FEED_CATEGORIES_FAILED',
};

export const NEWS_FEED_SUB_CATEGORIES_CONST = {
  NEWS_FEED_SUB_CATEGORIES_REQUEST: 'NEWS_FEED_SUB_CATEGORIES_REQUEST',
  NEWS_FEED_SUB_CATEGORIES_SUCCESS: 'NEWS_FEED_SUB_CATEGORIES_SUCCESS',
  NEWS_FEED_SUB_CATEGORIES_FAILED: 'NEWS_FEED_SUB_CATEGORIES_FAILED',
};

export const GET_ALL_NEWS_FEED_CONST = {
  GET_ALL_NEWS_FEED_REQUEST: 'GET_ALL_NEWS_FEED_REQUEST',
  GET_ALL_NEWS_FEED_SUCCESS: 'GET_ALL_NEWS_FEED_SUCCESS',
  GET_ALL_NEWS_FEED_FAILED: 'GET_ALL_NEWS_FEED_FAILED',
};

export const GET_BY_ID_NEWS_FEED_CONST = {
  GET_BY_ID_NEWS_FEED_REQUEST: 'GET_BY_ID_NEWS_FEED_REQUEST',
  GET_BY_ID_NEWS_FEED_SUCCESS: 'GET_BY_ID_NEWS_FEED_SUCCESS',
  GET_BY_ID_NEWS_FEED_FAILED: 'GET_BY_ID_NEWS_FEED_FAILED',
};

export const NEWS_FEED_ADD_CONST = {
  NEWS_FEED_ADD_REQUEST: 'NEWS_FEED_ADD_REQUEST',
  NEWS_FEED_ADD_SUCCESS: 'NEWS_FEED_ADD_SUCCESS',
  NEWS_FEED_ADD_FAILED: 'NEWS_FEED_ADD_FAILED',
};

export const NEWS_FEED_DELETE_CONST = {
  NEWS_FEED_DELETE_REQUEST: 'NEWS_FEED_DELETE_REQUEST',
  NEWS_FEED_DELETE_SUCCESS: 'NEWS_FEED_DELETE_SUCCESS',
  NEWS_FEED_DELETE_FAILED: 'NEWS_FEED_DELETE_FAILED',
};

export const NEWS_FEED_UPDATE_CONST = {
  NEWS_FEED_UPDATE_REQUEST: 'NEWS_FEED_UPDATE_REQUEST',
  NEWS_FEED_UPDATE_SUCCESS: 'NEWS_FEED_UPDATE_SUCCESS',
  NEWS_FEED_UPDATE_FAILED: 'NEWS_FEED_UPDATE_FAILED',
};

export const NEWS_FEED_SUBSCRIBE_CONST = {
  NEWS_FEED_SUBSCRIBE_REQUEST: 'NEWS_FEED_SUBSCRIBE_REQUEST',
  NEWS_FEED_SUBSCRIBE_SUCCESS: 'NEWS_FEED_SUBSCRIBE_SUCCESS',
  NEWS_FEED_SUBSCRIBE_FAILED: 'NEWS_FEED_SUBSCRIBE_FAILED',
};

export const NEWS_FEED_CHANGE_STATUS_CONST = {
  NEWS_FEED_CHANGE_STATUS_REQUEST: 'NEWS_FEED_CHANGE_STATUS_REQUEST',
  NEWS_FEED_CHANGE_STATUS_SUCCESS: 'NEWS_FEED_CHANGE_STATUS_SUCCESS',
  NEWS_FEED_CHANGE_STATUS_FAILED: 'NEWS_FEED_CHANGE_STATUS_FAILED',
}