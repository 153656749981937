import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card, CardBody, Label } from 'reactstrap';
import Button from 'reactstrap-button-loader';
import { Field, reduxForm, change } from 'redux-form';
import PropTypes from 'prop-types';
import { showSuccess } from '../../Components/Notifications';
import {
  staticResourceAddRequest,
  staticResourceAddSuccess,
  staticResourceSuccess,
  updateStaticResourceRequest,
  updateStaticResourceSuccess,
} from '../../redux/staticResource/staticResourceAction';
import moment from 'moment';
import { t } from 'react-multi-lang';
import _ from 'lodash';
import { isSuperAdmin } from '../../Components/common/utils';
import {
  renderReactSelectInputField,
  renderTextField,
  renderImageField,
} from '../../Components/common/renderInputField';
import {
  DISPLAY_DATE_FORMAT,
  FORECAST_LEAD_TIME_LIST,
  DATA_SOURCES,
} from '../../Components/constants';

const validate = (values) => {
  const errors = {};
  if (!values.country) {
    errors.country = 'Required';
  } else {
    if (values.country.value === 'select') {
      errors.country = 'Required';
    }
  }
  if (!values.region) {
    errors.region = 'Required';
  } else {
    if (values.region.value === 'select') {
      errors.region = 'Required';
    }
  }
  if (!values.forecast_category) {
    errors.forecast_category = 'Required';
  }
  if (!values.datasource_type) {
    errors.datasource_type = 'Required';
  }
  if (values.datasource_type?.id === DATA_SOURCES.IRI.id) {
    if (!values.forecast_lead_time) {
      errors.datasource_type = 'Required';
    }
    if (!values.forecast_year_month) {
      errors.datasource_type = 'Required';
    }
  }
  if (!values.parameter_type) {
    errors.parameter_type = 'Required';
  }
  if (!values.Upload) {
    errors.Upload = 'Required';
  }
  if (!values.start_date) {
    errors.start_date = 'Required';
  }
  if (!values.end_date) {
    errors.end_date = 'Required';
  }
  if (values.start_date && values.end_date && values.start_date >= values.end_date) {
    errors.start_date = 'start date must be  less than end date';
  }
  return errors;
};

const Filter = (props) => {
  const {
    invalid,
    descriptionHandler,
    handleSubmit,
    pristine,
    reset,
    submitting,
    editClicked,
    regionList,
    handleRegion,
    countryList,
    handleCountry,
    dataSources,
    handleCategoryData,
    dataSourceList,
    handleSelectedDataSource,
    handlePreSelectedDataSource,
    parameterList,
    handleParameter,
    startDateHandler,
    endDateHandler,
    linkHandler,
    fileHandler,
    handleSelectedTab,
    handleUpdatedListData,
    handleResetInitialValues,
    handleDataSourcesEmptyListChange,
    updatedPermissions,
    setStaticResourcesList,
    isPristine,
    handleFilter,
    isIRISelected,
    forecastYearMonthHandler,
    forecastLeadTimeHandler,
    handleResetIRIFields,
  } = props;
  const req_data = {
    region: '',
    country: '',
    forecast_data_source: '',
    parameter: '',
    start_date: '',
    end_date: '',
    forecast_year_month: '',
    source_link: '',
    description: '',
    upload_image: '',
  };
  const filter_req_data = {
    region_id: '',
    country_id: '',
    forecast_data_source_id: [],
    parameter_id: [],
    start_date: null,
    end_date: null,
    limit: 0,
  };

  const dispatch = useDispatch();
  const [forecastLeadTimeList, setForecastLeadTimeList] = useState([]);
  const [isImgaeUploaded, setIsImgaeUploaded] = useState(false);
  const [formData, setFormData] = useState(req_data);
  const [countryFieldDisabled, setCountryFieldDisabled] = useState(false);
  const [regionFieldDisabled, setRegionFieldDisabled] = useState(false);
  const [isPreventAddDispatch, setIsPreventAddDispatch] = useState(true);
  const [file, setFile] = useState();
  let form = new FormData();
  const nextProps = useSelector((state) => ({
    staticResource: state.staticResource || null,
  }));
  const {
    staticResourceAddData,
    isStaticResourceAddRequesting,
    deleteStaticResourceData,
    updateStaticResourceData,
    deleteStaticResourceDataError,
    isUpdateStaticResourceRequesting,
  } = nextProps.staticResource;
  useEffect(() => {
    return () => {
      dispatch(staticResourceAddSuccess({}));
      dispatch(updateStaticResourceSuccess({}));
    };
  }, []);

  useEffect(() => {
    if (props.initialValues) {
      setFormData(props.initialValues);
      if (props.initialValues?.region?.id) {
        setCountryFieldDisabled(true);
        handleRegion(props.initialValues?.region?.id);
      } else if (props.initialValues?.country?.id) {
        setRegionFieldDisabled(true);
        handleCountry(props.initialValues?.country?.id);
      }
      dataSources?.map((i) => {
        if (i.id === props.initialValues?.forecast_category?.id) {
          handleCategoryData(i.data);
          i.data.map((item) => {
            if (item.forecast_data_source_id === props.initialValues?.datasource_type?.id) {
              handlePreSelectedDataSource(
                item.forecast_data_source.forecast_data_source_with_parameter,
                item.forecast_data_source_id,
              );
            }
          });
        }
      });
      if (props.initialValues.Upload) {
        fileHandler(props.initialValues.Upload);
      }
    }
  }, [props.initialValues]);

  useEffect(() => {
    if (staticResourceAddData) {
      const {
        data: { result },
      } = staticResourceAddData;
      if (result) {
        handleResetIRIFields(false);
        showSuccess(result);
        reset();
        dispatch(change('FilterForm', 'Upload', ''));
        handleSelectedTab();
      }
    }
  }, [staticResourceAddData]);

  useEffect(() => {
    if (formData.forecast_year_month) {
      let month = formData.forecast_year_month.split('-')[1];
      let list = JSON.parse(JSON.stringify(FORECAST_LEAD_TIME_LIST));
      let option_list = list?.splice(parseInt(month), 4);
      let latest_list;
      if (month >= 9) {
        let new_list = JSON.parse(JSON.stringify(FORECAST_LEAD_TIME_LIST));
        let new_arr = new_list?.splice(parseInt(0), 4 - option_list.length);
        latest_list = option_list.concat(new_arr);
      } else {
        latest_list = option_list;
      }
      latest_list?.map((i, index) => {
        i.name = i.name + '(' + `${index + 1} Month` + ')';
      });
      setForecastLeadTimeList(latest_list);
    }
  }, [formData.forecast_year_month]);

  useEffect(() => {
    if (updateStaticResourceData) {
      const {
        data: { result },
      } = updateStaticResourceData;
      if (result) {
        handleResetIRIFields(false);
        showSuccess(result);
        handleSelectedTab();
        handleResetInitialValues();
        setIsImgaeUploaded(false);
        // handleFilter(filter_req_data);
        props.setEditClicked(false)
      }
    }
  }, [updateStaticResourceData]);

  useEffect(() => {
    if (deleteStaticResourceDataError) {
      handleResetInitialValues();
    }
  }, [deleteStaticResourceDataError]);

  useEffect(() => {
    if (deleteStaticResourceData) {
      const {
        data: { result },
      } = deleteStaticResourceData;
      if (result) {
        showSuccess(result);
        handleUpdatedListData();
      }
    }
  }, [deleteStaticResourceData]);

  const renderAPIParameterOptions = (list, action_name) => {
    if (list) {
      let params = [...list];
      let options = [];
      if (params.length) {
        if (
          action_name !== 'forecast_lead_time' &&
          action_name !== 'parameter_type' &&
          action_name !== 'datasource_type' &&
          action_name !== 'forecast_category'
        ) {
          let default_option = { value: 'select', label: 'select', id: 'select' };
          options.push(default_option);
        }
        params.map((param) => {
          let data = {
            value: param.name,
            label: `${param.name}`,
            id: param.id,
          };
          options.push(data);
          return true;
        });
        return options;
      }
    }
  };

  const handleCountryChange = (e) => {
    if (e.value === 'select') {
      formData.region = '';
      setFormData({ ...formData });
      setRegionFieldDisabled(false);
      handleRegion(e.id);
    } else {
      setRegionFieldDisabled(true);
      formData.country = e.id;
      setFormData({ ...formData });
      handleCountry(e.id);
    }
    handleCategoryData();
    handleDataSourcesEmptyListChange();
    handleSelectedDataSource([]);
    dispatch(change('FilterForm', 'forecast_category', ''));
    dispatch(change('FilterForm', 'datasource_type', ''));
    dispatch(change('FilterForm', 'parameter_type', ''));
    dispatch(change('FilterForm', 'forecast_lead_time', ''));
  };
  const handleRegionChange = (e) => {
    if (e.value === 'select') {
      formData.region = '';
      setFormData({ ...formData });
      setCountryFieldDisabled(false);
      handleDataSourcesEmptyListChange();
      dispatch(change('FilterForm', 'forecast_category', ''));
      dispatch(change('FilterForm', 'datasource_type', ''));
      dispatch(change('FilterForm', 'parameter_type', ''));
      dispatch(change('FilterForm', 'forecast_lead_time', ''));
      handleCategoryData();
      handleSelectedDataSource([]);
      handleRegion(e.id);
    } else {
      setCountryFieldDisabled(true);
      formData.region = e.id;
      setFormData({ ...formData });
      handleRegion(e.id);
    }
  };
  const handleCategoryChange = (e) => {
    if (e.value === 'select') {
      handleCategoryData();
      formData.forecast_data_source_id = '';
      setFormData({ ...formData });
    } else {
      formData.forecast_data_source_id = e.id;
      setFormData({ ...formData });
      dataSources.map((i) => {
        if (i.id === e.id) {
          handleCategoryData(i.data);
        }
      });
    }
    formData.datasource_type = '';
    formData.parameter = '';
    formData.forecast_lead_time = '';
    setFormData({ ...formData });
    handleSelectedDataSource([], e.id);
    dispatch(change('FilterForm', 'datasource_type', ''));
    dispatch(change('FilterForm', 'parameter_type', ''));
    dispatch(change('FilterForm', 'forecast_lead_time', ''));
  };
  const ref = useRef();

  const resetImage = () => {
    if (ref?.current?.value) {
      ref.current.value = '';
    }
  };
  const handleDataSourceChange = (e) => {
    if (e.value === 'select') {
      handleSelectedDataSource([], e.id);
      formData.forecast_data_source_id = '';
      setFormData({ ...formData });
    } else {
      formData.forecast_data_source_id = e.id;
      setFormData({ ...formData });
      let parameter_list = [];
      dataSources?.map((i) => {
        i.data.map((d) => {
          if (d.forecast_data_source_id === e.id) {
            d.forecast_data_source.forecast_data_source_with_parameter.map((i) => {
              i.parameter.map((data) => {
                parameter_list.push(data);
              });
            });
            handleSelectedDataSource(parameter_list, e.id);
          }
        });
      });
    }
    dispatch(change('FilterForm', 'parameter_type', ''));
    dispatch(change('FilterForm', 'forecast_lead_time', ''));
    dispatch(change('FilterForm', 'forecast_year_month', ''));
    dispatch(change('FilterForm', 'start_date', ''));
    dispatch(change('FilterForm', 'end_date', ''));
    dispatch(change('FilterForm', 'Link', ''));
    dispatch(change('FilterForm', 'Description', ''));
    dispatch(change('FilterForm', 'Upload', ''));
    setFile(null);
    setIsImgaeUploaded(false);
    props.setFile(null);
  };
  const handleforecastYearMonth = (e) => {
    const val = e.target.value;
    if (!val) return false;

    if (parseInt(val.split('-')[0]) > new Date().getFullYear()) {
      dispatch(change('FilterForm', 'forecast_year_month', null));
      const ele = document.getElementsByName('forecast_year_month');
      if (ele[0]) ele[0].value = null;
      return false;
    }
    formData.forecast_lead_time = null;
    dispatch(change('FilterForm', 'forecast_lead_time', ''));
    formData.forecast_year_month = val;
    setFormData({ ...formData });
    forecastYearMonthHandler(val);
  };

  const handleForecastLeadTimeChange = (e) => {
    formData.forecast_lead_time = e;
    setFormData({ ...formData });
    forecastLeadTimeHandler(e);
  };
  const handleParameterChange = (e) => {
    //once parameter changes then we need to reset all the values that are filled on the basis of previously selected parameter

    formData.parameter = e.id;
    setFormData({ ...formData });
    handleParameter(e.id);
  };

  const handleStartDate = (e) => {
    formData.start_date = e.target.value;
    setFormData({ ...formData });
    startDateHandler(moment(e.target.value).format(DISPLAY_DATE_FORMAT));
  };

  const handleEndDate = (e) => {
    formData.end_date = e.target.value;
    setFormData({ ...formData });
    endDateHandler(moment(e.target.value).format(DISPLAY_DATE_FORMAT));
  };

  const handleLink = (e) => {
    if (e.target.value === '') {
      formData.source_link = '';
    } else {
      formData.source_link = e.target.value;
    }
    setFormData({ ...formData });
    linkHandler(e.target.value);
  };

  const handleDescription = (e) => {
    if (e.target.value === '') {
      formData.description = '';
    } else {
      formData.description = e.target.value;
    }
    setFormData({ ...formData });
    descriptionHandler(e.target.value);
  };

  const handleFileChange = (e) => {
    if (e.target.value) {
      fileHandler(URL.createObjectURL(e?.target?.files[0]), e);
      setFile(e?.target?.files[0]);
      setIsImgaeUploaded(true);
    }
  };

  const onFormSubmit = (e) => {
    if (isIRISelected) {
      if (!formData.country) {
        form.append('country', '');
      } else {
        form.append('country', formData.country);
      }
      form.append('forecast_time_year', formData?.forecast_year_month?.split('-')[0]);
      form.append('forecast_time_month', formData?.forecast_year_month?.split('-')[1]);
      form.append(
        'forecast_lead_time',
        parseInt(
          formData?.forecast_lead_time?.label?.substr(
            formData?.forecast_lead_time?.label?.indexOf('(') + 1,
            formData?.forecast_lead_time?.label?.indexOf(')') -
              formData?.forecast_lead_time?.label?.indexOf('(') -
              1,
          ),
        ),
      );
      form.append('parameter', formData?.parameter);
      form.append('upload_image', file);
      form.append('forecast_data_source', formData.forecast_data_source_id);
      if (!formData.source_link) {
        form.append('source_link', '');
      } else {
        form.append('source_link', formData.source_link);
      }
      if (!formData.description) {
        form.append('description', '');
      } else {
        form.append('description', formData.description);
      }
    } else {
      form.append('upload_image', file);
      if (!formData.country) {
        form.append('country', '');
      } else {
        form.append('country', formData.country);
      }
      form.append('forecast_data_source', formData.forecast_data_source_id);
      form.append('parameter', formData.parameter);
      form.append('start_date', formData.start_date);
      form.append('end_date', formData.end_date);
      if (!formData.source_link) {
        form.append('source_link', '');
      } else {
        form.append('source_link', formData.source_link);
      }
      if (!formData.description) {
        form.append('description', '');
      } else {
        form.append('description', formData.description);
      }
    }
    if (isPreventAddDispatch) {
      setStaticResourcesList([]);
      dispatch(staticResourceSuccess({}));
      dispatch(staticResourceAddSuccess({}));
      dispatch(staticResourceAddRequest(form));
    }
    setIsPreventAddDispatch(true);
  };

  const updateStaticResource = () => {
    if (isIRISelected) {
      form.append('forecast_time_year', formData?.forecast_year_month?.split('-')[0]);
      form.append('forecast_time_month', formData?.forecast_year_month?.split('-')[1]);
      if (formData?.forecast_lead_time) {
        form.append(
          'forecast_lead_time',
          formData?.forecast_lead_time?.id - parseInt(formData?.forecast_year_month?.split('-')[1]),
        );
      }

      if (formData?.parameter) {
        form.append('parameter', formData.parameter);
      }
      form.append('static_resource_id', formData.static_resource_id);
      if (formData?.forecast_data_source_id) {
        form.append('forecast_data_source', formData.forecast_data_source_id);
      } else {
        form.append('forecast_data_source', formData?.datasource_type?.id);
      }
      if (!formData.source_link) {
        form.append('source_link', '');
      } else {
        form.append('source_link', formData.source_link);
      }
      if (!formData.description) {
        form.append('description', '');
      } else {
        form.append('description', formData.description);
      }
    } else {
      if (file) {
        form.append('upload_image', file);
      }

      if (!formData.country) {
        form.append('country', '');
      } else {
        form.append('country', getCountryValue(formData.country));
      }
      if (formData.forecast_data_source_id) {
        form.append('forecast_data_source', formData.forecast_data_source_id);
      } else {
        form.append('forecast_data_source', formData?.datasource_type?.id);
      }
      if (formData?.parameter) {
        form.append('parameter', formData.parameter);
      } else {
        form.append('parameter', props?.initialValues?.parameter_type?.id);
      }
      form.append('start_date', formData.start_date);
      form.append('end_date', formData.end_date);
      form.append('static_resource_id', formData.static_resource_id);
      if (!formData.source_link) {
        form.append('source_link', '');
      } else {
        form.append('source_link', formData.source_link);
      }
      if (!formData.description) {
        form.append('description', '');
      } else {
        form.append('description', formData.description);
      }
    }
    if (isImgaeUploaded) {
      form.append('image', file);
    }
    setIsPreventAddDispatch(false);
    setStaticResourcesList([]);
    dispatch(staticResourceSuccess({}));
    dispatch(updateStaticResourceSuccess({}));
    dispatch(updateStaticResourceRequest(form));
  };

  const getCountryValue = (value) => {
    if (typeof value === 'string') {
      return value;
    }
    return !value?.id ? '' : value?.id;
    //typeof formData.country === "string" ? formData.country : (!formData.country?.id ? "" :formData.country?.id )
  };
  const getCurrentYearMonth = () => {
    let dateObj = new Date();
    let month =
      (dateObj.getMonth() + 1) % 10 !== dateObj.getMonth() + 1
        ? dateObj.getMonth() + 1
        : `0${dateObj.getMonth() + 1}`;
    return dateObj.getUTCFullYear() + '-' + month;
  };
  return (
    <>
      <Card className="card-style">
        <CardBody
          className={
            isSuperAdmin() || updatedPermissions?.includes('add_static_resource')
              ? ''
              : 'not-add-static-resource'
          }
        >
          <form
            onSubmit={handleSubmit((e) => onFormSubmit(e))}
            id={!editClicked ? 'publish_btn' : 'update_btn'}
          >
            <div className="">
              {!countryFieldDisabled ? (
                <Field
                  component={renderReactSelectInputField}
                  name="country"
                  placeholder="Select Country"
                  className="static-resource-filter-field my-3 filter-select-placeholder"
                  type="select"
                  onChange={(e) => handleCountryChange(e)}
                  required={true}
                  isMulti={false}
                  options={renderAPIParameterOptions(countryList)}
                ></Field>
              ) : (
                ''
              )}
              <Field
                component={renderReactSelectInputField}
                name="forecast_category"
                placeholder="Select Category"
                className="static-resource-filter-field my-3 filter-select-placeholder"
                type="select"
                onChange={(e) => handleCategoryChange(e)}
                required={true}
                isMulti={false}
                options={renderAPIParameterOptions(dataSources, 'forecast_category')}
                id="forecast_category"
              ></Field>
              <div className="filter-section-one">
                <Label className="static-resource-filter-field">
                  {t('STATIC_RESOURCE_ADMIN.DATA_SOURCES_PARAMETERS')}
                </Label>
                <Field
                  component={renderReactSelectInputField}
                  name="datasource_type"
                  placeholder="Select Data Source"
                  className="my-3 static-resource-filter-field filter-select-placeholder"
                  type="select"
                  onChange={(e) => handleDataSourceChange(e)}
                  required={true}
                  isMulti={false}
                  options={renderAPIParameterOptions(dataSourceList, 'datasource_type')}
                  id="datasource_type"
                ></Field>
                {!_.isEmpty(parameterList) && (
                  <Field
                    component={renderReactSelectInputField}
                    placeholder="Select Parameters"
                    name="parameter_type"
                    className="my-3 static-resource-filter-field filter-select-placeholder"
                    type="select"
                    onChange={(e) => handleParameterChange(e)}
                    required={true}
                    isMulti={false}
                    options={renderAPIParameterOptions(parameterList, 'parameter_type')}
                    id="parameter_type"
                  ></Field>
                )}
              </div>
              {isIRISelected ? (
                <>
                  <div className="filter-section-one static-resource-filter-field">
                    <Field
                      component={renderTextField}
                      name="forecast_year_month"
                      bsSize="sm"
                      className="mt-1 my-3 input-style filter-select-placeholder"
                      placeholder="Select Start date"
                      type="month"
                      max={getCurrentYearMonth()}
                      onChange={(e) => handleforecastYearMonth(e)}
                      isMulti={false}
                    ></Field>
                    <Field
                      component={renderReactSelectInputField}
                      name="forecast_lead_time"
                      placeholder="Select Forecast Lead Time"
                      className=" my-3 filter-select-placeholder"
                      type="select"
                      onChange={(e) => handleForecastLeadTimeChange(e)}
                      required={true}
                      isMulti={false}
                      options={renderAPIParameterOptions(
                        forecastLeadTimeList,
                        'forecast_lead_time',
                      )}
                      id="forecast_category"
                    ></Field>
                  </div>
                </>
              ) : (
                <div className="filter-section-one static-resource-filter-field">
                  <Label>{t('STATIC_RESOURCE_ADMIN.DATE_RANGE')}</Label>
                  <Field
                    component={renderTextField}
                    id="start_date"
                    name="start_date"
                    bsSize="sm"
                    className="mt-1 my-3 input-style filter-select-placeholder"
                    placeholder="Select Start date"
                    type="date"
                    onChange={(e) => handleStartDate(e)}
                    isMulti={false}
                  ></Field>
                  <Field
                    component={renderTextField}
                    id="end_date"
                    name="end_date"
                    bsSize="sm"
                    className="mt-1 my-3 input-style filter-select-placeholder"
                    placeholder="Select End date"
                    type="date"
                    onChange={(e) => handleEndDate(e)}
                    required={true}
                    isMulti={false}
                  ></Field>
                </div>
              )}
              <div className="filter-section-one static-resource-filter-field">
                <Label>{t('STATIC_RESOURCE_ADMIN.UPLOAD_CONTENT')}</Label>
                <Field
                  component={renderTextField}
                  name="Link"
                  className="mt-1 my-3 filter-select-placeholder"
                  type="textarea"
                  onChange={(e) => handleLink(e)}
                  required={false}
                  isMulti={false}
                  placeholder="Add a Link"
                ></Field>
                <Field
                  component={renderTextField}
                  name="Description"
                  className="mt-1 my-3 filter-select-placeholder"
                  type="textarea"
                  onChange={(e) => handleDescription(e)}
                  required={false}
                  isMulti={false}
                  placeholder="Add Description"
                ></Field>
                <Field
                  component={renderImageField}
                  id="Upload"
                  name="Upload"
                  type="file"
                  ref={ref}
                  onChange={(e) => handleFileChange(e)}
                  required={true}
                  isMulti={false}
                  className="filter-select-placeholder"
                  placeholder="Upload image"
                ></Field>
              </div>
              <div 
              className="publish-button"
              >
                {!editClicked ? (
                  <Button
                    type="submit"
                    disabled={pristine || submitting || invalid}
                    className="publish-btn"
                    loading={isStaticResourceAddRequesting}
                  >
                    {t('STATIC_RESOURCE_ADMIN.PUBLISH')}
                  </Button>
                ) : (
                  <Button
                    type="submit"
                    disabled={pristine || submitting}
                    className="publish-btn"
                    loading={isUpdateStaticResourceRequesting}
                    onClick={updateStaticResource}
                  >
                    {t('STATIC_RESOURCE_ADMIN.UPDATE')}
                  </Button>
                )}
              </div>
            </div>
          </form>
        </CardBody>
      </Card>
    </>
  );
};

Filter.propTypes = {
  regionList: PropTypes.array,
  handleRegion: PropTypes.func,
  countryList: PropTypes.array,
  handleCountry: PropTypes.func,
  dataSources: PropTypes.array,
  handleCategoryData: PropTypes.func,
  dataSourceList: PropTypes.array,
  handleSelectedDataSource: PropTypes.func,
  parameterList: PropTypes.array,
  handleParameter: PropTypes.func,
  startDateHandler: PropTypes.func,
  endDateHandler: PropTypes.func,
  linkHandler: PropTypes.func,
  fileHandler: PropTypes.func,
  publishButtonClicked: PropTypes.bool,
  descriptionHandler: PropTypes.func,
  handleSelectedTab: PropTypes.func,
  handleUpdatedListData: PropTypes.func,
  handleResetInitialValues: PropTypes.func,
};

export default reduxForm({
  form: 'FilterForm',
  enableReinitialize: true,
  validate,
})(Filter);
