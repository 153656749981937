import { USER_LIST_CONST, USER_BY_ID_CONST, USER_DELETE_CONST,USER_PERMISSIONS_CONST,UPDATE_PERMISSIONS_CONST,USER_PERMISSIONS_BY_ID_CONST,USER_DETAIL_CONST }  from './userListConst';

const UserList = (state = {}, action) => {
  switch (action.type) {
    case USER_LIST_CONST.USER_LIST_REQUEST:
      return {
        ...state,
        isUserListDataRequesting: true,
        userListData: false,
        userListDataError: false,
        userListDataNetworkError: false,
      };
    case USER_LIST_CONST.USER_LIST_SUCCESS:
      return {
        ...state,
        isUserListDataRequesting: false,
        userListData: action.payload.response,
        userListDataError: false,
        userListDataNetworkError: false,
      };
    case USER_LIST_CONST.USER_LIST_FAILED:
      return {
        ...state,
        isUserListDataRequesting: false,
        userListData: false,
        userListDataError: action.payload.error ? false : action.payload.response,
        userListDataNetworkError: action.payload.error ? action.payload.error : false,
      };

      case USER_BY_ID_CONST.USER_BY_ID_REQUEST:
        return {
          ...state,
          isUserByIdRequesting: true,
          userById: false,
          userByIdError: false,
          userByIdNetworkError: false,
        };
      case USER_BY_ID_CONST.USER_BY_ID_SUCCESS:
        return {
          ...state,
          isUserByIdRequesting: false,
          userById: action.payload.response,
          userByIdError: false,
          userByIdNetworkError: false,
        };
      case USER_BY_ID_CONST.USER_BY_ID_FAILED:
        return {
          ...state,
          isUserByIdRequesting: false,
          userById: false,
          userByIdError: action.payload.error ? false : action.payload.response,
          userByIdNetworkError: action.payload.error ? action.payload.error : false,
        };  

        case USER_DELETE_CONST.USER_DELETE_REQUEST:
          return {
            ...state,
            isDeleteUserRequesting: true,
            deleteUser: false,
            deleteUserError: false,
            deleteUserNetworkError: false,
          };
        case USER_DELETE_CONST.USER_DELETE_SUCCESS:
          return {
            ...state,
            isDeleteUserRequesting: false,
            deleteUser: action.payload.response,
            deleteUserError: false,
            deleteUserNetworkError: false,
          };
        case USER_DELETE_CONST.USER_DELETE_FAILED:
          return {
            ...state,
            isDeleteUserRequesting: false,
            deleteUser: false,
            deleteUserError: action.payload.error ? false : action.payload.response,
            deleteUserNetworkError: action.payload.error ? action.payload.error : false,
          }; 

          case USER_PERMISSIONS_CONST.USER_PERMISSION_REQUEST:
          return {
            ...state,
            isUserPermissionsRequesting: true,
            userPermissionsData: false,
            userPermissionsError: false,
            userPermissionsNetworkError: false,
          };
        case USER_PERMISSIONS_CONST.USER_PERMISSION_SUCCESS:
          return {
            ...state,
            isUserPermissionsRequesting: false,
            userPermissionsData: action.payload.response,
            userPermissionsError: false,
            userPermissionsNetworkError: false,
          };
        case USER_PERMISSIONS_CONST.USER_PERMISSION_FAILED:
          return {
            ...state,
            isUserPermissionsRequesting: false,
            userPermissionsData: false,
            userPermissionsError: action.payload.error ? false : action.payload.response,
            userPermissionsNetworkError: action.payload.error ? action.payload.error : false,
          }; 

          case UPDATE_PERMISSIONS_CONST.UPDATE_PERMISSION_REQUEST:
            return {
              ...state,
              isUpdatePermissionsRequesting: true,
              updatePermissionsData: false,
              updatePermissionsError: false,
              updatePermissionsNetworkError: false,
            };
          case UPDATE_PERMISSIONS_CONST.UPDATE_PERMISSION_SUCCESS:
            return {
              ...state,
              isUpdatePermissionsRequesting: false,
              updatePermissionsData: action.payload.response,
              updatePermissionsError: false,
              updatePermissionsNetworkError: false,
            };
          case UPDATE_PERMISSIONS_CONST.UPDATE_PERMISSION_FAILED:
            return {
              ...state,
              isUpdatePermissionsRequesting: false,
              updatePermissionsData: false,
              updatePermissionsError: action.payload.error ? false : action.payload.response,
              updatePermissionsNetworkError: action.payload.error ? action.payload.error : false,
            }; 




            case USER_PERMISSIONS_BY_ID_CONST.USER_PERMISSIONS_BY_ID_REQUEST:
              return {
                ...state,
                isUserPermissionsByIdDataRequesting: true,
                userPermissionsByIdData: false,
                userPermissionsByIdDataError: false,
                userPermissionsByIdDataNetworkError: false,
              };
            case USER_PERMISSIONS_BY_ID_CONST.USER_PERMISSIONS_BY_ID_SUCCESS:
              return {
                ...state,
                isUserPermissionsByIdDataRequesting: false,
                userPermissionsByIdData: action.payload.response,
                userPermissionsByIdDataError: false,
                userPermissionsByIdDataNetworkError: false,
              };
            case USER_PERMISSIONS_BY_ID_CONST.USER_PERMISSIONS_BY_ID_FAILED:
              return {
                ...state,
                isUserPermissionsByIdDataRequesting: false,
                userPermissionsByIdData: false,
                userPermissionsByIdDataError: action.payload.error ? false : action.payload.response,
                userPermissionsByIdDataNetworkError: action.payload.error ? action.payload.error : false,
              };
              case USER_DETAIL_CONST.USER_DETAIL_REQUEST:
                return {
                  ...state,
                  isUserDetailDataRequesting: true,
                  userDetailData: false,
                  userDetailDataError: false,
                  userDetailDataNetworkError: false,
                };
              case USER_DETAIL_CONST.USER_DETAIL_SUCCESS:
                return {
                  ...state,
                  isUserDetailDataRequesting: false,
                  userDetailData: action.payload.response,
                  userDetailDataError: false,
                  userDetailDataNetworkError: false,
                };
              case USER_DETAIL_CONST.USER_DETAIL_FAILED:
                return {
                  ...state,
                  isUserDetailDataRequesting: false,
                  userDetailData: false,
                  userDetailDataError: action.payload.error ? false : action.payload.response,
                  userDetailDataNetworkError: action.payload.error ? action.payload.error : false,
                };

    default:
      return { ...state };
  }
};

export default UserList;
