import React, { useEffect, useState } from 'react';
import { DROUGHT_MODULES_CONST } from '../../Components/constants';
import Droughtmodal from './Drought-modal';
import DroughtMap from './rightmap';

const DroughtMonitor = (props) => {
  const [showPopup, setShowPopup] = useState(false);
  const [popUpSelectedModule, setpopUpSelectedModule] = useState();
  const [modal, setModal] = useState(false);
  const handleModal= (value)=> {
    setModal(value)
  }
  useEffect(() => {
    let params = new URLSearchParams(window.location.search);
    let keyValue;
    keyValue = params.get('key');
    if(keyValue){
      let module = DROUGHT_MODULES_CONST.find(item=>item.api_slug === keyValue);
      if(module){
        if(keyValue === "drought-decision-support"){
          setShowPopup(false);
        }
        else{
          setShowPopup(true);
        }
        setpopUpSelectedModule(module);
      }
    }
    else{
      setShowPopup(true)
    }
  }, []);

  const moduleHandler = data => {
    setpopUpSelectedModule(data);
  };

  return (
    <>
      <DroughtMap popUpSelectedModule={popUpSelectedModule} modal={modal} />
      {showPopup && <Droughtmodal moduleHandler={moduleHandler} modal={modal} handleModal={handleModal}/>}
    </>
  );
};

export default DroughtMonitor;
