import { useSelector, useDispatch } from 'react-redux';
import HeaderImage from '../../assets/Images/user_guide_home_header.png';
import { getAllTopicsListRequest } from '../../redux/actions';
import React, { useState, useEffect } from 'react';
import { Row, Col, Card } from 'reactstrap';
import { htmlToText } from '../../Components/common/utils';
import '../../assets/CSS/StaticSidebar.css';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';
import { t } from 'react-multi-lang';
import Footer from '../landing-page/footer';
import InfiniteScroll from 'react-infinite-scroll-component';
import Loader from '../../Components/common/loader';
import {isLogedIn } from '../../Components/common/utils';

const UserGuide = (props) => {
  let history = useHistory();
  const dispatch = useDispatch();

  const [pageNumber, setPageNumber] = useState(1);
  const [pageCount, setPageCount] = useState();
  const [getAllTopicsList, setGetAllTopicsList] = useState([]);
  const [searchContentWordEntered, setSearchContentWordEntered] = useState('');
  const { UserGuideState } = useSelector((state) => ({
    UserGuideState: state.UserGuide,
  }));

  useEffect(() => {
    if ( !isLogedIn()) {
      let path = window.location.pathname
      history.push(`/login?prevPath=${path.substring(1,path.length)}`);
      }
    setGetAllTopicsList([]);
    window.scrollTo({ top: 0, behavior: 'instant' });
    dispatch(getAllTopicsListRequest({ page: 1 }));
    return () => {
      setGetAllTopicsList([]);
    };
  }, []);

  useEffect(() => {
    if (UserGuideState?.getAllTopicsListData?.data?.data?.result) {
      let new_list = [];
      const {
        data: { result },
      } = UserGuideState?.getAllTopicsListData?.data;
      if (result) {
        if (getAllTopicsList) {
          if (pageNumber > 1) {
            new_list = getAllTopicsList.concat(result.results);
          } else {
            new_list = result.results;
          }
        } else {
          new_list = result.results;
        }
        setGetAllTopicsList(new_list);
        setPageCount(result?.count);
      } else {
        setGetAllTopicsList([]);
      }
    }
  }, [UserGuideState.getAllTopicsListData]);

  const handleSearchContent = (e) => {
    const searchContent = e.target.value;
    setSearchContentWordEntered(searchContent);
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter' && event.target.value?.length !== 0) {
      history.push({
        pathname: '/user-guide-search',
        search: `?q=${searchContentWordEntered}`,
      });
    }
  };

  const handleClick = () => {
    if (searchContentWordEntered?.length !== 0) {
      history.push({
        pathname: '/user-guide-search',
        search: `?q=${searchContentWordEntered}`,
      });
    }
  };

  const fetchNextTemplateData = () => {
    setPageNumber(pageNumber + 1);
    dispatch(
      getAllTopicsListRequest({
        page: pageNumber + 1,
      }),
    );
  };


  const renderTools = () => {
    return (
      <>
        {getAllTopicsList?.length
          ? getAllTopicsList.map((item) => {
              return (
                <Col lg={3} sm={6} xs={12}>
                  <Card
                    className="custom-card-x cb-1 cursor-ponter card-size"
                    onClick={() => props.history.push(`/user-guide-details?topicId=${item.id}&topic_name=${item.name}`)}
                  >
                    <div>
                      <p className="icon-text">{item?.name}</p>

                      {htmlToText(item.description)?.length > 100 ? (
                        <div id="card-description">
                          <div className="news-feed-raw-data-section discription-text">
                            {htmlToText(item.description).substring(0, 100)}
                            <span>...</span>
                          </div>
                        </div>
                      ) : (
                        <div dangerouslySetInnerHTML={{ __html: item.description }} />
                      )}
                    </div>
                  </Card>
                </Col>
              );
            })
          : ''}
      </>
    );
  };

  return (
    <>
      <div className="user-guide-index-container">
        <img src={HeaderImage} className="index-image" />
        <div className="south-asia-heading">{t('USER_GUIDE.SOUTH_ASIA_TEXT')}</div>
      </div>

      <div className="user-guide-bottom-container">
        <div className="search-box common-sidebar common-search-bar search-size">
          <input
            className="inputField input-box"
            type="text"
            placeholder="Looking for something specific?"
            value={searchContentWordEntered}
            onChange={handleSearchContent}
            onKeyPress={handleKeyPress}
          />
          <i
            className="fa fa-search fa-lg searchIcons user-guide-search-icon"
            onClick={handleClick}
          ></i>
        </div>
      </div>
      <div className="loader-div">{UserGuideState.isGetAllTopicsListRequesting && <Loader />}</div>
      {_.isEmpty(getAllTopicsList) && !UserGuideState.isGetAllTopicsListRequesting && (
        <h2 className="user-guide-home-page-no-records-found">
          {t('USER_GUIDE.NO_RECORDS_FOUND')}
        </h2>
      )}
      <InfiniteScroll
        dataLength={pageNumber * 10 < pageCount ? pageNumber * 10 : ''}
        next={fetchNextTemplateData}
        hasMore={pageNumber * 10 < pageCount ? true : false}
        loader={
          UserGuideState.isGetAllTopicsListRequesting && (
            <center>
              <h4>{t('BULLET_IN.LOADING')} </h4>
            </center>
          )
        }
      >
        <div className="rendered-container cards-row">
          <Row>{renderTools()}</Row>
        </div>
      </InfiniteScroll>
      {/* hide this section in future */}
      <Footer publicFooter={false} />
    </>
  );
};

export default UserGuide;
