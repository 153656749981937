export const LOGIN_CONST = {
  LOGIN_REQUEST: 'LOGIN_REQUEST',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_FAILED: 'LOGIN_FAILED',
  LOGIN_CAROUSEL_REQUEST: 'LOGIN_CAROUSEL_REQUEST',
  LOGIN_CAROUSEL_SUCCESS: 'LOGIN_CAROUSEL_SUCCESS',
  LOGIN_CAROUSEL_FAILED: 'LOGIN_CAROUSEL_FAILED',
};

export const FORGOT_PASSWORD = {
  FORGOT_PASSWORD_REQUEST: 'FORGOT_PASSWORD_REQUEST',
  FORGOT_PASSWORD_SUCCESS: 'FORGOT_PASSWORD_SUCCESS',
  FORGOT_PASSWORD_FAILED: 'FORGOT_PASSWORD_FAILED'
}

export const CHANGE_PASSWORD = {
  CHANGE_PASSWORD_REQUEST: 'CHANGE_PASSWORD_REQUEST',
  CHANGE_PASSWORD_SUCCESS: 'CHANGE_PASSWORD_SUCCESS',
  CHANGE_PASSWORD_FAILED: 'CHANGE_PASSWORD_FAILED'
}

export const RESET_PASSWORD = {
  RESET_PASSWORD_REQUEST: 'RESET_PASSWORD_REQUEST',
  RESET_PASSWORD_SUCCESS: 'RESET_PASSWORD_SUCCESS',
  RESET_PASSWORD_FAILED: 'RESET_PASSWORD_FAILED'
}

export const RESET_LINK_VERIFICATION = {
  RESET_LINK_VERIFICATION_REQUEST: 'RESET_LINK_VERIFICATION_REQUEST',
  RESET_LINK_VERIFICATION_SUCCESS: 'RESET_LINK_VERIFICATION_SUCCESS',
  RESET_LINK_VERIFICATION_FAILED: 'RESET_LINK_VERIFICATION_FAILED'
}
