import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import { t } from 'react-multi-lang';
import Right_Arrow from '../../assets/Images/right-arrow.png';
import Facebook from '../../assets/Images/icons/bx_bxl-facebook-circle.svg';
import Twitter from '../../assets/Images/icons/akar-icons_twitter-fill.svg';
import Flickr from '../../assets/Images/icons/bx_bxl-flickr.svg';
import Youtube from '../../assets/Images/icons/bx_bxl-youtube.svg';
import RSSFeed from '../../assets/Images/icons/ic_twotone-rss-feed.svg';
import Mail from '../../assets/Images/icons/fluent_mail-16-filled.svg';
import LinkedIn from '../../assets/Images/icons/bx_bxl-linkedin.svg';
import IWMI_NEW from '../../assets/Images/iwmi_neww.svg';
import ZAMBIA from '../../assets/Images/ZAMBIA.png';
//import SADMS_NEW from '../../assets/Images/zadms-logo.png';
//import ICAR_NEW from '../../assets/Images/icar_neww.svg';
import ICAR_NEW from '../../assets/Images/cgairClimateResilience.png';
import AICCRA from '../../assets/Images/AICCRA_SVG.svg';
import PUBLIC_CLIMBER_NEW_LOGO from '../../assets/Images/cgairClimateResilience.png';
import PUBLIC_IWMI_NEW_LOGO from '../../assets/Images/publicIWMILogo.svg';
import PUBLIC_AICCRA_LOGO from '../../assets/Images/AICCRA.png';
//import PUBLIC_ICAR_NEW_LOGO from '../../assets/Images/publicICARLogo.svg';
//import PUBLIC_ZMA_NEW_LOGO from '../../assets/Images/ZMA.png';
import myConstClass from '../../Constants';

const RenderSocialMediaProfiles = () => {
  return (
    <>
      <div className="follow">
        <p className="follow-text">{t('FOOTER.FOLLOW')}</p>
        <div className="follow-icon-wrapper d-flex">
          <ul className="list-unstyled icon-ul footer-logos">
            <li className="icon-circle">
              <a href={myConstClass.IWMI_FB_URL} target="_blank" rel="noreferrer">
                <img src={Facebook} className="f-img-icon img-fluid" alt="FACEBOOK" />
              </a>
            </li>
            <li className="icon-circle">
              <a href={myConstClass.IWMI_FLICKR_URL} target="_blank" rel="noreferrer">
                <img src={Flickr} className="f-img-icon img-fluid" alt="FLICKR" />
              </a>
            </li>
            <li className="icon-circle">
              <a href={myConstClass.IWMI_LINKEDIN_URL} target="_blank" rel="noreferrer">
                <img src={LinkedIn} className="f-img-icon img-fluid" alt="LINKEDIN" />
              </a>
            </li>
            <li className="icon-circle">
              <a href={`mailto:${myConstClass.IWMI_EMAIL}?subject="subject text"`}>
                <img src={Mail} className="f-img-icon img-fluid" alt="MAIL" />
              </a>
            </li>
            <li className="icon-circle">
              <a href={myConstClass.IWMI_RSS_FEEDS} target="_blank" rel="noreferrer">
                <img src={RSSFeed} className="f-img-icon img-fluid" alt="RSSFEED" />
              </a>
            </li>
            <li className="icon-circle">
              <a href={myConstClass.IWMI_TWITTER_LINK} target="_blank" rel="noreferrer">
                <img src={Twitter} className="f-img-icon img-fluid" alt="TWITTER" />
              </a>
            </li>
            <li className="icon-circle">
              <a href={myConstClass.IWMI_YOUTUBE_LINK} target="_blank" rel="noreferrer">
                <img src={Youtube} className="f-img-icon img-fluid" alt="YOUTUBE" />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

const RenderContactus = () => {
  return (
    <div className="margin-contact">
      <a href="/terms-conditions" target="_blank" className="footer-terms-style">
        <p className="follow-text">{t('FOOTER.TERMS_CONDITIONS')}</p>
      </a>
    </div>
  );
};

const RenderCopyRightText = () => {
  return (
    <>
      <p className="copyright-text copy-text-left">
        <span className="copy-icon">&copy;</span> {t('FOOTER.COPY_RIGHT_TEXT')}
      </p>
    </>
  );
};

const CommonRenderCopyRightText = () => {
  return (
    <>
      <p className="common-copyright-text common-copy-text-left">
        <span className="copy-icon">&copy;</span> {t('FOOTER.COPY_RIGHT_TEXT')}
      </p>
    </>
  );
};

const PublicFooter = () => {
  return (
    <footer className="footer-wrapper">
      <span className="Footer-icon-container">
        <Container>
          <Row className="item-alignment pt-3">
          <Col lg={3} md={4} sm={4} xs={4} style={{ textAlign: 'center' }}>
              <a href={myConstClass.ZAMBIA_URL} target="_blank" rel="noreferrer">
                <img src={ZAMBIA} className="img-fluid " alt="SADMS" />{' '}
              </a>
            </Col>
            <Col lg={3} md={4} sm={4} xs={4} style={{ textAlign: 'center' }}>
              <a href={myConstClass.CGIAR_RESOURCES_URL} target="_blank" rel="noreferrer">
                <img src={ICAR_NEW} className="img-fluid " alt="CGAIR_WLE" />{' '}
              </a>
            </Col>
            <Col lg={3} md={4} sm={4} xs={4} style={{ textAlign: 'center' }}>
              <a href={myConstClass.AICCRA_URL} target="_blank" rel="noreferrer"> 
                <img src={AICCRA} className="img-fluid new-img-fluid" alt="AICCRA" />{' '}
              </a>
            </Col>
            <Col lg={3} md={4} sm={4} xs={4} style={{ textAlign: 'center' }}>
              <a href={myConstClass.IWMI_URL} target="_blank" rel="noreferrer">
                <img src={IWMI_NEW} className="img-fluid" alt="IWMI" />{' '}
              </a>
            </Col>
            {/* <Col lg={3} md={3} sm={3} xs={3} style={{ textAlign: 'center' }}>
              <a href={myConstClass.ICAR_URL} target="_blank" rel="noreferrer">
                <img src={ICAR_NEW} className="img-fluid " alt="CGAIR_WLE" />{' '}
              </a>
            </Col> */}

           
          </Row>
        </Container>
      </span>
      <span />
      <hr className="hr-color" />
      <span className="footer-social-meida-profils">
        <Container>
          <Row>
            <Col className="fl-content" md={6} xs={21} lg={8}>
              <div className="subscribe">
                <p className="subscribe-text">{t('FOOTER.SUBSCRIBE_NEWS')}</p>
                <form
                  action={`mailto:${myConstClass.IWMI_EMAIL}`}
                  method="post"
                  encType="text/plain"
                >
                  <div className="input-group c-width">
                    <input
                      type="text"
                      className="form-control footer-input"
                      placeholder="Email Address"
                    />
                    <span className="input-group-btn pr">
                      <button className="btn btn-right">
                        <img className="" src={Right_Arrow} alt="right arrow" />
                      </button>
                    </span>
                  </div>
                </form>
              </div>
            </Col>
            <Col className="fr-content ml-auto " lg={4} sm={6} xs={12}>
              <div className="home-footer-right">
                <RenderSocialMediaProfiles />
                <RenderContactus />
              </div>
            </Col>
          </Row>
          <RenderCopyRightText />
        </Container>
      </span>
    </footer>
  );
};

const AfterLoginFooter = () => {
  return (
    <footer className="common-footer-wrapper">
      <Container>
        <Row className="item-alignment pt-4">
          <Col className="" md={4} xs={12} lg={6}>
            <ul className="list-unstyled d-flex footer-logos">
            <li>
                <a href={myConstClass.SADMS_URL} target="_blank" rel="noreferrer">
                  <img src={ZAMBIA} className="footer-sadms-icon" alt="iwmi" />{' '}
                </a>
              </li>
              {/* <li>
                <a href={myConstClass.SADMS_URL} target="_blank" rel="noreferrer">
                  <img src={PUBLIC_SADMS_NEW_LOGO} className="footer-sadms-icon" alt="iwmi" />{' '}
                </a>
              </li> */}
              <li>
                <a href={myConstClass.CGIAR_RESOURCES_URL} target="_blank" rel="noreferrer">
                  <img src={PUBLIC_CLIMBER_NEW_LOGO} className="footer-cgiar-icon" alt="cgair_wle" />{' '}
                </a>
              </li>
              <li>
                <a href={myConstClass.ICAR_URL} target="_blank" rel="noreferrer">
                  <img src={PUBLIC_AICCRA_LOGO} className="footer-aiccra-icon" alt="aiccra" />{' '}
                </a>
              </li>
              <li>
                <a href={myConstClass.IWMI_URL} target="_blank" rel="noreferrer">
                  <img src={PUBLIC_IWMI_NEW_LOGO} className="footer-iwmi-icon" alt="sadms" />{' '}
                </a>
              </li>
            </ul>
          </Col>
          <Col lg={6}>
            <div className="common-home-footer-right">
              <RenderSocialMediaProfiles />
            </div>
          </Col>
        </Row>
        <div>
          <CommonRenderCopyRightText />
        </div>
      </Container>
    </footer>
  );
};

const Footer = ({ publicFooter }) => {
  return <>{publicFooter ? <PublicFooter /> : <AfterLoginFooter />}</>;
};

export default Footer;
