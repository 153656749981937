 const HOME_CONST = {
     GET_BANNERS_REQUEST: 'GET_BANNERS_REQUEST',
     GET_BANNERS_SUCCESS: 'GET_BANNERS_SUCCESS',
     GET_BANNERS_FAILED: 'GET_BANNERS_FAILED',

    GET_PARAMETERS_REQUEST: 'GET_PARAMETERS_REQUEST',
    GET_PARAMETERS_SUCCESS: 'GET_PARAMETERS_SUCCESS',
    GET_PARAMETERS_FAILED: 'GET_PARAMETERS_FAILED',
 }

 export default HOME_CONST;