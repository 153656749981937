import AboutUsImage2 from '../../assets/Images/AboutUs2.jpeg';
import AboutUsImage4 from '../../assets/Images/aboutUsBackImage.png';
import TopBannerImage from '../../assets/Images/AboutUs1.jpeg';
import BottomBannerImage from '../../assets/Images/AboutUs3.jpeg';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';
import { t } from 'react-multi-lang';
import { Row, Col } from 'reactstrap';
import '../../assets/CSS/StaticSidebar.css';
import Footer from './footer';
import { getToken } from '../../Components/common/utils';

const AboutUs = props => {
  let history = useHistory();
  window.scrollTo({ top: 0, behavior: 'auto' });
  return (
    <>
      <div className="user-guide-index-container about-us-position">
        <img src={TopBannerImage} className="top-index-image" />
      </div>
      <div className="about-us-container">
        <div className="about-us-content" sm={12} lg={12}>
          {/* <p className="about-us-main-content">{t('ABOUT_US.ABOUT_US_CONTENT')}</p> */}
        </div>
        <Row>
          <Col sm={12} lg={6}>
            <div>
              <p className="about-us-main-content">{t('ABOUT_US.ABOUT_US_CONTENT_ONE')}</p>
              <p className="about-us-main-content">{t('ABOUT_US.ABOUT_US_CONTENT_TWO')}</p>
            </div>
          </Col>
          <Col sm={12} lg={6}>
            <div className="image-container">
              <img className="back-image" src={AboutUsImage4} />
              <img className="front-image" src={AboutUsImage2} />
            </div>
          </Col>
        </Row>
      </div>
      <div className="user-guide-index-container">
        <img src={BottomBannerImage} className="index-image" />
      </div>
      {/* hide this section in future */}
      <div className="pre-footer mt-3">
        <div className="pre-footer-content">
          <p className="contact-text">{t('HOME_PAGE.KEEP_IN_TOUCH')}</p>
          <span>
            {t('HOME_PAGE.REGISTER')}
            {!getToken() ? (
              <button className="pre-footer-register-btn" onClick={() => history.push('/register')}>
                {t('HOME_PAGE.REGISTER_NOW')}
              </button>
            ) : (
              ''
            )}
          </span>
        </div>
      </div>
      {/* Footer here */}
      <Footer publicFooter={true} />
    </>
  );
};

export default AboutUs;
