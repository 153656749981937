import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { t } from 'react-multi-lang';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { default as MultiDropdown } from 'react-multilevel-dropdown';

import {
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavLink,
  NavItem,
  NavbarText,
  UncontrolledDropdown,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import ZADMS from '../../assets/Images/zadms-logo.png';
import IWMI from '../../assets/Images/ZAMBIA.png'; // "C:\Users\THALLA\OneDrive\Desktop\work\IDMT\web-app\src\assets\Images\iwmi.png"
import RightArrowIcon from '../../assets/Images/right_arrow_icon.svg';
import { menuBarMoreOptions, STATIC_RESOURCE_GET_CATEGORY_LIST } from '../constants/index';
import ProfileImage from '../../assets/Images/profile-placeholder.png';
import Routes from '../../routes/routes';
import { isSuperAdmin, handleLogoutRedirect, isStaffUser } from '../../Components/common/utils';
import Cookies from 'js-cookie';
import Closeicon from '../../assets/Images/close.png';
import DataPopUp from "../../Components/common/DataModal";

const Header = props => {
  const nextProps = useSelector(state => ({
    userDetailData: state.userList.userDetailData || null,
  }));

  useEffect(() => {
    if (nextProps.userDetailData) {
      const {
        data: { result },
      } = nextProps.userDetailData;
    }
  }, [nextProps.userDetailData]);

  const routeOptions = [
    {
      id: 1,
      headerName: 'Zambia Drought Management System',
      routeName: 'home',
    },
    {
      id: 2,
      headerName: 'Weather Forecast',
      routeName: 'weather-forecast',
    },
    {
      id: 3,
      headerName: 'Public API',
      routeName: 'public-api',
    },
    {
      id: 4,
      headerName: 'Drought Management Tool',
      routeName: 'drought-monitor',
    },
    // {
    //   id: 5,
    //   headerName: 'Contingency Plan',
    //   routeName: 'contingency-plan',
    // },
    {
      id: 6,
      headerName: 'Online Bulletin',
      routeName: 'bulletin',
    },
    {
      id: 7,
      headerName: 'About Us',
      routeName: 'about-us',
    },
  ];

  const history = useHistory();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [headerSubtitle, setHeaderSubtitle] = useState('');
  const [selectedDataSourceId, setSelectedDataSourceId] = useState();
  const [subHeadingList, setSubHeadingList] = useState();
  const [isDefaultSelectedSourceId, setIsDefaultSelectedSourceId] = useState(false);
  const toggle = () => setDropdownOpen(prevState => !prevState);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [dataModal, setDataModal]= useState(false);

  
  const handleToggle = () => {
    setSidebarOpen(!sidebarOpen);
  };
  
  const togglePopUp = () => setDataModal(!dataModal);

  let url = window.location.href;
  useEffect(() => {
    let title;
    if (url) {
      routeOptions.map((item) => {
        if (url.includes(item.routeName) && !url.includes('login')) {
          title = item.headerName;
        }
      });
    } else {
      title = t('NAVBAR.HEADER.TITLE');
    }
    setHeaderSubtitle(title);
  }, [url]);

  useEffect(() => {
    if (Routes) {
      let a = [];
      Routes.map(i => {
        if (i.visible) {
          a.push(i);
        }
      });
      setSubHeadingList(a);
    }
  }, [Routes]);

  const handleLogout = () => {
    handleLogoutRedirect();
    history.push('login');
  };

  const checkPages = () => {
    return (
      window.location.href.indexOf('home') === -1 &&
      window.location.href.indexOf('register') === -1 &&
      window.location.href.indexOf('login') === -1 &&
      window.location.href.indexOf('forgot-password') === -1 &&
      window.location.href.indexOf('reset-password') === -1 &&
      window.location.href.indexOf('change-password') === -1 &&
      window.location.href.indexOf('ManageCrops') === -1
    );
  };

  useEffect(() => {
    if (selectedDataSourceId) {
      history.push(
        `/weather-forecast?dataSourceId=${selectedDataSourceId}&isDefaultSelectedSourceId=${isDefaultSelectedSourceId}`,
      );
    }
  }, [selectedDataSourceId]);

  const handleSubCategory = (value, type, e) => {
    if (value === selectedDataSourceId) {
      history.push(
        `/weather-forecast?dataSourceId=${selectedDataSourceId}&isDefaultSelectedSourceId=${isDefaultSelectedSourceId}`,
      );
      setIsDefaultSelectedSourceId(true);
    } else {
      setIsDefaultSelectedSourceId(true);
      setSelectedDataSourceId(value);
    }
  };

  const handleChangePassword = () => {
    history.push('/change-password');
  };

  const handleMenuClick = (data, e) => {
    if (data.name === 'Public API') {
      if (isSuperAdmin()) {
        history.push('/public-api');
      } else {
        history.push(data.path);
      }
    } else {
      history.push(data.path);
    }
  };

  const handleHeaderButtons = () => {
    if (
      window.location.href.includes('login') ||
      window.location.href.includes('forgot-password') ||
      window.location.href.includes('reset-password')
    ) {
      return (
        <NavLink>
          <button className="btn btn-white mvb-button" onClick={() => history.push('/register')}>
            {t('HEADER.SIGN_UP')}
          </button>
        </NavLink>
      );
    } else {
      return (
        <NavLink>
          <button className="btn btn-white mvb-button" onClick={() => history.push('/login')}>
            {t('HEADER.SIGN_IN')}
          </button>
        </NavLink>
      );
    }
  };

  const redirectToDashBoard = () => {
    history.push('/settingsDashboard');
  };

  return (
    <>
     <DataPopUp  isOpen={dataModal} toggle={togglePopUp}/>
      <div className="header-div">
        <Navbar color="light" light expand="md" className="navbar-header nav-main">
          <NavbarBrand href="https://www.agriculture.gov.zm/" target="_blank" rel="noreferrer">
              <img className="sadams-iwmi-logo ml-auto" src={IWMI} alt="IWMI LOGO" />
          </NavbarBrand>
          <div className="vl"></div>
          <NavbarBrand onClick={() => history.push('/home')}>
            <img className="iwmi-logo ml-auto" src={ZADMS} alt="IWMI LOGO" />
            <span className="iwmi-title">
              {headerSubtitle ? headerSubtitle : t('NAVBAR.HEADER.TITLE')}
            </span>
          </NavbarBrand>
          {/* <NavbarToggler onClick={handleToggle} className="navtoogle-1" /> */}
          {window.location.href.indexOf('home') === -1 ? (
            <NavbarToggler onClick={handleToggle} className="navtoogle-1" />
          ):''}
          <Nav className="nav-header-right" navbar>
            {!Cookies.get('user') ? (
              <>
                <NavItem>{handleHeaderButtons()}</NavItem>
                <span className="element"
                >
                  {' '}
                  Development Version{' '}
                </span>
              </>
            ) : (
              <>
                <NavItem className="profile-item">
                  <span className="profile">
                    <Dropdown isOpen={dropdownOpen} toggle={toggle} className="profile-dropdown">
                        <DropdownToggle>
                          <NavLink className="profile-image">
                            <img className="profile-image" src={ProfileImage} alt={'not-available'} />
                          </NavLink>
                          {t('HEADER.ACCOUNT')}
                        </DropdownToggle>
                      <DropdownMenu className="profile-dropdown-menu">
                        {(isStaffUser()) && (
                          <DropdownItem
                            className="profile-dropdown-item"
                            onClick={redirectToDashBoard}
                          >
                            Settings
                          </DropdownItem>
                        )}
                        <DropdownItem
                          className="profile-dropdown-item"
                          onClick={handleChangePassword}
                        >
                          Change Password
                        </DropdownItem>
                        <DropdownItem className="profile-dropdown-item" onClick={handleLogout}>
                          {t('HEADER.LOGOUT')}
                        </DropdownItem>
                      </DropdownMenu>
                    </Dropdown>
                  </span>
                </NavItem>
              </>
            )}
          </Nav>
        </Navbar>
      </div>
      <div className="headernav-div">
        {checkPages() && (
          <Navbar className="public-api-navbar p-0 nav2" expand="md">
            <Nav className="mx-auto mobi-scroll" navbar>
              <ul className="nav-menu-items mobi-scroll">
                  <li
                    key={0}
                    className={
                      window.location.href.includes(subHeadingList?.[0].path)
                        ? 'nav-text active'
                        : ''
                    }
                  >
                    <a 
                      className={
                        window.location.href.includes(subHeadingList?.[0].path) ? 'active-link' : ''
                      }
                      onClick={e => handleMenuClick(subHeadingList?.[0], e)} 
                    >
                      <div className="api-block">
                      <span className="icon-color">{subHeadingList?.[0].icon}</span>
                      {subHeadingList?.[0].name}
                      </div>
                    </a>
                  </li>
                  <li
                    key={1}
                    className={
                      window.location.href.includes(subHeadingList?.[1].path)
                        ? 'nav-text active'
                        : ''
                    }
                  >
                    <div className="api-block">
                    <MultiDropdown
                      title={subHeadingList?.[1].name}
                      className="weather-forecast-dropdown-icon"
                    >
                      {STATIC_RESOURCE_GET_CATEGORY_LIST?.map((i,index) => (
                        <>
                          <MultiDropdown.Item key={index} className="category-menu-item">
                            {i.category}
                            <span>
                              <img src={RightArrowIcon} alt={'not-available'} />
                            </span>
                            <MultiDropdown.Submenu position="right" className="weather-menusub-ul">
                              {i.data &&
                                i.data.map((submenu) => (
                                  <>
                                    <MultiDropdown.Item
                                      className="weather-menu-sub"
                                      key={submenu.forecast_data_source.id}
                                      onClick={() =>
                                        handleSubCategory(submenu.forecast_data_source.id)
                                      }
                                    >
                                      {submenu.forecast_data_source.name}
                                    </MultiDropdown.Item>
                                  </>
                                ))}
                            </MultiDropdown.Submenu>
                          </MultiDropdown.Item>
                        </>
                      ))}
                    </MultiDropdown>
                    </div>
                  </li>
                  <li
                    key={3}
                    className={
                      window.location.href.includes(subHeadingList?.[3].path)
                        ? 'nav-text active'
                        : ''
                    }
                  >
                    <a
                      className={
                        window.location.href.includes(subHeadingList?.[3].path) ? 'active-link' : ''
                      }
                      onClick={e => handleMenuClick(subHeadingList?.[3], e)}
                    >
                    <div className="api-block">
                      <span className="icon-color">{subHeadingList?.[3].icon}</span>
                      {/* &nbsp; &nbsp; */}
                      {subHeadingList?.[3].name}
                    </div>
                    </a>
                  </li>
                  {/* <li
                    key={4}
                    className={
                      window.location.href.includes(subHeadingList?.[4].path)
                        ? 'nav-text active'
                        : ''
                    } 
                  >
                    <a
                      className={
                        window.location.href.includes(subHeadingList?.[4].path) ? 'active-link' : ''
                      }
                      onClick={e => handleMenuClick(subHeadingList?.[4], e)}
                    >
                      <div className="api-block">
                      <span className="icon-color">{subHeadingList?.[4].icon}</span>
                       &nbsp; &nbsp;
                      {subHeadingList?.[4].name}
                      </div>
                    </a>
                  </li> */}

                  <li
                    key={2}
                    className={
                      window.location.href.includes(subHeadingList?.[2].path)
                        ? 'nav-text active'
                        : ''
                    }
                  >
                    <a
                      className={
                        window.location.href.includes(subHeadingList?.[2].path) ? 'active-link' : ''
                      }
                      onClick={e => handleMenuClick(subHeadingList?.[2], e)} 
                    >
                      <div className="api-block">
                      <span className="icon-color">{subHeadingList?.[2].icon}</span>
                      {subHeadingList?.[2].name}
                      </div>
                    </a>
                  </li>
              </ul>
              <UncontrolledDropdown className="link-text menubar-dropdown" nav inNavbar>
                <DropdownToggle nav caret className="dropdown-more">
                  {t('MENU_BAR.MORE')}
                </DropdownToggle>
                <DropdownMenu left="true" className="dropdown-more-menu">
                  <DropdownItem className="menu-drop-title" header>
                    {t('MENU_BAR.TOOLS')}
                    {/* <article className="menu-tool-desc">{t('MENU_BAR.TOOLS_DESC')}</article> */}
                  </DropdownItem>
                  <DropdownItem divider />
                  {menuBarMoreOptions.map((item, index) => {
                    // if(item.name==="Disclaimer"){
                    //   return(
                    //     <DropdownItem className="menu-drop-item" key={index} onClick={() => setDataModal(true)}>
                    //     {item.name}
                    //   </DropdownItem>
                    //   )
                    // }else{
                    return (
                      <DropdownItem className="menu-drop-item" href={item?.path}>
                        {item.name}
                      </DropdownItem>
                    );
                  //}
                })}
                </DropdownMenu>
              </UncontrolledDropdown>
              <li className="disclaimer-text" onClick={() => setDataModal(true)}>
                <i class='cursor-ponter fa fa-exclamation-triangle info-icon-edit-btn1'></i>
                {t('Disclaimer')}
                </li>
            </Nav>
          </Navbar>
        )}
      </div>
      {/* sidebar */}
      {sidebarOpen && (
        <div className="div-navbar">
          {checkPages() && (
            <Navbar className="nav-header-mvb">
              <img src={Closeicon} className="close-icon-nav" onClick={handleToggle} />
              <Nav className="mx-auto mobi-scroll mt-3" navbar>
                <ul className="nav-menu-items mobi-scroll">
                  <>
                    <li
                      key={0}
                      className={
                        window.location.href.includes(subHeadingList?.[0].path)
                          ? 'nav-text active navbar-li-mvb'
                          : 'navbar-li-mvb'
                      }
                      // className="navbar-li-mvb"
                    >
                      <a
                        className={
                          window.location.href.includes(subHeadingList?.[0].path)
                            ? 'active-link'
                            : ''
                        }
                        onClick={e => handleMenuClick(subHeadingList?.[0], e)}
                      >
                        <span className="icon-color">{subHeadingList?.[0].icon}</span>
                        {subHeadingList?.[0].name}
                      </a>
                    </li>
                    <li
                      key={1}
                      className={
                        window.location.href.includes(subHeadingList?.[1].path)
                          ? 'nav-text active navbar-li-mvb'
                          : 'navbar-li-mvb'
                      }
                      // className="navbar-li-mvb"
                    >
                      <MultiDropdown
                        title={subHeadingList?.[1].name}
                        className="weather-forecast-dropdown-icon"
                      >
                        {STATIC_RESOURCE_GET_CATEGORY_LIST.map((i,index) => (
                          <>
                            <MultiDropdown.Item key={index} className="category-menu-item">
                              {i.category}
                              <span>
                                <img src={RightArrowIcon} />
                              </span>
                              <MultiDropdown.Submenu
                                position="right"
                                className="weather-menusub-ul"
                              >
                                {i.data &&
                                  i.data.map(submenu => (
                                    <>
                                      <MultiDropdown.Item
                                        className="weather-menu-sub"
                                        key={submenu.forecast_data_source.id}
                                        onClick={() =>
                                          handleSubCategory(submenu.forecast_data_source.id)
                                        }
                                      >
                                        {submenu.forecast_data_source.name}
                                      </MultiDropdown.Item>
                                    </>
                                  ))}
                              </MultiDropdown.Submenu>
                            </MultiDropdown.Item>
                          </>
                        ))}
                      </MultiDropdown>
                    </li>
                    <li
                      key={2}
                      className={
                        window.location.href.includes(subHeadingList?.[2].path)
                          ? 'nav-text active navbar-li-mvb'
                          : 'navbar-li-mvb'
                      }
                      // className="navbar-li-mvb"
                    >
                      <a
                        className={
                          window.location.href.includes(subHeadingList?.[2].path)
                            ? 'active-link'
                            : ''
                        }
                        onClick={e => handleMenuClick(subHeadingList?.[2], e)}
                      >
                        <span className="icon-color">{subHeadingList?.[2].icon}</span>
                        {subHeadingList?.[2].name}
                      </a>
                    </li>
                    <li
                      key={3}
                      className={
                        window.location.href.includes(subHeadingList?.[3].path)
                          ? 'nav-text active navbar-li-mvb'
                          : 'navbar-li-mvb'
                      }
                      // className="navbar-li-mvb"
                    >
                      <a
                        className={
                          window.location.href.includes(subHeadingList?.[3].path)
                            ? 'active-link'
                            : ''
                        }
                        onClick={e => handleMenuClick(subHeadingList?.[3], e)}
                      >
                        <span className="icon-color">{subHeadingList?.[3].icon}</span>
                        {subHeadingList?.[3].name}
                      </a>
                    </li>
                  </>
                </ul>
                <UncontrolledDropdown
                  className="link-text menubar-dropdown navbar-li-mvb"
                  nav
                  inNavbar
                >
                  <DropdownToggle nav caret className="dropdown-more">
                    {t('MENU_BAR.MORE')}
                  </DropdownToggle>
                  <DropdownMenu left="true" className="dropdown-more-menu">
                    <DropdownItem className="menu-drop-title" header>
                      {t('MENU_BAR.TOOLS')}
                      <article className="menu-tool-desc">{t('MENU_BAR.TOOLS_DESC')}</article>
                    </DropdownItem>
                    <DropdownItem divider />
                    {menuBarMoreOptions.map((item, index) => {
                      return <DropdownItem key={index} className="menu-drop-item">{item.name}</DropdownItem>;
                    })}
                  </DropdownMenu>
                </UncontrolledDropdown>
              </Nav>
            </Navbar>
          )}
        </div>
      )}
      <div
        style={
          window.location.href.indexOf('home') !== -1
            ? { paddingTop: '5%' }
            : { paddingTop: 'unset' }
        }
      />
    </>
  );
};

export default Header;
Header.propTypes = {
  showProfile: PropTypes.bool,
};
Navbar.propTypes = {
  light: PropTypes.bool,
  dark: PropTypes.bool,
  fixed: PropTypes.string,
  color: PropTypes.string,
  role: PropTypes.string,
  expand: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  tag: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
};

NavbarBrand.propTypes = {
  tag: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
};

NavbarText.propTypes = {
  tag: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
};
