import { useEffect, memo } from 'react';
import L from 'leaflet';
import _ from 'lodash';
import { DRY_SPELL, LAND_USE_LAND_COVER } from '../../../../Components/constants/index';

function Legend({ map, add, layer, position }) {
  const id = layer?.id + position;
  useEffect(() => {
    if (map) {
      const legend = L?.control({ position: position });
      if (add) {
        legend.onAdd = () => {
          let legendDiv = L.DomUtil.create('div');
          legendDiv.id = id || 'legend_div';
          let containerDiv = L.DomUtil.create('div');
          if (position === 'bottomleft') {
            containerDiv.className =
              'legend-container-div  discrete-legend info-discrete left-discrete-legend-style discrete-margin-right-bottom-left ';
          } else {
            containerDiv.className = 'legend-container-div  discrete-legend info-discrete ';
          }
          let labels = [];
          let grades = layer?.palette;
          let names = '';

          for (let i = 0; i < grades?.length; i++) {
            if (!Array.isArray(grades[i])) {
              names = grades[i].name;
              labels.push('<i style="background:' + grades[i].color + '"></i> ' + names);
            }
          }
          if (layer?.parameter === DRY_SPELL.DRYSPELL || layer?.parameter === DRY_SPELL.DRY_SPELL) {
            containerDiv.innerHTML =
              '<b>' + layer?.parameter + ' ' + '(Days)' + '</b><br><br>' + labels.join('<br>');
          } else {
            if (layer.units) {
              containerDiv.innerHTML =
                '<b>' +
                (layer?.fromMapPreferences ? layer?.dataSource : layer.parameter) +
                ' ' +
                '(' +
                layer.units +
                ')' +
                '</b><br><br>' +
                labels.join('<br>');
            } else {
              if (layer.dataSource === DRY_SPELL.FINAL_DROUGHT_MAP) {
                containerDiv.innerHTML =
                  '<b>' + layer.dataSource + '</b><br>' + labels.join('<br>');
              } else {
                /**
                 * added by sai krishna thalla
                 * reason: lulc legends names are not displaying their respective label names
                 * date: 19-sep-2022
                 */
                containerDiv.innerHTML =
                  '<b>' +
                  (layer?.fromMapPreferences
                    ? layer?.dataSource == LAND_USE_LAND_COVER
                      ? layer.id
                      : layer?.dataSource
                    : layer.parameter) +
                  '</b><br><br>' +
                  labels.join('<br>');
              }
            }
          }
          //
          let containerOfContainerDiv = L.DomUtil.create('div');
          containerOfContainerDiv.className = 'container-of-container-div';
          let datesContainer = L.DomUtil.create('div');
          datesContainer.className = 'discrete-legend-container-of-container-div';
          if (layer?.selectedDates?.startDate && layer?.selectedDates?.endDate) {
            datesContainer.innerHTML =
              '&nbsp;&nbsp;' +
              layer.selectedDates.startDate +
              ' to ' +
              layer.selectedDates.endDate +
              '&nbsp;&nbsp;';
            containerOfContainerDiv.appendChild(datesContainer);
            containerOfContainerDiv.appendChild(containerDiv);
            legendDiv.append(containerOfContainerDiv);
          } else {
            legendDiv.append(containerDiv);
          }
          return legendDiv;
        };
        if (layer && !_.isEmpty(legend) && !_.isEmpty(map)) {
          if (!_.isEmpty(map?._layers)) {
            legend?.addTo(map);
          }
        }
      } else {
        if (layer) {
          const image = document.getElementById(id || 'legend_div');
          legend.onRemove = () => {
            if (image) {
              L.DomUtil.remove(image);
            }
          };
          legend.onRemove();
        }
      }
    }
  }, [add, map, layer]); //here add map
  return null;
}

export default memo(Legend);
