import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Row, Col } from 'reactstrap';
import _ from 'lodash';
import MandatoryDesign from '../../../assets/Images/mandatory-design-three-resize.png';
import ImpactDesign from '../../../assets/Images/impact-design.png'
import { t } from 'react-multi-lang';

const DroughtPhaseInfoModal = props => {
  return (
    <div>
      <Modal isOpen={props.isOpen} toggle={props.toggle} className="drought-phase-modal ">
        <ModalHeader toggle={props.toggle}></ModalHeader>
        <ModalBody>
          <Row>
            <Col sm={6} md={6} lg={6} className="cursor-ponter">
              <img className='mandatory-design-img' src={MandatoryDesign} alt="mandatory template" />
              {/* <MandatoryTemplate /> */}
            </Col>
            <Col sm={6} md={6} lg={6} className="cursor-ponter">
              <img src={ImpactDesign} alt="impact template" />
              {/* <ImpactTemplate /> */}
            </Col>
          </Row>
          <Row className="dss-info-style">
            <p>
              {t('DROUGHT_DSS.DROUGHT_PHASE_INFO')}
            </p>
          </Row>
        </ModalBody>
        <ModalFooter></ModalFooter>
      </Modal>
    </div>
  );
};

export default DroughtPhaseInfoModal;
