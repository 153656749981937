import React, { useEffect, useState } from 'react';
import { Row, Col, Card, Container } from 'reactstrap';
import { t } from 'react-multi-lang';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import SettingIcon from '../assets/Images/dashboard-img.png';
import ManageStaticIcon from '../assets/Images/dashboard-img1.png';
import ManageUserIcon from '../assets/Images/dashboard-img2.png';
import ManagePublicIcon from '../assets/Images/dashboard-img3.png';
import { isSuperAdmin, isStaffUser } from './common/utils';
import { userDetailRequest, userPermissionsRequest } from '../redux/user-list/userListAction';
import Loader from '../Components/common/loader';
const SettingsDashboard = () => {
  const cardsInfo = [
    {
      title: 'Manage Static Data',
      icon: ManageStaticIcon,
      path: '/manageStaticData',
      group_name: 'Manage Static Resource',
    },
    {
      title: 'Manage Public API',
      icon: ManagePublicIcon,
      path: '/public-api-admin',
      group_name: 'Manage Public API Access Key',
    },
    {
      title: 'Manage User',
      icon: ManageUserIcon,
      path: '/manage-user-list',
      group_name: 'Manage Users',
    },
    // {
    //   title: 'Manage Crops',
    //   icon: ManageUserIcon,
    //   path: '/ManageCrops',
    //   group_name: 'Manage Crops',
    // },
    {
      title: 'Manage Newsfeed',
      icon: ManageUserIcon,
      path: '/manage-newsfeed',
      group_name: 'News Feed',
    },
    {
      title: 'Manage User Guide',
      icon: ManageUserIcon,
      path: '/manage-userguide',
      group_name: 'User Guide',
    },
    // {
    //   title: 'Manage Template',
    //   icon: ManageUserIcon,
    //   path: '/bulletin',
    //   group_name: 'Manage Template',
    // },
    {
      title: 'Manage Bulletin',
      icon: ManageUserIcon,
      path: '/back-office-bulletin/:currentTab',
      group_name: 'Manage Bulletin',
    },
  ];
  const history = useHistory();
  const dispatch = useDispatch();
  const [cardList, setCardList] = useState([]);
  const [userPermissions, setUserPermissions] = useState(); // individual user permissions
  const [cardsInformation, setCardsInformation] = useState();
  const nextProps = useSelector(state => ({
    userDetailData: state.userList.userDetailData || null,
    userPermissionsData: state.userList.userPermissionsData || null,
    isUserPermissionsRequesting: state.userList.isUserPermissionsRequesting,
  }));
  useEffect(() => {
    if (!isSuperAdmin()) {
      if (!isStaffUser()) {
        history.push('/home');
      }
    }
    dispatch(userDetailRequest());
    dispatch(userPermissionsRequest());
  }, []);

  useEffect(() => {
    if (nextProps.userPermissionsData) {
      const {
        data: { result },
      } = nextProps.userPermissionsData;
      if (result) {
        cardsInfo.map(i => {
          let b = [];
          result.map(data => {
            if (i.group_name === data.group_name) {
              b.push(data.code_name);
            }
            i.code_name_list = b;
          });
        });
        setCardsInformation(cardsInfo);
      }
    }
  }, [nextProps.userPermissionsData]);

  useEffect(() => {
    if (nextProps.userDetailData) {
      const {
        data: { result },
      } = nextProps.userDetailData;
      if (result?.user_permissions) {
        setUserPermissions(result?.user_permissions);
      }
    }
  }, [nextProps.userDetailData]);

  useEffect(() => {
    if (cardsInformation?.length && userPermissions) {
      handleCards(userPermissions);
    }
  }, [cardsInformation, userPermissions]);

  const handleCards = list => {
    let a = [];
    if (list.length) {
      cardsInformation?.map(cardInfo => {
        list?.map(item => {
          if (cardInfo.code_name_list.includes(item.codename)) {
            if (!a.includes(cardInfo)) {
              a.push(cardInfo);
            }
          }
        });
      });
      setCardList(a);
    } else if (isSuperAdmin()) {
      setCardList(cardsInfo);
    }
  };

  const handleCardClick = i => {
    history.push(i.path);
  };

  return (
    <>
      <div className="global-top dashboard-border my-5 mx-4">
        <div className="dashboard-header">
          <img src={SettingIcon} alt={'icon-not available'} />
          <p className="dashboard-text">{t('DASHBOARD.DASHBOARD')}</p>
        </div>
        <Container>
          <Row>
            {!cardList?.length && nextProps.isUserPermissionsRequesting ? (
              <Loader />
            ) : (
              cardList.map(item => {
                return (
                  <>
                    <Col
                      lg={4}
                      sm={6}
                      xs={12}
                      key={item.id}
                      className="card-column"
                      onClick={() => handleCardClick(item)}
                    >
                      <Card className="dashboard-card custom-card-x cb-1 cursor-ponter">
                        <div className="">
                          <img src={item.icon} alt={'not-available'} />
                          <p className="icon-text dt-1">{item.title}</p>
                          <p className="icon-small-text mrl-1">{t('HOME_PAGE.DESCRIPTION')}</p>
                        </div>
                      </Card>
                    </Col>
                  </>
                );
              })
            )}
          </Row>
        </Container>
      </div>
    </>
  );
};

export default SettingsDashboard;
