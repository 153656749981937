import React, { useState, useEffect } from 'react';
import { BsFillRecordFill, BsCheckAll } from 'react-icons/bs';
import Loader from '../../Components/common/loader';
import ReactTooltip from 'react-tooltip';
import Footer from '../landing-page/footer';
import Cookies from 'js-cookie';
import Button from 'reactstrap-button-loader';
import Menu from './Menu/index';
import { Card, CardImg, CardSubtitle, CardTitle, Row, Col, Input } from 'reactstrap';
import '../../assets/CSS/bulletin.css';
import InfiniteScroll from 'react-infinite-scroll-component';
import moment from 'moment';
import { DISPLAY_DATE_FORMAT, LOCATION, MAP_PREFERENCES_lABELS } from '../../Components/constants';
import { t } from 'react-multi-lang';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { isValidEmail,isLogedIn } from '../../Components/common/utils';
import { showSuccess, showError } from '../../Components/Notifications';
import {
  getAllTemplateListRequest,
  getAllBulletinRequest,
  subscribeBulletinRequest,
  subscribeBulletinSuccess,
} from '../../redux/bulletin/bulletinActions';
import _ from 'lodash';
import RightArrow from '../../assets/Images/back-button.svg';
import { getNestedObjectData } from '../../Components/helpers';
import OnlineBulletinModal from '../create-online-bulletin/OnlineBulletinModal';
import {
  getCountryRequest,
  getIndicesRequest,
  getStateByCountryRequest,
} from '../../redux/actions';
//import DummyImage from '../..//assets/Images/Frame 8239.jpg';
import NewDummyImage from "../..//assets/Images/Zambia_map.png";

const HomePageBulletin = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [pageNumber, setPageNumber] = useState(1);
  const [pageCount, setPageCount] = useState();
  const [dataList, setDataList] = useState([]);
  const [email, setEmail] = useState();
  const [emailSubscribed, setEmailSubscribed] = useState(false);
  const [isSubscribeClicked, setIsSubscribeClicked] = useState(false);
  const [isEmailSubmit, setIsEmailSubmit] = useState(false);
  const [categoryFilter, setCategoryFilter] = useState([]);
  const [filteredYear, setFilteredYear] = useState(new Date().getFullYear().toString());
  const [selectedCategory, setSelectedCategory] = useState('');
  const [indicatorsList, setIndicatorsList] = useState([]);
  let listOfYears = [];

  /**
   * value and toggle for create bulletin model
   */
  const [isOpenBulletin, setIsOpenBulletin] = useState(false);
  const toggleModal = () => {
    setIsOpenBulletin(!isOpenBulletin);
  };
  const { Bulletin, LocationData, DroughtIndicesData } = useSelector(state => ({
    Bulletin: state.Bulletin,
    LocationData: state.Location,
    DroughtIndicesData: state.AccessKey.getIndicesData,
  }));

  /**TODO indicators will change */
  const selectedIndicators = [
    'NDVI',
    'Crop Cover',
    'VCI',
    'Extreme Rainfall',
    'Accumulated Rainfall',
  ];
  useEffect(() => {
    if (_.get(DroughtIndicesData, 'data.result')?.length) {
      let filteredIndicatorsList = [];
      selectedIndicators.forEach(item => {
        filteredIndicatorsList.push(
          getNestedObjectData(_.get(DroughtIndicesData, 'data.result'), item),
        );
      });
      setIndicatorsList(filteredIndicatorsList);
    }
  }, [DroughtIndicesData]);

  useEffect(() => {
    if ( !isLogedIn()) {
      let path = window.location.pathname
      history.push(`/login?prevPath=${path.substring(1,path.length)}`);
    }
    dispatch(getAllTemplateListRequest({}));
    dispatch(getCountryRequest({ location_type: LOCATION.Country, parent_id: '' }));
    window.scrollTo({
      top: 0,
      behavior: 'instant' /*scrolling behavior changed by Ramya.s on 6/9/2022*/,
    });
    setDataList([]);
    return () => {
      setDataList([]);
      dispatch(subscribeBulletinSuccess({}));
    };
  }, []);

  /**
   * to get indices based on country id
   */
  useEffect(() => {
    const countryList = _.get(LocationData, 'countryList.result');
    if (countryList?.length) {
      dispatch(
        getIndicesRequest({
          requestObj: {
            type: MAP_PREFERENCES_lABELS.COUNTRY,
            type_id: countryList[0].id,
          },
          module: 'drought-monitor',
        }),
      );
      dispatch(
        getStateByCountryRequest({
          requestObj: {
            location_type: LOCATION.State,
            parent_id: countryList[0].id,
          },
          isCompareLocation: false,
        }),
      );
    }
  }, [LocationData.countryList]);

  useEffect(() => {
    if (Bulletin?.getAllBulletinData?.data) {
      let new_list = [];
      const {
        data: { result },
      } = Bulletin?.getAllBulletinData;
      if (result) {
        if (dataList) {
          if (pageNumber > 1) {
            new_list = dataList?.concat(result?.results);
          } else {
            new_list = result?.results;
          }
        } else {
          new_list = result?.results;
        }
        setDataList(new_list);
        setPageCount(result?.count);
      } else {
        setDataList([]);
      }
    }
  }, [Bulletin.getAllBulletinData]);

  useEffect(() => {
    if (Bulletin?.getAllTemplateListData?.data) {
      if (
        Bulletin?.getAllTemplateListData?.data?.status === 200 &&
        Bulletin?.getAllTemplateListData?.data?.data?.result
      ) {
        setCategoryFilter(Bulletin?.getAllTemplateListData?.data?.data?.result);
        setSelectedCategory(Bulletin?.getAllTemplateListData?.data?.data?.result[1]?.id);
        dispatch(
          getAllBulletinRequest({
            page: pageNumber,
            data: {
              is_delete: 'False',
              is_publish: 'True',
              bulletin_status: '1',
              year: new Date().getFullYear().toString(),
              category_id: Bulletin?.getAllTemplateListData?.data?.data?.result[1]?.id,
            },
          }),
        );
      } else {
        setCategoryFilter([]);
      }
    }
  }, [Bulletin.getAllTemplateListData]);

  useEffect(() => {
    if (Bulletin.subscribeBulletinData) {
      const {
        data: { result },
      } = Bulletin.subscribeBulletinData;
      if (result) {
        showSuccess(result);
        setIsEmailSubmit(false);
        setIsSubscribeClicked(false);
        setEmail();
        setEmailSubscribed(true);
      }
    }
  }, [Bulletin.subscribeBulletinData]);

  useEffect(() => {
    if (Bulletin.subscribeBulletinDataError) {
      const {
        data: { response },
      } = Bulletin.subscribeBulletinDataError;
      if (response) {
        showError(response?.data?.message[0]);
        setIsEmailSubmit(false);
        setIsSubscribeClicked(false);
        setEmail();
      }
    }
  }, [Bulletin.subscribeBulletinDataError]);

  const pdfPreviewHandler = (e, data) => {
    console.log({ data });
    if (data.bulletin_file) window.open(data.bulletin_file);
    // data?.template_id ? window.open(data.pdf_url) : window.open(data.bulletin_file);
    e?.stopPropagation();
  };

  const emailSubmitHandler = () => {
    setIsEmailSubmit(false);
    dispatch(subscribeBulletinRequest({ email: email }));
  };

  const subscribeButtonHandler = () => {
    setIsSubscribeClicked(true);
  };

  const emailInputHandler = value => {
    setEmail(value);
    if (isValidEmail(value)) {
      setIsEmailSubmit(true);
    } else {
      setIsEmailSubmit(false);
    }
  };

  const subscribeLoggedInButtonHandler = () => {
    setIsSubscribeClicked(true);
    dispatch(subscribeBulletinRequest({ email: Cookies.get('loggedin_user_details') }));
  };

  const handleEmailSubmitClick = () => {
    emailSubmitHandler();
  };
  const handleSubscribeClick = () => {
    subscribeButtonHandler();
  };
  const handleEmailInput = e => {
    emailInputHandler(e.target.value);
  };
  const handleSubscribeClickForLoggedIn = () => {
    subscribeLoggedInButtonHandler();
  };

  const fetchNextTemplateData = () => {
    setPageNumber(pageNumber + 1);
    dispatch(
      getAllBulletinRequest({
        page: pageNumber + 1,
        data: { is_delete: 'False', is_publish: 'True', bulletin_status: '1', year: filteredYear },
      }),
    );
  };

  const handleSelectedItem = value => {
    setFilteredYear(value);
    setDataList([]);
    setPageNumber(1);
    dispatch(
      getAllBulletinRequest({
        page: '1',
        data: {
          is_delete: 'False',
          is_publish: 'True',
          bulletin_status: '1',
          year: value.toString(),
          category_id: selectedCategory,
        },
      }),
    );
  };

  const handleSelectedCategory = value => {
    setDataList([]);
    setPageNumber(1);
    setSelectedCategory(value.id);
    dispatch(
      getAllBulletinRequest({
        page: '1',
        data: {
          is_delete: 'False',
          is_publish: 'True',
          bulletin_status: '1',
          year: filteredYear,
          category_id: value.id,
        },
      }),
    );
  };
  return (
    <>
      <div className="news-feed-header">
        {isOpenBulletin && (
          <OnlineBulletinModal
            LocationData={LocationData}
            setIsOpenBulletin={setIsOpenBulletin}
            isOpen={isOpenBulletin}
            toggleModal={toggleModal}
            bulletinCategories={categoryFilter}
            indicatorsList={indicatorsList}
          />
        )}
        <Row className="news-feed-top-text">
          <Col xs={2} lg={2}>
            <img
              src={RightArrow}
              className="news-feed-header-back-button news-feed-backarrow-position"
              onClick={() => history.goBack()}
            />
          </Col>
          <Col xs={8} lg={8}>
            <h3 className="home-page-bulletin-header-h3 ">
              {t('BULLET_IN.ALL_PUBLISHED_BULLETIN')}
            </h3>
          </Col>
          <Col xs={2} lg={2} className="mt-3">
            <Button className="primary-btn create-bulletin-btn" onClick={() => toggleModal()}>
              {' '}
              {t('ONLINE_BULLETIN.CREATE_BULLETIN.HEADER')}
            </Button>
          </Col>
        </Row>
      </div>

      <div className="">
        <div className="w-100">
          <Row className="content-section">
            <Col xs={2} md={2} lg={2} className="news_feed_menu">
              <Menu
                // selectedMenuItem={selectedMenuItem}
                handleSelectedItem={handleSelectedItem}
                // categoriesList={categoriesList}
                listOfYears={listOfYears}
              />
            </Col>
            <Col xs={9} md={9} lg={9} className="news_feed_data">
              {/* <div className="news-feed-content"> */}
              <Row className="news-feed-menu filter-btn-alignment">
                <Col xs={6}>
                  <button
                    className={
                      selectedCategory === categoryFilter[1]?.id
                        ? ' filter-btn-active '
                        : '  fliter-button-bulleten'
                    }
                    onClick={() => categoryFilter[1] && handleSelectedCategory(categoryFilter[1])}
                  >
                    {categoryFilter[1]?.name}
                  </button>

                  <button
                    type="button"
                    className={
                      selectedCategory === categoryFilter[0]?.id
                        ? '  filter-btn-active margin-left'
                        : '  fliter-button-bulleten margin-left'
                    }
                    onClick={() => categoryFilter[0] && handleSelectedCategory(categoryFilter[0])}
                  >
                    {categoryFilter[0]?.name}
                  </button>
                </Col>
                <Col xs={6} className="">
                  {!Cookies.get('user') ? (
                    !emailSubscribed &&
                    (isSubscribeClicked ? (
                      <div className="user-input">
                        <Row>
                          <Col sm="8" md="8" xs="8" lg="8">
                            <Input
                              type="email"
                              name="email"
                              id="exampleEmail"
                              placeholder="Your Email Address"
                              bsSize="md"
                              size="50"
                              className="email-input"
                              onChange={e => handleEmailInput(e)}
                            />
                          </Col>
                          <Col sm="4" md="4" xs="4" lg="4">
                            <Button
                              className="email-submit-btn"
                              onClick={handleEmailSubmitClick}
                              loading={Bulletin.isSubscribeBulletinRequesting}
                              disabled={!isEmailSubmit}
                            >
                              {t('NEWS_FEED.SUBMIT')}
                            </Button>
                          </Col>
                        </Row>
                      </div>
                    ) : (
                      <Button className="bulletin-subscribe-btn " onClick={handleSubscribeClick}>
                        {t('NEWS_FEED.SUBSCRIBE_NOW')}
                      </Button>
                    ))
                  ) : (
                    <Button
                      className="bulletin-subscribe-btn"
                      onClick={handleSubscribeClickForLoggedIn}
                      // loading={true}
                      loading={Bulletin.isSubscribeBulletinRequesting}
                      disabled={!isEmailSubmit}
                    >
                      {t('NEWS_FEED.SUBSCRIBED')}
                    </Button>
                  )}
                </Col>
                <hr className="title-hr" />
              </Row>
              <div className="loader-div">{Bulletin.isGetAllBulletinRequesting && <Loader />}</div>
              {_.isEmpty(dataList) && !Bulletin.isGetAllBulletinRequesting && (
                <h2 className="bulletin-template-modal-no-records-found no-record-found-font-size">
                  {t('BULLET_IN.NO_RECORDS_FOUND')}
                </h2>
              )}
              <InfiniteScroll
                dataLength={pageNumber * 10 < pageCount ? pageNumber * 10 : ''}
                next={fetchNextTemplateData}
                hasMore={pageNumber * 10 < pageCount ? true : false}
                loader={
                  Bulletin.isGetAllBulletinRequesting && (
                    <center>
                      <h4>{t('BULLET_IN.LOADING')} </h4>
                    </center>
                  )
                }
                className="flex flex-wrap"
              >
                <div className="mt-3">
                  <Row className="m-3">
                    {dataList?.length ? (
                      dataList?.map((item, index) => {
                        return (
                          <Col className="bulletin-template-modal-card-col" key={index} lg={4}>
                            <div className="position-relative">
                              <Card
                                className="mt-3 bulletin-card-pointer"
                                onClick={e => {
                                  pdfPreviewHandler(e, item);
                                }}
                              >
                                <CardImg
                                  src={item?.content_file || NewDummyImage }
                                  top
                                  width="100%"
                                  className="front-office-bulletin-card-Image"
                                />
                                <div className="m-3">
                                  <CardTitle tag="h5">
                                    {item?.topic?.length > 25
                                      ? item?.topic?.substring(0, 25) + '...'
                                      : item?.topic}
                                  </CardTitle>
                                  <CardSubtitle className="mb-2 text-muted" tag="h6">
                                    {item?.category_name}
                                  </CardSubtitle>

                                  <div className="d-flex">
                                    {!item?.is_publish ? (
                                      <div className="back-office-bulletin-In-progress">
                                        <BsFillRecordFill />
                                        <span>{t('BULLET_IN.IN_PROGRESS')}</span>
                                      </div>
                                    ) : (
                                      <div className="back-office-bulletin-Publish">
                                        <BsCheckAll />
                                        <span>
                                          {t('BULLET_IN.PUBLISHED_ON')}{' '}
                                          {moment(item?.updated_date).format(DISPLAY_DATE_FORMAT)}
                                        </span>
                                      </div>
                                    )}
                                  </div>
                                  <ReactTooltip id="download-pdf" effect="solid" place="top">
                                    {t('BULLET_IN.CLICK_TO_DOWNLOAD')}
                                  </ReactTooltip>
                                </div>{' '}
                              </Card>
                            </div>
                          </Col>
                        );
                      })
                    ) : (
                      <></>
                    )}
                  </Row>
                </div>
              </InfiniteScroll>
            </Col>
          </Row>
        </div>
      </div>

      <div className="mt-5">
        <Footer publicFooter={false} />
      </div>
    </>
  );
};
export default HomePageBulletin;
