import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { AXIOS_INSTANCE, USER_URL } from '../api/apiEndPoint';
import {REGISTER_CONST} from './registerConst';
import {VERIFICATION_CONST} from './registerConst';
import { registerSuccess, registerFailed } from './registerAction';
import { verificationSuccess, verificationFailed } from './registerAction';
import { showError } from '../../Components/Notifications';

require('dotenv').config();

const registerApiRequest = async (payload) => {
  let response = {};
  try {
    const data = await AXIOS_INSTANCE.post(`${USER_URL}/register`,payload);
    response = {
      data: data.data,
      status: 200,
    };
  } catch (error) {
    const data = error;
    showError(error.response.data.message);
    response = {
      error: data,
      status: 400,
    };
  }
  return response;
};



const verificationApiRequest = async (payload) => {
  let response = {};
  try {
    const data = await AXIOS_INSTANCE.post(`${USER_URL}/verification`,payload);
    response = {
      data: data.data,
      status: 200,
    };
  } catch (error) {
    const data = error;
    showError(error.response.data.message);
    response = {
      error: data,
      status: 400,
    };
  }
  return response;
};


function* registerRequest(action) {
    
  try {
    const apiResponse = yield call(registerApiRequest, action.payload);
    if (apiResponse.status === 200) {
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.data,
        },
      };
      yield put(registerSuccess(responseData));
    } else {
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.error,
        },
      };
      yield put(registerFailed(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(registerFailed(errorData));
  }
}


function* verificationRequest(action) {
    
  try {
    const apiResponse = yield call(verificationApiRequest, action.payload);
    if (apiResponse.status === 200) {
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.data,
        },
      };
      yield put(verificationSuccess(responseData));
    } else {
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.error,
        },
      };
      yield put(verificationFailed(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(verificationFailed(errorData));
  }
}


export function* watchRegister() {
  yield takeEvery(REGISTER_CONST.REGISTER_REQUEST, registerRequest);
  yield takeEvery(VERIFICATION_CONST.VERIFICATION_REQUEST, verificationRequest);
}

function* registerSaga() {
  yield all([fork(watchRegister)]);
}

export default registerSaga;
