import React, { useState, useEffect, memo } from 'react';
import myConstClass from '../../../Constants';
import {
  MapContainer,
  TileLayer,
  useMap,
  useMapEvents,
  LayerGroup,
  LayersControl,
  ScaleControl,
  ZoomControl,
} from 'react-leaflet';
import _ from 'lodash';
import 'leaflet-side-by-side';
import 'leaflet.control.opacity/dist/L.Control.Opacity.css';
import 'leaflet.control.opacity';
import { Row, Col,Button } from 'reactstrap';
import Summary from './Summary';
import IndicatorInfo from './IndicatorInfo';
import CustomLegend from './CustomLayerControl/CustomLegend';
import DiscreteLegend from './CustomLayerControl/DiscreteLegend';
import { getBulletinDateFormat } from '../../../Components/helpers';
import { COORDINATES_DATA } from '../../../Components/constants/urlConstants';
import ResetIcon from "../../../assets/Images/reset.png";

const countryLoc = {
  cordinates: [-14.207133, 27.385223],
  locationCordinates: [-14.207133, 27.385223],
  markerCordinates: [-14.207133, 27.385223],
  zoom: 4.8,
};
const BulletinPreview = props => {
  const { selectedLocation, layerRef, setMapReference, mapInstances, indicatorRows, indicatorsList, mapURLResponse} = props;
  const [selectedIndicator, setSelectedIndicator] = useState();
  const [cordinateAndZoom, setCordinateAndZoom] = useState([]);
  const [isReset, setIsReset]=useState(false);
  const [selectedIndex,setSelectedIndex]=useState();
  const [currentLoc,setCurrentLoc] = useState({});

  
  let defaultLoc = [];
  useEffect(()=>{
    for( let i=0;i< (Object.keys(indicatorRows)?.length*3);i++){
      defaultLoc.push(countryLoc)
  if(i+1 === (Object.keys(indicatorRows)?.length*3)){
    setCordinateAndZoom(defaultLoc);

 }
}
  },[indicatorRows])
  const { Overlay } = LayersControl;

  function ChangeView({ center, zoom , index }) {
    const mapEvents = useMapEvents({
      zoomend: () => {
       const currentCoordinateAndZoom = cordinateAndZoom
       currentCoordinateAndZoom[index].zoom = mapEvents.getZoom();
       currentCoordinateAndZoom[index].cordinates = mapEvents.getCenter();
       setCordinateAndZoom(currentCoordinateAndZoom);
        // setCordinateAndZoom(prev => {
        //   return {
        //     ...prev,
        //     zoom: mapEvents.getZoom(),
        //     cordinates: mapEvents.getCenter(),
        //   };
        // });
      },
      dragend: () => {
        const currentCoordinateAndZoom = cordinateAndZoom
       currentCoordinateAndZoom[index].zoom = mapEvents.getZoom();
       currentCoordinateAndZoom[index].cordinates = mapEvents.getCenter();
       setCordinateAndZoom(currentCoordinateAndZoom);
        // setCordinateAndZoom(prev => {
        //   return {
        //     ...prev,
        //     zoom: mapEvents.getZoom(),
        //     cordinates: mapEvents.getCenter(),
        //   };
        // });
      },
    });
    const map = useMap();
    setInterval(function() {
      if (!_.isEmpty(map) && !_.isEmpty(map?._layers)) map.invalidateSize();
    }, 500);
    if (zoom && center) map.setView(center, zoom);
    return null;
  }

  const handleZoomLevel = (e,index) => {
    e?.stopPropagation();
     setSelectedIndex(index)
     Reset(index)
 };
 
 const Reset = (index) => {
   const currentCoordinateAndZoom = [...cordinateAndZoom];
   currentCoordinateAndZoom[index].zoom =currentLoc.zoom;
   currentCoordinateAndZoom[index].cordinates =currentLoc.cordinates;
   setCordinateAndZoom(currentCoordinateAndZoom);
 }
 

  useEffect(() => {
      // using static data for better zoom and position
      let stateData = {};
      if(selectedLocation?.district?.name) {
        stateData = COORDINATES_DATA.find(e => e.districtName === selectedLocation?.district.name);
      }else if(selectedLocation?.state?.name){
        stateData = COORDINATES_DATA.find(e => e.stateName === selectedLocation?.state.name);
      }
       if(Object.keys(stateData)?.length){
        setCurrentLoc(Object.assign({},stateData));
        let defaultLoc1 = []
        for( let i=0;i< (Object.keys(indicatorRows)?.length*3);i++){
          defaultLoc1.push(stateData)
      if(i+1 === (Object.keys(indicatorRows)?.length*3)){
        setCordinateAndZoom(defaultLoc1);

     }
    }
       }
  },[selectedLocation,indicatorRows]);
  

  let layerIndex = -1;
 
  const renderMapContainer = (keyName, index) => {
    return (
      <div className="mapcontainerlayout">
        <MapContainer
          center={cordinateAndZoom[index]?.cordinates}
          id={`leaflet-map${keyName}`}
          zoom={cordinateAndZoom[index]?.zoom}
          className="map-container b-radius"
          zoomControl={false}
          scrollWheelZoom={true}
          whenCreated={e => setMapReference(e, keyName)}
        >
         <ChangeView center={cordinateAndZoom[index]?.cordinates} index={index} zoom={cordinateAndZoom[index]?.zoom}/>

          <ScaleControl position="topright" />
          <ZoomControl position="topright" className="map-zoom-control" />
          <LayersControl position="topleft">
                <Button className="reset-button" title="Click here to reset zoom and centre"onClick={e =>{handleZoomLevel(e,index)}}>
                  <img src={ResetIcon} alt="Reset" width={15} />
                </Button>
            </LayersControl>

          <TileLayer url={myConstClass.TITLE_LAYER_URL} options={{ tileSize: 256 }} />

          <LayersControl name="indicator" position="topright">
            <Overlay checked name="indicator-risk-force">
              <LayerGroup ref={layerRef.current[index]} style={{ zIndex: 1001 }}>
                <TileLayer
                  attribution='Weather from <a href="https://openweathermap.org/" alt="World Map and worldwide Weather Forecast online">OpenWeatherMap</a>'
                  url={''}
                />
              </LayerGroup>
            </Overlay>
          </LayersControl>

          <LayersControl key={`legend-${keyName}`} position="topleft">
            {mapInstances[`legendObj${keyName}`]?.descret_legend ? (
              <DiscreteLegend
                map={mapInstances[`mapInstance${keyName}`]}
                add={mapInstances[`legendObj${keyName}`]?.add}
                layer={mapInstances[`legendObj${keyName}`]}
                position="bottomleft"
              />
            ) : (
              <CustomLegend
                map={mapInstances[`mapInstance${keyName}`]}
                add={mapInstances[`legendObj${keyName}`]?.add}
                layer={mapInstances[`legendObj${keyName}`]}
                position="bottomleft"
              />
            )}
          </LayersControl>
        </MapContainer>
      </div>
    );
  };

  return (
    <>
      {Object.keys(indicatorRows)?.length
        ? Object.keys(indicatorRows).map((eachRow, key) => {
            return (
              <>
              <div className="indi-div d-flex align-items-center">
              <h5 className="text-indi">{" "}INDICATOR SUMMARY- {key+1}/{Object.keys(indicatorRows)?.length}</h5>
              </div>
              <Row key={eachRow}>
                {indicatorRows[eachRow]?.indicatorsList.map((eachIndicator, index) => {
                  layerIndex++;
                  return (
                    <>
                      <Col md={4} className={`map-div ${key > 0 ? 'mt-3' : ''}`} key={eachIndicator.name}>
                        {' '}
                        {renderMapContainer(`${eachRow}${eachIndicator.name}`, layerIndex)}
                        <ul className="map-text">
                          <li >Indicator Name :{eachIndicator.name}</li>
                          <li >Region :
                            {indicatorRows[eachRow].district ? indicatorRows[eachRow].district :indicatorRows[eachRow].state ? indicatorRows[eachRow].state : indicatorRows[eachRow].country}(
                            {getBulletinDateFormat(indicatorRows[eachRow].startDate)} -{' '}
                            {getBulletinDateFormat(indicatorRows[eachRow].endDate)})
                          </li>
                        </ul>
                      </Col>
                    </>
                  );
                })}
                <Col md={12}>
                  <div className="mt-2"></div>
                <IndicatorInfo mapURLResponse={mapURLResponse} indicatorRows={indicatorRows} rowKey={eachRow} selectedIndicator={selectedIndicator} setSelectedIndicator={setSelectedIndicator}/>
                </Col>
                <Col md={12}>
                  <div className="mt-2">
                    <Summary name={`summary${key}`} condition="Current"  indicatorsList={indicatorsList}/>
                  </div>
                  <hr className="section-break" />
                </Col>
              </Row>
              </>
            );
          })
        : null}
    </>
  );
};
export default memo(BulletinPreview);
