import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { AXIOS_INSTANCE, MANAGE_CROPS_URL } from '../api/apiEndPoint';
import {MANAGE_CROPS_CONST,MANAGE_CROPS_ADD_CONST, MANAGE_CROPS_UPDATE_CONST, MANAGE_CROPS_DELETE_CONST, MANAGE_CROPS_SEASONS_CONST,MANAGE_CROPS_LIST_BY_ID_CONST }  from './manageCropsConst';
import { manageCropsListByIdSuccess,manageCropsListByIdFailed,manageCropsSeasonsSuccess,manageCropsSeasonsFailed,manageCropsListSuccess, manageCropsListFailed, manageCropsAddSuccess,manageCropsAddFailed,manageCropsUpdateSuccess,manageCropsUpdateFailed,manageCropsDeleteFailed,manageCropsDeleteSuccess  } from './manageCropsAction';

require('dotenv').config();

const manageCropsListApiRequest = async (payload) => {
  let response = {};
  try {
   const data = await AXIOS_INSTANCE.post(`${MANAGE_CROPS_URL}/getAllByCriteria?page=${payload.page}&limit=${payload.limit}`,payload);
    response = {
      data: data.data,
      status: 200,
    };
  } catch (error) {
    const data = error;
    response = {
      error: data,
      status: 400,
    };
  }
  return response;
};



const manageCropsAddApiRequest = async (payload) => {
  let response = {};
  try {
   const data = await AXIOS_INSTANCE.post(`${MANAGE_CROPS_URL}/add`,payload);
    response = {
      data: data.data,
      status: 200,
    };
  } catch (error) {
    const data = error;
    response = {
      error: data,
      status: 400,
    };
  }
  return response;
};



const manageCropsUpdateApiRequest = async (payload) => {
  let response = {};
  try {
   const data = await AXIOS_INSTANCE.put(`${MANAGE_CROPS_URL}/update`,payload);
    response = {
      data: data.data,
      status: 200,
    };
  } catch (error) {
    const data = error;
    response = {
      error: data,
      status: 400,
    };
  }
  return response;
};

const manageCropsDeleteApiRequest = async (payload) => {
  let response = {};
  try {
   const data = await AXIOS_INSTANCE.delete(`${MANAGE_CROPS_URL}/delete/${payload.id}`,payload);
    response = {
      data: data.data,
      status: 200,
    };
  } catch (error) {
    const data = error;
    response = {
      error: data,
      status: 400,
    };
  }
  return response;
};



const manageCropsSeasonsApiRequest = async (payload) => {
  let response = {};
  try {
   const data = await AXIOS_INSTANCE.get(`${MANAGE_CROPS_URL}/getAllSeason`,payload);
    response = {
      data: data.data,
      status: 200,
    };
  } catch (error) {
    const data = error;
    response = {
      error: data,
      status: 400,
    };
  }
  return response;
};




const manageCropsListByIdApiRequest = async (payload) => {
  let response = {};
  try {
   const data = await AXIOS_INSTANCE.get(`${MANAGE_CROPS_URL}/getById/${payload.id}`,payload);
    response = {
      data: data.data,
      status: 200,
    };
  } catch (error) {
    const data = error;
    response = {
      error: data,
      status: 400,
    };
  }
  return response;
};



function* manageCropsListRequest(action) {
    
    try {
      const apiResponse = yield call(manageCropsListApiRequest, action.payload);
      if (apiResponse.status === 200) {
        const responseData = {
          response: {
            statusCode: 200,
            data: apiResponse.data,
          },
        };
        yield put(manageCropsListSuccess(responseData));
      } else {
        const responseData = {
          response: {
            statusCode: apiResponse.status,
            data: apiResponse.error,
          },
        };
        yield put(manageCropsListFailed(responseData));
      }
    } catch (error) {
      const errorData = {
        error: {
          statusText: error,
          netWorkError: true,
        },
      };
      yield put(manageCropsListFailed(errorData));
    }
  }




  function* manageCropsAddRequest(action) {
    
    try {
      const apiResponse = yield call(manageCropsAddApiRequest, action.payload);
      if (apiResponse.status === 200) {
        const responseData = {
          response: {
            statusCode: 200,
            data: apiResponse.data,
          },
        };
        yield put(manageCropsAddSuccess(responseData));
      } else {
        const responseData = {
          response: {
            statusCode: apiResponse.status,
            data: apiResponse.error,
          },
        };
        yield put(manageCropsAddFailed(responseData));
      }
    } catch (error) {
      const errorData = {
        error: {
          statusText: error,
          netWorkError: true,
        },
      };
      yield put(manageCropsListFailed(errorData));
    }
  }

  function* manageCropsUpdateRequest(action) {
    
    try {
      const apiResponse = yield call(manageCropsUpdateApiRequest, action.payload);
      if (apiResponse.status === 200) {
        const responseData = {
          response: {
            statusCode: 200,
            data: apiResponse.data,
          },
        };
        yield put(manageCropsUpdateSuccess(responseData));
      } else {
        const responseData = {
          response: {
            statusCode: 200,
            data: apiResponse.error,
          },
        };
        yield put(manageCropsUpdateFailed(responseData));
      }
    } catch (error) {
      const errorData = {
        error: {
          statusText: error,
          netWorkError: true,
        },
      };
      yield put(manageCropsUpdateFailed(errorData));
    }
  }








  function* manageCropsDeleteRequest(action) {
    
    try {
      const apiResponse = yield call(manageCropsDeleteApiRequest, action.payload);
      if (apiResponse.status === 200) {
        const responseData = {
          response: {
            statusCode: 200,
            data: apiResponse.data,
          },
        };
        yield put(manageCropsDeleteSuccess(responseData));
      } else {
        const responseData = {
          response: {
            statusCode: 200,
            data: apiResponse.error,
          },
        };
        yield put(manageCropsDeleteFailed(responseData));
      }
    } catch (error) {
      const errorData = {
        error: {
          statusText: error,
          netWorkError: true,
        },
      };
      yield put(manageCropsDeleteFailed(errorData));
    }
  }


  function* manageCropsSeasonsRequest(action) {
    
    try {
      const apiResponse = yield call(manageCropsSeasonsApiRequest, action.payload);
      if (apiResponse.status === 200) {
        const responseData = {
          response: {
            statusCode: 200,
            data: apiResponse.data,
          },
        };
        yield put(manageCropsSeasonsSuccess(responseData));
      } else {
        const responseData = {
          response: {
            statusCode: 200,
            data: apiResponse.error,
          },
        };
        yield put(manageCropsSeasonsFailed(responseData));
      }
    } catch (error) {
      const errorData = {
        error: {
          statusText: error,
          netWorkError: true,
        },
      };
      yield put(manageCropsSeasonsFailed(errorData));
    }
  }






  function* manageCropsListByIdRequest(action) {
    
    try {
      const apiResponse = yield call(manageCropsListByIdApiRequest, action.payload);
      if (apiResponse.status === 200) {
        const responseData = {
          response: {
            statusCode: 200,
            data: apiResponse.data,
          },
        };
        yield put(manageCropsListByIdSuccess(responseData));
      } else {
        const responseData = {
          response: {
            statusCode: 200,
            data: apiResponse.error,
          },
        };
        yield put(manageCropsListByIdFailed(responseData));
      }
    } catch (error) {
      const errorData = {
        error: {
          statusText: error,
          netWorkError: true,
        },
      };
      yield put(manageCropsListByIdFailed(errorData));
    }
  }



  export function* watchManageCropsSaga() {
    yield takeEvery(MANAGE_CROPS_CONST.MANAGE_CROPS_REQUEST, manageCropsListRequest);
    yield takeEvery(MANAGE_CROPS_ADD_CONST.MANAGE_CROPS_ADD_REQUEST, manageCropsAddRequest);
    yield takeEvery(MANAGE_CROPS_UPDATE_CONST.MANAGE_CROPS_UPDATE_REQUEST, manageCropsUpdateRequest);
    yield takeEvery(MANAGE_CROPS_DELETE_CONST.MANAGE_CROPS_DELETE_REQUEST, manageCropsDeleteRequest);
    yield takeEvery(MANAGE_CROPS_SEASONS_CONST.MANAGE_CROPS_SEASONS_REQUEST, manageCropsSeasonsRequest);
    yield takeEvery(MANAGE_CROPS_LIST_BY_ID_CONST.MANAGE_CROPS_LIST_BY_ID_REQUEST, manageCropsListByIdRequest);
  }
  
  function* manageCropsSaga() {
    yield all([fork(watchManageCropsSaga)]);
  }
  
  export default manageCropsSaga;