import {
  CONTINGENCY_PLAN_RISK_CONST,
  CONTINGENCY_MEASURES_DATA_CONST,
  CONTINGENCY_PLAN_MONSOON_CONST,
  STAGE_CONST,
  CONTINGENCY_MEASURES_CROP_TYPE_CONST,
  CATEGORY_CONST,
  DISTRICT_PROFILE_CONST,
  SUB_CATEGORY_CONST,
  SYSTEM_CONST,
  DROUGHT_INDICES_CONST,
  STRATEGY_CONST,
  DROUGHT_STAGES_TIME_PERIOD_CONST,
  CONTINGENCY_DISTRICT_GRAPH_CONST,
} from './contingencyPlanConsts';

const ContingencyPlan = (state = {}, action) => {
  switch (action.type) {
    case CONTINGENCY_PLAN_RISK_CONST.CONTINGENCY_PLAN_RISK_REQUEST:
      return {
        ...state,
        isContingencyPlanRiskRequesting: true,
        contingencyPlanRiskListData: false,
        contingencyPlanRiskListDataError: false,
        contingencyPlanRiskListDataNetworkError: false,
      };
    case CONTINGENCY_PLAN_RISK_CONST.CONTINGENCY_PLAN_RISK_SUCCESS:
      return {
        ...state,
        isContingencyPlanRiskRequesting: false,
        contingencyPlanRiskListData: action.payload.response,
        contingencyPlanRiskListDataError: false,
        contingencyPlanRiskListDataNetworkError: false,
      };
    case CONTINGENCY_PLAN_RISK_CONST.CONTINGENCY_PLAN_RISK_FAILED:
      return {
        ...state,
        isContingencyPlanRiskRequesting: false,
        contingencyPlanRiskListData: false,
        contingencyPlanRiskListDataError: action.payload.error ? false : action.payload.response,
        contingencyPlanRiskListDataNetworkError: action.payload.error
          ? action.payload.error
          : false,
      };

    case CONTINGENCY_PLAN_MONSOON_CONST.CONTINGENCY_PLAN_MONSOON_REQUEST:
      return {
        ...state,
        isContingencyPlanMonsoonRequesting: true,
        contingencyPlanMonsoonListData: false,
        contingencyPlanMonsoonListDataError: false,
        contingencyPlanMonsoonListDataNetworkError: false,
      };
    case CONTINGENCY_PLAN_MONSOON_CONST.CONTINGENCY_PLAN_MONSOON_SUCCESS:
      return {
        ...state,
        isContingencyPlanMonsoonRequesting: false,
        contingencyPlanMonsoonListData: action.payload.response,
        contingencyPlanMonsoonListDataError: false,
        contingencyPlanMonsoonListDataNetworkError: false,
      };
    case CONTINGENCY_PLAN_MONSOON_CONST.CONTINGENCY_PLAN_MONSOON_FAILED:
      return {
        ...state,
        isContingencyPlanMonsoonRequesting: false,
        contingencyPlanMonsoonListData: false,
        contingencyPlanMonsoonListDataError: action.payload.error ? false : action.payload.response,
        contingencyPlanMonsoonListDataNetworkError: action.payload.error
          ? action.payload.error
          : false,
      };

    case STAGE_CONST.STAGE_REQUEST:
      return {
        ...state,
        isContingencyPlanStageRequesting: true,
        contingencyPlanStageListData: false,
        contingencyPlanStageListDataError: false,
        contingencyPlanStageListDataNetworkError: false,
      };
    case STAGE_CONST.STAGE_SUCCESS:
      return {
        ...state,
        isContingencyPlanStageRequesting: false,
        contingencyPlanStageListData: action.payload.response,
        contingencyPlanStageListDataError: false,
        contingencyPlanStageListDataNetworkError: false,
      };
    case STAGE_CONST.STAGE_FAILED:
      return {
        ...state,
        isContingencyPlanStageRequesting: false,
        contingencyPlanStageListData: false,
        contingencyPlanStageListDataError: action.payload.error ? false : action.payload.response,
        contingencyPlanStageListDataNetworkError: action.payload.error
          ? action.payload.error
          : false,
      };

    case SYSTEM_CONST.SYSTEM_REQUEST:
      return {
        ...state,
        isContingencyPlanSystemRequesting: true,
        contingencyPlanSystemListData: false,
        contingencyPlanSystemListDataError: false,
        contingencyPlanSystemListDataNetworkError: false,
      };
    case SYSTEM_CONST.SYSTEM_SUCCESS:
      return {
        ...state,
        isContingencyPlanSystemRequesting: false,
        contingencyPlanSystemListData: action.payload.response,
        contingencyPlanSystemListDataError: false,
        contingencyPlanSystemListDataNetworkError: false,
      };
    case SYSTEM_CONST.SYSTEM_FAILED:
      return {
        ...state,
        isContingencyPlanSystemRequesting: false,
        contingencyPlanSystemListData: false,
        contingencyPlanSystemListDataError: action.payload.error ? false : action.payload.response,
        contingencyPlanSystemListDataNetworkError: action.payload.error
          ? action.payload.error
          : false,
      };

    case DROUGHT_INDICES_CONST.DROUGHT_INDICES_REQUEST:
      return {
        ...state,
        isContingencyPlanDroughtIndicesRequesting: true,
        contingencyPlanDroughtIndicesListData: false,
        contingencyPlanDroughtIndicesListDataError: false,
        contingencyPlanDroughtIndicesListDataNetworkError: false,
      };
    case DROUGHT_INDICES_CONST.DROUGHT_INDICES_SUCCESS:
      return {
        ...state,
        isContingencyPlanDroughtIndicesRequesting: false,
        contingencyPlanDroughtIndicesListData: action.payload.response,
        contingencyPlanDroughtIndicesListDataError: false,
        contingencyPlanDroughtIndicesListDataNetworkError: false,
      };
    case DROUGHT_INDICES_CONST.DROUGHT_INDICES_FAILED:
      return {
        ...state,
        isContingencyPlanDroughtIndicesRequesting: false,
        contingencyPlanDroughtIndicesListData: false,
        contingencyPlanDroughtIndicesListDataError: action.payload.error
          ? false
          : action.payload.response,
        contingencyPlanDroughtIndicesListDataNetworkError: action.payload.error
          ? action.payload.error
          : false,
      };

    case STRATEGY_CONST.STRATEGY_REQUEST:
      return {
        ...state,
        isContingencyPlanStrategyRequesting: true,
        contingencyPlanStrategyListData: false,
        contingencyPlanStrategyListDataError: false,
        contingencyPlanStrategyListDataNetworkError: false,
      };
    case STRATEGY_CONST.STRATEGY_SUCCESS:
      return {
        ...state,
        isContingencyPlanStrategyRequesting: false,
        contingencyPlanStrategyListData: action.payload.response,
        contingencyPlanStrategyListDataError: false,
        contingencyPlanStrategyListDataNetworkError: false,
      };
    case STRATEGY_CONST.STRATEGY_FAILED:
      return {
        ...state,
        isContingencyPlanStrategyRequesting: false,
        contingencyPlanStrategyListData: false,
        contingencyPlanStrategyListDataError: action.payload.error
          ? false
          : action.payload.response,
        contingencyPlanStrategyListDataNetworkError: action.payload.error
          ? action.payload.error
          : false,
      };

    case DROUGHT_STAGES_TIME_PERIOD_CONST.DROUGHT_STAGES_TIME_PERIOD_REQUEST:
      return {
        ...state,
        isContingencyPlanTimePeriodRequesting: true,
        contingencyPlanTimePeriodData: false,
        contingencyPlanTimePeriodDataError: false,
        contingencyPlanTimePeriodDataNetworkError: false,
      };
    case DROUGHT_STAGES_TIME_PERIOD_CONST.DROUGHT_STAGES_TIME_PERIOD_SUCCESS:
      return {
        ...state,
        isContingencyPlanTimePeriodRequesting: false,
        contingencyPlanTimePeriodData: action.payload.response,
        contingencyPlanTimePeriodDataError: false,
        contingencyPlanTimePeriodDataNetworkError: false,
      };
    case DROUGHT_STAGES_TIME_PERIOD_CONST.DROUGHT_STAGES_TIME_PERIOD_FAILED:
      return {
        ...state,
        isContingencyPlanTimePeriodRequesting: false,
        contingencyPlanTimePeriodData: false,
        contingencyPlanTimePeriodDataError: action.payload.error ? false : action.payload.response,
        contingencyPlanTimePeriodDataNetworkError: action.payload.error
          ? action.payload.error
          : false,
      };

    case CATEGORY_CONST.CATEGORY_REQUEST:
      return {
        ...state,
        isContingencyPlanCategoryRequesting: true,
        contingencyPlanCategoryData: false,
        contingencyPlanCategoryDataError: false,
        contingencyPlanCategoryDataNetworkError: false,
      };
    case CATEGORY_CONST.CATEGORY_SUCCESS:
      return {
        ...state,
        isContingencyPlanCategoryRequesting: false,
        contingencyPlanCategoryData: action.payload.response,
        contingencyPlanCategoryDataError: false,
        contingencyPlanCategoryDataNetworkError: false,
      };
    case CATEGORY_CONST.CATEGORY_FAILED:
      return {
        ...state,
        isContingencyPlanCategoryRequesting: false,
        contingencyPlanCategoryData: false,
        contingencyPlanCategoryDataError: action.payload.error ? false : action.payload.response,
        contingencyPlanCategoryDataNetworkError: action.payload.error
          ? action.payload.error
          : false,
      };

    case SUB_CATEGORY_CONST.SUB_CATEGORY_REQUEST:
      return {
        ...state,
        isContingencyPlanSubCategoryRequesting: true,
        contingencyPlanSubCategoryData: false,
        contingencyPlanSubCategoryDataError: false,
        contingencyPlanSubCategoryDataNetworkError: false,
      };
    case SUB_CATEGORY_CONST.SUB_CATEGORY_SUCCESS:
      return {
        ...state,
        isContingencyPlanSubCategoryRequesting: false,
        contingencyPlanSubCategoryData: action.payload.response,
        contingencyPlanSubCategoryDataError: false,
        contingencyPlanSubCategoryDataNetworkError: false,
      };
    case SUB_CATEGORY_CONST.SUB_CATEGORY_FAILED:
      return {
        ...state,
        isContingencyPlanSubCategoryRequesting: false,
        contingencyPlanSubCategoryData: false,
        contingencyPlanSubCategoryDataError: action.payload.error ? false : action.payload.response,
        contingencyPlanSubCategoryDataNetworkError: action.payload.error
          ? action.payload.error
          : false,
      };

    case DISTRICT_PROFILE_CONST.DISTRICT_PROFILE_REQUEST:
      return {
        ...state,
        isContingencyPlanDistrictProfileRequesting: true,
        contingencyPlanDistrictProfileData: false,
        contingencyPlanDistrictProfileView: '',
        contingencyPlanDistrictProfileDataError: false,
        contingencyPlanDistrictProfileDataNetworkError: false,
      };
    case DISTRICT_PROFILE_CONST.DISTRICT_PROFILE_SUCCESS:
      return {
        ...state,
        isContingencyPlanDistrictProfileRequesting: false,
        contingencyPlanDistrictProfileData: action.payload.response,
        contingencyPlanDistrictProfileView: '',
        contingencyPlanDistrictProfileDataError: false,
        contingencyPlanDistrictProfileDataNetworkError: false,
      };
    case DISTRICT_PROFILE_CONST.DISTRICT_PROFILE_FAILED:
      return {
        ...state,
        isContingencyPlanDistrictProfileRequesting: false,
        contingencyPlanDistrictProfileData: false,
        contingencyPlanDistrictProfileView: '',
        contingencyPlanDistrictProfileDataError: action.payload.error
          ? false
          : action.payload.response,
        contingencyPlanDistrictProfileDataNetworkError: action.payload.error
          ? action.payload.error
          : false,
      };

    case CONTINGENCY_MEASURES_CROP_TYPE_CONST.CONTINGENCY_MEASURES_CROP_TYPE_REQUEST:
      return {
        ...state,
        isContingencyPlanCropTypeRequesting: true,
        contingencyPlanCropTypeData: false,
        contingencyPlanCropTypeDataError: false,
        contingencyPlanCropTypeDataNetworkError: false,
      };
    case CONTINGENCY_MEASURES_CROP_TYPE_CONST.CONTINGENCY_MEASURES_CROP_TYPE_SUCCESS:
      return {
        ...state,
        isContingencyPlanCropTypeRequesting: false,
        contingencyPlanCropTypeData: action.payload.response,
        contingencyPlanCropTypeDataError: false,
        contingencyPlanCropTypeDataNetworkError: false,
      };
    case CONTINGENCY_MEASURES_CROP_TYPE_CONST.CONTINGENCY_MEASURES_CROP_TYPE_FAILED:
      return {
        ...state,
        isContingencyPlanCropTypeRequesting: false,
        contingencyPlanCropTypeData: false,
        contingencyPlanCropTypeDataError: action.payload.error ? false : action.payload.response,
        contingencyPlanCropTypeDataNetworkError: action.payload.error
          ? action.payload.error
          : false,
      };

    case CONTINGENCY_MEASURES_DATA_CONST.CONTINGENCY_MEASURES_DATA_REQUEST:
      return {
        ...state,
        isContingencyPlanMeasuresDataRequesting: true,
        contingencyPlanMeasuresData: false,
        contingencyPlanMeasuresDataError: false,
        contingencyPlanMeasuresDataNetworkError: false,
      };
    case CONTINGENCY_MEASURES_DATA_CONST.CONTINGENCY_MEASURES_DATA_SUCCESS:
      return {
        ...state,
        isContingencyPlanMeasuresDataRequesting: false,
        contingencyPlanMeasuresData: action.payload.response,
        contingencyPlanMeasuresDataError: false,
        contingencyPlanMeasuresDataNetworkError: false,
      };
    case CONTINGENCY_MEASURES_DATA_CONST.CONTINGENCY_MEASURES_DATA_FAILED:
      return {
        ...state,
        isContingencyPlanMeasuresDataRequesting: false,
        contingencyPlanMeasuresData: false,
        contingencyPlanMeasuresDataError: action.payload.error ? false : action.payload.response,
        contingencyPlanMeasuresDataNetworkError: action.payload.error
          ? action.payload.error
          : false,
      };

      case CONTINGENCY_DISTRICT_GRAPH_CONST.CONTINGENCY_DISTRICT_GRAPH_REQUEST:
        return {
          ...state,
         isContingencyDistrictGraphRequesting: true,
         contingencyDistrictGraphData: false,
         contingencyDistrictGraphDataError: false,
         contingencyDistrictGraphDataNetworkError: false,
        };
      case CONTINGENCY_DISTRICT_GRAPH_CONST.CONTINGENCY_DISTRICT_GRAPH_SUCCESS:
        return {
          ...state,
          isContingencyDistrictGraphRequesting: false,
         contingencyDistrictGraphData: action.payload.response,
         contingencyDistrictGraphDataError: false,
         contingencyDistrictGraphDataNetworkError: false,
        };
      case CONTINGENCY_DISTRICT_GRAPH_CONST.CONTINGENCY_DISTRICT_GRAPH_FAILED:
        return {
          ...state,
          isContingencyDistrictGraphRequesting: false,
         contingencyDistrictGraphData: false,
         contingencyDistrictGraphDataError: action.payload.error ? false : action.payload.response,
         contingencyDistrictGraphDataNetworkError: action.payload.error
         ? action.payload.error
         : false,
        };

    default:
      return { ...state };
  }
};

export default ContingencyPlan;
