export const REGISTER_CONST = {
    REGISTER_REQUEST: 'REGISTER_REQUEST',
    REGISTER_SUCCESS: 'REGISTER_SUCCESS',
    REGISTER_FAILED: 'REGISTER_FAILED',
  };

export const VERIFICATION_CONST = {
  VERIFICATION_REQUEST: 'VERIFICATION_REQUEST',
  VERIFICATION_SUCCESS: 'VERIFICATION_SUCCESS',
  VERIFICATION_FAILED: 'VERIFICATION_FAILED',
}
  
  