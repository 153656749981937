import React, { useEffect, useMemo, useState } from 'react';
import { useTable, usePagination, useSortBy, useFilters, useGlobalFilter } from 'react-table';
import PropTypes from 'prop-types';
import DisabledLeftArrowIcon from '../../assets/Images/disable_previous_image.svg';
import DisabledRightArrowIcon from '../../assets/Images/disable_next_image.svg';
import EnabledLeftArrowIcon from '../../assets/Images/enable_previous_image.svg';
import EnabledRightArrowIcon from '../../assets/Images/enable_next_image.svg';
import { useTranslation } from 'react-multi-lang';
import { Row, Col, Table } from 'reactstrap';
import { isSuperAdmin } from '../../Components/common/utils';

export const TableList = (props) => {
  const { loading = true, userDetailData } = props;
  const t = useTranslation();
  const notAvailable = 'N/A';

  const COLUMNS = [
    {
      Header: 'Crop Season',
      accessor: (row) => (row.season_name ? row.season_name : notAvailable),
      sticky: 'top',
    },
    {
      Header: 'From(dd/mm)',
      accessor: (row) =>
        row.start_month
          ? (row.start_day.length <= 1 ? '0' + row.start_day : row.start_day) +
            '/' +
            (row.start_month.length <= 1 ? '0' + row.start_month : row.start_month)
          : notAvailable,
      sticky: 'top',
    },
    {
      Header: 'To(dd/mm)',
      accessor: (row) =>
        row.end_month
          ? (row.end_day.length <= 1 ? '0' + row.end_day : row.end_day) +
            '/' +
            (row.end_month.length <= 1 ? '0' + row.end_month : row.end_month)
          : notAvailable,
      sticky: 'top',
    },
    {
      Header: 'Location',
      accessor: (row) => (row.location_name ? row.location_name : notAvailable),
      sticky: 'top',
    },
  ];

  const columns = useMemo(() => COLUMNS, []);
  const data = useMemo(() => props.req_data, [props.req_data]);
  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
  );

  const pageSizeHandler = (e) => {
    setPageSize(Number(e.target.value));
    props.handlePageSize(Number(e.target.value));
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    state,
    setPageSize,
    setAllFilters,
    prepareRow,
  } = tableInstance;
  const { pageSize } = state;
  const page_range = [10, 15, 20, 25];

  const handlepreviousPage = (value) => {
    props.handlePageNumber(props.pageNumber - 1);
  };
  const handlenextPage = (value) => {
    props.handlePageNumber(props.pageNumber + 1);
  };
  const handleGoToPage = (e) => {
    props.handlePageNumber(Number(e.target.value));
  };
  const handleEditClick = (value) => {
    props.handleRowClick(value.original);
  };

  const handleDeleteClick = (value) => {
    props.handleDeleteById(value.original);
  };

  return (
    <>
      {/* <div className=""> */}
      <Table {...getTableProps()} className="table-list crop-table-list">
        <thead className="table-list-th">
          {headerGroups.map((headerGroup) => (
            <>
              <tr {...headerGroup.getHeaderGroupProps()} className="user-list-keys">
                {headerGroup.headers.map((column) => {
                  return (
                    <>
                      <th
                        {...column.getHeaderProps(column.getSortByToggleProps())}
                        className="name-list manage-crop-header-cell"
                      >
                        {column.render('Header')}
                        <span className="sort-icons">
                          {!column.notShowSortingDisplay ? (
                            column.isSorted ? (
                              column.isSortedDesc ? (
                                <>
                                  <i class="fa fa-sort-down"></i>
                                </>
                              ) : (
                                <>
                                  <i class="fa fa-sort-up"></i>
                                </>
                              )
                            ) : (
                              <i class="fa fa-sort"></i>
                            )
                          ) : (
                            ''
                          )}
                        </span>
                      </th>
                    </>
                  );
                })}
                <th className="manage-crop-header-cell">&nbsp;</th>
              </tr>
            </>
          ))}
        </thead>
        {!loading ? (
          <tbody className="table-body manage-crop-body">
            <tr className="crop-tr-items">
              <td colSpan="10000" style={{ width: '20%' }} className="crop-td-items ">
                <img src="" alt="Loading..." />
              </td>
            </tr>
          </tbody>
        ) : (
          <>
            {!page.length ? (
              <tbody className="manage-crop-body">
                <div className="no_records_label">{t('MANAGE_CROP.NO_RECORDS_FOUND')}</div>
              </tbody>
            ) : (
              <tbody
                {...getTableBodyProps()}
                className="body table-wrapper-scroll-y my-custom-scrollbar manage-crop-body"
              >
                {page.map((row) => {
                  prepareRow(row);
                  return (
                    <>
                      <tr
                        {...row.getRowProps()}
                        className="user-list-details"
                        style={{ marginBottom: '7px' }}
                      >
                        {row.cells.map((cell) => {
                          return (
                            <>
                              {cell.column.Header === 'Location' ? (
                                <>
                                  <td
                                    {...cell.getCellProps()}
                                    style={{
                                      width: '20%',
                                      paddingTop: '15px',
                                      paddingBottom: '15px',
                                    }}
                                    className=" name-list-details e-div"
                                  >
                                    {cell.render('Cell')}
                                  </td>
                                  <td
                                    style={{
                                      width: '20%',
                                      paddingTop: '2px',
                                      paddingTop: '5px',
                                      paddingBottom: '5px',
                                    }}
                                    className=""
                                  >
                                    {(isSuperAdmin() ||
                                      userDetailData?.includes('change_crop')) && (
                                      <button
                                        className="crop-edit-button"
                                        onClick={() => handleEditClick(row)}
                                      >
                                        {t('MANAGE_CROP.EDIT')}
                                      </button>
                                    )}
                                    {(isSuperAdmin() ||
                                      userDetailData?.includes('delete_crop')) && (
                                      <button
                                        className="crop-delete-button"
                                        onClick={() => handleDeleteClick(row)}
                                      >
                                        {t('MANAGE_CROP.DELETE')}
                                      </button>
                                    )}
                                  </td>
                                </>
                              ) : (
                                <td
                                  {...cell.getCellProps()}
                                  className=" name-list-details e-div"
                                  style={{
                                    width: '20%',
                                    paddingTop: '15px',
                                    paddingBottom: '15px',
                                  }}
                                >
                                  {cell.render('Cell')}
                                </td>
                              )}
                            </>
                          );
                        })}
                      </tr>
                    </>
                  );
                })}
              </tbody>
            )}
          </>
        )}
        <div className="pagination-section">
          <Row>
            <Col md="6" lg="6">
              <span className="item-text">{t('PUBLIC_API_ADMIN.ITEMS_PER_PAGE')}</span>
              <select value={pageSize} onChange={(e) => pageSizeHandler(e)} className="page-count">
                {page_range.map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    {pageSize}
                  </option>
                ))}
              </select>
              <span className="num-of-items">
                {(props.pageNumber - 1) * pageSize + 1}-
                {props.pageCount < props.pageNumber * pageSize
                  ? props.pageCount
                  : props.pageNumber * pageSize}{' '}
                of {props.pageCount ? props.pageCount : ''} items
              </span>
            </Col>
            <Col md="6" lg="6">
              <div className="pagination-right-col">
                <span>
                  <input
                    type="number"
                    value={props.pageNumber}
                    onChange={(e) => {
                      handleGoToPage(e);
                    }}
                    disabled={
                      (props.pageCount % pageSize
                        ? Math.trunc(props.pageCount / pageSize + 1)
                        : Math.trunc(props.pageCount / pageSize)) === props.pageNumber ||
                      (props.pageCount % pageSize
                        ? Math.trunc(props.pageCount / pageSize + 1)
                        : Math.trunc(props.pageCount / pageSize)) === 0
                        ? true
                        : false
                    }
                    style={{ width: '50px', border: 'none' }}
                  />
                </span>
                <span>
                  of{' '}
                  {props.pageCount % pageSize
                    ? Math.trunc(props.pageCount / pageSize + 1)
                    : Math.trunc(props.pageCount / pageSize)}{' '}
                  Pages
                </span>
                <span>
                  <button
                    onClick={() => handlepreviousPage()}
                    className="previous-btn"
                    disabled={props.pageNumber <= 1 ? true : false}
                  >
                    {props.pageNumber <= 1 ? (
                      <img src={DisabledLeftArrowIcon} />
                    ) : (
                      <img src={EnabledLeftArrowIcon} />
                    )}
                  </button>
                  <button
                    onClick={() => handlenextPage()}
                    className="next-btn"
                    disabled={
                      (props.pageCount % pageSize
                        ? Math.trunc(props.pageCount / pageSize + 1)
                        : Math.trunc(props.pageCount / pageSize)) === props.pageNumber ||
                      (props.pageCount % pageSize
                        ? Math.trunc(props.pageCount / pageSize + 1)
                        : Math.trunc(props.pageCount / pageSize)) === 0
                        ? true
                        : false
                    }
                  >
                    {(props.pageCount % pageSize
                      ? Math.trunc(props.pageCount / pageSize + 1)
                      : Math.trunc(props.pageCount / pageSize)) === props.pageNumber ||
                    (props.pageCount % pageSize
                      ? Math.trunc(props.pageCount / pageSize + 1)
                      : Math.trunc(props.pageCount / pageSize)) === 0 ? (
                      <img src={DisabledRightArrowIcon} />
                    ) : (
                      <img src={EnabledRightArrowIcon} />
                    )}
                  </button>
                </span>
              </div>
            </Col>
          </Row>
        </div>
      </Table>
      {/* </div> */}
    </>
  );
};
TableList.propTypes = {
  req_data: PropTypes.array,
  handleLatestData: PropTypes.func,
  latestData: PropTypes.func,
  handlePageSize: PropTypes.func,
  pageCount: PropTypes.number,
  handlePageNumber: PropTypes.func,
  pageNumber: PropTypes.number,
  recordsCount: PropTypes.number,
  activeTab: PropTypes.string,
};
