import HOME_CONST from './homeConsts';

const HOME = (state = {}, action) => {
    switch(action.type) {
        case HOME_CONST.GET_BANNERS_REQUEST: 
            return {
                ...state,
                isGetBannersDataRequesting: true,
                getBannersData: false,
                getBannersDataError: false,
                getBannersDataNetworkError: false,
            }
        case HOME_CONST.GET_BANNERS_SUCCESS: 
            return { 
                ...state,
                isGetBannersDataRequesting: false,
                getBannersData: action.payload.response,
                getBannersDataError: false,
                getBannersDataNetworkError: false,
            }
        case HOME_CONST.GET_BANNERS_FAILED: 
            return {
                ...state,
                isGetBannersDataRequesting: false,
                getBannersData: false,
                getBannersDataError:  action.payload ? action.payload : false,
                getBannersDataNetworkError: action.payload.netWorkError ? action.payload.statusText : false,
            }
        case HOME_CONST.GET_PARAMETERS_REQUEST: 
            return {
                ...state,
                isGetParametersDataRequesting: true,
                getParametersData: false,
                getParametersDataError: false,
                getParametersDataNetworkError: false,
            }
        case HOME_CONST.GET_PARAMETERS_SUCCESS: 
            return { 
                ...state,
                isGetParametersDataRequesting: false,
                getParametersData: action.payload.response,
                getParametersDataError: false,
                getParametersDataNetworkError: false,
            }
        case HOME_CONST.GET_PARAMETERS_FAILED: 
            return {
                ...state,
                isGetParametersDataRequesting: false,
                getParametersData: false,
                getParametersDataError:  action.payload ? action.payload : false,
                getParametersDataNetworkError: action.payload.netWorkError ? action.payload.statusText : false,
            }
        default: 
          return state;
    }
};

export default HOME;