import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { AXIOS_INSTANCE, NEWS_FEED_URL } from '../api/apiEndPoint';
import {
  NEWS_FEED_CATEGORIES_CONST,
  NEWS_FEED_SUB_CATEGORIES_CONST,
  NEWS_FEED_ADD_CONST,
  GET_ALL_NEWS_FEED_CONST,
  NEWS_FEED_DELETE_CONST,
  NEWS_FEED_UPDATE_CONST,
  GET_BY_ID_NEWS_FEED_CONST,
  NEWS_FEED_SUBSCRIBE_CONST,
  NEWS_FEED_CHANGE_STATUS_CONST,
} from './newsFeedConsts';
import {
  newsFeedCategoriesSuccess,
  newsFeedCategoriesFailed,
  newsFeedSubCategoriesSuccess,
  newsFeedSubCategoriesFailed,
  newsFeedAddSuccess,
  newsFeedAddFailed,
  getAllNewsFeedSuccess,
  getAllNewsFeedFailed,
  newsFeedDeleteSuccess,
  newsFeedDeleteFailed,
  newsFeedUpdateSuccess,
  newsFeedUpdateFailed,
  getByIdNewsFeedSuccess,
  getByIdNewsFeedFailed,
  subscribeNewsFeedSuccess,
  subscribeNewsFeedFailed,
  newsFeedChangeStatusSuccess,
  newsFeedChangeStatusFailed,
} from './newsFeedAction';

require('dotenv').config();
const newsFeedCategoriesApiRequest = async payload => {
  let response = {};
  try {
    const apiResponseData = await AXIOS_INSTANCE.get(`${NEWS_FEED_URL}/getCategories`, payload);
    response = {
      data: apiResponseData.data,
      status: 200,
    };
  } catch (error) {
    const data = error;
    response = {
      error: data,
      status: 400,
    };
  }
  return response;
};

const newsFeedSubCategoriesApiRequest = async payload => {
  let response = {};
  try {
    const apiResponseData = await AXIOS_INSTANCE.get(
      `${NEWS_FEED_URL}/getSubCategories/${payload.category_id}`,
      payload,
    );
    response = {
      data: apiResponseData.data,
      status: 200,
    };
  } catch (error) {
    const data = error;
    response = {
      error: data,
      status: 400,
    };
  }
  return response;
};

const newsFeedAddApiRequest = async payload => {
  let response = {};
  try {
    const apiResponseData = await AXIOS_INSTANCE.post(`${NEWS_FEED_URL}/add`, payload);
    response = {
      data: apiResponseData.data,
      status: 200,
    };
  } catch (error) {
    const data = error;
    response = {
      error: data,
      status: 400,
    };
  }
  return response;
};

const getAllNewsFeedApiRequest = async payload => {
  let response = {};
  try {
    const apiResponseData = await AXIOS_INSTANCE.post(
      `${NEWS_FEED_URL}/getAllByCriteria${payload.page ? `?page=${payload.page}` : ''}`,
      payload.data,
    );
    response = {
      data: apiResponseData.data,
      status: 200,
    };
  } catch (error) {
    const data = error;
    response = {
      error: data,
      status: 400,
    };
  }
  return response;
};

const newsFeedDeleteApiRequest = async payload => {
  let response = {};
  try {
    const apiResponseData = await AXIOS_INSTANCE.delete(
      `${NEWS_FEED_URL}/delete/${payload.newsfeed_id}`,
    );
    response = {
      data: apiResponseData.data,
      status: 200,
    };
  } catch (error) {
    const data = error;
    response = {
      error: data,
      status: 400,
    };
  }
  return response;
};

const newsFeedUpdateApiRequest = async payload => {
  let response = {};
  try {
    const apiResponseData = await AXIOS_INSTANCE.put(`${NEWS_FEED_URL}/update`, payload);
    response = {
      data: apiResponseData.data,
      status: 200,
    };
  } catch (error) {
    const data = error;
    response = {
      error: data,
      status: 400,
    };
  }
  return response;
};

const getByIdNewsFeedApiRequest = async payload => {
  let response = {};
  try {
    const apiResponseData = await AXIOS_INSTANCE.get(
      `${NEWS_FEED_URL}/getById/${payload.newsfeed_id}`,
    );
    response = {
      data: apiResponseData.data,
      status: 200,
    };
  } catch (error) {
    const data = error;
    response = {
      error: data,
      status: 400,
    };
  }
  return response;
};

const subscribeNewsFeedApiRequest = async payload => {
  let response = {};
  try {
    const apiResponseData = await AXIOS_INSTANCE.post(`${NEWS_FEED_URL}/subscription`, payload);
    response = {
      data: apiResponseData.data,
      status: 200,
    };
  } catch (error) {
    const data = error;
    response = {
      error: data,
      status: 400,
    };
  }
  return response;
};

const newsFeedChangeStatusApiRequest = async payload => {
  let response = {};
  try {
    const apiResponseData = await AXIOS_INSTANCE.put(`${NEWS_FEED_URL}/changeStatus`, payload);
    response = {
      data: apiResponseData.data,
      status: 200,
    };
  } catch (error) {
    const data = error;
    response = {
      error: data,
      status: 400,
    };
  }
  return response;
};

function* newsFeedCategoriesRequest(action) {
  try {
    const apiResponse = yield call(newsFeedCategoriesApiRequest, action.payload);
    if (apiResponse.status === 200) {
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.data,
        },
      };
      yield put(newsFeedCategoriesSuccess(responseData));
    } else {
      const responseData = {
        response: {
          statusCode: apiResponse.status,
          data: apiResponse.error,
        },
      };
      yield put(newsFeedCategoriesFailed(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(newsFeedCategoriesFailed(errorData));
  }
}

function* newsFeedSubCategoriesRequest(action) {
  try {
    const apiResponse = yield call(newsFeedSubCategoriesApiRequest, action.payload);
    if (apiResponse.status === 200) {
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.data,
        },
      };
      yield put(newsFeedSubCategoriesSuccess(responseData));
    } else {
      const responseData = {
        response: {
          statusCode: apiResponse.status,
          data: apiResponse.error,
        },
      };
      yield put(newsFeedSubCategoriesFailed(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(newsFeedSubCategoriesFailed(errorData));
  }
}

function* newsFeedAddRequest(action) {
  try {
    const apiResponse = yield call(newsFeedAddApiRequest, action.payload);
    if (apiResponse.status === 200) {
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.data,
        },
      };
      yield put(newsFeedAddSuccess(responseData));
    } else {
      const responseData = {
        response: {
          statusCode: apiResponse.status,
          data: apiResponse.error,
        },
      };
      yield put(newsFeedAddFailed(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(newsFeedAddFailed(errorData));
  }
}

function* getAllNewsFeedRequest(action) {
  try {
    const apiResponse = yield call(getAllNewsFeedApiRequest, action.payload);
    if (apiResponse.status === 200) {
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.data,
        },
      };
      yield put(getAllNewsFeedSuccess(responseData));
    } else {
      const responseData = {
        response: {
          statusCode: apiResponse.status,
          data: apiResponse.error,
        },
      };
      yield put(getAllNewsFeedFailed(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(getAllNewsFeedFailed(errorData));
  }
}

function* newsFeedDeleteRequest(action) {
  try {
    const apiResponse = yield call(newsFeedDeleteApiRequest, action.payload);
    if (apiResponse.status === 200) {
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.data,
        },
      };
      yield put(newsFeedDeleteSuccess(responseData));
    } else {
      const responseData = {
        response: {
          statusCode: apiResponse.status,
          data: apiResponse.error,
        },
      };
      yield put(newsFeedDeleteFailed(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(newsFeedDeleteFailed(errorData));
  }
}

function* newsFeedUpdateRequest(action) {
  try {
    const apiResponse = yield call(newsFeedUpdateApiRequest, action.payload);
    if (apiResponse.status === 200) {
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.data,
        },
      };
      yield put(newsFeedUpdateSuccess(responseData));
    } else {
      const responseData = {
        response: {
          statusCode: apiResponse.status,
          data: apiResponse.error,
        },
      };
      yield put(newsFeedUpdateFailed(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(newsFeedUpdateFailed(errorData));
  }
}

function* getByIdNewsFeedRequest(action) {
  try {
    const apiResponse = yield call(getByIdNewsFeedApiRequest, action.payload);
    if (apiResponse.status === 200) {
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.data,
        },
      };
      yield put(getByIdNewsFeedSuccess(responseData));
    } else {
      const responseData = {
        response: {
          statusCode: apiResponse.status,
          data: apiResponse.error,
        },
      };
      yield put(getByIdNewsFeedFailed(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(getByIdNewsFeedFailed(errorData));
  }
}

function* subscribeNewsFeedRequest(action) {
  try {
    const apiResponse = yield call(subscribeNewsFeedApiRequest, action.payload);
    if (apiResponse.status === 200) {
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.data,
        },
      };
      yield put(subscribeNewsFeedSuccess(responseData));
    } else {
      const responseData = {
        response: {
          statusCode: apiResponse.status,
          data: apiResponse.error,
        },
      };
      yield put(subscribeNewsFeedFailed(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(subscribeNewsFeedFailed(errorData));
  }
}

function* newsFeedChangeStatusRequest(action) {
  try {
    const apiResponse = yield call(newsFeedChangeStatusApiRequest, action.payload);
    if (apiResponse.status === 200) {
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.data,
        },
      };
      yield put(newsFeedChangeStatusSuccess(responseData));
    } else {
      const responseData = {
        response: {
          statusCode: apiResponse.status,
          data: apiResponse.error,
        },
      };
      yield put(newsFeedChangeStatusFailed(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(newsFeedChangeStatusFailed(errorData));
  }
}

export function* watchNewsFeedSagaSaga() {
  yield takeEvery(
    NEWS_FEED_CATEGORIES_CONST.NEWS_FEED_CATEGORIES_REQUEST,
    newsFeedCategoriesRequest,
  );
  yield takeEvery(
    NEWS_FEED_SUB_CATEGORIES_CONST.NEWS_FEED_SUB_CATEGORIES_REQUEST,
    newsFeedSubCategoriesRequest,
  );
  yield takeEvery(NEWS_FEED_ADD_CONST.NEWS_FEED_ADD_REQUEST, newsFeedAddRequest);
  yield takeEvery(GET_ALL_NEWS_FEED_CONST.GET_ALL_NEWS_FEED_REQUEST, getAllNewsFeedRequest);
  yield takeEvery(NEWS_FEED_DELETE_CONST.NEWS_FEED_DELETE_REQUEST, newsFeedDeleteRequest);
  yield takeEvery(NEWS_FEED_UPDATE_CONST.NEWS_FEED_UPDATE_REQUEST, newsFeedUpdateRequest);
  yield takeEvery(GET_BY_ID_NEWS_FEED_CONST.GET_BY_ID_NEWS_FEED_REQUEST, getByIdNewsFeedRequest);
  yield takeEvery(NEWS_FEED_SUBSCRIBE_CONST.NEWS_FEED_SUBSCRIBE_REQUEST, subscribeNewsFeedRequest);
  yield takeEvery(
    NEWS_FEED_CHANGE_STATUS_CONST.NEWS_FEED_CHANGE_STATUS_REQUEST,
    newsFeedChangeStatusRequest,
  );
}

function* newsFeedSaga() {
  yield all([fork(watchNewsFeedSagaSaga)]);
}

export default newsFeedSaga;
