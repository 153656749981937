import React, { useState } from 'react';
import { Nav, NavItem, NavLink } from 'reactstrap';
import PropTypes from 'prop-types';
import { t } from 'react-multi-lang';
import { SWAGGER_JSON_FILE_URL_LIST } from './constants/index';

const Apicategories = (props) => {
  const {handleApiCategories} = props;
  const [activeTab, setActiveTab] = useState('1');
  const toggle = (tab) => {
    SWAGGER_JSON_FILE_URL_LIST.map((i) => {
        if(i.id === tab){
          setActiveTab(tab);
          handleApiCategories(i.id)
        }
      })
  }

  return (
    <div className="api-categories-section">
        <h3>{t('PUBLIC_API.API_DIRECTORIES')}</h3>
      <Nav className="api-categories-list">
        {SWAGGER_JSON_FILE_URL_LIST.map((i,index) => {
          return (
          <NavItem className="api-categories-item" key={index}>
          <NavLink
            className={activeTab === i.id ? "active" : ""}
            onClick={() =>  toggle(i.id)}
          >
           {i.name}
          </NavLink>
        </NavItem>
          )
        })}
      </Nav>
    </div>
  );
}

export default Apicategories;
Apicategories.propTypes = {
  handleApiCategories: PropTypes.func,
};
