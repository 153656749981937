import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-multi-lang';
import { FormGroup, Input, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Button from 'reactstrap-button-loader';
import { changeStatusRequest } from '../../redux/accessKey/accessKeyActions';
import _ from 'lodash';

const ReasonModal = props => {
  const {
    className,
    isOpen,
    modalData,
    handleLatestData,
    handleRejectModal,
    handleModalToggle,
  } = props;
  const dispatch = useDispatch();
  const t = useTranslation();
  const [textAreaData, setTextAreaData] = useState('');
  const [requestData, setRequestData] = useState();
  const nextProps = useSelector(state => ({
    changeStatusData: state.AccessKey.changeStatusData || '',
    isChangeStatusRequesting: state.AccessKey.changeStatusRequesting,
  }));
  useEffect(() => {
    setRequestData(modalData);
  }, [isOpen]);

  function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }

  const currentprops = nextProps.changeStatusData && nextProps.changeStatusData.data;
  const previousprops = usePrevious(currentprops);

  const isSecondRun = useRef(true);

  useEffect(() => {
    if (isSecondRun.current) {
      isSecondRun.current = false;
      return;
    }
    if (currentprops && currentprops !== previousprops) {
      if (currentprops) {
        handleRejectModal(false);
        handleModalToggle(false);
        handleLatestData('reject');
      }
    }
  }, [currentprops, previousprops]);

  const toggle = value => {
    if (value === 'cancel') {
      handleRejectModal(false);
    } else if (value === 'reject') {
      requestData.reject_response = textAreaData;
      dispatch(changeStatusRequest(requestData));
    }
  };

  const handleClose = () => {
    handleRejectModal(false);
  };

  return (
    <div>
      <Modal isOpen={isOpen} toggle={toggle} className={className}>
        <ModalHeader toggle={() => handleClose()} className="reason-modal-header">
          {t('PUBLIC_API_ADMIN.REASON_TITLE')}
        </ModalHeader>
        <ModalBody>
          <FormGroup>
            <Input
              type="textarea"
              name="text"
              id="exampleText"
              className="reason-modal-textarea"
              placeholder="Write a reason for rejection."
              onChange={e => setTextAreaData(e.target.value)}
            />
          </FormGroup>
        </ModalBody>
        <ModalFooter className="reason-modal">
          <Button color="secondary" onClick={() => toggle('cancel')} className="api-cancel-button">
            {t('PUBLIC_API_ADMIN.CANCEL')}
          </Button>{' '}
          <Button
            color="primary"
            loading={nextProps.isChangeStatusRequesting}
            onClick={() => toggle('reject')}
            // className="modal-reject-button"
            className="api-modal-reject-button"
            disabled={_.isEmpty(textAreaData.replace(/\s/g, ''))}
          >
            {t('PUBLIC_API_ADMIN.REJECT')}
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default ReasonModal;
ReasonModal.propTypes = {
  isOpen: PropTypes.bool,
  modalData: PropTypes.object,
  handleLatestData: PropTypes.func,
  handleRejectModal: PropTypes.func,
  handleModalToggle: PropTypes.func,
};
