import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Container, Row, Col, Card } from 'reactstrap';
import { t } from 'react-multi-lang';
import '../../assets/CSS/homePage.css';
import WeatherForecast from '../../assets/Images/weather-forecast.svg';
import DraughtMonitor from '../../assets/Images/drought-monitor.svg';
import Reporting from '../../assets/Images/reporting.svg';
import PublicApi from '../../assets/Images/public-api.svg';
import NewsFeed from '../../assets/Images/news-feed-latest.svg';
import DigitalSurvey from '../../assets/Images/digital-survay.svg';
import UserGuide from '../../assets/Images/user-guide.svg';
import Carousel from './carousal';
import Footer from './footer';
import { getBannersRequest } from '../../redux/home/homeActions';
import { getToken } from '../../Components/common/utils';

const HomePage = props => {
  const dispatch = useDispatch();
  let history = useHistory();
  const [BannersData, setBannersData] = useState([]);

  const { Banners } = useSelector(state => ({
    Banners: state.Home,
  }));
  useEffect(() => {
    dispatch(getBannersRequest());
  }, []);

  useEffect(() => {
    if (Banners.getBannersData) {
      const { result } = Banners.getBannersData.data;
      setBannersData(result);
    }
  }, [Banners.getBannersData]);

  const renderTools = () => {
    return (
      <>
        <Col lg={3} sm={6} xs={12} className="card-column">
          <Card
            className="mr-2 custom-card-x cb-1 cursor-ponter"
            onClick={() => props.history.push('/weather-forecast')}
          >
            <div className="landing-page-card">
              <img className="card-icons" src={WeatherForecast} alt="weather forecast" />
              <p className="icon-text">{t('HOME_PAGE.WEATHER_FORECAST')}</p>
              <p className="icon-small-text">{t('WEATHER_FORECAST.DESCRIPTION')}</p>
            </div>
          </Card>
        </Col>
        <Col lg={3} sm={6} xs={12} className="card-column">
          <Card
            className="custom-card-x cb-2 cursor-ponter"
            onClick={() =>
              props.history.push({
                pathname: '/drought-monitor',
                search: '?key=drought-monitor',
              })
            }
          >
            <div className="landing-page-card">
              <img className="card-icons" src={DraughtMonitor} alt="drought monitor" />
              <p className="icon-text">{t('HOME_PAGE.DROUGHT_MANAGEMENT')}</p>
              <p className="icon-small-text">{t('DROUGHT_MANAGEMENT.DESCRIPTION')}</p>
            </div>
          </Card>
        </Col>
        <Col lg={3} sm={6} xs={12} className="card-column">
          <Card
            className="custom-card-x cb-7 cursor-ponter"
            onClick={() =>
              props.history.push({
                pathname: '/drought-monitor',
                search: '?key=drought-decision-support',
              })
            }
          >
            <div className="landing-page-card">
              <img className="card-icons" src={DigitalSurvey} alt="Digital survey" />
              <p className="icon-text">{t('HOME_PAGE.DECISION_SUPPORT_TOOL')}</p>
              <p className="icon-small-text">{t('DECISION_SUPPORT_TOOL.DESCRIPTION')}</p>
            </div>
          </Card>
        </Col>
        <Col lg={3} sm={6} xs={12} className="card-column">
          <Card
            className="custom-card-x cb-4 cursor-ponter"
            onClick={() => {
              props.history.push('/public-api');
            }}
          >
            <div className="landing-page-card">
              <img className="card-icons" src={PublicApi} alt="public api" />
              <p className="icon-text">{t('HOME_PAGE.PUBLIC_API')}</p>
              <p className="icon-small-text">{t('PUBLIC_API.DESCRIPTION')}</p>
            </div>
          </Card>
        </Col>
        <Col lg={3} sm={6} xs={12} className="card-column">
          <Card
            className="custom-card-x cb-5 cursor-ponter"
            onClick={() => props.history.push('/online-bulletin')} //(todo in future)
          >
            <div className="landing-page-card">
              <img className="card-icons" src={NewsFeed} alt="NewsFeed" />
              <p className="icon-text">{t('HOME_PAGE.ONLINE_BULLETIN')}</p>
              <p className="icon-small-text">{t('NEWS_FEED.DESCRIPTION')}</p>
              {/* <p className="icon-small-text">Coming soon...</p> */}
            </div>
          </Card>
        </Col>
        <Col lg={3} sm={6} xs={12} className="card-column">
          <Card
            className="custom-card-x cb-5 cursor-ponter"
             onClick={() => props.history.push('/news-feed')}
          >
            <div className="landing-page-card">
              <img className="card-icons" src={NewsFeed} alt="NewsFeed" />
              <p className="icon-text">{t('HOME_PAGE.NEWS_FEED')}</p>
              <p className="icon-small-text">{t('NEWS_FEED.DESCRIPTION')}</p>
            </div>
          </Card>
        </Col>
        <Col lg={3} sm={6} xs={12} className="card-column">
          <Card
            className="custom-card-x cb-8 cursor-ponter"
            onClick={() => props.history.push('/user-guide')}
          >
            <div className="landing-page-card">
              <img className="card-icons" src={UserGuide} alt="User guide" />
              <p className="icon-text">{t('HOME_PAGE.USER_GUIDE')}</p>
              <p className="icon-small-text">{t('USER_GUIDE.DESCRIPTION')}</p>
            </div>
          </Card>
        </Col>
        <Col lg={3} sm={6} xs={12} className="card-column">
          <Card
            className="custom-card-x cb-3 cursor-ponter"
            //onClick={() => props.history.push('/contingency-plan')}
          >
            <div className="landing-page-card">
              <img className="card-icons" src={Reporting} alt="contingency plan" />
              <p className="icon-text">{t('HOME_PAGE.CONTINGENCY_PLAN')}</p>
              {/* <p className="icon-small-text">{t('CONTINGENCY_PLAN.DESCRIPTION')}</p> */}
              <p className="icon-small-text">Coming soon...</p>
            </div>
          </Card>
        </Col>
      </>
    );
  };

  return (
    <>
      <Carousel carouselData={BannersData} />
      <Container fluid id="content">
        <Row className="no-gutters row-padding">
          <center lg={12}>
            <h2 className="about-us">{t('HOME_PAGE.ABOUT_US')}</h2>
          </center>
          <Col lg={12}>
            <p className="select-text">
              {t('HOME_PAGE.ABOUT_US_INFO')}
              <span>
                <a className="readmore-text" onClick={() => props.history.push('/about-us')}>
                  {' '}
                  {t('HOME_PAGE.READ_MORE_WITHOUT')}
                </a>
              </span>
            </p>
          </Col>
          {renderTools()}
        </Row>
      </Container>
      <div className="pre-footer mt-3">
        <div className="pre-footer-content">
          <p className="contact-text">{t('HOME_PAGE.KEEP_IN_TOUCH')}</p>
          <span>
            {t('HOME_PAGE.REGISTER')}
            {!getToken() ? (
              <button className="pre-footer-register-btn" onClick={() => history.push('/register')}>
                {t('HOME_PAGE.REGISTER_NOW')}
              </button>
            ) : (
              ''
            )}
          </span>
        </div>
      </div>
      {/* Footer here */}
      <Footer publicFooter={true} />
    </>
  );
};

export default HomePage;
