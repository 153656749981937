import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { t } from 'react-multi-lang';
import { convertTimeFormat } from '../../Components/common/utils';
import _ from 'lodash';
import {
  getByIdNewsFeedRequest,
  getByIdNewsFeedSuccess,
} from '../../redux/newsFeed/newsFeedAction';
import moment from 'moment';
import { DISPLAY_DATE_FORMAT } from '../../Components/constants';
import Footer from '../landing-page/footer';
import { Link } from 'react-router-dom';

const NewsFeedDetailsPage = props => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [newsFeedDetailsData, setNewsFeedDetailsData] = useState('');
  const nextProps = useSelector(state => ({
    getByIdNewsFeedData: state.NewsFeed.getByIdNewsFeedData,
  }));

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'instant' });
    let search = window.location.search;
    let URLParams = new URLSearchParams(search);
    let newsFeedId = URLParams.get('newsFeedId');
    dispatch(getByIdNewsFeedRequest({ newsfeed_id: newsFeedId }));
    return () => {
      dispatch(getByIdNewsFeedSuccess({}));
    };
  }, []);

  useEffect(() => {
    if (nextProps.getByIdNewsFeedData) {
      const {
        data: { result },
      } = nextProps.getByIdNewsFeedData;
      if (result?.length) {
        setNewsFeedDetailsData(result[0]);
      }
    }
  }, [nextProps.getByIdNewsFeedData]);
  
  const goBack = ()=>{
    let search = window.location.search;
    let URLParams = new URLSearchParams(search);
    let fromBackOffice=URLParams.get('from');
    if (fromBackOffice){
      history.push('/manage-newsfeed')
    }
    else{
      history.push('/news-feed')
    }
  }


  return (
    <>
      <div className="news-feed-details-page">
        <div className="w-100">
          <div className="content-section news-feed-details-section">
            {!_.isEmpty(newsFeedDetailsData) ? (
              <>
                <p className="news-feed-details-small-title">
                  <span className="news-feed-category" onClick={goBack}>
                    {newsFeedDetailsData?.newsfeed_category_name}
                  </span>{' '}
                  / {newsFeedDetailsData?.title}
                </p>
                <p className="news-feed-details-big-title">{newsFeedDetailsData?.title}</p>
                <p className="news-feed-details-sub-tittle">
                  {/* {newsFeedDetailsData?.newsfeed_category_name}{' '} */}
                  {newsFeedDetailsData?.newsfeed_sub_category_name
                    ? newsFeedDetailsData?.newsfeed_sub_category_name
                    : ''}{' '}
                  {newsFeedDetailsData?.created_date
                    ? t('BULLET_IN.PUBLISHED_ON') + ' ' +moment(newsFeedDetailsData?.created_date).format(DISPLAY_DATE_FORMAT)
                    : ''}
                  {newsFeedDetailsData?.event_time
                    ? convertTimeFormat(newsFeedDetailsData?.event_time)
                    : ''}
                </p>
                {newsFeedDetailsData?.external_link
                ?<p className="link-text-color">Link:
                <Link style={{color:'#2a547c'}}
                 to={{pathname: newsFeedDetailsData?.external_link }} 
                 target={"_blank"} 
                 rel='noopener noreferrer' > {newsFeedDetailsData?.external_link.length > 40
                                              ? newsFeedDetailsData?.external_link?.slice(0,40) + '...'
                                              : newsFeedDetailsData?.external_link.length}
                </Link>
                </p>
                :""
                }
              </>
            ) : (
              <div className="no_data_text_style_news">Loading..</div>
            )}

            <div>
              <img src={newsFeedDetailsData?.media_name} className="news-feed-details-image" />
            </div>
            <div className="news-feed-details-content-section">
              <div dangerouslySetInnerHTML={{ __html: newsFeedDetailsData?.content }} />
            </div>
          </div>
        </div>
      </div>
      <div className="mt-5">
        <Footer publicFooter={false} />
      </div>
    </>
  );
};
export default NewsFeedDetailsPage;
