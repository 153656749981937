import React from 'react';
import { useTranslation } from 'react-multi-lang';
import PropTypes from 'prop-types';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Button from 'reactstrap-button-loader';

const ConfirmationModal = props => {
  const { className, isOpen, handleModalToggle, handleDeleteConfirm } = props;
  const t = useTranslation();

  const toggle = value => {
    if (value === 'no') {
      handleModalToggle(false);
    } else if (value === 'yes') {
      handleDeleteConfirm();
      handleModalToggle(false);
    }
  };

  const handleClose = () => {
    handleModalToggle(false);
  };

  return (
    <div>
      <Modal isOpen={isOpen} toggle={toggle} className={className}>
        <ModalHeader toggle={() => handleClose()} className="reason-modal-header">
          {t('STATIC_RESOURCE_ADMIN.CONFIRMATION')}
        </ModalHeader>
        <ModalBody>
          <p className="confirmation-modal-text">{t('STATIC_RESOURCE_ADMIN.DELETE_CONFIRM_MSG')}</p>
        </ModalBody>
        <ModalFooter className="reason-modal">
          <Button color="secondary" onClick={() => toggle('no')} className="cancel-button">
            {t('STATIC_RESOURCE_ADMIN.NO')}
          </Button>{' '}
          <Button color="primary" onClick={() => toggle('yes')} className="modal-reject-button">
            {t('STATIC_RESOURCE_ADMIN.YES')}
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default ConfirmationModal;
ConfirmationModal.propTypes = {
  isOpen: PropTypes.bool,
  handleModalToggle: PropTypes.func,
  handleDeleteConfirm: PropTypes.func,
};
