import { BULLETIN_CONSTS } from './bulletinConsts';

const Bulletin = (state = {}, action) => {
  switch (action.type) {
    case BULLETIN_CONSTS.BULLETIN_SUBSCRIBE_REQUEST:
      return {
        ...state,
        isSubscribeBulletinRequesting: true,
        subscribeBulletinData: false,
        subscribeBulletinDataError: false,
        subscribeBulletinDataNetworkError: false,
      };
    case BULLETIN_CONSTS.BULLETIN_SUBSCRIBE_SUCCESS:
      return {
        ...state,
        isSubscribeBulletinRequesting: false,
        subscribeBulletinData: action.payload.response,
        subscribeBulletinDataError: false,
        subscribeBulletinDataNetworkError: false,
      };
    case BULLETIN_CONSTS.BULLETIN_SUBSCRIBE_FAILED:
      return {
        ...state,
        isSubscribeBulletinRequesting: false,
        subscribeBulletinData: false,
        subscribeBulletinDataError: action.payload.error ? false : action.payload.response,
        subscribeBulletinDataNetworkError: action.payload.error ? action.payload.error : false,
      };
    case BULLETIN_CONSTS.PREVIEW_BULLETIN_REQUEST:
      return {
        ...state,
        isPreviewBulletinRequesting: true,
        getPreviewBulletinData: false,
        getPreviewBulletinDataError: false,
        getPreviewBulletinDataNetworkError: false,
      };
    case BULLETIN_CONSTS.PREVIEW_BULLETIN_SUCCESS:
      return {
        ...state,
        isPreviewBulletinRequesting: false,
        getPreviewBulletinData: action.payload.response,
        getPreviewBulletinDataError: false,
        getPreviewBulletinDataNetworkError: false,
      };
    case BULLETIN_CONSTS.PREVIEW_BULLETIN_FAILED:
      return {
        ...state,
        isPreviewBulletinRequesting: false,
        getPreviewBulletinData: false,
        getPreviewBulletinDataError: action.payload.error ? false : action.payload.response,
        getPreviewBulletinDataNetworkError: action.payload.error ? action.payload.error : false,
      };

    case BULLETIN_CONSTS.PREVIEW_TEMPLATE_REQUEST:
      return {
        ...state,
        isPreviewTemplateRequesting: true,
        getPreviewTemplateData: false,
        getPreviewTemplateDataError: false,
        getPreviewTemplateDataNetworkError: false,
      };
    case BULLETIN_CONSTS.PREVIEW_TEMPLATE_SUCCESS:
      return {
        ...state,
        isPreviewTemplateRequesting: false,
        getPreviewTemplateData: action.payload.response,
        getPreviewTemplateDataError: false,
        getPreviewTemplateDataNetworkError: false,
      };
   case BULLETIN_CONSTS.PREVIEW_TEMPLATE_FAILED:
      return {
         ...state,
        isPreviewTemplateRequesting: false,
        getPreviewTemplateData: false,
        getPreviewTemplateDataError: action.payload.error ? false : action.payload.response,
        getPreviewTemplateDataNetworkError: action.payload.error ? action.payload.error : false,
      };
    case BULLETIN_CONSTS.BULLETIN_DELETE_REQUEST:
      return {
        ...state,
        isBulletinDeleteRequesting: true,
        isBulletinDeleteData: false,
        isBulletinDeleteDataError: false,
        isBulletinDeleteDataNetworkError: false,
      };
    case BULLETIN_CONSTS.BULLETIN_DELETE_SUCCESS:
      return {
        ...state,
        isBulletinDeleteRequesting: false,
        isBulletinDeleteData: action.payload.response,
        isBulletinDeleteDataError: false,
        isBulletinDeleteDataNetworkError: false,
      };
    case BULLETIN_CONSTS.BULLETIN_DELETE_FAILED:
      return {
        ...state,
        isBulletinDeleteRequesting: false,
        isBulletinDeleteData: false,
        isBulletinDeleteDataError: action.payload.error ? false : action.payload.response,
        isBulletinDeleteDataNetworkError: action.payload.error ? action.payload.error : false,
      };
      case BULLETIN_CONSTS.TEMPLATE_DELETE_REQUEST:
        return {
          ...state,
          isTemplateDeleteRequesting: true,
          isTemplateDeleteData: false,
          isTemplateDeleteDataError: false,
          isTemplateDeleteDataNetworkError: false,
        };
      case BULLETIN_CONSTS.TEMPLATE_DELETE_SUCCESS:
        return {
          ...state,
          isTemplateDeleteRequesting: false,
          isTemplateDeleteData: action.payload.response,
          isTemplateDeleteDataError: false,
          isTemplateDeleteDataNetworkError: false,
        };
      case BULLETIN_CONSTS.TEMPLATE_DELETE_FAILED:
        return {
          ...state,
          isTemplateDeleteRequesting: false,
          isTemplateDeleteData: false,
          isTemplateDeleteDataError: action.payload.error ? false : action.payload.response,
          isTemplateDeleteDataNetworkError: action.payload.error ? action.payload.error : false,
        };
    case BULLETIN_CONSTS.BULLETIN_PUBLISH_REQUEST:
      return {
        ...state,
        isPublishRequesting: true,
        bulletinPublishData: false,
        bulletinPublishDataError: false,
        bulletinPublishDataNetworkError: false,
      };
    case BULLETIN_CONSTS.BULLETIN_PUBLISH_SUCCESS:
      return {
        ...state,
        isPublishRequesting: false,
        bulletinPublishData: action.payload.response,
        bulletinPublishDataError: false,
        bulletinPublishDataNetworkError: false,
      };
    case BULLETIN_CONSTS.BULLETIN_PUBLISH_FAILED:
      return {
        ...state,
        isPublishRequesting: false,
        bulletinPublishData: false,
        bulletinPublishDataError: action.payload.error ? false : action.payload.response,
        bulletinPublishDataNetworkError: action.payload.error ? action.payload.error : false,
      };
    case BULLETIN_CONSTS.GET_ALL_BULLETIN_REQUEST:
      return {
        ...state,
        isGetAllBulletinRequesting: true,
        getAllBulletinData: false,
        getAllBulletinDataError: false,
        getAllBulletinDataNetworkError: false,
      };
    case BULLETIN_CONSTS.GET_ALL_BULLETIN_SUCCESS:
      return {
        ...state,
        isGetAllBulletinRequesting: false,
        getAllBulletinData: action.payload.response,
        getAllBulletinDataError: false,
        getAllBulletinDataNetworkError: false,
      };
    case BULLETIN_CONSTS.GET_ALL_BULLETIN_FAILED:
      return {
        ...state,
        isGetAllBulletinRequesting: false,
        getAllBulletinData: false,
        getAllBulletinDataError: action.payload.error ? false : action.payload.response,
        getAllBulletinDataNetworkError: action.payload.error ? action.payload.error : false,
      };
    case BULLETIN_CONSTS.ADD_BULLETIN_REQUEST:
      return {
        ...state,
        isAddBulletinRequesting: true,
        addBulletinData: false,
        addBulletinDataError: false,
        addBulletinDataNetworkError: false,
      };

    case BULLETIN_CONSTS.ADD_BULLETIN_SUCCESS:
      return {
        ...state,
        isAddBulletinRequesting: false,
        addBulletinData: action.payload.response,
        addBulletinDataError: false,
        addBulletinDataNetworkError: false,
      };

    case BULLETIN_CONSTS.ADD_BULLETIN_FAILED:
      return {
        ...state,
        isAddBulletinRequesting: false,
        addBulletinData: false,
        addBulletinDataError: action.payload.error ? false : action.payload.response,
        addBulletinDataNetworkError: action.payload.error ? action.payload.error : false,
      };
    case BULLETIN_CONSTS.GET_ALL_TEMPLATE_LIST_REQUEST:
      return {
        ...state,
        isGetAllTemplateListRequesting: true,
        getAllTemplateListData: false,
        getAllTemplateListDataError: false,
        getAllTemplateListDataNetworkError: false,
      };
    case BULLETIN_CONSTS.GET_ALL_TEMPLATE_LIST_SUCCESS:
      return {
        ...state,
        isGetAllTemplateListRequesting: false,
        getAllTemplateListData: action.payload.response,
        getAllTemplateListDataError: false,
        getAllTemplateListDataNetworkError: false,
      };
    case BULLETIN_CONSTS.GET_ALL_TEMPLATE_LIST_FAILED:
      return {
        ...state,
        isGetAllTemplateListRequesting: false,
        getAllTemplateListData: false,
        getAllTemplateListDataError: action.payload.error ? false : action.payload.response,
        getAllTemplateListDataNetworkError: action.payload.error ? action.payload.error : false,
      };
    case BULLETIN_CONSTS.ADD_TEMPLATE_REQUEST:
      return {
        ...state,
        isAddTemplateRequesting: true,
        addTemplateData: false,
        addTemplateDataError: false,
        addTemplateDataNetworkError: false,
      };

    case BULLETIN_CONSTS.ADD_TEMPLATE_SUCCESS:
      return {
        ...state,
        isAddTemplateRequesting: false,
        addTemplateData: action.payload.response,
        addTemplateDataError: false,
        addTemplateDataNetworkError: false,
      };

    case BULLETIN_CONSTS.ADD_TEMPLATE_FAILED:
     
      return {
        ...state,
        isAddTemplateRequesting: false,
        addTemplateData: false,
        addTemplateDataError: action.payload.error ? false : action.payload.response,
        addTemplateDataNetworkError: action.payload.error ? action.payload.error : false,
      };
    case BULLETIN_CONSTS.GET_TEMPLATE_REQUEST:
      return {
        ...state,
        isGetTemplateRequesting: true,
        getTemplateData: false,
        getTemplateDataError: false,
        getTemplateDataNetworkError: false,
      };
    case BULLETIN_CONSTS.GET_TEMPLATE_SUCCESS:
      return {
        ...state,
        isGetTemplateRequesting: false,
        getTemplateData: action.payload.response,
        getTemplateDataError: false,
        getTemplateDataNetworkError: false,
      };
    case BULLETIN_CONSTS.GET_TEMPLATE_FAILED:
      return {
        ...state,
        isGetTemplateRequesting: false,
        getTemplateData: false,
        getTemplateDataError: action.payload.error ? false : action.payload.response,
        getTemplateDataNetworkError: action.payload.error ? action.payload.error : false,
      };
    case BULLETIN_CONSTS.UPDATE_TEMPLATE_REQUEST:
      return {
        ...state,
        isUpdateTemplateRequesting: true,
        updateTemplateData: false,
        updateTemplateDataError: false,
        updateTemplateDataNetworkError: false,
      };
    case BULLETIN_CONSTS.UPDATE_TEMPLATE_SUCCESS:
      return {
        ...state,
        isUpdateTemplateRequesting: false,
        updateTemplateData: action.payload.response,
        updateTemplateDataError: false,
        updateTemplateDataNetworkError: false,
      };
    case BULLETIN_CONSTS.UPDATE_TEMPLATE_FAILED:
      return {
        ...state,
        isUpdateTemplateRequesting: false,
        updateTemplateData: false,
        updateTemplateDataError: action.payload.error ? false : action.payload.response,
        updateTemplateDataNetworkError: action.payload.error ? action.payload.error : false,
      };
    case BULLETIN_CONSTS.APPROVE_REJECT_BULLETIN_REQUEST:
      return {
        ...state,
        isApproveRejectRequesting: true,
        approveRejectData: false,
        approveRejectDataError: false,
        approveRejectDataNetworkError: false,
      };
    case BULLETIN_CONSTS.APPROVE_REJECT_BULLETIN_SUCCESS:
      return {
        ...state,
        isApproveRejectRequesting: false,
        approveRejectData: action.payload.response,
        approveRejectDataError: false,
        approveRejectDataNetworkError: false,
      };
    case BULLETIN_CONSTS.APPROVE_REJECT_BULLETIN_FAILED:
      return {
        ...state,
        isApproveRejectRequesting: false,
        approveRejectData: false,
        approveRejectDataError: action.payload.error ? false : action.payload.response,
        approveRejectDataNetworkError: action.payload.error ? action.payload.error : false,
      };
    case BULLETIN_CONSTS.UPDATE_BULLETIN_REQUEST:
      return {
        ...state,
        isUpdateBulletinRequesting: true,
        updateBulletinData: false,
        updateBulletinDataError: false,
        updateBulletinDataNetworkError: false,
      };
    case BULLETIN_CONSTS.UPDATE_BULLETIN_SUCCESS:
      return {
        ...state,
        isUpdateBulletinRequesting: false,
        updateBulletinData: action.payload.response,
        updateBulletinDataError: false,
        updateBulletinDataNetworkError: false,
      };
    case BULLETIN_CONSTS.UPDATE_BULLETIN_FAILED:
      return {
        ...state,
        isUpdateBulletinRequesting: false,
        updateBulletinData: false,
        updateBulletinDataError: action.payload.error ? false : action.payload.response,
        updateBulletinDataNetworkError: action.payload.error ? action.payload.error : false,
      };
    default:
      return state;
  }
};

export default Bulletin;
