import React, { memo } from 'react';
import { t } from 'react-multi-lang';
import { useHistory } from 'react-router-dom';
import DownloadWrapper from './DownloadWrapper';
import RightArrow from '../../../assets/Images/back-button.svg';
import Button from 'reactstrap-button-loader';

function Header({ queryPrams, setIsOpenBulletin, elementToPrint, title}) {
  const history = useHistory();

  return (
    <div className="bulletin-nav p-2 px-4 d-flex justify-content-between">
      <div>
        <img src={RightArrow} className="" onClick={() => history.goBack()} />
        <h5 className="d-inline m-3">{t('ONLINE_BULLETIN.CREATE_BULLETIN.HEADER')}</h5>
      </div>

      <div className="text-right">
        <DownloadWrapper
          queryPrams={queryPrams}
          elementToPrint={elementToPrint}
          downloadFileName={`${queryPrams.state}-${queryPrams.bulletinType}`}
          title={title}
        />
        <Button className="primary-btn" onClick={() => setIsOpenBulletin(true)}>
          {' '}
          + {t('ONLINE_BULLETIN.ADD_MORE')}
        </Button>
      </div>
    </div>
  );
}

export default memo(Header);
