import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { TabContent, TabPane, Nav, NavItem, NavLink, Row, Col } from 'reactstrap';
import classnames from 'classnames';
import { useTranslation } from 'react-multi-lang';
import { getAllByTypeRequest } from '../../redux/accessKey/accessKeyActions';
import {userDetailRequest } from "../../redux/user-list/userListAction";
import { isSuperAdmin, isStaffUser } from "../../Components/common/utils";
import { RequestListPagination } from './pagination.js';
import RightArrow from "../../assets/Images/back-button.svg";
import { REQUEST_STATUS } from '../../Components/constants/index';
import { ToastContainer } from 'react-toastify';
import Loader from '../../Components/common/loader';

const Publicapikey = () => {
  const nextProps = useSelector((state) => ({
    newRequestData: state.AccessKey.getAllByTypeData || null,
    getAllByTypeRequesting: state.AccessKey.getAllByTypeRequesting,
    userDetailData: state.userList.userDetailData || null,
  }));
  const dispatch = useDispatch();
  let history = useHistory();
  const [activeTab, setActiveTab] = useState('new');
  const [newRequestList, setNewRequestList] = useState();
  const [updatedPermissions,setUpdatedPermissions] = useState();
  const [pageLimit, setPageLimit] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageCount, setPageCount] = useState();
  const [recordsCount, setRecordsCount] = useState();
  const t = useTranslation();

  const toggle = (tab) => {
    setActiveTab(tab);
    setPageNumber(1);
    dispatch(getAllByTypeRequest({ status: tab, page: 1, limit: pageLimit }));
  };

  useEffect(() => {
    if (isSuperAdmin() || isStaffUser()) {
      dispatch(
        getAllByTypeRequest({ status: REQUEST_STATUS.NEW, page: pageNumber, limit: pageLimit }),
      );
    }
     else {
      history.push("/home");
    }
    dispatch(userDetailRequest());
  }, []);


  
  useEffect(() => {
    if(nextProps.userDetailData){
      let a = [];
      const {
        data: { result },
      } = nextProps.userDetailData;
      if(result){
        result.user_permissions.map((i) => {
          a.push(i.codename);
        })
        setUpdatedPermissions(a);
      }
    }

  },[nextProps.userDetailData])

  useEffect(() => {
    if (nextProps.newRequestData) {
      const {
        data: { result },
      } = nextProps.newRequestData;
      if (result?.results?.length) {
        setNewRequestList(result.results);
        setPageCount(result.count);
        setRecordsCount(result.results.length);
      }else{
        setNewRequestList([]);
        setPageCount(0);
        setRecordsCount(0);
      }
    }
  }, [nextProps.newRequestData]);

  const handleLatestData = (value) => {
    dispatch(getAllByTypeRequest({ status: activeTab, page: pageNumber, limit: pageLimit }));
  };

  const handlePageSize = (value) => {
    setPageLimit(value);
    setPageNumber(1);
    dispatch(getAllByTypeRequest({ status: activeTab, page: 1, limit: value }));
  };

  const handleLatestDataForDelete = (value) => {
    dispatch(getAllByTypeRequest({ status: activeTab, page: pageNumber, limit: pageLimit }));
  };

  const handlePageNumber = (value) => {
    setPageNumber(value);
    dispatch(getAllByTypeRequest({ status: activeTab, page: value, limit: pageLimit }));
  };

  const handleManageCropsBackButton = () => {
    history.push("/settingsDashboard");
}

  return (
    <>
      <ToastContainer />
      <div className="right-img-wrapper api-key-list">
        <Row>
          <Col xs="12" sm="12" md="12" lg="12" className="subheader-apikey">
          <img src={RightArrow} alt="Right Arrow" className="public-api-header-back-button" onClick={() => handleManageCropsBackButton()}/>
        <h3 className="heading-apikey api-heading-mt">{t('PUBLIC_API_ADMIN.API_KEYS')}</h3>
          </Col>
        </Row>
        <Nav tabs className="api-key-navtabs">
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === 'new' })}
              onClick={() => {
                toggle('new');
              }}
            >
              {t('PUBLIC_API_ADMIN.NEW_REQUEST')}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === 'approve' })}
              onClick={() => {
                toggle('approve');
              }}
            >
              {t('PUBLIC_API_ADMIN.APPROVED')}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === 'reject' })}
              onClick={() => {
                toggle('reject');
              }}
            >
              {t('PUBLIC_API_ADMIN.REJECTED')}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === 'delete' })}
              onClick={() => {
                toggle('delete');
              }}
            >
              {t('PUBLIC_API_ADMIN.DELETED')}
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={activeTab}>
          <TabPane tabId={activeTab}>
            <Row>
              <Col lg="12" md="12" className="table-col">
                 
                  <>
                  {nextProps.getAllByTypeRequesting && <Loader />}
                  {newRequestList &&
                    <RequestListPagination
                      loading={false}
                      req_data={newRequestList ||[]}
                      handleLatestData={handleLatestData}
                      selectedTab={activeTab}
                      handleLatestDataForDelete={handleLatestDataForDelete}
                      handlePageSize={handlePageSize}
                      pageCount={pageCount}
                      handlePageNumber={handlePageNumber}
                      pageNumber={pageNumber}
                      recordsCount={recordsCount}
                      activeTab={activeTab}
                      updatedPermissions={updatedPermissions}
                    />
                  }
                  </>
                
              </Col>
            </Row>
          </TabPane>
        </TabContent>
      </div>
    </>
  );
};

export default Publicapikey;
