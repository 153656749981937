import '../../assets/CSS/register.css';
import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import AutoSuggest from 'react-autosuggest';
import Button from 'reactstrap-button-loader';
import {
  Card,
  Col,
  Container,
  FormGroup,
  FormFeedback,
  Input,
  InputGroup,
  InputGroupAddon,
  Label,
  Row,
  Spinner,
} from 'reactstrap';
import ReCAPTCHA from 'react-google-recaptcha';
import { t } from 'react-multi-lang';
import LeftImg from '../../assets/Images/left-vector-login.png';
import RightImg from '../../assets/Images/right-vector-login.png';
import { LOCATION } from '../../Components/constants';
import SADMS from '../../assets/Images/SADMS-logo.png';
import {
  isValidEmail,
  isValidMobileNumber,
  specialCharacterRegex,
} from '../../Components/common/utils';
import { getToken } from '../../Components/common/utils';
import { getCountryRequest } from '../../redux/location/actions';
import { registerRequest } from '../../redux/register/registerAction';
import { COUNTRIES_JSON_LIST } from '../../Components/constants/index';
import SuccessModal from './Success';
import { verificationRequest } from '../../redux/register/registerAction';

const Register = () => {
  let history = useHistory();
  const dispatch = useDispatch();
  let defaultValues = {
    name: '',
    mobile: '',
    email: '',
    password: '',
    organization: '',
    country: '',
  };
  const nextProps = useSelector(state => ({
    countryData: state.Location || null,
    registerDataError: state.Register.registerDataError,
    registerData: state.Register.registerData || null,
    isRegisterRequesting: state.Register.isRegisterRequesting,
    verificationData: state.Register.verifiedData || null,
    isVerificationLoading: state.Register.isRegisterRequesting,
    verifiedDataError: state.Register.verifiedDataError,
  }));
  const [countryData, setCountryData] = useState();
  const [value, setValue] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [emailRequired, setEmailRequired] = useState(false);
  const [passwortType, setPasswordType] = useState(true);
  const [passwordRequired, setPasswordRequired] = useState(false);
  const [passwordErrorMessage, setPasswordErrorMessage] = useState();
  const [organizationErrorMessage, setOrganizationErrorMessage] = useState();
  const [mobileNumberErrorMessage, setMobileNumberErrorMessage] = useState();
  const [nameRequired, setNameRequired] = useState(false);
  const [emailErrorMessage, setEmailErrorMessage] = useState();
  const [nameErrorMessage, setNameErrorMessage] = useState();
  const [organisationRequired, setOrganisationRequired] = useState(false);
  const [countryRequired, setCountryRequired] = useState(false);
  const [mobileNumberRequired, setMobileNumberRequired] = useState(false);
  const [captchaValidation, setCaptchaValidation] = useState(false);
  const [formData, setFormData] = useState(defaultValues);
  const [registerButtonDisable, setRegisterButtonDisable] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const [modalMessage, setModalMessage] = useState();
  const [fromVerification, setFromVerification] = useState(false);
  const [modalHeaderMessage, setModalHeaderMessage] = useState();
  const recaptchaRef = React.createRef();

  let lowerCasedCompanies = [];
  useEffect(() => {
    if (getToken()) {
      history.push('/home');
    } else {
      let params = new URLSearchParams(window.location.search);
      let keyValue;
      keyValue = params.get('key');
      if (window.location.href.includes('key')) {
        dispatch(
          verificationRequest({
            verification_code: keyValue,
          }),
        );
      } else {
        history.push('/register');
        dispatch(getCountryRequest({ location_type: LOCATION.Country, parent_id: '' }));
      }
    }
    lowerCasedCompanies = COUNTRIES_JSON_LIST.map(company => {
      return {
        id: company.code,
        name: company.name.charAt(0).toUpperCase() + company.name.slice(1),
      };
    });
    setCountryData(lowerCasedCompanies);
    window.scrollTo({ top: 0, behavior: 'instant' });
  }, []);
  const isVerificationFirstRun = useRef(true);
  useEffect(() => {
    if (isVerificationFirstRun.current) {
      isVerificationFirstRun.current = false;
      return;
    }
    if (
      nextProps.verificationData &&
      nextProps.verificationData.data &&
      nextProps.verificationData.data.result
    ) {
      setModalHeaderMessage(nextProps.verificationData.data.title);
      setSuccessModal(true);
      setFromVerification(true);
      setModalMessage(nextProps.verificationData.data.result);
    }
  }, [nextProps.verificationData]);
  useEffect(() => {
    if (nextProps.verifiedDataError) {
      setSuccessModal(false);
      history.push('/register');
    }
  }, [nextProps.verifiedDataError]);

  const isRegisterFirstRun = useRef(true);
  useEffect(() => {
    if (isRegisterFirstRun.current) {
      isRegisterFirstRun.current = false;
      return;
    }
    if (
      nextProps.registerData &&
      nextProps.registerData.data &&
      nextProps.registerData.data.result
    ) {
      setModalHeaderMessage(nextProps.registerData.data.title);
      setSuccessModal(true);
      setModalMessage(nextProps.registerData.data.result);
    }
  }, [nextProps.registerData]);

  useEffect(() => {
    if (nextProps.registerDataError) {
      setRegisterButtonDisable(true);
    }
  }, [nextProps.registerDataError]);

  useEffect(() => {

    let dataArrayValues = [];
    dataArrayValues = Object.values(formData);
    dataArrayValues?.splice(1, 1)
    if (dataArrayValues.some(item => item == '')) {
      setRegisterButtonDisable(false);
    } else {
      if (
        nameRequired === true ||
        emailRequired === true ||
        passwordRequired === true ||
        organisationRequired === true ||
        countryRequired === true ||
        mobileNumberRequired === true
      ) {
        setRegisterButtonDisable(false);
      } else {
        if (captchaValidation === false) {
          setRegisterButtonDisable(false);
        } else {
          setRegisterButtonDisable(true);
        }
      }
    }
  }, [formData, captchaValidation,countryRequired]);
  
  const getSuggestions = value => {
    return countryData.filter(data =>
      data.name.includes(
        value
          .trim()
          .charAt(0)
          .toUpperCase() + value.slice(1),
      ),
    );
  };
  const handleOkayButtonClick = value => {
    if (value === true && fromVerification) {
      setSuccessModal(false);
      history.push('/login');
    } else {
      setSuccessModal(false);
      history.push('/home');
    }
  };

  const handleNameChange = e => {
    if (e.target.value === '') {
      setNameErrorMessage(t('REGISTER.REQUIRED_NAME_ERROR_MSG'));
      setNameRequired(true);
      formData.name = e.target.value;
      setFormData({ ...formData });
    } else if (e.target.value.length > 20) {
      setNameErrorMessage('Name must be below 20 characters');
      setNameRequired(true);
      formData.name = e.target.value;
      setFormData({ ...formData });
    } else {
      formData.name = e.target.value;
      setFormData({ ...formData });
      setNameRequired(false);
    }
  };
  const handleMobileChange = e => {
    if (isValidMobileNumber(e.target.value)) {
      formData.mobile = e.target.value;
      setFormData({ ...formData });
      setMobileNumberRequired(false);
    } 
    else if (e.target.value == '')
    {
      formData.mobile = e.target.value;
      setFormData({ ...formData });
      setMobileNumberRequired(false);
    }
    else {
      formData.mobile = e.target.value;
      setFormData({ ...formData });
      setMobileNumberErrorMessage(t('REGISTER.MOBILE_NUMBER_ERROR_MSG'));
      setMobileNumberRequired(true);
    }
  };
  const handleChangeEmail = e => {
    formData.email = e.target.value.trim();
    setFormData({ ...formData });
    if (!isValidEmail(e.target.value.trim())) {
      if (e.target.value === '') {
        setEmailErrorMessage(t('REGISTER.REQUIRED_EMAIL_ERROR_MSG'));
      } else {
        setEmailErrorMessage(t('REGISTER.EMAIL_ERROR_MSG'));
      }
      setEmailRequired(true);
    } else {
      setEmailRequired(false);
    }
  };

  const handleChangePassword = e => {
    formData.password = e.target.value?.split(' ').join('');
    setFormData({ ...formData });
    if (e.target.value === '') {
      setPasswordRequired(true);
      setPasswordErrorMessage(t('REGISTER.REQUIRED_PASSWORD_ERROR_MSG'));
    } else if (e.target.value.length < 8 || e.target.value.length > 20) {
      setPasswordRequired(true);
      setPasswordErrorMessage(t('REGISTER.PASSWORD_ERROR_MSG'));
    } else {
      setPasswordRequired(false);
    }
  };

  const showPassword = () => {
    setPasswordType(!passwortType);
  };

  const handleChangeOrganization = e => {
    formData.organization = e.target.value;
    setFormData({ ...formData });
    if (e.target.value === '') {
      setOrganisationRequired(true);
      setOrganizationErrorMessage(t('REGISTER.REQUIRED_ORGANIZATION_ERROR_MSG'));
    } else {
      if (specialCharacterRegex.test(formData.organization)) {
        setOrganisationRequired(true);
        setOrganizationErrorMessage(t('REGISTER.ORGANIZATION_SPCL_CHAR_ERROR_MSG'));
      } else {
        if (formData.name.trim() == e.target.value.trim()) {
          setOrganisationRequired(true);
          setOrganizationErrorMessage(t('REGISTER.ORGANIZATION_AND_NAME_SIMILAR_ERROR_MSG'));
        } else {
          setOrganisationRequired(false);
        }
      }
    }
  };

  const handleRegister = () => {
    dispatch(registerRequest(formData));
  };
  const onChange = () => {
    setCaptchaValidation(true);
  };

  const onExpired = () => {
    setCaptchaValidation(false);
  };
  return (
    <>
      <div className="register-wrapper">
        <Container fluid>
          <Row>
            <Col
              lg={5}
              sm={7}
              xs={12}
              className="mx-auto register-form-wrapper register-zIndex w-100"
            >
              <div className="register-card-wrapper" id="register-card-wrapper">
                <Card className="register-card register-card-height">
                  {!successModal ? (
                    <>
                      {!window.location.href.includes('key') ? (
                        <div className="register-form w-100">
                          <div className="pb-3">
                            <img src={SADMS} alt="logo" className="d-none" />
                            <h5 className="register-key-text">
                              {' '}
                              {t('REGISTER.REGISTER_FORM_TITLE')}
                            </h5>
                            <small className="register-small-key-text">
                              {t('REGISTER.REGISTER_FORM_CAPTION')}
                            </small>
                          </div>
                        <div className='register-fields-container'>
                          <FormGroup className="textOnInput mb-3">
                            <Label htmlFor="exampleEmail">{t('REGISTER.NAME')}</Label>
                            <Input
                              type="text"
                              name="name"
                              className="register-input"
                              defaultValue={formData && formData.name}
                              onChange={handleNameChange}
                              invalid={nameRequired}
                            />
                            <FormFeedback invalid={nameRequired}>{nameErrorMessage}</FormFeedback>
                          </FormGroup>
                          <FormGroup className="textOnInput mb-3">
                            <Label htmlFor="exampleEmail">{t('REGISTER.MOBILE')}</Label>
                            <Input
                              name="name"
                              className="register-input"
                              defaultValue={formData && formData.mobile}
                              onChange={handleMobileChange}
                             invalid={mobileNumberRequired}
                            />
                            <FormFeedback invalid={mobileNumberRequired}>
                              {mobileNumberErrorMessage}
                            </FormFeedback>
                          </FormGroup>
                          <FormGroup className="textOnInput mb-3">
                            <Label htmlFor="exampleEmail">{t('REGISTER.EMAIL')}</Label>
                            <Input
                              type="text"
                              name="email"
                              className="register-input"
                              defaultValue={formData && formData.email}
                              onChange={handleChangeEmail}
                              invalid={emailRequired}
                            />
                            <FormFeedback invalid={emailRequired}>{emailErrorMessage}</FormFeedback>
                          </FormGroup>

                          <FormGroup className="textOnInput mb-3 password-field-form-group">
                            <InputGroup className="form-login">
                              <Label htmlFor="exampleEmail" className='password-legend'>{t('REGISTER.PASSWORD')}</Label>
                              <Input
                                className="password-input"
                                type={passwortType ? 'password' : 'text'}
                                defaultValue={formData && formData.password}
                                onChange={handleChangePassword}
                                // value={formData && formData.password}
                                invalid={passwordRequired}
                              />
                              <InputGroupAddon addonType="append">
                                <span
                                  className={
                                    passwordRequired ? 'show-hide-with-icons' : 'show-hides'
                                  }
                                  onClick={showPassword}
                                >
                                  {passwortType ? t('REGISTER.SHOW') : t('REGISTER.HIDE')}
                                </span>
                              </InputGroupAddon>
                              <FormFeedback invalid={passwordRequired}>
                                {passwordErrorMessage}
                              </FormFeedback>
                            </InputGroup>
                          </FormGroup>
                          <FormGroup className="textOnInput mb-3">
                            <Label htmlFor="exampleEmail">{t('REGISTER.ORGANIZATION')}</Label>
                            <Input
                              type="text"
                              name="organization"
                              className="register-input"
                              defaultValue={formData && formData.organization}
                              onChange={handleChangeOrganization}
                              invalid={organisationRequired}
                            />
                            <FormFeedback invalid={organisationRequired}>
                              {/* {t('REGISTER.REQUIRED_ORGANIZATION_ERROR_MSG')} */}
                              {organizationErrorMessage}
                            </FormFeedback>
                          </FormGroup>
                          <FormGroup className="textOnInput mb-3">
                            <Label htmlFor="exampleEmail">{t('REGISTER.COUNTRY')}</Label>
                            <AutoSuggest
                              className="register-input"
                              suggestions={suggestions}
                              onSuggestionsClearRequested={() => {
                                setSuggestions([]);
                              }}
                              onSuggestionsFetchRequested={({ value }) => {
                                setValue(value);
                                formData.country = value;
                                setFormData({ ...formData });
                                setSuggestions(getSuggestions(value));
                              }}
                              onSuggestionSelected={(_) => {
                                setCountryRequired(false);
                              }}
                              getSuggestionValue={suggestion => suggestion.name}
                              renderSuggestion={suggestion => <span>{suggestion.name}</span>}
                              inputProps={{
                                value: value,
                                autoComplete: 'none',
                                onChange: (_, { newValue }) => {
                                  formData.country = newValue;
                                  if (newValue !== '') {
                                    setCountryRequired(true);
                                  } else {
                                    setCountryRequired(false);
                                  }
                                  setFormData({ ...formData });
                                  setValue(newValue);
                                },
                              }}
                              highlightFirstSuggestion={true}
                              invalid={countryRequired}
                            />
                            <FormFeedback invalid={countryRequired}>
                              {t('REGISTER.REQUIRED_COUNTRY_ERROR_MSG')}
                            </FormFeedback>
                          </FormGroup>
                          </div>
                          <FormGroup className="textOnInput mb-3">
                            <ReCAPTCHA
                              ref={recaptchaRef}
                              sitekey={`${process.env.REACT_APP_SITE_KEY}`}
                              onChange={onChange}
                              onExpired={onExpired}
                            />
                          </FormGroup>
                          <div className="register-text-center w-100 mb-3">
                            <Button
                              loading={nextProps.isRegisterRequesting}
                              className="btn register-btn-grn-key btn-block w-100"
                              onClick={handleRegister}
                              disabled={!registerButtonDisable}
                            >
                              {t('REGISTER.REGISTER')}
                            </Button>
                            <p className="m-0 p-0 register-term-text mt-3">
                              {t('REGISTER.AGREE')}{' '}
                              <a href="/terms-conditions" target="_blank" className="register-text">
                                {t('REGISTER.TERMS_CONDITIONS')}
                              </a>
                            </p>
                            <p className="m-0 p-0 register-term-text mt-3">
                              {t('REGISTER.HAVE_ACCOUNT')}{' '}
                              <a
                                href="javascript:void(0)"
                                onClick={() => history.push('/login')}
                                className="register-text"
                              >
                                {t('REGISTER.SIGN_IN')}
                              </a>
                            </p>
                          </div>
                        </div>
                      ) : (
                        <span className="register-spinner">
                          <Spinner color="primary" size="lg" />
                        </span>
                      )}{' '}
                    </>
                  ) : (
                    <SuccessModal
                      fromVerification={fromVerification}
                      modalHeaderMessage={modalHeaderMessage}
                      okayButtonClickHandler={handleOkayButtonClick}
                      modalSuccessMessage={modalMessage}
                    />
                  )}
                </Card>
              </div>
            </Col>
          </Row>
        </Container>
        <img className="register-img-fluid register-vector-left" alt="" src={LeftImg} />
        <img className="register-img-fluid register-vector-right" alt="" src={RightImg} />
      </div>
    </>
  );
};
export default Register;
