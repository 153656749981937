import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { AXIOS_INSTANCE, HOME_URL, PUBLIC_API_URL } from '../api/apiEndPoint';
import HOME_CONST from './homeConsts';
import {
  getBannersSuccess,
  getBannersFailed,
  getParametersSuccess,
  getParametersFailed,
} from './homeActions';

const getBannersApiRequest = async () => {
  let response = {};
  try {
    const responseData = await AXIOS_INSTANCE.get(`${HOME_URL}/get-banners`);
    response = {
      data: responseData.data,
      status: 200,
    };
  } catch (error) {
    const data = error;
    response = {
      error: data,
      status: 400,
    };
  }
  return response;
};

function* getBanners() {
  try {
    const apiResponse = yield call(getBannersApiRequest);
    if (apiResponse.status === 200) {
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.data,
        },
      };
      yield put(getBannersSuccess(responseData));
    } else {
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.error,
        },
      };
      yield put(getBannersFailed(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(getBannersFailed(errorData));
  }
}

const getParametersApiRequest = async () => {
  let response = {};
  try {
    const data = await AXIOS_INSTANCE.get(`${PUBLIC_API_URL}/getDroughtIndices`);
    response = {
      data: data.data,
      status: 200,
    };
  } catch (error) {
    const data = error;
    response = {
      error: data,
      status: 400,
    };
  }
  return response;
};

function* getParametersData(action) {
  try {
    const apiResponse = yield call(getParametersApiRequest, action.payload);
    if (apiResponse.status === 200) {
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.data,
        },
      };
      yield put(getParametersSuccess(responseData));
    }
  } catch (error) {
    if (error.response.status === 400) {
      const responseData = {
        data: error.response.data,
      };
      yield put(getParametersFailed(responseData));
    } else {
      const errorData = {
        error: {
          statusText: error,
          netWorkError: true,
        },
      };
      yield put(getParametersFailed(errorData));
    }
  }
}

export function* watchGetBanners() {
  yield takeEvery(HOME_CONST.GET_BANNERS_REQUEST, getBanners);
}

export function* watchGetParameters() {
  yield takeEvery(HOME_CONST.GET_PARAMETERS_REQUEST, getParametersData);
}

function* HomeSaga() {
  yield all([fork(watchGetBanners), fork(watchGetParameters)]);
}

export default HomeSaga;
