import React, { memo, useState, useEffect } from 'react';
import { t } from 'react-multi-lang';
import domtoimage from 'dom-to-image';
import { jsPDF } from 'jspdf';
import { showError, showSuccess } from '../../../Components/Notifications';
import Button from 'reactstrap-button-loader';
import { addBulletinRequest,addBulletinSuccess,addBulletinFailed } from '../../../redux/bulletin/bulletinActions';
import { userDetailRequest, userPermissionsRequest } from '../../../redux/user-list/userListAction';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import PublishModel from './PublishModel';
import { isSuperAdmin } from '../../../Components/common/utils';
import { BULLETIN_CODES } from '../../../Components/constants/index';

function DownloadWrapper({ elementToPrint, downloadFileName ,queryPrams, title}) {
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingPdf, setIsLoadingPDF] = useState(false);
  const [isLoadingPng, setIsLoadingPng] = useState(false);
  const [isPublishModal, setIsPublishModal] = useState(false);
  const [userPermisions, setUserPermisions] = useState([]);
  const [imageExtension, setImageExtension] = useState();

  const dispatch = useDispatch();

  const category = `${queryPrams.bulletinId}`;
  const [formData, setFormData] = useState({
    title:title,
    json: '',
    html: '',
    preview_image: '',
    category_id: category,
    id: '',
  });

  const form = new FormData();
  form.append("topic",title);
 form.append('category_id',category);
 form.append('content_file',formData.preview_image);
 form.append('html_content',``);
 form.append('json_content',``);
 form.append('templete_id',``)


 const { Bulletin } = useSelector(state => ({
  Bulletin: state.Bulletin,
}));

const nextProps = useSelector((state) => ({
  userDetailData: state.userList.userDetailData || null,
  userPermissionsData: state.userList.userPermissionsData || null,
}));

useEffect(() => {
  let message =
    Bulletin?.addBulletinDataError?.data?.response?.data?.message ||
    Bulletin?.updateBulletinDataError?.data?.response?.data?.message;
  if (message && message[0]) {
    showError(message[0]);
  }
}, [Bulletin]);

useEffect(() => {
  if (Bulletin?.addBulletinData?.data ) {
    showSuccess(Bulletin?.addBulletinData?.data?.data?.result);
  }
}, [Bulletin.addBulletinData]);

useEffect(() => {
  return () => {
    dispatch(addBulletinSuccess({}));
    dispatch(addBulletinFailed({}));
  };
}, []);

useEffect(() => {
  dispatch(userDetailRequest());
  dispatch(userPermissionsRequest());
}, []);

useEffect(() => {
  if (nextProps.userPermissionsData && nextProps.userDetailData) {
    const {
      data: { result },
    } = nextProps?.userPermissionsData;
    const {
      data: {
        result: { user_permissions },
      },
    } = nextProps?.userDetailData;
    let a = [];
    if (user_permissions) {
      BULLETIN_CODES.map(i => {
        user_permissions?.map(j => {
          if (i == j.codename) {
            a?.push(i);
          }
        });
      });
    }
    setUserPermisions(a);
  }
}, [nextProps.userPermissionsData, nextProps.userDetailData]);

useEffect(() => {
  if (formData?.preview_image) {
    form.append('content_file', formData?.preview_image);
  }
}, [
formData.preview_image,
]);

  const handleHideAndShowIcons = value => {
    const icons = document.getElementsByClassName('indicator-icon');

    if(value){
    for (let i = 0; i < icons?.length; i++) {
      icons[i].style.display = value;
    }  
  };
 }
 const fileHandler = (value,e) => {
  if(e?.target?.value){
    setImageExtension((e?.target?.value)?.substr(e?.target?.value?.lastIndexOf('.') + 1))
  }
}
  /**
   * to download as image based on selected element
   */
  const downloadAsImage = () => {
    setIsLoadingPng(true);
    const options = { backgroundColor: '#fcfcfc' };
    const element = document.getElementById(elementToPrint);
    element.style = 'background-color:#fcfcfc';
    handleHideAndShowIcons('none');
    domtoimage
      .toBlob(element, options)
      .then(function(blob) {
        setIsLoadingPng(false);
        window.saveAs(blob, `${downloadFileName}.png`);
        element.style = 'background-color:unset';
        handleHideAndShowIcons('initial');
      })
      .catch(() => {
        setIsLoadingPng(false);
        handleHideAndShowIcons('initial');
        showError(t('ONLINE_BULLETIN.CREATE_BULLETIN.PNG_ERROR'));
      });
  };
  /**
   * to download as pdf based on selected element
   */
  const downloadAsPdfDocument = () => {
    const element = document.getElementById(elementToPrint);
    setIsLoadingPDF(true);

    handleHideAndShowIcons('none');
    domtoimage
      .toPng(element)
      .then(function(dataUrl) {
        //to get original pdf
        // const doc = new jsPDF();
        //to get less size of pdf
        const doc = new jsPDF('p', 'pt', 'letter', true);
        const width = doc.internal.pageSize.getWidth();
        let height = doc.internal.pageSize.getHeight();
        let i = new Image();
        i.onload = function() {
          height = i.height;
        };
        i.src = dataUrl;
        doc.addImage(dataUrl, 'PNG', 2, 0, width, height);
        setIsLoadingPDF(false);
        doc.save(`${downloadFileName}.pdf`);
        handleHideAndShowIcons('initial');

      })
      .catch(() => {
        setIsLoadingPDF(false);
        handleHideAndShowIcons('initial');
        showError(t('ONLINE_BULLETIN.CREATE_BULLETIN.PDF_ERROR'));
      });
  };

  const handlePublishBulletinModal = (e, data) => {
    e?.stopPropagation();
    setIsPublishModal(!isPublishModal);
  };

  const handlePreviewImage = (e) => {
    setFormData({ ...formData, preview_image: e?.target?.files[0]});
  };

  const publishBulletin =()=>{
    setIsLoading(true);
    const element = document.getElementById(elementToPrint);
   
    handleHideAndShowIcons('none');
    domtoimage
      .toPng(element)
      .then(function(dataUrl) {

        const doc = new jsPDF('p', 'pt', 'letter', true);
        const width = doc.internal.pageSize.getWidth();
        let height = doc.internal.pageSize.getHeight();
        let i = new Image();
        i.onload = function() {
          height = i.height;
        };
        i.src = dataUrl;
        doc.addImage(dataUrl, 'PNG', 2, 0, width, height);
        setIsLoading(false);
        let pdf = doc.output('blob')
        handleHideAndShowIcons('initial');
        let reader = new window.FileReader();
      reader.readAsDataURL(pdf);
      reader.onloadend = function(){
        let pdfResult=reader.result;
        let slicedResult = pdfResult.slice(28, );
      if(!_.isEmpty(pdfResult)){
        form.append('bulletin_content',slicedResult);
        dispatch(addBulletinRequest(form))
      }}})
      .catch(() => {
        setIsLoading(false);
        handleHideAndShowIcons('initial');
        showError(t('ONLINE_BULLETIN.CREATE_BULLETIN.PUBLISH_ERROR'));
      });
      }


  return (
    <>
    <PublishModel
            isOpen={isPublishModal}
            isToggle={handlePublishBulletinModal}
           publishBulletin={publishBulletin}
           handlePreviewImage={handlePreviewImage}
           fileHandler={fileHandler}
          />
    {(isSuperAdmin() || userPermisions?.includes(BULLETIN_CODES[0])) &&
    <Button className="primary-btn-outline mx-1"
      loading={isLoading}
      style={{ width: 'initial', height: 'auto' }}
     onClick={e => {
      handlePublishBulletinModal(e);

    }}>
    {t('ONLINE_BULLETIN.CREATE_BULLETIN.PUBLISH')}
    </Button>}
      <Button
        className="btn primary-btn-outline "
        onClick={downloadAsImage}
        loading={isLoadingPng}
        style={{ width: 'initial', height: 'auto' }}
      >
        {t('ONLINE_BULLETIN.SAVE_PNG')}
      </Button>
      <Button
        style={{ width: 'initial', height: 'auto' }}
        className="primary-btn-outline mx-1 "
        loading={isLoadingPdf}
        onClick={downloadAsPdfDocument}
      >
        {t('ONLINE_BULLETIN.EXPORT_PDF')}
      </Button>
    </>
  );
}

export default memo(DownloadWrapper);
