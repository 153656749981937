import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { t } from 'react-multi-lang';
import {
  Row,
  Col,
  ListGroup,
  ListGroupItem,
  ListGroupItemHeading,
} from 'reactstrap';
import { MdChevronLeft, MdChevronRight } from 'react-icons/md';
import ShareIcon from '../../../assets/Images/share_icon.svg';
import {
  EXTERNAL_RSS_FEED_REQUEST_URL,
  EXTERNAL_RSS_FIELD_CONST,
  NEWS_FEED_CATEGORIES,
  NEWS_FEED_KEYWORDS_CONST,
} from '../../../Components/constants/index';
import InfiniteScroll from 'react-infinite-scroll-component';
import { newsFeedSubCategoriesRequest } from '../../../redux/newsFeed/newsFeedAction';
import { newsFeedDateFormat } from '../../../Components/common/utils';
import Loader from '../../../Components/common/loader';

const ExternalNews = props => {
  const dispatch = useDispatch();
  const [rssFeedList, setRssFeedList] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const [selectedRssFeedItem, setSelectedRssFeedItem] = useState('');
  const [initialRssFeedItemList, setInitialRssFeedItemList] = useState('');
  const [selectedRssFeedItemList, setSelectedRssFeedItemList] = useState([]);
  const [subCategoryIndex, setSubCategoryIndex] = useState(0);
  const [visibleRssFeedList] = useState({
    start: EXTERNAL_RSS_FIELD_CONST.startIndex,
    end: EXTERNAL_RSS_FIELD_CONST.endIndex,
  });


  const { NewsFeed } = useSelector(state => ({
    NewsFeed: state.NewsFeed,
  }));

  useEffect(() => {
    if (!_.isEmpty(props.selectedMenuItem)) {
      dispatch(newsFeedSubCategoriesRequest({ category_id: props.selectedMenuItem.id }));
    }
  }, [props.selectedMenuItem]);

  useEffect(() => {
    if (!_.isEmpty(selectedRssFeedItem)) {
      axios
        .get(EXTERNAL_RSS_FEED_REQUEST_URL.replace('{link}', selectedRssFeedItem.url))
        .then(response => {
          let data = response?.data;
          data['items'] = data?.items?.filter(item => {
            for (let x of NEWS_FEED_KEYWORDS_CONST) {
              if (item.title.toLowerCase()?.includes(x.toLowerCase())) {
                return item;
              }
            }
          });
          setInitialRssFeedItemList(data);
          let items = data?.items?.slice(0, 6);
          setSelectedRssFeedItemList({ ...data, items });
          setPageCount(data?.items?.length);
          setPageNumber(1);
        });
    }
  }, [selectedRssFeedItem]);

  useEffect(() => {
    if (NewsFeed.newsFeedSubCategoriesListData) {
      if (NewsFeed.newsFeedSubCategoriesListData?.data) {
        const { result } = NewsFeed.newsFeedSubCategoriesListData?.data;
        setRssFeedList(result);
        if (result?.length) {
          result.map((i, index) => {
            if (index === 0) {
              setSelectedRssFeedItem(i);
            }
          });
        }
      }
    }
  }, [
    NewsFeed.newsFeedSubCategoriesListData,
    NewsFeed.isNewsFeedSubCategoriesRequesting,
    NewsFeed.newsFeedSubCategoriesListDataError,
  ]);

  const handleSelectedRSSFeedItem = (i, index) => {
    setSelectedRssFeedItem(i);
    setSubCategoryIndex(index);
  };

  const handleNext = () => {
    setSubCategoryIndex(subCategoryIndex + 1);
    setSelectedRssFeedItem(rssFeedList[subCategoryIndex + 1]);
  };

  const handlePrevious = () => {
    setSubCategoryIndex(subCategoryIndex - 1);
    setSelectedRssFeedItem(rssFeedList[subCategoryIndex - 1]);
  };


  const fetchNextTemplateData = () => {
    let pageNuM = pageNumber + 1;
    setPageNumber(pageNuM);
    if (initialRssFeedItemList?.items) {
      let news_list = [];
      let list = _.cloneDeep(selectedRssFeedItemList?.items);
      let hasMoreData =
        selectedRssFeedItemList?.items?.length + 6 < initialRssFeedItemList?.items?.length
          ? true
          : false;
      let data = initialRssFeedItemList?.items?.slice(
        selectedRssFeedItemList?.items?.length,
        hasMoreData
          ? selectedRssFeedItemList?.items?.length + 6
          : initialRssFeedItemList?.items?.length,
      );
      if (selectedRssFeedItemList?.items?.length) {
        if (pageNuM > 1) {
          news_list = data?.length ? list?.concat(data) : list;
        }
      } else {
        news_list = list;
      }
      setSelectedRssFeedItemList({ ...selectedRssFeedItemList, items: news_list });
    } else {
      setSelectedRssFeedItemList([]);
    }
  };


  return (
    <div className="iwmi-content-section news-feed-cls">
      <div className="content-title">
        <span className="">{t('NEWS_FEED.NEWS_SOURCES')} </span>
        <hr className="title-hr" />
      </div>
      <div>
        {props.isNewsFeedRequesting && <Loader />}
        <Row>
          <Col xs={6} md={6} lg={6} className="external-cards-section">
            <div className="external-news-content-title">
              <span className="heading">{NEWS_FEED_CATEGORIES.External_News}</span>
              <hr className="external-news-title-hr" />
            </div>
            <p className="new-sources-desc">
              The following sources provide up-to-date news on Zambia Drought Monitoring System.
              The RSS feeds displayed at right provide the latest stories, or click on the links
              below for more in-depth coverage.
            </p>
            <ListGroup flush>
              {props?.newsFeedList?.length &&
                props?.newsFeedList?.map(i => {
                  return (
                    <ListGroupItem className="new-sources-item">
                      <ListGroupItemHeading className="new-sources-item-heading">
                        {i?.external_link ? (<a href={i?.external_link || '#'} target="_blank" rel="noreferrer">
                          {i.newsfeed_sub_category_name
                            ? `${i.title} | ${i.newsfeed_sub_category_name}`
                            : i.title}
                        </a>)  : (<span className='external-news-feed-text'>{
                          i.newsfeed_sub_category_name
                            ? `${i.title} | ${i.newsfeed_sub_category_name}`
                            : i.title}</span>
                       )}
                      </ListGroupItemHeading>
                      <div className="new-sources-item-text">
                        <div dangerouslySetInnerHTML={{ __html: i?.content }}></div>{' '}
                      </div>
                    </ListGroupItem>
                  );
                })}
            </ListGroup>
          </Col>
          <Col xs={6} md={6} lg={6}>
            <div className="external-news-content-title">
              <Row>
                <Col xs="6" sm="6" md="6" lg="6">
                  <span className="heading">RSS Feed</span>
                </Col>
                <Col xs="6" sm="6" md="6" lg="6">
                  <span className="website-feed-section">
                    <span className="website cursor-ponter">
                      <a
                        href={selectedRssFeedItem?.website_url || selectedRssFeedItemList?.feed?.link }
                        target="_blank"
                        rel="noreferrer"
                        style={{ textDecoration: 'none' }}
                      >
                        <div className="div-block">
                          <label className="website-text cursor-ponter">Website</label>
                          <img src={ShareIcon} />
                        </div>
                      </a>
                    </span>

                    <span className="feed cursor-ponter">
                      <a
                        href={selectedRssFeedItem?.url || '#'}
                        target="_blank"
                        rel="noreferrer"
                        style={{ textDecoration: 'none' }}
                      >
                        <div className="div-block">
                          <label className="feed-text cursor-ponter">Feed</label>
                          <img src={ShareIcon} />
                        </div>
                      </a>
                    </span>
                  </span>
                </Col>
              </Row>
              <hr className="external-news-title-hr" />
              <div className="external-rss-feed-list">
                <ListGroup horizontal>
                  <ListGroupItem
                    active={false}
                    tag="button"
                    onClick={handlePrevious}
                    action
                    disabled={subCategoryIndex === 0 ? true : false}
                  >
                    <MdChevronLeft />
                  </ListGroupItem>
                  {rssFeedList?.length
                    ? rssFeedList
                        ?.slice(visibleRssFeedList.start, visibleRssFeedList.end + 1)
                        ?.map((item, index) => {
                          return (
                            <ListGroupItem
                              active={selectedRssFeedItem.id === item.id ? true : false}
                              tag="button"
                              onClick={() => handleSelectedRSSFeedItem(item, index)}
                              action
                              key={index}
                            >
                              {item.sub_category_name}
                            </ListGroupItem>
                          );
                        })
                    : ''}
                  <ListGroupItem
                    active={false}
                    tag="button"
                    onClick={handleNext}
                    action
                    disabled={subCategoryIndex >= rssFeedList?.length - 1 ? true : false}
                  >
                    <MdChevronRight />
                  </ListGroupItem>
                </ListGroup>
              </div>
            </div>
            <div className="">
              <InfiniteScroll
                dataLength={pageNumber * 6 < pageCount ? pageNumber * 6 : ''}
                next={() => fetchNextTemplateData()}
                hasMore={pageNumber * 6 < pageCount ? true : false}
                className="flex flex-wrap"
              >
                <ListGroup flush>
                  {!_.isEmpty(selectedRssFeedItemList) && selectedRssFeedItemList?.items?.length ? (
                    selectedRssFeedItemList?.items.map((i,index) => {
                      return (
                        <ListGroupItem className="rss-item" key={index}>
                          <ListGroupItemHeading className="rss-item-heading">
                            {' '}
                            <a href={i?.link} target="_blank" rel="noreferrer">
                              {i?.title}
                            </a>
                          </ListGroupItemHeading>
                          <div className="external-rss-item-text">
                            <div dangerouslySetInnerHTML={{ __html: i?.description }}></div>
                          </div>
                          <span className="rss-item-date">{newsFeedDateFormat(i?.pubDate)}</span>
                        </ListGroupItem>
                      );
                    })
                  ) : (
                    <div className="no_data_text_style">{t('NEWS_FEED.NO_DATA')}</div>
                  )}
                </ListGroup>
              </InfiniteScroll>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};
export default ExternalNews;
