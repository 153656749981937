import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Button from 'reactstrap-button-loader';
import { reduxForm } from 'redux-form';
import {
  Form,
  Card,
  Container,
  Col,
  FormFeedback,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  Input,
  Label,
  Row,
} from 'reactstrap';
import { ToastContainer } from 'react-toastify';
import { t } from 'react-multi-lang';
import '../../assets/CSS/login.css';
import LeftImg from '../../assets/Images/left-vector-login.png';
import RightImg from '../../assets/Images/right-vector-login.png';
import SADMS from '../../assets/Images/SADMS-logo.png';
import { showError } from '../../Components/Notifications.js';
import {
  getToken,
  isValidEmail,
  signIn,
  hasPermissions,
  hasStaffUserPermission,
} from '../../Components/common/utils';
import { loginUser, loginUserSuccess } from '../../redux/login/loginActions';

const LoginPage = props => {
  const dispatch = useDispatch();
  let history = useHistory();
  const { handleSubmit} = props;
  const [password, setPassword] = useState('');
  const [signInButtonDisabled, setSignInButtonDisabled] = useState(true);
  const [email, setEmail] = useState('');
  const [emailRequired, setEmailRequired] = useState(false);
  const [passwortType, setPasswordType] = useState(true);
  const [passwordRequired, setPasswordRequired] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const [previourUrlPath,setPreviousUrlPath] = useState('')
  const [ddsKey,setDdsKey] = useState('')
  const nextProps = useSelector(state => ({
    loginData: state.Login.loginData || null,
    isLoginRequesting: state.Login.isLoginRequesting,
    loginDataError: state.Login.loginDataError,
  }));
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const myParam = urlParams.get('prevPath');
    const keyParam = urlParams.get('key');
    if (myParam) setPreviousUrlPath(myParam)
    if (keyParam) setDdsKey(keyParam)
    if (getToken()) {
      history.push('/home');
    } 
  }, []);

  const isFirstRun = useRef(true);
  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }
    if (nextProps.loginData) {
      let tokenData = {};
      tokenData = {
        token: nextProps.loginData.data.result.token,
        userDetails: nextProps.loginData.data.user_details,
      };
      signIn(tokenData);
      hasPermissions(nextProps.loginData.data.user_details[0].is_superuser);
      hasStaffUserPermission(nextProps.loginData.data.user_details[0].is_staff);
      if (nextProps.loginData.data.user_details[0].is_superuser) {
        history.push('/settingsDashboard');
      } else {
        if (previourUrlPath) {
          if (ddsKey) history.push('/'+ previourUrlPath + '?' + "key=" + ddsKey)
          else history.push('/'+previourUrlPath)
        }
        else history.push('/home');
      }
    } else {
      history.push('/login');
    }
  }, [nextProps.loginData]);

  useEffect(() => {
    if (
      nextProps.loginDataError &&
      nextProps.loginDataError.data &&
      nextProps.loginDataError.data.response &&
      nextProps.loginDataError.data.response.data &&
      nextProps.loginDataError.data.response.data.code
    ) {
      showError(t('LOGIN.EMAIL_PASSWORD_ERROR_MESSAGE'));
    }
  }, [nextProps.loginDataError]);

  useEffect(() => {
    return () => {
      dispatch(loginUserSuccess({}));
    };
  }, []);

  useEffect(() => {
    if (email === '' || password === '' || emailRequired || passwordRequired) {
      setSignInButtonDisabled(true);
    } else {
      setSignInButtonDisabled(false);
    }
  }, [email, password, emailRequired, passwordRequired]);
  const handleLoginClick = () => {
    let formDetails = {};
    formDetails = {
      email: email,
      password: password,
    };
    dispatch(loginUser(formDetails));
  };
  const onSubmit = formProps => {
    let formDetails = {};
    formDetails = {
      email: email,
      password: password,
    };
    dispatch(loginUser(formDetails));
  };
  const handleChangeEmail = e => {
    setEmail(e.target.value.trim());
    if (!isValidEmail(e.target.value.trim())) {
      if (e.target.value === '') {
        setErrorMessage(t('LOGIN.REQUIRED_EMAIL_ERROR'));
      } else {
        setErrorMessage(t('LOGIN.EMAIL_ERROR_MSG'));
      }
      setEmailRequired(true);
      setSignInButtonDisabled(true);
    } else {
      setEmailRequired(false);
    }
  };

  const showPassword = () => {
    setPasswordType(!passwortType);
  };

  const handleChangePassword = e => {
    if (e.target.value === '') {
      setPasswordRequired(true);
      setSignInButtonDisabled(true);
    } else {
      setPasswordRequired(false);
    }
    setPassword(e.target.value);
  };

  const handleForgotPassword = () => {
    history.push('/forgot-password');
  };

  return (
    <>
      <ToastContainer />
      <div className="login-wrapper">
        <Container fluid className="p-0">
          <Row>
            <Col lg={5} sm={7} xs={12} className="mx-auto login-form-wrapper login-zIndex w-100">
              <div className="login-card-wrapper" id="login-card-wrapper">
                <Card className="card-shadow py-4 px-5 login-card">
                  <div className="login-form w-100">
                    <div className="pb-3">
                      <img src={SADMS} alt="logo" className="d-none" />
                      <h5 className="login-key-text mt-5">{t('LOGIN.LOGIN_TITLE')}</h5>
                    </div>
                    <Form className="login-form" onSubmit={handleSubmit(onSubmit)}>
                      <FormGroup className="textOnInput mb-3">
                        <Label htmlFor="exampleEmail">{t('LOGIN.EMAIL')}</Label>
                        <Input
                          type="text"
                          name="email"
                          defaultValue={email}
                          onChange={handleChangeEmail}
                          invalid={emailRequired}
                          className="email-input"
                        />
                        <FormFeedback invalid>{errorMessage}</FormFeedback>
                      </FormGroup>
                      <FormGroup className="textOnInput mb-3 password-field-form-group">
                        <InputGroup className="form-login">
                          <Label htmlFor="exampleEmail " className='password-legend' >{t('LOGIN.PASSWORD')}</Label>
                          <Input
                            className="password-input field-border"
                            type={passwortType ? 'password' : 'text'}
                            defaultValue={password}
                            onChange={handleChangePassword}
                            invalid={passwordRequired}
                          />
                          <InputGroupAddon addonType="append">
                            <span
                              className={passwordRequired ? 'show-hide-with-icon' : 'show-hide'}
                              onClick={showPassword}
                            >
                              {passwortType ? t('LOGIN.SHOW') : t('LOGIN.HIDE')}
                            </span>
                          </InputGroupAddon>
                          <FormFeedback invalid={passwordRequired}>
                            {t('LOGIN.REQUIRED_PASSWORD_ERROR')}
                          </FormFeedback>
                        </InputGroup>
                      </FormGroup>
                      <div>
                        <FormGroup>
                          <Label className="forgot-password-label">
                            <p
                              className="forgot-password-link"
                              onClick={() => handleForgotPassword()}
                            >
                              {t('LOGIN.FORGOT_PASSWORD')}
                            </p>
                          </Label>
                        </FormGroup>
                      </div>
                      <Button
                        className="btn login-btn-grn-key btn-block w-100"
                        loading={nextProps.isLoginRequesting}
                        onClick={handleLoginClick}
                        disabled={signInButtonDisabled}
                        type="submit"
                      >
                        {t('LOGIN.SIGN_IN')}
                      </Button>

                      <div className="text-center w-100 mb-3">
                        <p className="m-0 p-0 login-term-text mt-3 cursor-ponter">
                          {t('LOGIN.HAVE_ACCOUNT')}{' '}
                          <a
                            href="javascript:void(0)"
                            onClick={() => history.push('/register')}
                            className="register-link"
                          >
                            {t('LOGIN.SIGN_UP')}
                          </a>
                        </p>
                      </div>
                    </Form>
                  </div>
                </Card>
              </div>
            </Col>
          </Row>
          <div className="login-footer">
            <img className="img-fluid login-vector-left" alt="" src={LeftImg} />
            <img className="img-fluid login-vector-right" alt="" src={RightImg} />
          </div>
        </Container>
      </div>
    </>
  );
};
export default reduxForm({ form: 'simple' })(LoginPage);
