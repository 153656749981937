import React from 'react';
import { Card, CardBody, Col, Row } from 'reactstrap';
import { Field, reduxForm, change } from 'redux-form';
import { useDispatch } from 'react-redux';
import _ from 'lodash';
import {
  renderReactSelectInputField,
  renderTextField,
} from '../../Components/common/renderInputField';
import Button from 'reactstrap-button-loader';
import { t } from 'react-multi-lang';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';
import {DATE_FORMAT} from '../../Components/constants'
import moment from 'moment';
const Filter = props => {
  const {
    isHideBar,
    setHideBar,
    categoryList,
    reset,
    handleReset,
  } = props;

  // const nextProps = useSelector(state => ({
  //   isGetAllNewsFeedRequesting: state.NewsFeed.isGetAllNewsFeedRequesting,
  // }));
  const dispatch = useDispatch();
  const renderAPIParameterOptions = (list, action_name) => {
    if (list) {
      let params = [...list];
      let options = [];
      if (params.length) {
        if (action_name === 'category') {
          let default_option = { value: 'select', label: 'select', id: 'select' };
          options.push(default_option);
        }
        params.map(param => {
          let data = {
            value: param.name,
            label: `${param.name}`,
            id: param.id,
          };
          options.push(data);
          return true;
        });
        return options;
      }
    }
  };

  const handleFilterCategoryChange = data => {
    dispatch(change('FilterForm', 'filter_type', ''));
    props.handleFilterCategoryChange(data);
  };

  const handleFilterTitleChange = e => {
    props.filterTitleChange(e?.target?.value);
  };
  const handleFilterDate = e => {
    props?.filterDateHandler(e?.target?.value);
  };
  const handleClearFilters = () => {
    reset('FilterForm');
    handleReset();
  };
  return (
    <>
      {isHideBar ? (
        <div className="news-feed-expand-bar-st">
          <span className="cursor-ponter navyBlueColor ">
            {t('FILTER.EXPAND_BAR')}
            <span>
              <MdKeyboardArrowLeft
                className="hide-icon expand-margin"
                onClick={() => setHideBar(!isHideBar)}
                size={25}
              />
            </span>
          </span>
        </div>
      ) : (
        <Card className="card-style">
          <CardBody className="add_static_resource">
            <form id="FilterForm">
              <Row className="">
                {!isHideBar && (
                  <Col md={12} lg={12} className="filter-col-st filter-news-col pr-5">
                    <div className="hide-bar">
                      <span className="cursor-ponter navyBlueColor ">
                        {t('FILTER.HIDE_BAR')}
                        <span>
                          <MdKeyboardArrowRight
                            className="hide-icon expand-margin"
                            onClick={() => setHideBar(!isHideBar)}
                            size={25}
                          />
                        </span>
                      </span>
                    </div>
                  </Col>
                )}
              </Row>
              <div className="static-resource-filter-field pt-3">
                <Field
                  component={renderReactSelectInputField}
                  name="filter_category"
                  placeholder={t('NEWS_FEED.SELECT_CATEGORY')}
                  className="my-3 filter-select-placeholder"
                  onChange={e => handleFilterCategoryChange(e)}
                  required={true}
                  isMulti={false}
                  options={renderAPIParameterOptions(categoryList, 'category')}
                ></Field>
                <Field
                  component={renderTextField}
                  name="filter_title"
                  placeholder="Title"
                  className="my-3 filter-select-placeholder"
                  onChange={e => handleFilterTitleChange(e)}
                  required={true}
                  isMulti={false}
                ></Field>
                <Field
                  component={renderTextField}
                  name="filter_date"
                  bsSize="sm"
                  className="mt-1 my-3 input-style filter-select-placeholder news-feed-date"
                  placeholder="date"
                  type="date"
                  isMulti={false}
                  onChange={e => handleFilterDate(e)}
                  max= {moment().format(DATE_FORMAT)}
                ></Field>
                {/* /// */}
                <div className='btn-filter'>
                  <Button
                    className="btn st-btn-custom mx-4 manage-news-feed-filter-btn"
                    // loading={nextProps.isGetAllNewsFeedRequesting}
                    onClick={() => handleClearFilters()}
                  >
                    {t('FILTER.CLEAR_FILTERS')}
                  </Button>
                </div>
              </div>
            </form>
          </CardBody>
        </Card>
      )}
    </>
  );
};
export default reduxForm({
  form: 'FilterForm',
  enableReinitialize: true,
})(Filter);
