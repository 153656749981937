import {
  STATIC_RESOURCE_CONST,
  STATIC_RESOURCE_ADD_CONST,
  UPDATE_STATIC_RESOURCE_CONST,
  DELETE_STATIC_RESOURCE_CONST,
  STATIC_RESOURCE_ALL_DATE_CONST,
  STATIC_RESOURCE_YEAR_CONST
} from './staticResourceConst';

let initialState={
  isStaticResourceRequesting: false,
  isStaticAllDateResourceRequesting: false,
  isStaticResourceAddRequesting: false,
}

const staticResource = (state = initialState, action) => {
  switch (action.type) {
    case STATIC_RESOURCE_CONST.STATIC_RESOURCE_REQUEST:
      return {
        ...state,
        isStaticResourceRequesting: true,
        staticResourceData: false,
        staticResourceDataError: false,
        staticResourceDataNetworkError: false,
        id: action.payload,
      };
    case STATIC_RESOURCE_CONST.STATIC_RESOURCE_SUCCESS:
      return {
        ...state,
        isStaticResourceRequesting: false,
        staticResourceData: action.payload.response,
        staticResourceDataError: false,
        staticResourceDataNetworkError: false,
      };
    case STATIC_RESOURCE_CONST.STATIC_RESOURCE_FAILED:
      return {
        ...state,
        isStaticResourceRequesting: false,
        staticResourceData: false,
        staticResourceDataError: action.payload.error ? false : action.payload.response,
        staticResourceDataNetworkError: action.payload.error ? action.payload.error : false,
      };

    case STATIC_RESOURCE_ALL_DATE_CONST.STATIC_RESOURCE_ALL_DATE_REQUEST:
      return {
        ...state,
        isStaticAllDateResourceRequesting: true,
        staticResourceAllDateData: false,
        staticResourceAllDateDataError: false,
        staticResourceAllDateDataNetworkError: false,
        id: action.payload,
      };
    case STATIC_RESOURCE_ALL_DATE_CONST.STATIC_RESOURCE_ALL_DATE_SUCCESS:
      return {
        ...state,
        isStaticAllDateResourceRequesting: false,
        staticResourceAllDateData: action.payload.response,
        staticResourceAllDateDataError: false,
        staticResourceAllDateDataNetworkError: false,
      };
    case STATIC_RESOURCE_ALL_DATE_CONST.STATIC_RESOURCE_ALL_DATE_FAILED:
      return {
        ...state,
        isStaticAllDateResourceRequesting: false,
        staticResourceAllDateData: false,
        staticResourceAllDateDataError: action.payload.error ? false : action.payload.response,
        staticResourceAllDateDataNetworkError: action.payload.error ? action.payload.error : false,
      };

    case STATIC_RESOURCE_ADD_CONST.STATIC_RESOURCE_ADD_REQUEST:
      return {
        ...state,
        isStaticResourceAddRequesting: true,
        staticResourceAddData: false,
        staticResourceAddDataError: false,
        staticResourceAddDataNetworkError: false,
      };
    case STATIC_RESOURCE_ADD_CONST.STATIC_RESOURCE_ADD_SUCCESS:
      return {
        ...state,
        isStaticResourceAddRequesting: false,
        staticResourceAddData: action.payload.response,
        staticResourceAddDataError: false,
        staticResourceAddDataNetworkError: false,
      };
    case STATIC_RESOURCE_ADD_CONST.STATIC_RESOURCE_ADD_FAILED:
      return {
        ...state,
        isStaticResourceAddRequesting: false,
        staticResourceAddData: false,
        staticResourceAddDataError: action.payload.error ? false : action.payload.response,
        staticResourceAddDataNetworkError: action.payload.error ? action.payload.error : false,
      };

    case UPDATE_STATIC_RESOURCE_CONST.UPDATE_STATIC_RESOURCE_REQUEST:
      return {
        ...state,
        isUpdateStaticResourceRequesting: true,
        updateStaticResourceData: false,
        updateStaticResourceDataError: false,
        updateStaticResourceDataNetworkError: false,
      };
    case UPDATE_STATIC_RESOURCE_CONST.UPDATE_STATIC_RESOURCE_SUCCESS:
      return {
        ...state,
        isUpdateStaticResourceRequesting: false,
        updateStaticResourceData: action.payload.response,
        updateStaticResourceDataError: false,
        updateStaticResourceDataNetworkError: false,
      };
    case UPDATE_STATIC_RESOURCE_CONST.UPDATE_STATIC_RESOURCE_FAILED:
      return {
        ...state,
        isUpdateStaticResourceRequesting: false,
        updateStaticResourceData: false,
        updateStaticResourceDataError: action.payload.error ? false : action.payload.response,
        updateStaticResourceDataNetworkError: action.payload.error ? action.payload.error : false,
      };

    case DELETE_STATIC_RESOURCE_CONST.DELETE_STATIC_RESOURCE_REQUEST:
      return {
        ...state,
        isDeleteStaticResourceRequesting: true,
        deleteStaticResourceData: false,
        deleteStaticResourceDataError: false,
        deleteStaticResourceDataNetworkError: false,
      };
    case DELETE_STATIC_RESOURCE_CONST.DELETE_STATIC_RESOURCE_SUCCESS:
      return {
        ...state,
        isDeleteStaticResourceRequesting: false,
        deleteStaticResourceData: action.payload.response,
        deleteStaticResourceDataError: false,
        deleteStaticResourceDataNetworkError: false,
      };
    case DELETE_STATIC_RESOURCE_CONST.DELETE_STATIC_RESOURCE_FAILED:
      return {
        ...state,
        isDeleteStaticResourceRequesting: false,
        deleteStaticResourceData: false,
        deleteStaticResourceDataError: action.payload.error ? false : action.payload.response,
        deleteStaticResourceDataNetworkError: action.payload.error ? action.payload.error : false,
      };


      case STATIC_RESOURCE_YEAR_CONST.STATIC_RESOURCE_YEAR_REQUEST:
        return {
          ...state,
          isStaticResourceYearRequesting: true,
          staticResourceYearData: false,
          staticResourceYearDataError: false,
          staticResourceYearDataNetworkError: false,
        };
      case STATIC_RESOURCE_YEAR_CONST.STATIC_RESOURCE_YEAR_SUCCESS:
        return {
          ...state,
          isStaticResourceYearRequesting: false,
          staticResourceYearData: action.payload.response,
          staticResourceYearDataError: false,
          staticResourceYearDataNetworkError: false,
        };
      case STATIC_RESOURCE_YEAR_CONST.STATIC_RESOURCE_YEAR_FAILED:
        return {
          ...state,
          isStaticResourceYearRequesting: false,
          staticResourceYearData: false,
          staticResourceYearDataError: action.payload.error ? false : action.payload.response,
          staticResourceYearDataNetworkError: action.payload.error ? action.payload.error : false,
        };

    default:
      return { ...state };
  }
};

export default staticResource;
