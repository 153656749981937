import axios from 'axios';
import Cookies from 'js-cookie';

require('dotenv').config();

export const AXIOS_INSTANCE = axios.create({
  headers: {
    Accept: 'application/json',
  },
});

AXIOS_INSTANCE.interceptors.request.use(function(config) {
  if (Cookies.get('user')) {
    const token = `Bearer  ${Cookies.get('user')}`;
    config.headers.Authorization = token;
    // config.headers.ContentType = 'multipart/form-data'
  }
  return config;
}); 

//API URL
export const API_BASE_URL = `${process.env.REACT_APP_API_URL}`;

export const WEATHER_URL = `${process.env.WEATHER_API_URL}`;

export const MAP_ACCESS_TOKEN = `${process.env.REACT_APP_MAP_ACCESS_TOKEN}`;

export const OPEN_WEATHER_API_KEY = `${process.env.REACT_APP_OPENWEATHER_APP_KEY}`;

export const LOCATION_URL = `${API_BASE_URL}/location/get-location`;
export const LOCATION_URL_BO = `${API_BASE_URL}/location/get-location-bo`;
export const DATA_SOURCES = `${API_BASE_URL}/weather/get-forecast-data-sources`;
export const LOCATION_CONVENTION = `${API_BASE_URL}/location/location-convention`;

export const HOME_URL = `${API_BASE_URL}/home`;
export const USER_URL = `${API_BASE_URL}/user`;
export const PERMISSIONS_URL = `${API_BASE_URL}/permission`;
export const STATIC_RESOURCE_URL = `${API_BASE_URL}/static-resource`;
export const MANAGE_CROPS_URL = `${API_BASE_URL}/crop-season`;
export const CONTINGENCY_PLAN_URL = `${API_BASE_URL}/contingency-plan`;
export const NEWS_FEED_URL = `${API_BASE_URL}/newsfeed`;
export const USER_GUIDE_URL = `${API_BASE_URL}/user-guide`;

export const BASE_LAYER = `${API_BASE_URL}/weather/get-boundaries`;

export const WEATHER_FORECAST_DATA_URL = `${API_BASE_URL}/weather/forecast-data`;
export const NOAA_TIMESERIES = `${API_BASE_URL}/weather/noaa/timeseries`;
export const ERPAS_TIMESERIES = `${API_BASE_URL}/weather/erpas/timeseries`;
export const IRI_TIMESERIES = `${API_BASE_URL}/weather/iri/timeseries`;

export const WEATHER_MAP_PREFERENCE = `${API_BASE_URL}/weather/get-map-preference-data`;

export const ACCESS_KEY_URL = `${API_BASE_URL}/accesskey`;
export const RASTER_LAYER_URL = `${API_BASE_URL}/weather/get-map-preference-data`;

export const GOOGLE_LOCATION_API_KEY = `${process.env.REACT_APP_LOCATION_API_KEY}`

export const PUBLIC_API_URL = `${API_BASE_URL}/public-api`;
export const DROUGHT_MONITOR_URL = `${API_BASE_URL}/drought-monitor`;
export const INDICES_API_URL = `${API_BASE_URL}/public-api/getIndices/drought_monitoring`;

export const BULLETIN_URL = `${API_BASE_URL}/bulletin`;
export const DROUGHT_DECISION_SUPPORT_URL = `${API_BASE_URL}/drought-decision-support`;

export const BACK_OFFICE_URL = `${process.env.REACT_APP_API_IP_URL}/bulletin`
