import React from 'react';
import { Row, Col } from 'reactstrap';
import { t } from 'react-multi-lang';
import CGIAR from '../../../assets/Images/cgairClimateResilience.png';
import MEE_IMAGE from '../../../assets/Images/ZMA.png';
import IWMI from '../../../assets/Images/iwmi.png';
 import { GrMail } from 'react-icons/gr';
import { BsGlobe } from 'react-icons/bs';

function BulletinFooter() {
  const contactPersons = [
    { name: 'Dr. Giriraj Amarnath', email: 'a.giriraj@cgiar.org', icon: GrMail},
    // { name: 'Dr. Niranga Alahacoon', email: 'n.alahacoon@cgiar.org', icon:GrMail},
  ];

  const contactSite = {
      name: 'Website',
      email: 'ZADMS Drought Monitor',
      link: 'https://13.126.101.46/drought-monitor?key=drought-monitor',
      icon: BsGlobe,
    }

  const copyright ="@ CopyRights 2023 ZADMS"

  return (
    <>
    <div className="ty-div">
    <h4 className="title-ty">{t('ONLINE_BULLETIN.BULLETIN_FOOTER.TITLE')}!</h4>
    <Row >
      <Col md={1}>
        <p className="text-right">{t('ONLINE_BULLETIN.BULLETIN_FOOTER.DISCLAIMER')} :</p>
        </Col>
        <Col md={10}>
        <p className="text-left">{t('ONLINE_BULLETIN.BULLETIN_FOOTER.STATIC')}</p>
        </Col>
      </Row>
      </div>
    <div className="bulletin-footer pb-2 px-4 pt-4">
   
      <Row className="title">
        {/* <Col md={1} className="line-width1"> <div className="vl-line"></div></Col> */}
        <Col md={5}>
        <h6 style={{fontSize:"20px"}}>{t('ONLINE_BULLETIN.BULLETIN_FOOTER.FURTHUR_INFO')}</h6>
          {contactPersons.map(item => {
            return (
              <div className="contacts" key={item.email}>
                <p>
                  <span className="pr-4 contact-icon">
                    <item.icon />
                  </span>{' '}
                  {item.name} :{' '}
                  {/* <a
                    href={item.link || myConstClass.CGIAR_RESOURCES_URL}
                    target="_blank"
                    rel="noreferrer"
                  > */}
                    {item.email}
                  {/* </a> */}
                </p>
              </div>
            );
          })}
        </Col>
        <Col md={6} className="img-div text-center">
          <ul className="list-unstyled d-flex  justify-content-end">
            <li>
                <img src={MEE_IMAGE} className="preview-image1" alt="cgair_wle" />{' '}
            </li>

            <li>
                <img src={CGIAR} className="preview-image1" alt="sadms" />{' '}
            </li>
            <li>
                <img src={IWMI} className="preview-image1" alt="iwmi" />{' '}
            </li>
          </ul>
        </Col>
      </Row>
      <Row className="d-flex pt-3 div-footer">
        <Col md={6}>
          <h6 className='contacts1'>{copyright}</h6>
        </Col>
        <Col md={6}>
        <div className="contacts1 space-div">
                <p>
                  <span className="pr-4 contact-icon">
                    <contactSite.icon />
                  </span>{' '}
                  {contactSite.name} :{' '}
                  {/* <a
                    href={contactSite.link || myConstClass.CGIAR_RESOURCES_URL}
                    target="_blank"
                    rel="noreferrer"
                  > */}
                    {contactSite.email}
                  {/* </a> */}
                </p>
              </div>
        </Col> 
      </Row>
    </div>
    </>
  );
}

export default BulletinFooter;
