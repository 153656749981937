
import React, { useState,  memo } from 'react';
import { FiCheck } from 'react-icons/fi';
import { GrFormClose } from 'react-icons/gr';
import { FaEdit } from 'react-icons/fa';
import _ from 'lodash';
import { Input } from 'reactstrap';



function IndicatorsInfo ({indicatorRows,selectedIndicator,setSelectedIndicator, rowKey}) {

  const [isTextEdit, setIsTextEdit] = useState(false);

  


  const INDICATORS_INFO = {
    'NDVI':"NDVI stands for Normalize Difference Vegetation Index.Although there are, several vegetation indices, one of the most widely used is the Normalized Difference Vegetation Index (NDVI).",
    'Crop Cover': "This is Crop Cover (MODIS, S1) API.",
    'VCI':"VCI stands for Vegetation Condition Index.Pixel-based VCI calculation is more effective to identify the drought condition irrespective of the ecological region.",
    'Extreme Rainfall':"This is Extreme Rainfall API.",
    'Accumulated Rainfall':"This is accumulated rainfall indices.",
  }
  const [isText,setIsText] = useState(INDICATORS_INFO);

 const editInfo = (row) =>{
  setSelectedIndicator(row)
  setIsTextEdit(true)
 }

 const handleTextEdit =(value, indicatorName)=>{
  let updatedText = _.cloneDeep(isText);
  if(!_.isEmpty(updatedText)){
    setIsText(prev => ({
      ...prev,
      [indicatorName] : value
    }));
  }
 }

  
    return(
      <div className="info-div">
        <h6 className="text-div">Indicators Basic Info:</h6>
        <div className="hr-lines"></div>
            <div className="info-div" key={rowKey}>
              {indicatorRows[rowKey]?.indicatorsList.map((eachIndicator,index)=>{
                let defaultText = INDICATORS_INFO[eachIndicator.name]
                let indicator = `${rowKey}.${eachIndicator.name}`
                return(<>
                  { indicator === selectedIndicator && isTextEdit  ? ( 
                    <div className="d-flex">
                      <Input
                        value={isText[eachIndicator.name]}
                        title="Text"
                        placeholder="Enter Title"
                      onChange={e => handleTextEdit(e.target.value, eachIndicator.name)}
                      //defaultValue={defaultText}
                      />
                      <FiCheck
                        size={30}
                        onClick={() => setIsTextEdit(false)}
                        className="cursor-pointer green-icon mx-2 mt-1"
                      />
                      <GrFormClose
                        size={30}
                        color="#e86933"
                        className="cursor-pointer danger-icon mt-1"
                       onClick={() => {
                        setIsText(prev => ({
                          ...prev,
                          [eachIndicator.name] : defaultText
                        }));
                        setSelectedIndicator("");
                       }}
                      />
                    </div>
                  ):(
                    <div key={eachIndicator.name}>
                    <p>{eachIndicator.name} : {isText[eachIndicator.name]}
                    <FaEdit
                id={`${rowKey}.${eachIndicator.name}`}
                color="#dd9103"
                size={18}
                className="cursor-pointer mx-2 indicator-icon"
                onClick={() => editInfo(`${rowKey}.${eachIndicator.name}`)}
              /></p>
              </div>
              )}
              </> )
              })}
              </div>
              </div>

    )
      
      }

export default memo(IndicatorsInfo);
