import React from 'react';
import ReactDOM from 'react-dom';
import { Route, BrowserRouter } from 'react-router-dom';
import './index.css';
import { Provider } from 'react-redux';
// import App from './App';
import reportWebVitals from './reportWebVitals';
import ViewsLayout from './layouts';
import configureStore from './redux/store';
import 'bootstrap/dist/css/bootstrap.min.css';
import { setTranslations, setDefaultLanguage } from 'react-multi-lang';
import en from './translations/en.json';
import './assets/CSS/Header.css';
import './assets/CSS/Filter.css';
import './assets/CSS/Common.css';
import './assets/CSS/Custom.css';
import './assets/CSS/NewsFeed.css';
import 'font-awesome/css/font-awesome.min.css';
import './assets/scss/theme.scss';
setTranslations({ en });
setDefaultLanguage('en');

ReactDOM.render(
  <Provider store={configureStore()}>
    <BrowserRouter>
      <Route path="/" component={ViewsLayout} />
    </BrowserRouter>
  </Provider>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
