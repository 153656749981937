// eslint-disable-next-line no-unused-vars
import React, { useEffect, useState, Fragment } from 'react';
import { Route, Switch, Redirect,  withRouter  } from 'react-router-dom';
import { Container } from 'reactstrap';
import Header from '../Components/common/Header';
import Routes from '../routes/routes';
//import { getToken } from "../Components/common/utils"; 
import { ToastContainer } from 'react-toastify';
import ReactGA from "react-ga4";


if (window.location.hostname === "zadmsdemo.iwmi.org")ReactGA.initialize("G-F611FCPMFV");


const  Layout = () => {
  // eslint-disable-next-line no-unused-vars
  const [isLoaderVisible, setIsLoaderVisible] = useState(false);
  const setLoader = (isLoading) => {
    setIsLoaderVisible(isLoading);
  };
  useEffect(() => {
    setIsLoaderVisible(false);
    setLoader();
  }, []);

  useEffect (() => {
    if (window.location.hostname === "zadmsdemo.iwmi.org") ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search  });
  })

  const getRoutes = (routeItems) => {
    const routes = routeItems.map((prop, key) => {
      return (
        <Route
          path={prop.path}
          // render={() => <prop.component loader={setLoader} />}
          component={prop.component}
          // eslint-disable-next-line react/no-array-index-key
          key={key}
        />
      );
    });
    return routes;
  };
  const containerStyle = {
    display: 'block',
    padding: '0px',
  };
  return (
    <>
    <Fragment>
    {isLoaderVisible ? <span>loader</span> : ""}
      <Container className="container-layout" fluid style={containerStyle}>
       {/* {getToken ? <Header showProfile={true}/> : <Header showProfile={false}/> } */}
       <Header />   
         <Switch>
          {getRoutes(Routes)}
          <Redirect from="/" to="/home" />
        </Switch>
      </Container>
    </Fragment>
    <ToastContainer />
    </>
  );
}

export default withRouter(Layout)