import React, { memo, useState,useEffect } from 'react';
import { FaEdit } from 'react-icons/fa';
import { t } from 'react-multi-lang';
import { Input, Row, Col } from 'reactstrap';
import { FiCheck } from 'react-icons/fi';
import { GrFormClose } from 'react-icons/gr';
import { getBulletinDateFormat,getBulletinMonthFormat } from '../../../Components/helpers';
import MEE_IMAGE from '../../../assets/Images/online-bulletin-img-1.png';
import CGIAR from '../../../assets/Images/online-bulletin-img-2.png';
import IWMI from '../../../assets/Images/online-bulletin-img-3.png';
import PlusIcon from '../../../assets/Images/ic_round-plus.png';
import CrossIcon from '../../../assets/Images/maki_cross.png';
 import myConstClass from '../../../Constants';
 import { MONTHLY_BULLETIN } from '../../../Components/constants';
 import Textarea from 'rc-textarea';
 import Header from "../../../assets/Images/headerBoard.png";


 const logoList = [
  {
    id: 0,
    image:MEE_IMAGE,
    url:myConstClass.ZAMBIA_URL,
    name:"cgair_wle",


  },
  {
    id: 1,
    image:CGIAR,
    url:myConstClass.IWMI_URL,
    name:"sadms",
  },
  {
    id: 2,
    image:IWMI,
    url:myConstClass.SADMS_URL,
    name:"iwmi",
  },
]
function Branding({ queryPrams, title,setTitle}) {
  const [isTitleEdit, setIsTitleEdit] = useState(false);
  const [isPreparedBy, setIsPreparedBy] = useState(false);
  const [file, setFile] = useState([]);
  const [shouldHide,setShouldHide] = useState(false);
  const [newLogoList,setNewLogoList]= useState(logoList);



  const defaultTitle =
  queryPrams?.bulletinType === MONTHLY_BULLETIN  ? `${
    queryPrams.district ? queryPrams.district : queryPrams.state ? queryPrams.state : queryPrams.country
  } :
   ${getBulletinMonthFormat(queryPrams.endDate,
  )}`:
  `${
    queryPrams.district ? queryPrams.district : queryPrams.state ? queryPrams.state : queryPrams.country
  } : ${getBulletinDateFormat(queryPrams.startDate)} - ${getBulletinDateFormat(
    queryPrams.endDate,
  )}` ;
   
  function removeImage(id){
    setNewLogoList(newLogoList.filter(item=>item.id !== id))
  }
 

  function uploadSingleFile(e) {
    setFile([...file, URL.createObjectURL(e.target.files[0])]);
    if (file.length ===1){
      setShouldHide(true);
    }
  }

    const removeFile = (e) => {
      const s = file.filter((item, index) => index !== e);
          setFile(s);
          setShouldHide(false);
      };


  const defaultPreparedBy = `${t('ONLINE_BULLETIN.PREPARED_BY')} : `;

 useEffect(()=>{
  setTitle(defaultTitle); 
},[defaultTitle])
 
  const [preparedBy, setPreparedBy] = useState(defaultPreparedBy);



  return (
    <div >
    <Row className=" mx-4 pb-2 px-4 pt-4  d-flex justify-content-center header-div">
      <Col >
        <ul className="list-unstyled d-flex  justify-content-start footer-logos">
        <div className="logo-image">
      {!shouldHide?(
       <div class="file-input-wrapper">
       <img src={PlusIcon} alt="add-image" className="plus-icon indicator-icon"/>
       <input accept="image/*"
          type="file"
          disabled={file.length === 2}
          name="image"
          onChange={uploadSingleFile}
          style={{cursor:'pointer'}}
        />
         </div>
        ):""}
      </div> 
          <li>
          {file.length > 0 &&
          file.reverse().map((item, index) => {
            return (
              <>
              <img src={item} className="preview-image" alt=""/>
              <img src={CrossIcon} className='indicator-icon delete-cross'
                onClick={() => removeFile(index)}/>
                </>)})}
          </li>
          {newLogoList.map(item => (
            <li>
              <img src={item.image} alt={item.name}/>
            <img src={CrossIcon} className='indicator-icon delete-cross'
                onClick={() => removeImage(item.id)}
                />
            </li>
          ))}
        </ul>
      </Col>
    </Row>
    <Row className="d-flex justify-content-between online-bulletin-header-div">
    <Col md={5} className="title-div bulletin-header-text-card ">
        <Col className="title-div d-flex justify-content-start">
          <div className="heading-area ml-1">
            <h4 className="category-type-div">{queryPrams.bulletinType}</h4>
        <h5 className="mb-1 text-div1">
          {isTitleEdit ? (
            <div className="d-flex">
              <Input
                value={title}
                title="Title"
                placeholder="Enter Title"
                onChange={e => setTitle(e.target.value)}
                defaultValue={defaultTitle}
              />
              <FiCheck
                size={30}
                onClick={() => setIsTitleEdit(false)}
                className="cursor-pointer green-icon mx-2 mt-1"
              />
              <GrFormClose
                size={30}
                color="#e86933"
                className="cursor-pointer danger-icon mt-1"
                onClick={() => {
                  setTitle(defaultTitle);
                  setIsTitleEdit(false);
                }}
              />
            </div>
          ) : (
            <span className="title-brand">
              {title}
              <FaEdit
                id="hide-icon-1"
                color="#dd9103"
                size={18}
                className="cursor-pointer mx-2 indicator-icon"
                onClick={() => setIsTitleEdit(true)}
              />
            </span>
          )}

          <h6 className="sub-header text-div1 sub-header-margin">
            {isPreparedBy ? (
              <div className="d-flex">
                <Input
                  value={preparedBy}
                  title="Title"
                  placeholder="Enter Title"
                  onChange={e => setPreparedBy(e.target.value)}
                  defaultValue={defaultPreparedBy}
                />
                <FiCheck
                  size={30}
                  onClick={() => setIsPreparedBy(false)}
                  className="cursor-pointer green-icon mx-2 mt-1"
                />
                <GrFormClose
                  size={30}
                  color="#e86933"
                  className="cursor-pointer danger-icon mt-1"
                  onClick={() => {
                    setPreparedBy(defaultPreparedBy);
                    setIsPreparedBy(false);
                  }}
                />
              </div>
            ) : (
              <span>
                <p className="mb-2 text-div1">
                  {preparedBy}
                  <FaEdit
                    id="hide-icon-2"
                    color="#dd9103"
                    size={18}
                    className="cursor-pointer mx-2 indicator-icon"
                    onClick={() => setIsPreparedBy(true)}
                  />
                </p>
              </span>
            )}
          </h6>
          <p className="text-div2">
              {t('ONLINE_BULLETIN.PREPARED')} {getBulletinDateFormat(new Date())}{' '}
            </p>
        </h5>
       </div>
       </Col>
      </Col>
    </Row>
    <Row>
    <Col md={12}>
    <div className="bulletin-summary">
  
  <h5 className="mt-3 text-div mx-3"><img src={Header}/></h5>
  <Textarea
    autoSize={{ minRows: 40 }}
    onPressEnter={e => e.preventDefault()}
    className="mb-3 mt-2 mx-3 text-area-border-radius text-area-width"
    placeholder={`    Write down something...`}
  />
</div>
                </Col>
    </Row>
    </div>
  );
}

export default memo(Branding);
