import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { AXIOS_INSTANCE, LOCATION_URL, LOCATION_CONVENTION,LOCATION_URL_BO } from '../api/apiEndPoint';
import {
  getCountrySuccess,
  getCountryFailed,
  getStateByCountrySuccess,
  getStateByCountryFailed,
  getDistrictByStateSuccess,
  getDistrictByStateFailed,
  getLocationConventionSuccess,
  getLocationConventionFailed,
  getCountryBoFailed,
  getCountryBoSuccess
} from './actions';
import LOCATION_CONST from './const';

require('dotenv').config();

const ApiRequest = async payLoad => {
  let response = {};
  try {
    const responseData = await AXIOS_INSTANCE.post(LOCATION_URL, payLoad.requestObj);
    response = {
      data: responseData.data,
      config: {
        isCompareLocation: payLoad?.isCompareLocation || false,
      },
      status: 200,
    };
  } catch (error) {
    const data = error;
    response = {
      error: data,
      status: 400,
    };
  }
  return response;
};

const countryApiRequest = async payLoad => {
  let response = {};
  try {
    const responseData = await AXIOS_INSTANCE.post(LOCATION_URL, payLoad);
    response = {
      data: responseData.data,
      status: 200,
    };
  } catch (error) {
    const data = error;
    response = {
      error: data,
      status: 400,
    };
  }
  return response;
};

function* getCountry(action) {
  try {
    const apiResponse = yield call(countryApiRequest, action.payload);
    if (apiResponse.status === 200) {
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.data,
        },
      };
      yield put(getCountrySuccess(responseData));
    } else {
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.error,
        },
      };
      yield put(getCountryFailed(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(getCountryFailed(errorData));
  }
}

function* getState(action) {
  try {
    const apiResponse = yield call(ApiRequest, action.payload);
    if (apiResponse.status === 200) {
      const responseData = {
        response: {
          statusCode: 200,
          config: apiResponse.config,
          data: apiResponse.data,
        },
      };
      yield put(getStateByCountrySuccess(responseData));
    } else {
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.error,
        },
      };
      yield put(getStateByCountryFailed(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(getStateByCountryFailed(errorData));
  }
}
function* getDistrict(action) {
  try {
    const apiResponse = yield call(ApiRequest, action.payload);
    if (apiResponse.status === 200) {
      const responseData = {
        response: {
          statusCode: 200,
          config: apiResponse.config,
          data: apiResponse.data,
        },
      };
      yield put(getDistrictByStateSuccess(responseData));
    } else {
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.error,
        },
      };
      yield put(getDistrictByStateFailed(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(getDistrictByStateFailed(errorData));
  }
}

const LocationConventionApiRequest = async payLoad => {
  let response = {};
  try {
    const responseData = await AXIOS_INSTANCE.get(`${LOCATION_CONVENTION}/${payLoad}`);
    response = {
      data: responseData.data,
      status: 200,
    };
  } catch (error) {
    const data = error;
    response = {
      error: data,
      status: 400,
    };
  }
  return response;
};

function* getLocationConvention(action) {
  try {
    const apiResponse = yield call(LocationConventionApiRequest, action.payload);
    if (apiResponse.status === 200) {
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.data.result,
        },
      };
      yield put(getLocationConventionSuccess(responseData));
    } else {
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.error,
        },
      };
      yield put(getLocationConventionFailed(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(getLocationConventionFailed(errorData));
  }
}


const countryBoApiRequest = async payLoad => {
  let response = {};
  try {
    const responseData = await AXIOS_INSTANCE.post(LOCATION_URL_BO, payLoad);
    response = {
      data: responseData.data,
      status: 200,
    };
  } catch (error) {
    const data = error;
    response = {
      error: data,
      status: 400,
    };
  }
  return response;
};

function* getCountryBo(action) {
  try {
    const apiResponse = yield call(countryBoApiRequest, action.payload);
    if (apiResponse.status === 200) {
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.data,
        },
      };
      yield put(getCountryBoSuccess(responseData));
    } else {
      const responseData = {
        response: {
          statusCode: 200,
          data: apiResponse.error,
        },
      };
      yield put(getCountryBoFailed(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(getCountryBoFailed(errorData));
  }
}

export function* watchCountry() {
  yield takeEvery(LOCATION_CONST.COUNTRY_LIST_REQUEST, getCountry);
}
export function* watchState() {
  yield takeEvery(LOCATION_CONST.STATE_LIST_REQUEST, getState);
}
export function* watchDistrict() {
  yield takeEvery(LOCATION_CONST.DISTRICT_LIST_REQUEST, getDistrict);
}
export function* watchLocationConvention() {
  yield takeEvery(LOCATION_CONST.LOCATION_CONVENTION_REQUEST, getLocationConvention);
}
export function* watchCountryBo() {
  yield takeEvery(LOCATION_CONST.COUNTRY_LIST_BO_REQUEST, getCountryBo);
}

function* locationSaga() {
  yield all([
    fork(watchCountry),
    fork(watchState),
    fork(watchDistrict),
    fork(watchLocationConvention),
    fork(watchCountryBo)
  ]);
}

export default locationSaga;
